import parse from 'csv-parse';

const DMACColumns = [
  'Last_Name',
  'First_Name',
  'Admin_Grade',
  'Admin_CampusId',
  'Admin_Campus',
  'Current_Campusid',
  'Current_Campus',
  'Fall_CampusId',
  'Fall_Campus',
  'Admin_Date',
  'Subject',
  'Course',
  'Curr',
  'Score_Code',
  'Disrupt',
  'Raw_Score',
  'Scale_Score',
  'stustd',
  'approaches',
  'meets',
  'masters',
  'Test_Version',
  'Language',
  'Pcnt_Score',
  'Local_Use',
  'CRLocalId',
  'TestLocalId',
  'UAC',
  'CRStateId',
  'TestStateId',
  'growth',
  'Met110',
  'ell',
  'firsttime',
  'Lexile',
  'Percentile',
  'Quantile',
  'dob',
  'agencyusec',
  'agencyused',
  'engiireraw',
  'engiireperf',
  'engiiwrraw',
  'engiiwrperf',
  'algperf',
  'bioperf',
  'engiiperf',
  'ushistperf',
];

const isDMAC = (file) => {
  console.log(`isDMAC ${file.type}`);
  return new Promise((resolve) => {
    console.log(`isDMAC ${file.type}`);
    if (
      file.type !== 'application/vnd.ms-excel' &&
      file.type !== 'text/csv' &&
      file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      resolve(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      parse(text, {}, (err, output) => {
        if (err) {
          resolve(false);
          return;
        }
        let fileHeader = output[0];
        if (JSON.stringify(fileHeader) === JSON.stringify(DMACColumns)) {
          resolve(true);
        } else if (isValideDMACHeaders(fileHeader)) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    };
    reader.readAsText(file);
  });
};

const isValideDMACHeaders = (csvHeaders) => {
  const expectedHeadersRegex = [
    /^StateId$/,
    /^LocalId$/,
    /^FirstName$/,
    /^LastName$/,
    /^(.+ PcntScore)|(.+ Grade .+)$/,
  ];

  if (csvHeaders.length < expectedHeadersRegex.length) {
    return false;
  }

  for (let i = 0; i < expectedHeadersRegex.length; i++) {
    if (!expectedHeadersRegex[i].test(csvHeaders[i])) {
      return false;
    }
  }

  return true;
};

export default isDMAC;
