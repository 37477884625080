import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Modal,
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  withStyles,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
  TextField,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { LoadingCircle } from '../../common';
import { getDynamicAssessmentModel } from '../+store';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '500px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '3px',
  },
  formControl: {
    display: 'block',
  },
  dataPickerLabel: {
    marginBotton: '16px',
  },
  uploadProgressWrapper: {
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadFormControl: {
    width: '100%',
  },
  uploadFormButtons: {
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'center',
  },
});

class UploadModal extends React.PureComponent {
  static propTypes = {
    uploadStaging: PropTypes.object,
    uploadProgressMsg: PropTypes.string.isRequired,
    classes: PropTypes.any.isRequired,
    onCancelUpload: PropTypes.func.isRequired,
    doUpload: PropTypes.func.isRequired,
    doUnrecognizedFileUpload: PropTypes.func.isRequired,
    assessmentTypes: PropTypes.array.isRequired,
    defaultAssessmentName: PropTypes.string,
  };

  getDefaultAssessmentName = () => {
    const { uploadStaging, defaultAssessmentName } = this.props;
    return uploadStaging && uploadStaging.assessmentType == 'DMACInterim' ? defaultAssessmentName : '';
  };

  state = {
    aName: this.getDefaultAssessmentName(),
    aDate: moment(),
    errors: {},
  };

  handleDateChange = (name) => (date) => {
    console.log('name', name, 'data', date);
    this.setState({
      [name]: date,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onSubmit = (event) => {
    const { doUpload, uploadStaging } = this.props;
    const errors = {};
    const { aName, aDate } = this.state;
    if (!aName) {
      errors.aName = 'This field is required';
    }
    if (!aDate) {
      errors.aDate = 'This field is required';
    }
    this.setState({
      errors,
    });
    if (!Object.values(errors).reduce((curr, prev) => prev || !!curr, false)) {
      doUpload(uploadStaging, { name: aName, date: aDate.format('YYYY-MM-DD') });
    }
    event.preventDefault();
  };

  onUnrecognizedFileUpload = (values) => {
    const { doUnrecognizedFileUpload, uploadStaging } = this.props;
    const { assessmentType, otherType, name, administrationDate } = values;
    if (assessmentType === 'Other') {
      uploadStaging.assessmentType = assessmentType;
      uploadStaging.customType = otherType;
    } else {
      uploadStaging.assessmentType = assessmentType;
    }
    doUnrecognizedFileUpload(uploadStaging, { name, date: administrationDate });
  };

  renderInterimUpload = () => {
    const { classes } = this.props;
    const { aDate, aName, errors } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Typography variant="subtitle1" id="simple-modal-description">
          Please provide a name and date for your assessment.
        </Typography>
        <FormControl className={classes.formControl} error={!!errors.aName}>
          <InputLabel htmlFor="uploadNameInput">Assessment Name</InputLabel>
          <Input id="uploadNameInput" value={aName} onChange={this.handleChange('aName')} />
          {!errors.aName && (
            <FormHelperText>{"We'll use this name to refer to this assessment when generating reports"}</FormHelperText>
          )}
          {!!errors.aName && <FormHelperText>{errors.aName}</FormHelperText>}
        </FormControl>
        <FormControl className={classes.formControl}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              margin="normal"
              label="Date"
              value={aDate}
              onChange={this.handleDateChange('aDate')}
              maxDate={moment()}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">
          Upload
        </Button>
      </form>
    );
  };

  renderUnrecognizedFileUpload = () => {
    const { assessmentTypes, classes, onCancelUpload } = this.props;
    const unrecognizedDateChange = (name, setFieldValue) => (date) => {
      setFieldValue(name, date.format('YYYY-MM-DD'));
    };
    return (
      <Formik
        initialValues={{
          assessmentType: '',
          otherType: '',
          name: '',
          administrationDate: moment().format('YYYY-MM-DD'),
        }}
        validateOnBlur
        validationSchema={Yup.object().shape({
          assessmentType: Yup.string().required('This field is required.'),
          otherType: Yup.string().test({
            name: 'Other',
            message: 'This field is required',
            // eslint-disable-next-line object-shorthand, func-names
            test: function(val) {
              return this.resolve(Yup.ref('assessmentType')) !== 'Other' || !!val;
            },
          }),
          name: Yup.string().required('This field is required.'),
          administrationDate: Yup.string()
            .test('date', 'Please enter a date in the format YYYY-MM-DD', (value) =>
              moment(value, 'YYYY-MM-DD').isValid()
            )
            .required('This field is required'),
        })}
        onSubmit={this.onUnrecognizedFileUpload}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            {console.log('values', values)}
            {console.log('errors', errors)}
            <Typography variant="subtitle1" gutterBottom>
              {// eslint-disable-next-line quotes
              `Please provide a name and file type so we can upload your file and process it as soon as possible.`}
            </Typography>
            <Grid container spacing={24} key="assessmentType" style={{ textAlign: 'left' }}>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" gutterBottom>
                  Type
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Field
                  name="assessmentType"
                  render={({ field }) => (
                    <Select {...field} style={{ width: '100%' }}>
                      {assessmentTypes.map((key) => (
                        <MenuItem key={key} value={key}>
                          {getDynamicAssessmentModel(key).name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
            {values.assessmentType === 'Other' && (
              <Grid container spacing={24} style={{ textAlign: 'left' }}>
                <Grid item xs={12} sm={3}>
                  <Typography variant="subtitle1" gutterBottom>
                    Custom Type
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <FormControl className={classes.uploadFormControl} error={touched.otherType && !!errors.otherType}>
                    <TextField
                      name="otherType"
                      className={classes.uploadFormControl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otherType}
                    />
                    <FormHelperText style={{ paddingRight: 4 }}>
                      {(touched.otherType && errors.otherType) || 'What type of file are you uploading?'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={24} style={{ textAlign: 'left' }}>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" gutterBottom>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControl className={classes.uploadFormControl} error={touched.name && !!errors.name}>
                  <TextField
                    name="name"
                    className={classes.uploadFormControl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <FormHelperText style={{ paddingRight: 4 }}>
                    {(touched.name && errors.name) || 'What would you like name your file?'}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={24} style={{ textAlign: 'left' }}>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" gutterBottom>
                  Date
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControl className={classes.uploadFormControl} error={touched.name && !!errors.name}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      value={values.administrationDate}
                      onChange={unrecognizedDateChange('administrationDate', setFieldValue)}
                      maxDate={moment()}
                    />
                  </MuiPickersUtilsProvider>
                  <FormHelperText style={{ paddingRight: 4 }}>
                    {(touched.name && errors.name) || 'Please select an adminstration date'}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.uploadFormButtons}>
              <Button type="submit" variant="contained" color="primary" style={{ marginLeft: 4, marginRight: 4 }}>
                Upload
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={onCancelUpload}
                style={{ marginLeft: 4, marginRight: 4 }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    const { uploadStaging, uploadProgressMsg, classes, onCancelUpload } = this.props;
    return (
      <Modal open={!!uploadStaging} onClose={onCancelUpload} disableBackdropClick={!!uploadProgressMsg}>
        {uploadStaging && (
          <div className={classes.paper}>
            <Typography variant="h6" id="modal-title">
              {uploadStaging.assessmentType
                ? getDynamicAssessmentModel(uploadStaging.assessmentType).name
                : 'Unrecognized File Type'}
            </Typography>
            {uploadProgressMsg && (
              <div className={classes.uploadProgressWrapper}>
                <LoadingCircle classes={classes} msg={uploadProgressMsg} />
              </div>
            )}
            {!uploadProgressMsg && uploadStaging.assessmentType && this.renderInterimUpload()}
            {!uploadProgressMsg && !uploadStaging.assessmentType && this.renderUnrecognizedFileUpload()}
          </div>
        )}
      </Modal>
    );
  }
}

export default withStyles(styles)(UploadModal);
