import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import {
  _reducer as assessments,
  epics as assessmentsEpics,
  groupAssessmentsReducer as groupAssessments,
} from './assessments';
import { _reducer as assessmentManager } from './assessmentManager';
import { _reducer as assessementCheckList, epics as assessementChecklistEpics } from './assessementChecklist';

export const reducer = combineReducers({
  assessments,
  assessmentManager,
  groupAssessments,
  assessementCheckList,
});
export const epics = combineEpics(assessmentsEpics, assessementChecklistEpics);

export * from './assessments/assessments.types';
export * from './assessments/assessments.validation';
export * from './assessments/assessments.actions';
export * from './assessmentManager/assessmentManager.actions';
