import * as React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends React.PureComponent {
  static propTypes = {
    path: PropTypes.string.isRequired,
    redirect: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    notSure: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    Component: PropTypes.any.isRequired,
  };

  renderRoute = (routeProps) => {
    const { Component, notSure, isAuthenticated, redirect, loadingMsg, ...rest } = this.props;
    if (notSure) {
      return <div style={{ marginLeft: '10px', marginTop: '10px' }}>{loadingMsg}</div>;
    }
    if (isAuthenticated) {
      return <Component {...rest} {...routeProps} />;
    }
    const { location } = routeProps;
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { from: location },
        }}
      />
    );
  };

  render() {
    const { path } = this.props;
    return <Route path={path} render={this.renderRoute} />;
  }
}

export default PrivateRoute;
