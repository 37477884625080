import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { _reducer as studentRoster, epics as studentRosterEpics } from './studentRoster';

export const reducer = combineReducers({
  studentRoster,
});

export const epics = combineEpics(studentRosterEpics);
