import firebase from 'firebase';
import { getMultipleCampusData, isMultipleCampusAdmin } from '../../common/utils';

export const ccmrEndpoint = firebase.functions().httpsCallable('getCCMRTrackingData');
export const getCcmrTrackingData = async (user) => {
  if (user.role === 'districtAdmin') {
    return firebase
      .database()
      .ref(`newDatabase/organizations/${user.organizationId}/ccmr`)
      .once('value')
      .then((snapshot) => snapshot.val());
  }
  if (isMultipleCampusAdmin(user)) {
    const multipleCCMR = await getMultipleCampusData(user, `newDatabase/organizations/${user.organizationId}/ccmr`);
    let students = {};
    Object.values(multipleCCMR).map((campusStudents) => {
      students = { ...students, ...campusStudents };
    });
    return students;
  }
  return firebase
    .database()
    .ref(`newDatabase/organizations/${user.organizationId}/ccmr`)
    .orderByChild('campusNumber')
    .equalTo(user.campusNumber)
    .once('value')
    .then((snapshot) => snapshot.val());
};

export const updateCCMRTrackingData = async (orgId, firebaseId, studentData) => {
  firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/ccmr/${firebaseId}`)
    .update(studentData);
};

export const updateBulkCCMRTrackingData = async (orgId, studentsData) => {
  firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/ccmr`)
    .update(studentsData);
};

export default 'ccmrTracking.api.js';
