import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { LoadingCircle, TitleBackButton } from '../../common';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import styles from './FlightPlanBase.styles';
import FlightPlanTable from '../FlightPlanTable';
import DesiredAFScore from './DesiredAFScore.component';
import AtoFFilters from '../../AtoF/AtoFFilters';
import { JobNames } from '../+store/flightPlan';

class FlightPlanBase extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string,
    profile: PropTypes.object,
    status: PropTypes.shape({
      error: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
    assessmentList: PropTypes.array.isRequired,
    getSchools: PropTypes.func.isRequired,
    flightPlanSteps: PropTypes.any,
    currentROOS: PropTypes.number,
    desiredAFScore: PropTypes.number.isRequired,
    updateDesiredAFScore: PropTypes.func.isRequired,
    maxAFScore: PropTypes.number.isRequired,
    flightPlanData: PropTypes.array,
    groupByValue: PropTypes.string,
    getAssessmentGroups: PropTypes.func.isRequired,
    assessmentGroups: PropTypes.object.isRequired,
    avatars: PropTypes.object,
    getAvatars: PropTypes.func,
    reportError: PropTypes.any,
    storeGroupBy: PropTypes.func.isRequired,
    students: PropTypes.any,
    schools: PropTypes.any,
    getStudentRoster: PropTypes.any,
    getAssessmentList: PropTypes.any,
  };

  inited = false;

  state = {
    items: 30,
    minItemsToShow: 10,
    loadingState: false,
    selected: null,
    noMoreStudents: false,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate(oldProps) {
    this.shouldInit();
    const newProps = this.props;
    if (oldProps.groupByValue !== newProps.groupByValue) {
      this.setState({ items: 30 });
    }
  }

  showMore = (val1, index, event) => {
    const nodeItem = event.currentTarget;
    if (nodeItem) {
      nodeItem.addEventListener(
        'scroll',
        () => {
          if (nodeItem.scrollTop + nodeItem.clientHeight >= nodeItem.scrollHeight) {
            this.loadMoreItems(index, val1);
          } else {
          }
        },
        true
      );
    } else {
    }
  };

  loadMoreItems(index, steps) {
    const { loadingState, items, minItemsToShow, selected } = this.state;
    if (loadingState) {
      return;
    }
    if (selected !== index && selected !== null) {
      this.setState({
        items: minItemsToShow,
        selected: index,
        loadingState: false,
      });
      return;
    }
    const numStudents = steps.length;

    if (items < numStudents) {
      this.setState({ loadingState: true });

      setTimeout(() => {
        var noMore = false;
        if (items + 30 >= numStudents) {
          noMore = true;
        }
        this.setState({ items: items + 30, loadingState: false, selected: index, noMoreStudents: noMore });
      }, 1000);
    } else {
      this.setState({ loadingState: false, noMoreStudents: true });
    }
  }

  shouldInit() {
    const {
      profile,
      loading,
      getSchools,
      getAssessmentGroups,
      avatars,
      getAvatars,
      assessmentList,
      students,
      schools,
      getStudentRoster,
      assessmentGroups,
      getAssessmentList,
    } = this.props;
    if (profile && !this.inited && !loading) {
      if (!Object.keys(schools).length) {
        getSchools(profile);
      }
      if (!assessmentGroups.length) {
        getAssessmentGroups(profile);
      }
      if (!assessmentList.length) {
        getAssessmentList(profile);
      }
      setTimeout(() => {
        if (!Object.keys(students).length) {
          getStudentRoster(profile);
        }
        if (!avatars) {
          getAvatars();
        }
      }, 3000);
      this.inited = true;
    }
  }

  render() {
    const {
      classes,
      loading,
      profile,
      assessmentList,
      flightPlanSteps,
      currentROOS,
      desiredAFScore,
      updateDesiredAFScore,
      maxAFScore,
      flightPlanData,
      groupByValue,
      assessmentGroups,
      avatars,
      loadingMsg,
      reportError,
      storeGroupBy,
      loadingStudents,
    } = this.props;
    const { minItemsToShow, selected, items, loadingState, noMoreStudents } = this.state;

    if (!!flightPlanData && (flightPlanSteps == null || flightPlanSteps.length == 0)) {
      // in case there is a result but there is no record matchs the Desired A-F Score
      // adding empty row to fix empty screen issue
      flightPlanSteps.push({
        studentId: '',
        testSubject: '',
        subject: '',
        steps: { current: '', next: '' },
        alreadyMasters: 0,
        individualAttainability: { raw: 0, weighted: 0 },
        individualPotentiality: { raw: null, weighted: null },
        groupProximityScore: { raw: 0, weighted: 0 },
        individualImpact: { raw: 0, weighted: 0 },
        impact: 0,
        attainability: 0,
        flightPlanScore: 0,
        roundedROOS: 0,
        unroundedROOS: 0,
      });
    }

    const groupByValueTypes = {
      math: 'Math',
      reading: 'Reading',
      3: '3rd Grade',
      4: '4th Grade',
      5: '5th Grade',
      6: '6th Grade',
      asian: 'Asian',
      black: 'African Americans',
      continuouslyEnrolled: 'Continuously Enrolled',
      ecoDis: 'Economic Disadvantage',
      hispanic: 'Hispanic',
      lepCurrent: 'Lep Current',
      lepMonitored: 'Lep Monitored',
      nonContinuouslyEnrolled: 'Non Continuously Enrolled',
      specialEdCurrent: 'Special Education Current',
      twoOrMoreRaces: 'Two Or More Races',
      white: 'White',
      Other: 'Other',
    };
    const horizontal = !flightPlanSteps.length && !Object.keys(flightPlanSteps).length;
    return (
      <div className={classes.root}>
        <TitleBackButton title="Flight Plan">
          {!loading && reportError && (
            <Typography color="error" className={classes.errorMsg}>
              {reportError}
            </Typography>
          )}
        </TitleBackButton>
        {(loading || loadingStudents || !profile) && (
          <LoadingCircle classes={classes} msg={loadingMsg || loadingStudents} />
        )}
        <div className={classes.body} style={{ display: loadingStudents || loading || !profile ? 'none' : '' }}>
          <div
            className={classes.leftSide}
            style={{
              width: horizontal ? '100%' : null,
              display: horizontal ? 'flex' : null,
              justifyContent: horizontal ? 'center' : null,
            }}
          >
            {(!!flightPlanData || groupByValue) && (
              <DesiredAFScore
                desiredValue={desiredAFScore}
                updateDesired={updateDesiredAFScore}
                classes={classes}
                currentAF={currentROOS}
                maxAF={maxAFScore}
                groupByValue={groupByValue}
                storeGroupBy={storeGroupBy}
              />
            )}
            {assessmentList && profile && (
              <AtoFFilters
                assessmentGroups={assessmentGroups}
                profile={profile}
                jobName={JobNames.flightPlan}
                orientation={
                  !!flightPlanSteps.length || Object.keys(flightPlanSteps).length ? 'vertical' : 'horizontal'
                }
              />
            )}
          </div>
          {!loading && !!flightPlanSteps.length && (
            <div className={classes.rightSide} onScroll={(event) => this.showMore(flightPlanSteps, null, event)}>
              <FlightPlanTable steps={flightPlanSteps.slice(0, items)} avatars={avatars} />
              {loadingState ? <p className="loading">Loading more students...</p> : null}

              {loadingState == false ? (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ float: 'right' }}
                  onClick={(event) => this.loadMoreItems(null, flightPlanSteps)}
                  className={classes.button}
                >
                  {'Show More Students'}
                  <Icon className={classes.rightIcon}>send</Icon>
                </Button>
              ) : null}
            </div>
          )}
          {!loading && groupByValue && groupByValue !== 'all' && (
            <div className={classes.rightSide}>
              {Object.entries(flightPlanSteps).map((val, index) => {
                let control = minItemsToShow;
                let loadingStateControl = false;
                if (selected === index) {
                  control = items;
                  loadingStateControl = loadingState;
                }
                const stepObj = val[1].slice(0, control);
                return (
                  <div
                    key={index}
                    className={classes.groupTables}
                    onScroll={(event) => this.showMore(val[1], index, event)}
                  >
                    <Typography className={classes.headerColumnText}>
                      {groupByValue === 'teacher' ? val[0] : groupByValueTypes[val[0]]}
                    </Typography>

                    <FlightPlanTable steps={stepObj} avatars={avatars} />
                    {loadingStateControl ? <p className="loading">Loading more students...</p> : null}

                    {!loadingStateControl ? (
                      <Button
                        variant="contained"
                        style={{ float: 'right' }}
                        color="primary"
                        onClick={(event) => this.loadMoreItems(index, val[1])}
                        className={classes.button}
                      >
                        {'Show More Students'}
                        <Icon className={classes.rightIcon}>send</Icon>
                      </Button>
                    ) : null}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FlightPlanBase);
