import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import moduleReducer, { epics } from '../../index';

const rootReducer = combineReducers({
  modules: moduleReducer,
});

const epicMiddleware = createEpicMiddleware();

let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware, thunk)));
epicMiddleware.run(epics);
export default store;
