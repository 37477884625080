import firebase from 'firebase';

export const saveAssessment = (user, assessmentSetObj, file, progressCallback) =>
  new Promise((resolve, reject) => {
    const [[aId, aSet]] = Object.entries(assessmentSetObj);
    const orgId = user.organizationId;
    const storageRef = firebase.storage().ref();
    const assessmentsRef = storageRef.child(aSet.file.storageKey);
    const metadata = {
      customMetadata: {
        type: aSet.type,
        organizationId: orgId,
        owner: aSet.owner,
        // ownerId: aSet.createdBy,
        ownerId: user.uid,
        assessmentSetId: aId,
      },
    };
    const uploadTask = assessmentsRef.put(file, metadata);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        progressCallback(progress, snapshot.state);
      },
      (error) => {
        reject(error);
      },
      () => {
        resolve('Success');
      }
    );
  });

export default 'assessments.storage.js';
