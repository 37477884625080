import parse from 'csv-parse';
// import * as Yup from 'yup';

// const rosterSchema = Yup.array().of(Yup.string());
const rosterColumns = [
  'STUDENT-ID',
  'TSDS Unique ID',
  'Local ID',
  'First-Name',
  'Middle-Name',
  'Last-Name',
  'Grade-Level',
  'Grad-Year',
  'Campus-ID',
  'Ethnicity',
  'EcoDis',
  'SpEd',
  'LEP',
  'Enrollment',
  'Snapshot',
  'Years in US Schools',
  'Asylee,Refugee,SIFE',
  'Test 1',
  'Test 1 Teacher ID or Name',
  'Test 2',
  'Test 2 Teacher ID or Name',
  'Test 3',
  'Test 3 Teacher ID or Name',
  'Test 4',
  'Test 4 Teacher ID or Name',
  'Test 5',
  'Test 5 Teacher ID or Name',
  'Tags',
];

export const validateStudentRoster = (file) =>
  new Promise((resolve, reject) => {
    if (file.type !== 'text/csv') {
      reject(new Error('Invalid file type'));
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      parse(text, {}, (err, output) => {
        if (err) {
          reject(new Error("Couldn't parse csv file"));
        }
        if (JSON.stringify(rosterColumns) !== JSON.stringify(output[0])) {
          reject(new Error('Invalid column names'));
        }
        // parse(text, { columns: true }, (err, output) => {
        //   rosterSchema.validateSync(output, { abortEarly: false });
        // )};
        resolve();
      });
    };
    reader.readAsText(file);
  });

export default 'StudentRoster.validation.js';
