import { connect } from 'react-redux';

import Component from './AssessmentManager.component';
import {
  getAssessmentGroups,
  validateAssessmentFile,
  uploadAssessment,
  downloadAssessment,
  reparseAssessment,
  changeAssessmentVisibility,
  addAssessmentsListener,
  removeAssessmentsListener,
  deleteAssessment,
  addAssessmentGroup,
  deleteAssessmentGroup,
  changeAssessmentGroupVisibility,
} from '../+store/assessments/assessments.actions';
import { needsToBeFetched } from '../+store/assessments/assessments.selectors';
import {
  cancelUpload,
  closeUploadDialog,
  openUploadDialog,
  openCreateGroupDialog,
  closeCreateGroupDialog,
} from '../+store/assessmentManager';

const mapStateToProps = (state) => {
  const assessmentList = state.modules.Assessments.assessments.assessmentList;
  const schoolList = state.modules.Settings.schoolSettings.schoolList;

  let uploaderList = {};
  if (assessmentList) {
    const uploaders = new Set();
    assessmentList.map((assessment) => {
      uploaders.add(assessment.owner);
    });
    uploaderList = [...uploaders].map((uploader) => {
      return {
        value: uploader,
        label: schoolList && schoolList[uploader] ? schoolList[uploader].name : uploader,
      };
    });
  }
  return {
    assessmentList,
    schoolList,
    uploaderList,
    profile: state.modules.Auth.auth.profile,
    needsInit: needsToBeFetched(state),
    loadingMsg: state.modules.Assessments.assessments.loadingMsg,
    loading: !!state.modules.Assessments.assessments.loadingMsg,
    status: state.modules.Assessments.assessmentManager.status,
    uploadStaging: state.modules.Assessments.assessmentManager.uploadStaging,
    uploadProgressMsg: state.modules.Assessments.assessmentManager.uploadProgressMsg,
    uploadOpen: state.modules.Assessments.assessmentManager.uploadOpen,
    assessmentGroups: state.modules.Assessments.groupAssessments.assessmentGroups,
    assessmentGroupCreateOpen: state.modules.Assessments.assessmentManager.assessmentGroupCreateOpen,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // init: (profile) => {
  //   dispatch(getAssessmentList(profile));
  // },
  onDrop: (accepted, profile) => {
    dispatch(validateAssessmentFile(accepted[0], profile));
  },
  onCancelUpload: () => {
    dispatch(cancelUpload());
  },
  doUpload: (uploadStaging, fields, profile, isUnrecognized = false) => {
    dispatch(uploadAssessment(uploadStaging, fields, profile, isUnrecognized));
  },
  onDownloadClick: (assessment) => {
    dispatch(downloadAssessment(assessment));
  },
  onReparseClick: (assessment) => {
    dispatch(reparseAssessment(assessment));
  },
  onAssessmentVisibilityBySuperAdminOnlyChange: (profile, assessment) => {
    dispatch(changeAssessmentVisibility(profile, assessment));
  },

  onAssessmentGroupVisibilityBySuperAdminOnlyChange: (profile, assessmentGroup) => {
    dispatch(changeAssessmentGroupVisibility(profile, assessmentGroup));
  },

  onCancelUploadClick: () => {
    dispatch(closeUploadDialog());
  },
  onUploadBtnClick: () => {
    dispatch(openUploadDialog());
  },
  addAssessmentsListener: (profile) => {
    dispatch(addAssessmentsListener(profile, 'assessmentManager'));
  },
  removeAssessmentsListener: (profile) => {
    dispatch(removeAssessmentsListener(profile, 'assessmentManager'));
  },
  handleDelete: (profile, assessment) => {
    dispatch(deleteAssessment(profile, assessment));
  },
  getAssessmentGroups: (profile) => {
    dispatch(getAssessmentGroups(profile));
  },
  onCreateAssessmentGroupClick: () => {
    dispatch(openCreateGroupDialog());
  },
  onCloseAssessmentGroupClick: () => {
    dispatch(closeCreateGroupDialog());
  },
  handleAddAssessmentGroup: (profile, assessmentGroup) => {
    dispatch(addAssessmentGroup(profile, assessmentGroup));
  },
  handleGroupDelete: (profile, assessmentGroupId) => {
    dispatch(deleteAssessmentGroup(profile, assessmentGroupId));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
