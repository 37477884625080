import { connect } from 'react-redux';
import Component from './TakeOffPage.component';
import { initOnBoarding, markCompleted } from '../+store';
import { Routes } from '../../App';

const mapStateToProps = (state, ownProps) => ({
  completed: state.modules.OnBoarding.onBoarding.status,
  needsInit: state.modules.OnBoarding.onBoarding.needsInit,
  loading: !!state.modules.OnBoarding.onBoarding.loadingMsg,
  user: state.modules.Auth.auth.user,
  profile: state.modules.Auth.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  init: (user, history) => {
    dispatch(initOnBoarding(user, history));
  },
  onNextClick: (history, next) => {
    dispatch(markCompleted(Routes.takeOff, next, history));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
