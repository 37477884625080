import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import classNames from 'classnames';
import styles from './TextInputField.styles';

class IconTextInputField extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    autoComplete: PropTypes.string,
    rightIcon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      isDisabled: PropTypes.bool,
    }),
    leftIcon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      isDisabled: PropTypes.bool,
    }),
    isError: PropTypes.bool,
    isDarkBorder: PropTypes.bool,
    isDisabled: PropTypes.bool,
  };

  render() {
    const {
      classes,
      id,
      type,
      autoComplete,
      onFocus,
      onBlur,
      isError,
      rightIcon,
      leftIcon,
      isDarkBorder,
      isDisabled,
    } = this.props;
    let leftIconColor = leftIcon && leftIcon.isDisabled != null && leftIcon.isDisabled ? 'disabled' : 'action';
    leftIconColor = isError ? 'error' : leftIconColor;
    const onBlurFocus = onBlur && onFocus ? { onBlur, onFocus } : {};
    return (
      <div
        className={classNames(classes.iconTextInputFieldWrapper, {
          [classes.iconTextInputFieldWrapperError]: isError,
          [classes.darkBorder]: isDarkBorder,
          [classes.disabled]: isDisabled,
        })}
      >
        {leftIcon && (
          <Icon color={leftIconColor} onClick={leftIcon.onClick}>
            {leftIcon.name}
          </Icon>
        )}
        <Field
          name={id}
          id={id}
          type={type}
          className={classNames(classes.iconTextInputField, { [classes.iconTextInputFieldError]: isError })}
          autoComplete={autoComplete}
          {...onBlurFocus}
        />
        {rightIcon && (
          <Icon
            color={rightIcon.isDisabled != null && rightIcon.isDisabled ? 'disabled' : 'action'}
            onClick={rightIcon.onClick}
          >
            {rightIcon.name}
          </Icon>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(IconTextInputField);
