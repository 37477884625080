import firebase from 'firebase';

export const getSchool = async (campusNumber) =>
  firebase
    .database()
    .ref(`newDatabase/dataStructure/constants/schools/${campusNumber}`)
    .once('value')
    .then((snapshot) => snapshot.val());

export const getSchoolsInDistrict = async (districtNumber) =>
  firebase
    .database()
    .ref('newDatabase/dataStructure/constants/schools')
    .orderByChild('districtNumber')
    .equalTo(districtNumber)
    .once('value')
    .then((snapshot) => snapshot.val());

export const getDistrict = async (districtNumber) =>
  firebase
    .database()
    .ref(`newDatabase/dataStructure/constants/districts/${districtNumber}`)
    .once('value')
    .then((snapshot) => snapshot.val());

export const getOrgSchool = async (user, campusNumber) => {
  const orgId = user.organizationId;
  return firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/schools/${campusNumber}`)
    .once('value')
    .then((snapshot) => snapshot.val());
};

export const getAllOrgSchools = async (user) => {
  const orgId = user.organizationId;
  return firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/schools`)
    .once('value')
    .then((snapshot) => snapshot.val());
};

export const getOrgDistrict = async (user) => {
  const orgId = user.organizationId;
  return firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/district`)
    .once('value')
    .then((snapshot) => snapshot.val());
};

export const getOrgUserType = async (user) => {
  const orgId = user.organizationId;
  return firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/metadata/userType`)
    .once('value')
    .then((snapshot) => snapshot.val());
};

export const getOrgLastUploadedInterimTimestamp = async (user) => {
  const orgId = user.organizationId;
  return firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/metadata/interimTimestamp`)
    .once('value')
    .then((snapshot) => snapshot.val());
};

export const updateOrgLastUploadedInterimTimestamp = async (user, timestamp) => {
  const orgId = user.organizationId;
  return firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/metadata`)
    .update({
      interimTimestamp: timestamp,
    });
};

export const getDomain2bTables = async () =>
  firebase
    .database()
    .ref('newDatabase/dataStructure/constants/domain2bTables')
    .once('value')
    .then((snapshot) => snapshot.val());
