import { connect } from 'react-redux';

import Component from './AssessmentChecklist.component';
import { initCheckList, getCcmrSource } from '../+store/assessementChecklist/assessementChecklist.actions';

const mapStateToProps = (state) => ({
  loading: !!state.modules.Assessments.assessementCheckList.loadingMsg,
  checkListData: state.modules.Assessments.assessementCheckList.assessementCheckList,
  ccmrSource: state.modules.Assessments.assessementCheckList.ccmrSource,
  profile: state.modules.Auth.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  initChecklist: (profile) => {
    dispatch(initCheckList(profile));
  },
  getCcmrSource: (profile, schoolId) => {
    dispatch(getCcmrSource(profile, schoolId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
