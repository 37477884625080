import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { _reducer as accountabilityWeight, epics as accountabilityWeightEpics } from './accountabilityWeight';

export const reducer = combineReducers({
  accountabilityWeight,
});

export const epics = combineEpics(accountabilityWeightEpics);
