import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Modal, Typography } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../../common';
import styles from '../ManageUsers.styles';
import TextInputField from '../../../Auth/TextInputField';
import SelectInputField from '../../../Auth/SelectInputField';

class UpdateAddUser extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    user: PropTypes.object,
    firebaseId: PropTypes.string,
    handleUpdateClose: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    schoolList: PropTypes.array.isRequired,
    onUpdateProfile: PropTypes.object.isRequired,
    onInviteUser: PropTypes.func.isRequired,
    invitedUsers: PropTypes.object,
  };

  UpdateAddUserSchema = () => {
    const { user, invitedUsers } = this.props;
    const invitedEmails = user ? [] : Object.values(invitedUsers).map((u) => u.emailAddress);
    return Yup.object().shape({
      displayName: Yup.string()
        .min(5, 'The Name should at least have 5 characters.')
        .required('Required.'),
      emailAddress: Yup.string()
        .email('Invalid email')
        .notOneOf(invitedEmails, 'This email has been invited to the organization already.')
        .required('Required.'),
      role: Yup.string().required('Required.'),
      phoneNumber: Yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, 'Invalid Phone Number Format (XXX-XXX-XXXX).'),
      campusNumber: Yup.array().when('role', (other, schema) =>
        other === 'campusAdmin' ? schema.required('Select a campus from the list.') : schema
      ),
    });
  };

  handleSubmit = (values) => {
    const { firebaseId, onUpdateProfile, onInviteUser, handleUpdateClose } = this.props;
    if (firebaseId) {
      onUpdateProfile(firebaseId, values);
    } else {
      onInviteUser(values);
    }
    handleUpdateClose();
  };

  render() {
    const { classes, user, profile, schoolList, handleUpdateClose } = this.props;
    const initialValues = {
      displayName: '',
      emailAddress: '',
      phoneNumber: '',
      role: profile.role,
      campusNumber: [],
      canViewAccountabilityReports: false,
    };
    if (profile.campusNumber && profile.campusNumber.length) {
      if (typeof profile.campusNumber === 'string') {
        initialValues.campusNumber = [profile.campusNumber];
      } else {
        initialValues.campusNumber = profile.campusNumber;
      }
    }
    if (user) {
      initialValues.displayName = user.displayName || '';
      initialValues.emailAddress = user.emailAddress;
      initialValues.phoneNumber = user.phoneNumber || '';
      initialValues.role = user.role;
      initialValues.canViewAccountabilityReports = user.canViewAccountabilityReports || false;
      if (user.campusNumber && user.campusNumber.length) {
        if (typeof user.campusNumber === 'string') {
          initialValues.campusNumber = [user.campusNumber];
        } else {
          initialValues.campusNumber = user.campusNumber;
        }
      }
    }
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={handleUpdateClose}
      >
        <div className={classes.modal}>
          <Formik
            key="updateProfile"
            validateOnBlur
            enableReinitialize
            initialValues={initialValues}
            validationSchema={this.UpdateAddUserSchema}
            onSubmit={this.handleSubmit}
          >
            {({ values, errors, touched }) => (
              <Form className={classes.form}>
                <Typography variant="h6" id="modal-title" gutterBottom>
                  {user ? 'Update Profile' : 'Invite User'}
                </Typography>
                <TextInputField
                  isDisabled={!!user}
                  label="Email"
                  id="emailAddress"
                  type="email"
                  touched={touched.emailAddress}
                  error={errors.emailAddress}
                  autoComplete="email"
                  className={classes.inputField}
                  flexDirection="column"
                />
                <TextInputField
                  label="Name*"
                  id="displayName"
                  touched={touched.displayName}
                  error={errors.displayName}
                  autoComplete="name"
                  className={classes.inputField}
                  flexDirection="column"
                />
                <TextInputField
                  label="Phone"
                  id="phoneNumber"
                  touched={touched.phoneNumber}
                  error={errors.phoneNumber}
                  autoComplete="phone"
                  className={classes.inputField}
                  flexDirection="column"
                />
                {profile.role === 'districtAdmin' && (
                  <SelectInputField
                    label="Role*"
                    id="role"
                    touched={touched.role}
                    error={errors.role}
                    className={classes.inputField}
                    flexDirection="column"
                    options={[
                      { value: 'districtAdmin', label: 'District Admin' },
                      { value: 'campusAdmin', label: 'Campus Admin' },
                    ]}
                  />
                )}
                {values.role === 'campusAdmin' && (profile.role === 'districtAdmin' || schoolList.length > 1) && (
                  <SelectInputField
                    isDisabled={!profile.isSuperAdmin}
                    multi
                    label="Campus*"
                    id="campusNumber"
                    flexDirection="column"
                    touched={touched.campusNumber}
                    error={errors.campusNumber}
                    className={classes.inputField}
                    options={schoolList}
                  />
                )}

                {profile.isSuperAdmin === true && (
                  <label>
                    <Field
                      type="checkbox"
                      touched={touched.canViewAccountabilityReports}
                      error={errors.canViewAccountabilityReports}
                      className={classes.inputField}
                      id="canViewAccountabilityReports"
                      checked={values.canViewAccountabilityReports === true}
                    />
                    Can View Accountability Reports
                  </label>
                )}

                <Grid container direction="row" justify="center" alignItems="center">
                  <Button large color="white" onClick={handleUpdateClose}>
                    Cancel
                  </Button>
                  <Button submit large color="dark">
                    {user ? 'Update' : 'Invite'}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(UpdateAddUser);
