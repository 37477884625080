import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { asyncFinish } from '../../../common/+store/async';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import { UPDATE_PROFILE } from './AccountSettings.actions';
import { updateProfileAndAccountInfo } from './AccountSettings.api';

const updateProfile = (action$) =>
  action$.pipe(
    ofType(UPDATE_PROFILE),
    switchMap((action) =>
      from(
        updateProfileAndAccountInfo({
          displayName: action.displayName,
          phoneNumber: action.phoneNumber,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Updating Profile'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(UPDATE_PROFILE, results.action));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const epics = combineEpics(updateProfile);

export default epics;
