export default (theme) => ({
  root: {
    padding: '16px 24px',
    height: '100%',
  },
  body: {
    width: '100%',
    height: 'calc(100% - 52px)',
    display: 'flex',
  },
  rightSide: {
    height: 'calc(100% + 28px)',
    overflow: 'auto',
    width: '100%',
    marginTop: -28,
  },
  leftSide: {
    width: 240,
    height: '100%',
    overflow: 'auto',
    flexShrink: 0,
  },
  flightPlanOptions: {
    width: '100%',
    paddingRight: 16,
  },
  content: {
    margin: 'auto',
  },
  button: {
    marginTop: 8,
  },
  progress: {},
  textField: {
    width: '90%',
    marginRight: 8,
    paddingLeft: '2px',
    margin: 0,
    border: 0,
    display: 'inline-flex',
    padding: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    verticalAlign: 'top',
    marginBottom: '8px',
  },
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: 'inherit',
    lineHeight: 1.5,
    paddingBottom: 14,
    paddingTop: 4,
  },
  valueText: {
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: 'inherit',
    // lineHeight: 1,
    paddingBottom: '6px',
    borderBottom: '1px solid rgba(0,0,0,0.54)',
    marginBottom: '2px',
  },
  helperText: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '0.75rem',
    fontFamily: 'inherit',
    lineHeight: 1,
    marginTop: '4px',
  },
  smallerTextField: {
    minWidth: '190px',
    marginRight: 8,
  },
  formControl: {
    width: '100%',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerColumnText: {
    fontSize: '1.125rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  showMoreOrLessBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  groupTables: {
    marginBottom: 28,
    height: 400,
    overflow: 'auto',
  },
  errorMsg: {
    fontSize: 16,
    textAlign: 'center',
    flexGrow: 1,
  },
});
