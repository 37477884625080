import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableCell, TableRow, TableBody } from '@material-ui/core';
import SubjectSubTable from '../Common/SubjectSubTable';
import SectionMsg from '../Common/SectionMsg';

const styles = (theme) => ({
  root: {},
  all: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #E1E1E1;',
  },

  reading: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(248, 220, 83, 0.7);',
  },

  math: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(92, 186, 243, 0.7);',
  },

  /*writing: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 47, 146, 0.7);',
  },*/

  science: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(94, 255, 0, 0.7);',
  },

  socialStudies: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(162, 31, 255, 0.7);',
  },
  menu: {},
  textField: {},
  formControl: {
    display: 'block',
  },
  finalTable: {
    width: 300,
  },
});

const indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  ecoDis: 'Economically Disadvantaged',
  lep: 'EL (Current & Monitored)',
  specialEdCurrent: 'Special Ed (Current)',
  specialEdFormer: 'Special Ed (Former)',
  continuouslyEnrolled: 'Continuously Enrolled',
  // nonContinuouslyEnrolled: 'Non-Continuously Enrolled',
  highlyMobile: 'Highly Mobile',
  highFocus: 'High Focus',
  acceleratedTesterMath: 'Accelerated Tester Math',
  acceleratedTesterReading: 'Accelerated Tester Reading',
  acceleratedTesterScience: 'Accelerated Tester Science',
};
const types = {
  all: 'All Subjects',
  reading: 'Reading',
  math: 'Math',
  // writing: 'Writing',
  science: 'Science',
  socialStudies: 'Social Studies',
};
const CustomTableCell = withStyles((theme) => ({
  head: {
    padding: 8,
    textAlign: 'center',
  },
  body: {
    padding: '8px 4px',
  },
}))(TableCell);

class D1Table extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    domain: PropTypes.object.isRequired,
  };

  render() {
    const { domain, classes } = this.props;
    const { table, msg } = domain;
    const rowComponents = [
      { label: '# of Does Not Meet', path: 'count.doesNotMeet', studentsPath: 'doesNotMeet' },
      { label: '# of Approaches', path: 'count.approaches', studentsPath: 'approaches' },
      { label: '# of Meets', path: 'count.meets', studentsPath: 'meets' },
      { label: '# of Masters', path: 'count.masters', studentsPath: 'masters' },
      { label: 'Total Tests', path: 'total' },
      { label: '% Does Not Meet', path: 'percent.doesNotMeet' },
      { label: '% Approaches', path: 'percent.approaches' },
      { label: '% Meets', path: 'percent.meets' },
      { label: '% Masters', path: 'percent.masters' },
      { label: '% Average of A/M/M', path: 'average' },
    ];
    return (
      <div id="reportD1">
        {!!msg && <SectionMsg msg={msg} />}
        {Object.entries(types).map(([name, label]) => (
          <Table key={name} className={classes[name]}>
            <TableHead>
              <TableRow>
                <CustomTableCell />
                {Object.entries(indicators).map(([key1, label1], index) => (
                  <CustomTableCell key={key1} style={index % 2 ? { background: 'rgba(255,255,255,0.4)' } : {}}>
                    {label1}
                  </CustomTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <SubjectSubTable
                key={label}
                studentsListPath={`d1/staar/${name}`}
                subject={name}
                subtableLabel={label}
                subtable={table[name]}
                rowComponents={rowComponents}
              />
            </TableBody>
          </Table>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(D1Table);
