import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, CssBaseline, withStyles, Typography, MuiThemeProvider, createMuiTheme } from '@material-ui/core';

import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../common';
import { Routes } from './App.routes';
import OnBoarding from '../OnBoarding';

import './App.css';
import AppHeaderFooter from '../AppHeaderFooter';
import StudentRoster from '../StudentRoster';
import packageJson from '../../../package.json';
import AtoF from '../AtoF';
import Settings from '../Settings';
import AccountabilityWeight from '../AccountabilityWeight';
import ProgressGoals from '../ProgressGoals';
import CCMRTracking from '../CCMRTracking';
import AssessmentManager from '../Assessments';
import HomePage from '../HomePage';
import { SignIn, ForgotPassword, SignUp } from '../Auth';
import FlightPlan from '../FlightPlan';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['"Questrial"', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#656565',
      light: '#C4C4C4',
      contrastText: '#fff',
    },
    secondary: {
      main: '#BFF7FF',
      light: '#E6FBFE',
      contrastText: '#fff',
    },
    warning: {
      main: '#DFC700',
      contrastText: '#fff',
    },
    error: {
      main: '#EE9696',
      dark: '#FF5656',
      contrastText: '#fff',
    },
    success: {
      main: '#68D190',
      light: '#E1F5E9',
    },
    inactive: '#E2E2E2',
    white: '#fff',
  },
});

const styles = (theme) => ({
  root: {
    height: '100%',
    background: 'white',
    position: 'relative',
  },
  content: {
    margin: 'auto',
  },
});

class App extends React.PureComponent {
  static propTypes = {
    appInit: PropTypes.func.isRequired,
    appTeardown: PropTypes.func.isRequired,
    classes: PropTypes.any.isRequired,
    user: PropTypes.object,
    history: PropTypes.object.isRequired,
    redirect: PropTypes.string.isRequired,
    doRedirect: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { appInit } = this.props;
    console.log('Version', process.env.REACT_APP_VERSION);
    console.log('Git Version', process.env.REACT_APP_GIT_SHORT_SHA);
    console.log('Env', process.env.REACT_APP_FIREBASE_PROJECT_ID);
    appInit();
  }

  componentDidUpdate() {
    const { redirect, history, doRedirect } = this.props;
    if (redirect) {
      doRedirect(history, redirect);
    }
  }

  componentWillUnmount() {
    const { appTeardown } = this.props;
    appTeardown();
  }

  renderApp = (props) => {
    const { profile } = this.props;
    return (
      <AppHeaderFooter {...props}>
        <Switch>
          <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.staar}`} Component={ProgressGoals} />
          <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.weights}`} Component={AccountabilityWeight} />
          <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.studentRoster}`} Component={StudentRoster} />
          {profile && (profile.isSuperAdmin === true || profile.canViewAccountabilityReports === true) && (
            <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.aToF}`} Component={AtoF} />
          )}

          {profile && (profile.isSuperAdmin === true || profile.canViewAccountabilityReports === true) && (
            <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.flightPlan}`} Component={FlightPlan} />
          )}
          <Redirect from={`/${Routes.settings}`} to={`/${Routes.settings}/account`} exact />
          <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.settings}/:type`} Component={Settings} />
          <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.assessments}`} Component={AssessmentManager} />
          <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.ccmrTracking}`} Component={CCMRTracking} />
          <PrivateRoute redirect={`/${Routes.signIn}`} exact path="" Component={HomePage} />
          <Route path={`/${Routes.versionNumber}`} render={this.renderVersionNumber} />
        </Switch>
      </AppHeaderFooter>
    );
  };

  renderVersionNumber = () => (
    <Grid container justify="center" alignItems="center">
      <div>
        <Typography variant="h1">{packageJson.version}</Typography>
        <Typography variant="subtitle1" align="center" style={{ color: '#c1c1c1' }}>
          {process.env.REACT_APP_GIT_SHORT_SHA}
        </Typography>
      </div>
    </Grid>
  );

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Grid container className={classes.root}>
          <CssBaseline />
          <Switch>
            <PrivateRoute redirect={`/${Routes.signIn}`} path={`/${Routes.onboarding}/:page?`} Component={OnBoarding} />
            <Route exact path={`/${Routes.forgotPassword}`} component={ForgotPassword} />
            <Route exact path={`/${Routes.signIn}`} component={SignIn} />
            <Route exact path={`/${Routes.signUp}/:code?`} component={SignUp} />
            <Route exact path="" strict={false} render={this.renderApp} />
          </Switch>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
