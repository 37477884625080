const styles = (theme) => ({
  root: {
    fontFamily: 'Questrial',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    height: '100%',
    width: '100%',
    padding: '16px 24px',
  },
  background: {},
  content: {
    display: 'flex',
    height: 'calc(100% - 52px)',
    width: '100%',
  },
  left: {
    width: 224,
    paddingRight: 24,
    height: '100%',
    overflowY: 'auto',
    flexShrink: 0,
  },
  progress: {},
  textField: {
    width: '280px',
    marginRight: 8,
  },
  formControl: {},
  header: {
    display: 'flex',
  },
  mountains: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
  },
  formFilter: {
    flex: 1.5,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  contentDiv: {
    flex: 9,
    marginLeft: '50px',
    overflow: 'auto',
    marginTop: '-65px',
  },
  loadingWrapper: {
    height: 300,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing.unit * 2,
  },
  colorSwitchBase: {
    color: theme.palette.success.main,
    '&$colorChecked': {
      color: theme.palette.success.light,
      '& + $colorBar': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  errorMsg: {
    fontSize: 16,
    textAlign: 'center',
    flexGrow: 1,
  },
  disclaimer: {
    fontSize: 11,
    marginTop: 16,
  },
  viewScore: {
    fontSize: 18,
    marginTop: 18,
    marginBottom: 12,
  },
});

export default styles;
