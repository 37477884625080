import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { CustomTableRow, CustomTableCell } from '../D3/Common/CustomTableComponents';
import SubjectSubTable from './SubjectSubTable';

const ComponentNames = {
  academicAchievement: 'academicAchievement',
  academicGrowth: 'academicGrowth',
  gradRate: 'gradRate',
  telpas: 'telpas',
  schoolQuality: 'schoolQuality',
  ccmr: 'ccmr',
  participation: 'participation',
};

const styles = (theme) => ({
  root: {},
  fourYear: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(134, 240, 255, 0.65);',
  },
  fiveYear: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFC2BA;',
  },
  sixYear: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #CDFBDF;',
  },
  menu: {},
  textField: {},
  formControl: {
    display: 'block',
  },
  finalTable: {
    width: 300,
  },
});

const indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  ecoDis: 'Economically Disadvantaged',
  lep: 'EL',
  specialEdCurrent: 'Special Ed',
};

const types = {
  fourYear: 'Four Year Longitudinal Grade Rate',
  fiveYear: 'Five Year Longitudinal Grade Rate',
  sixYear: 'Six Year Longitudinal Grade Rate',
};
class D1GradRate extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    domain: PropTypes.object.isRequired,
  };

  render() {
    const { domain, classes } = this.props;
    const { table, raw, scaled, letter } = domain;
    return (
      <div id="reportGrad">
        {/* <p style={{ marginLeft: '939px', marginTop: '-82px' }}>
          Letter:
          {letter}
        </p>
        <p style={{ marginLeft: '1022px', marginTop: '-33px' }}>
        Scaled:
          {scaled}
        </p>
        <p style={{ marginLeft: '1122px', marginTop: '-33px', paddingBottom: '35px' }}>
        Raw:
          {raw}
        </p> */}
        {Object.entries(types).map(
          ([name, label]) =>
            table &&
            table[name] && (
              <Table key={name} className={classes[name]}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell />
                    {Object.entries(indicators).map(([key1, label1], index) => {
                      if (index % 2) {
                        return (
                          <CustomTableCell style={{ background: 'rgba(255,255,255,0.4)' }} key={key1}>
                            {label1}
                          </CustomTableCell>
                        );
                      }
                      return <CustomTableCell key={key1}>{label1}</CustomTableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SubjectSubTable key={label} subtableLabel={label} subtable={table[name]} />
                </TableBody>
              </Table>
            )
        )}
      </div>
    );
  }
}

export default withStyles(styles)(D1GradRate);
