import { combineReducers } from 'redux';
import { SORT_BY, UPDATE_FILTER_OPTIONS, RESET_FILTERS } from './FilterBox.actions';

const sortByField = (state = '', action) => {
  if (action.type === SORT_BY) {
    return action.sortByField;
  }
  if (action.type === RESET_FILTERS) {
    return '';
  }
  return state;
};

const sortByKeys = (state = [], action) => {
  if (action.type === SORT_BY) {
    return action.sortByKeys;
  }
  if (action.type === RESET_FILTERS) {
    return [];
  }
  return state;
};

const sortReverse = (state = false, action) => {
  if (action.type === SORT_BY) {
    return action.sortReverse;
  }
  if (action.type === RESET_FILTERS) {
    return false;
  }
  return state;
};

const selectedFilterItems = (state = {}, action) => {
  if (action.type === UPDATE_FILTER_OPTIONS) {
    const { model } = state;
    let { filterOptions } = state;
    if (model !== action.model) {
      filterOptions = {};
    }

    if (action.key in filterOptions) {
      const keyFilterOption = filterOptions[action.key].values;
      if (action.isAdding) {
        keyFilterOption.push(action.value);
      } else {
        keyFilterOption.splice(keyFilterOption.indexOf(action.value), 1);
      }
    } else if (action.isAdding) {
      filterOptions[action.key] = {};
      filterOptions[action.key].values = [action.value];
    }
    filterOptions[action.key].type = action.filterType;
    filterOptions[action.key].key = action.filterKey;
    filterOptions[action.key].byRoster = action.byRoster;
    filterOptions[action.key].byCCMR = action.byCCMR;
    return { model: action.model, filterOptions };
  }
  if (action.type === RESET_FILTERS) {
    return {};
  }
  return state;
};

const model = (state = '', action) => {
  if (action.type === SORT_BY || action.type === UPDATE_FILTER_OPTIONS) {
    return action.model;
  }
  if (action.type === RESET_FILTERS) {
    return '';
  }
  return state;
};

export const _reducer = combineReducers({
  sortByField,
  sortByKeys,
  sortReverse,
  selectedFilterItems,
  model,
});

export default 'FilterBox.reducers.js';
