import { asyncStart } from '../../../common';

export const GET_SCHOOLS = 'settings.getSchools';
export const getSchools = (profile) => (dispatch) => {
  dispatch(asyncStart(GET_SCHOOLS));
  dispatch({ type: GET_SCHOOLS, profile });
};

export const GET_GRADE_SPAN_TABLE = 'settings.getGradeSpanTable';
export const getGradeSpanTable = (profile) => (dispatch) => {
  dispatch(asyncStart(GET_GRADE_SPAN_TABLE));
  dispatch({ type: GET_GRADE_SPAN_TABLE, profile });
};

export const UPDATE_SETTINGS = 'settings.updateSettings';
export const updateSettings = (profile, schoolId, settings) => (dispatch) => {
  dispatch(asyncStart(UPDATE_SETTINGS));
  dispatch({
    type: UPDATE_SETTINGS,
    profile,
    schoolId,
    settings,
  });
};

export const GET_DEFAULT_SETTINGS = 'settings.getDefault';
export const getDefaultSettings = (profile, schoolIds) => (dispatch) => {
  dispatch(asyncStart(GET_DEFAULT_SETTINGS));
  dispatch({
    type: GET_DEFAULT_SETTINGS,
    schoolIds,
    profile,
  });
};

export const GET_CCMR_MANUAL = 'settings.getCCMRManual';
export const getCCMRManual = () => (dispatch) => {
  dispatch(asyncStart(GET_CCMR_MANUAL));
  dispatch({
    type: GET_CCMR_MANUAL,
  });
};

export const SAVE_CCMR_MANUAL = 'settings.saveCCMRManual';
export const saveCCMRManual = (data) => (dispatch) => {
  dispatch(asyncStart(SAVE_CCMR_MANUAL));
  dispatch({
    type: SAVE_CCMR_MANUAL,
    data,
  });
};

export const GET_CCMR_INDICATORS = 'settings.getCCMRIndicators';
export const getCCMRIndicators = (data) => (dispatch) => {
  dispatch(asyncStart(GET_CCMR_INDICATORS));
  dispatch({
    type: GET_CCMR_INDICATORS,
    data,
  });
};

export const GET_GraduationData_INDICATORS = 'settings.getGraduationDataIndicators';
export const getGraduationDataIndicators = (data) => (dispatch) => {
  dispatch(asyncStart(GET_GraduationData_INDICATORS));
  dispatch({
    type: GET_GraduationData_INDICATORS,
    data,
  });
};

export const GET_DISTRICT_OR_SCHOOL_DATA = 'settings.getDistrictOrSchoolData';
export const getDistrictOrSchoolData = (data) => (dispatch) => {
  dispatch(asyncStart(GET_DISTRICT_OR_SCHOOL_DATA));
  dispatch({
    type: GET_DISTRICT_OR_SCHOOL_DATA,
    data,
  });
};
