import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  InputLabel,
  Input,
  FormHelperText,
} from '@material-ui/core';

const styles = {};

const MultiSelectControl = ({
  handleChange,
  value,
  label,
  getSelected,
  children,
  options,
  helpText,
  id,
  formControlClass,
}) => (
  <FormControl className={formControlClass}>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Select
      multiple
      value={value}
      onChange={handleChange}
      input={<Input id={id} />}
      renderValue={(selected) =>
        selected.map((s, index) => (
          <div key={index} style={{ marginTop: index > 0 ? 4 : 0 }}>
            {getSelected(s)}
          </div>
        ))
      }
    >
      {children}
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox style={{ color: '#60b9c5' }} checked={value.includes(option.value)} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
    <FormHelperText style={{ paddingRight: 4 }}>{helpText}</FormHelperText>
  </FormControl>
);
MultiSelectControl.propTypes = {
  handleChange: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.any,
  getSelected: PropTypes.any,
  children: PropTypes.any,
  options: PropTypes.any,
  helpText: PropTypes.any,
  id: PropTypes.any,
  formControlClass: PropTypes.any,
};

export default withStyles(styles)(MultiSelectControl);
