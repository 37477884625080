const styles = (theme) => ({
  root: {
    padding: '16px 24px',
    height: '100%',
    position: 'relative',
  },
  version: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 16,
  },
  body: {
    width: 700,
    marginLeft: 180,
    marginRight: 'auto',
  },
  manageUsersBody: {
    width: 1000,
    marginLeft: 50,
    marginRight: 'auto',
  },
  menu: {},
  textField: {
    padding: '6px 10px 7px',
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  formControl: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: 80,
    marginBottom: 8,
  },
  outerDiv: {
    height: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerDiv: {
    marginLeft: '26px',
    marginTop: '3px',
    display: 'flex',
  },
  noteText: {
    fontSize: 13,
    marginTop: 16,
  },
  textFieldMargin: {
    marginTop: '14px',
  },
  rowContent: {
    flex: '4',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  gradeSpan: {
    flex: '1',
    display: 'flex',
    flexFlow: 'row nowrap',
    border: '1px solid #aaa',
    justifyContent: 'space-around',
  },
  gradeSpanSelected: {
    color: 'white !important',
    backgroundColor: '#888',
  },
  gradeSpanCheckBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '6px',
  },
  checkboxSpan: {
    padding: '12px 12px 0px 12px',
  },
  gradeSpanRow: {
    display: 'flex',
  },
  gradeStartSelect: {
    marginRight: 4,
  },
  gradeEndSelect: {
    marginLeft: 4,
  },
  telpasRow: {
    display: 'flex',
  },
  ccmrRow: {
    display: 'flex',
  },
  ccmrD1Manual: {},
  ccmrD1Header: {
    fontWeight: 'bold',
  },
  ccmrD1Row: {
    display: 'flex',
    background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #E1E1E1;',
    border: '1px solid #E1E1E1',
  },
  ccmrD3Manual: {
    height: '300px',
    overflow: 'auto',
  },
  ccmrD3Row: {
    display: 'flex',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F9801D;',
    border: '1px solid #F9801D',
  },

  ccmrD3Header: {
    fontWeight: 'bold',
  },

  graduationDataD1Manual: {},
  ccmrD1Header: {
    fontWeight: 'bold',
  },
  graduationDataD1Row: {
    display: 'flex',
    // background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #E1E1E1;',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(134, 240, 255, 0.65);',
    border: '1px solid #E1E1E1',
  },
  graduationDataD3Manual: {
    height: '300px',
    overflow: 'auto',
  },
  graduationDataD3Row: {
    display: 'flex',
    // background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F9801D;',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #92C83E;',
    border: '1px solid #F9801D',
  },

  graduationDataD3Header: {
    fontWeight: 'bold',
  },

  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  settingsNavItemWrapper: {
    marginBottom: '1.5rem',
    display: 'flex',
    alignItems: 'center',
  },
  settingsNavItem: {
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginLeft: '0.6875rem',
    marginRight: '0.6875rem',
    fontSize: '1.125rem',
  },
  settingsNavIcon: {
    fontSize: '1.75rem',
  },
  link: {
    textDecoration: 'none',
  },
  settingsNavBar: {
    marginLeft: 26,
    width: 220,
    height: '100%',
  },
  settingsNavItemActive: {
    fontWeight: 600,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: theme.spacing.unit * 2,
    marginLeft: 100,
  },
  accountInputField: {
    width: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  accountSettingsTitle: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: '0.6875rem',
    marginRight: '0.6875rem',
    fontSize: '1.125rem',
    marginBottom: '1.5rem',
  },
  passwordStrengthContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 60,
  },
  statusMsg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1.5rem',
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: 10,
  },
  success: {
    color: theme.palette.success.main,
    marginLeft: 10,
  },
});

export default styles;
