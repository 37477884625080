export default (theme) => ({
  root: {},
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: '#f6feff',
  },
  headerRow: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    height: 56,
  },
  headerColumn1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.125rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  headerColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerColumnText: {
    fontSize: '1.125rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  profilePicture: {
    height: 30,
    width: 30,
    borderRadius: 15,
  },
  body: {
    overflow: 'auto',
  },
  bodyRow: {
    display: 'flex',
    height: 48,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  bodyColumn1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyColumnText: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
  },
  arrowIcon: {
    fontSize: 20,
    paddingLeft: 8,
    paddingRight: 8,
    width: 36,
    color: '#272727',
  },
});
