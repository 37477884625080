import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Grid, Typography } from '@material-ui/core';

const vector = require('../../../assets/img/vectorRight.png');

const Errors = {
  NOT_IN_CAF: 'No record in CAF',
  NO_TESTS: 'No tests listed on roster',
  UNKNOWN_ERROR: 'Unknown data error',
  NO_ALGEBRA_I: 'Not taking Algebra I this year',
  NO_ENGLISH_II: 'Not taking English II this year',
  NO_READING: 'Not taking reading this year',
  NO_MATH: 'Not taking math this year',
  NO_LAST_YEAR_SCORE: 'No eligible score from last year',
  ALGEBRA_I_RETAKE: 'Has already taken the Algebra I test',
  ENGLISH_II_RETAKE: 'Has already taken the English II test',
  NO_REPEATS: 'Retaking the same test',
  BAD_DATA: 'Bad data',
  SWITCHED_VERSIONS: 'Switched test versions',
  DIFFERENT_LANG: 'Switched test languages',
};

const styles = (theme) => ({
  cell: {
    padding: 0,
  },
  doesNotMeetCell: {
    background: 'rgba(177, 32, 21, 0.5)',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '65%',
    padding: '5px',
    margin: '10px',
  },
  approachesCell: {
    background: '#FF9900',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '60%',
    padding: '5px',
    margin: '10px',
  },
  approachesHighCell: {
    background: '#B45F06',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '60%',
    padding: '5px',
    margin: '10px',
  },
  doesNotMeetCell: {
    background: '#E06666',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '65%',
    padding: '5px',
    margin: '10px',
  },
  doesNotMeetHighCell: {
    background: '#C27BA0',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '65%',
    padding: '5px',
    margin: '10px',
  },
  meetsCell: {
    background: '#4A86E8',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '60%',
    padding: '5px',
    margin: '10px',
  },
  mastersCell: {
    background: '#38761D',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '60%',
    padding: '5px',
    margin: '10px',
  },
  maxCell: {
    background: '#38761D',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '60%',
    padding: '5px',
    margin: '10px',
  },
  accomplishesCell: {
    background: 'rgba(83, 134, 86, 0.5)',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '60%',
    padding: '5px',
    margin: '10px',
  },
  satisfactoryCell: {
    background: 'rgba(86, 138, 186, 0.5)',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '60%',
    padding: '5px',
    margin: '10px',
  },
  developingCell: {
    background: 'rgba(221, 129, 67, 0.5)',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    // height: '60%',
    padding: '5px',
    margin: '10px',
  },
  lastName: {
    textTransform: 'uppercase',
    fontSize: '0.875rem',
  },
  firstName: {
    textTransform: 'lowercase',
    fontSize: '0.875rem',
    marginRight: '5px',
    '&:first-letter': { textTransform: 'uppercase' },
  },
  firstTd: {
    width: 'calc(100%/3)',
    backgroundColor: '#eee',
    padding: '10px',
    fontSize: '1.1em',
  },
  firstTdDiv: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    // height: '100%',
  },
  td: {
    width: 'calc(100%/6)',
    backgroundColor: '#eee',
    padding: '5px',
    fontSize: '1.1em',
  },
  tdDiv: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tdScore: {
    flex: 2,
    textAlign: 'center',
  },
  tdStatus: {
    flex: 3,
    justifySelf: 'flex-start',
    color: 'white',
    padding: '10px 15px',
    borderRadius: '15px',
    textAlign: 'center',
    margin: '0px 10px',
    maxHeight: '50%',
  },
  userIcon: {
    fontSize: '2.5em',
    backgroundColor: 'white',
    color: '#666',
    borderRadius: '50%',
    padding: 'none',
  },
  profilePicture: {
    height: 45,
    width: 45,
    borderRadius: 15,
  },
  studentDisplay: {
    display: 'flex',
  },
  profileContent: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
});

const ammText = {
  doesNotMeet: 'Does not Meet',
  approaches: 'Approaches',
  meets: 'Meets',
  masters: 'Masters',
  accomplishes: 'Accomplishes',
  satisfactory: 'Satisfactory',
  developing: 'Developing',
  doesNotMeet: 'Does not Meet',
  doesNotMeetHigh: 'Does not Meet High',
  approachesHigh: 'Approaches High',
  max: 'Masters',
};

class StudentRow extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    student: PropTypes.any.isRequired,
    subject: PropTypes.any.isRequired,
    avatarUrl: PropTypes.string,
    view: PropTypes.string.isRequired,
  };

  getStudentProfilePicture = (photoUrl, avatarUrl, profileCss = '') => {
    const profilePictureURL = photoUrl && photoUrl.small ? photoUrl.small : avatarUrl;
    if (profilePictureURL) {
      return <img src={profilePictureURL} alt="Logo" className={profileCss} />;
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  };

  renderGoal = (student, year, subject, view) => {
    const { classes } = this.props;
    const className = student.goal[subject][year]
      ? classes[`${student.goal[subject][year].ammCode}Cell`]
      : classes.lastName;
    const error = Errors[student.goal[subject][year].error];
    const { ammCode } = student.goal[subject][year];
    const score =
      view === 'percentScore'
        ? `${Math.round(student.goal[subject][year][view])}%`
        : `${student.goal[subject][year][view]}`;
    return (
      <Typography className={className} color="primary">
        {error || ammText[ammCode]} {!error && `(${score})`}
      </Typography>
    );
  };

  render() {
    const { classes, student, subject, view, avatarUrl } = this.props;
    return (
      <React.Fragment>
        <TableCell key="name" align="left" className={classes.cell}>
          <Grid container alignItems="center">
            <div className={classes.studentDisplay}>
              {this.getStudentProfilePicture(student.photoUrl, avatarUrl, classes.profilePicture)}
              <div className={classes.profileContent}>
                <Typography className={classes.firstName} color="primary">
                  {student.firstName}
                </Typography>
                <Typography className={classes.lastName} color="primary">
                  {student.lastName}
                </Typography>
              </div>
            </div>
          </Grid>
        </TableCell>
        <TableCell key="mathprior" align="center" className={classes.cell}>
          {this.renderGoal(student, 'prev', subject, view)}
        </TableCell>
        <TableCell key="mathicon" align="center" className={classes.cell}>
          <img src={vector} alt="vector" />
        </TableCell>
        <TableCell key="mathgoal" align="center" className={classes.cell}>
          {this.renderGoal(student, 'curr', subject, view)}
        </TableCell>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(StudentRow);
