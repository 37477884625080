import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AcademicAchievement from './AcademicAchievement';
import AcademicGrowth from './AcademicGrowth';
import SchoolQuality from './SchoolQuality';
import Telpas from './Telpas';
import Ccmr from './Ccmr';
import GradRate from './GradRate';
import { Button, Grid, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
const ComponentNames = {
  academicAchievement: 'academicAchievement',
  academicGrowth: 'academicGrowth',
  gradRate: 'gradRate',
  telpas: 'telpas',
  schoolQuality: 'schoolQuality',
  ccmr: 'ccmr',
  participation: 'participation',
};

const styles = (theme) => ({
  root: {},
  menu: {},
  textField: {},
  formControl: {
    display: 'block',
  },
  finalTable: {
    width: 300,
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
});

class D3 extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    report: PropTypes.object.isRequired,
    handleGetStudentsList: PropTypes.func,
    profile: PropTypes.object,
  };
  state = {
    showAllTargets: false,
  };

  handleShowAllTargetsChange(val) {
    this.setState({ showAllTargets: val });
  }
  render() {
    const { report, profile, handleGetStudentsList, studentsListPath } = this.props;
    const { tables } = report.d3;
    const { schoolTypeConversion } = report;
    const { showAllTargets } = this.state;

    const sortTables = Object.entries(tables);
    sortTables.sort((a, b) => {
      if (a[0] === ComponentNames.participation) {
        return -1;
      }
      if (b[0] === ComponentNames.participation) {
        return -1;
      }
      return 1;
    });
    return (
      <div id="reportD3">
        <Grid container direction="row" justify="center" alignItems="center">
          <Button variant="contained" onClick={(e) => this.handleShowAllTargetsChange(!showAllTargets)}>
            {showAllTargets ? 'Hide Invalid Targets' : 'Show All Targets'}
            <Tooltip
              title='Due to the nature of how some targets are variable based on prior year performance while others are fixed
            based on statewide constants, sometimes a 1-point or even a 2-point target is greater than the 3-point or
            4-point target in the same indicator. In these cases, we hide these invalid targets that are "out of order"
            to avoid confusion. For example, if the 3-point target is 80 and the 2-point target is 82, we hide the 82,
            because if the school earns 82 points in the indicator, they would be awarded the full 3 points.'
              placement="bottom-end"
              enterDelay={300}
            >
              <InfoIcon style={{ float: 'right' }} />
            </Tooltip>
          </Button>
        </Grid>
        {tables.academicAchievement && (
          <AcademicAchievement
            component={tables.academicAchievement}
            studentsListPath={`${profile.uid}/d1/staar`}
            handleGetStudentsList={handleGetStudentsList}
            profile={profile}
            schoolTypeConversion={schoolTypeConversion}
            showAllTargets={showAllTargets}
          />
        )}
        {tables.academicGrowth && (
          <AcademicGrowth
            component={tables.academicGrowth}
            studentsListPath={`${profile.uid}/d2a`}
            handleGetStudentsList={handleGetStudentsList}
            profile={profile}
            schoolTypeConversion={schoolTypeConversion}
            showAllTargets={showAllTargets}
          />
        )}
        {tables.gradRate && (
          <GradRate
            component={tables.gradRate}
            schoolTypeConversion={schoolTypeConversion}
            showAllTargets={showAllTargets}
          />
        )}
        {tables.telpas && (
          <Telpas
            component={tables.telpas}
            schoolTypeConversion={schoolTypeConversion}
            showAllTargets={showAllTargets}
          />
        )}
        {tables.schoolQuality && (
          <SchoolQuality
            component={tables.schoolQuality}
            schoolTypeConversion={schoolTypeConversion}
            showAllTargets={showAllTargets}
          />
        )}
        {tables.ccmr && (
          <Ccmr component={tables.ccmr} schoolTypeConversion={schoolTypeConversion} showAllTargets={showAllTargets} />
        )}
        {/* {
          tables.participation && (
            <Participation component={tables.participation} />
          )
        } */}
      </div>
    );
  }
}

export default withStyles(styles)(D3);
