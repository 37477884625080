/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, withStyles, Button } from '@material-ui/core';
import { Security } from '@material-ui/icons';
import Icon from '@material-ui/core/Icon/Icon';
import classNames from 'classnames';

import { Routes } from '../../App';
import { BackSkipNext } from '../common';

const styles = (theme) => ({
  securityIcon: {
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    backgroundColor: '#3EA1F8',
    color: 'white',
    marginRight: theme.spacing.unit * 3,
  },
  smallFont: {
    fontSize: '0.75rem',
  },
  mediumFont: {
    fontSize: '0.875rem',
  },
  largeFont: {
    fontSize: '1rem',
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
});

class TakeOffPage extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.any.isRequired,
    completed: PropTypes.object.isRequired,
    user: PropTypes.object,
    profile: PropTypes.object,
    needsInit: PropTypes.bool.isRequired,
    init: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onNextClick: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.shouldInit();
    if (!this.props.needsInit) {
      this.shouldRedirect();
    }
  }

  componentDidUpdate() {
    this.shouldInit();
    this.shouldRedirect();
  }

  shouldRedirect = () => {
    const { completed, history } = this.props;
    if (completed) {
      if (!completed[Routes.studentUpload]) {
        history.push(`/${Routes.onboarding}/${Routes.studentUpload}`);
      }
      if (!completed[Routes.assessments]) {
        history.push(`/${Routes.onboarding}/${Routes.assessments}`);
      }
    }
  };

  shouldInit() {
    const { profile, needsInit, init, history, loading } = this.props;
    if (profile && needsInit && !loading) {
      init(profile, history);
    }
  }

  onBackClick = () => {
    const { history } = this.props;
    history.push(`/${Routes.onboarding}/${Routes.assessments}`);
  };

  onNextClick = () => {
    const { history, completed } = this.props;
    if (completed) {
      if (completed[Routes.studentUpload]) {
        history.push(`/`);
      }
    }
  };

  onNextClick = () => {
    const { onNextClick, history } = this.props;
    onNextClick(history, `/${Routes.roster}`);
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          flexGrow: 1,
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            marginBottom: '90px',
          }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classNames(classes.bold, classes.italic, classes.largeFont)}
          >
            Please place seat backs and tray tables in their full upright position. Prepare for take-off.
          </Typography>
          <Typography variant="body1" className={classNames(classes.largeFont)}>
            Let's go check out the app!
          </Typography>
        </div>
        <div>
          <Grid container justify="flex-start" alignItems="center" direction="row" wrap="nowrap">
            <Icon className={classes.securityIcon}>
              <Security />
            </Icon>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              direction="column"
              style={{ marginBottom: '25px' }}
            >
              <Typography variant="subtitle1" gutterBottom className={classNames(classes.bold, classes.mediumFont)}>
                {' '}
                Data Security{' '}
              </Typography>
              <Typography variant="body1" className={classes.smallFont}>
                CoPilot conforms to FERPA regulations regarding the transfer, storage, and distribution of
                student identifiable information. We will only make student-identifiable data available to those with a
                legitimate educational interest.
              </Typography>
            </Grid>
          </Grid>
        </div>
        <BackSkipNext handleBack={this.onBackClick} handleNext={this.onNextClick} />
      </div>
    );
  }
}

export default withStyles(styles)(TakeOffPage);
