import { isRealNumber } from '../../../common/utils';

export const d3Indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  lep: 'EL (Current & Monitored)',
  specialEdFormer: 'Special Ed (Former)',
  continuouslyEnrolled: 'Continuously Enrolled',
  highFocus: 'High Focus',
};
export const d3IndicatorsLength = Object.keys(d3Indicators).length;

export const d3EmptyIndicators = Object.keys(d3Indicators).reduce((prev, curr) => {
  prev[curr] = null;
  return prev;
}, {});

export const formatNumberOrDash = (number, suffix = '', decimalPoint = 1) =>
  isRealNumber(number) ? `${parseFloat(Number(number).toFixed(decimalPoint))}${suffix}` : '-';

export const numberStringOrDash = (number) => (isRealNumber(number) ? number : number || '-');
