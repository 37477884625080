import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

const BackSkipNext = (props) => {
  const { handleNext, handleBack, handleSkip, nextText, classes } = props;

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      {!!handleBack && (
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>
      )}
      <Grid item xs />
      {!!handleSkip && (
        <Button variant="contained" onClick={handleSkip} className={classes.button}>
          Skip for Now
        </Button>
      )}
      {!!handleNext && (
        <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
          {nextText || 'Next'}
          <Icon className={classes.rightIcon}>send</Icon>
        </Button>
      )}
    </Grid>
  );
};
BackSkipNext.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSkip: PropTypes.func,
  nextText: PropTypes.string,
  classes: PropTypes.any.isRequired,
};

export default withStyles(styles)(BackSkipNext);
