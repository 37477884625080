import { combineReducers } from 'redux';
import moment from 'moment';
import {
  GET_ASSESSMENT_LIST,
  VALIDATE_ASSESSMENT_FILE,
  ADD_ASSESSMENTS_LISTENER,
  REMOVE_ASSESSMENTS_LISTENER,
  ASSESSMENTS_UPDATED,
} from './assessments.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const lastFetched = (state = moment(0), action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ASSESSMENT_LIST) {
        return moment();
      }
      if (action.model === ADD_ASSESSMENTS_LISTENER) {
        return moment();
      }
      if (action.model === REMOVE_ASSESSMENTS_LISTENER) {
        return moment(0);
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_ASSESSMENT_LIST) {
        return 'Loading ...';
      }
      if (action.model === VALIDATE_ASSESSMENT_FILE) {
        return 'Analyzing ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_ASSESSMENT_LIST || action.model === VALIDATE_ASSESSMENT_FILE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const assessmentList = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ASSESSMENT_LIST) {
        return action.data.assessments;
      }
      return state;
    case ASSESSMENTS_UPDATED:
      return action.assessments;
    default:
      return state;
  }
};

const assessmensListeners = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === ADD_ASSESSMENTS_LISTENER) {
        return Object.assign({}, state, {
          [action.id]: action.listener,
        });
      }
      if (action.model === REMOVE_ASSESSMENTS_LISTENER) {
        const { [action.id]: listener, ...newState } = state;
        return newState;
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  loadingMsg,
  lastFetched,
  assessmentList,
  assessmensListeners,
});

export default 'studentsPage.reducers.js';
