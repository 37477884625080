import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Fuse from 'fuse.js';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Table,
  Paper,
  Modal,
  Grid,
  SnackbarContent,
  IconButton,
  Snackbar,
  Tooltip,
  TableSortLabel,
  Select,
  Icon,
  InputBase,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { RosterUploadInstructions } from '../common/RosterUploadInstructions';
import { LoadingCircle, TitleBackButton, Button, TablePaginationActions, makeCsv, ExportCSVButton } from '../common';
import { sortBy, getStudentAvatarUrl, getCurrentRosterYear } from './+store/studentRoster/studentRoster.utils';
import Student from './Student';
import StudentForm from './StudentForm';
import StudentStaarForm from './StudentStaarForm';
import { styles } from './StudentRoster.styles';
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

/*const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});*/

class StudentRoster extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    profile: PropTypes.object,
    needsInit: PropTypes.bool.isRequired,
    init: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onDropForDeleteRemaining: PropTypes.func,
    status: PropTypes.shape({
      uploaded: PropTypes.bool.isRequired,
      error: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
    roster: PropTypes.array,
    deletingStudentId: PropTypes.string,
    editingStudentId: PropTypes.string,
    deletingStudent: PropTypes.object,
    editingStudent: PropTypes.object,
    isAddingNewStudent: PropTypes.bool.isRequired,
    cancelDelete: PropTypes.func.isRequired,
    confirmDelete: PropTypes.func.isRequired,
    handleAddStudentRequest: PropTypes.func.isRequired,
    handleCancelEdit: PropTypes.func.isRequired,
    handleCancelAdd: PropTypes.func.isRequired,
    msg: PropTypes.object,
    handleMsgClose: PropTypes.func,
    uploadedRoster: PropTypes.bool,
    avatars: PropTypes.object,
    snapshotYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelectSnapshotYear: PropTypes.func,
    getSelectedYearRoster: PropTypes.func,
    rosterCSV: PropTypes.object,
    loadingCSV: PropTypes.bool,
    handleRosterExport: PropTypes.func,
    tags: PropTypes.object,
  };

  state = {
    query: '',
    searchedRoster: [],
    uploadNewRoster: false,
    page: 0,
    rowsPerPage: 50,
    order: 'asc',
    orderBy: 'studentId',
    initSearchRoster: false,
    downloaded: false,
    tabValue: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.roster && props.roster.length !== state.searchedRoster.length && !state.initSearchRoster) {
      return {
        ...state,
        //initSearchRoster: true,
        searchedRoster: props.roster,
      };
    }
    return state;
  }

  componentDidMount = () => {
    this.shouldInit();
  };

  componentDidUpdate = async (prevProps) => {
    const { profile, uploadedRoster, init, status, rosterCSV } = this.props;
    const { downloaded } = this.state;
    if (status && prevProps.status !== status) {
      this.setState({ uploadNewRoster: false });
    }
    if (uploadedRoster && uploadedRoster !== prevProps.uploadedRoster) {
      setTimeout(() => init(profile), 2500);
    } else {
      this.shouldInit();
    }
    if (rosterCSV && !downloaded) {
      const { data, columns } = rosterCSV;
      const output = await makeCsv(data, columns);
      const blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
      if (blob) {
        const date = moment();
        this.setState({ downloaded: true });
        saveAs(blob, `Exported-Roster-${date.format('MMDDYYYY')}.csv`);
      }
    }
  };

  onSearchChange = (e) => {
    this.setState({ query: e.target.value });
  };

  onSearchClick = (e) => {
    const options = {
      shouldSort: true,
      threshold: 0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['1.studentId', '1.firstName', '1.lastName', '1.tsdsId', '1.localId'],
    };
    const { roster } = this.props;
    const { query } = this.state;
    let searchedRoster = roster;
    const fuse = new Fuse(searchedRoster, options);
    if (query) {
      searchedRoster = fuse.search(query);
    }
    this.setState({ searchedRoster, initSearchRoster: !!query });
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    let newOrder = 'desc';
    if (orderBy === property && order === 'desc') {
      newOrder = 'asc';
    }
    this.setState({ order: newOrder, orderBy: property });
  };

  uploadNewRosterToggle = () => {
    const { uploadNewRoster } = this.state;
    this.setState({ uploadNewRoster: !uploadNewRoster });
  };

  onDrop = (accepted, rejected) => {
    const { onDrop, user, profile, snapshotYear } = this.props;
    onDrop(accepted, rejected, user, profile, snapshotYear);
  };

  onDropForDeleteRemaining = (accepted, rejected) => {
    const { onDropForDeleteRemaining, user, profile, snapshotYear } = this.props;
    onDropForDeleteRemaining(accepted, rejected, user, profile, snapshotYear);
  };

  renderSnackbar = () => {
    const { classes, msg, handleMsgClose } = this.props;
    const Icon = msg.isError ? ErrorIcon : CheckCircleIcon;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open
        onClose={handleMsgClose}
      >
        <SnackbarContent
          className={classNames(classes[msg.isError ? 'error' : 'success'], classes.margin)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {msg.text}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleMsgClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  };

  handleConfirmDelete = () => {
    const { profile, deletingStudentId, confirmDelete, snapshotYear } = this.props;
    confirmDelete(deletingStudentId, profile.organizationId, snapshotYear);
  };

  shouldInit() {
    const { profile, needsInit, init, loading } = this.props;
    if (profile && needsInit && !loading) {
      init(profile);
    }
  }

  onSearchEnter = (e) => {
    if (e.key === 'Enter') {
      this.onSearchClick();
    }
  };

  renderSearchBar = () => {
    const { classes } = this.props;
    return (
      <Paper className={classes.searchWrapper}>
        <InputBase
          onKeyDown={this.onSearchEnter}
          onChange={this.onSearchChange}
          className={classes.input}
          placeholder="Search By Name or ID"
          inputProps={{ 'aria-label': 'search by name or id' }}
        />
        <IconButton className={classes.iconButton} aria-label="search" onClick={this.onSearchClick}>
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  };

  renderDeleteModal = (student) => {
    const { cancelDelete, classes } = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={cancelDelete}
      >
        <div className={classes.deleteModal}>
          <Typography variant="h6" id="modal-title" gutterBottom>
            {`Are you sure you want to delete records of
            ${student.firstName} ${student.lastName} with ID number of
            ${student.studentId || student.tsdsId || student.localId}?`}
          </Typography>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Button color="dark" onClick={this.handleConfirmDelete}>
              Yes, I am sure.
            </Button>
            <Button color="light" onClick={cancelDelete}>
              Cancel
            </Button>
          </Grid>
        </div>
      </Modal>
    );
  };

  renderEditModal = (student, tags) => {
    const { handleCancelEdit, classes, avatars } = this.props;
    const { tabValue } = this.state;
    var studentAvatar = getStudentAvatarUrl(student, avatars);
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={handleCancelEdit}
      >
        <div className={classes.editModal}>
          <Typography variant="h6" id="modal-title">
            {`Editing the records of ${student.firstName} ${student.lastName} (
            ${student.studentId || student.tsdsId || student.localId})`}
          </Typography>

          <Tabs value={tabValue} onChange={this.handleTabChange}>
            <Tab label="Student Info" />
            <Tab label="Prior Year Staar" />
          </Tabs>
          {tabValue === 0 && (
            <TabContainer>
              <StudentForm studentAvatar={studentAvatar} tags={tags} />
            </TabContainer>
          )}
          {tabValue === 1 && (
            <TabContainer>
              {' '}
              <StudentStaarForm />
            </TabContainer>
          )}
        </div>
      </Modal>
    );
  };

  handleSnapshotYearSelect = (e) => {
    const { onSelectSnapshotYear, getSelectedYearRoster, profile } = this.props;
    const { value } = e.target;
    onSelectSnapshotYear(value);
    getSelectedYearRoster(profile, value);
    this.setState({ initSearchRoster: false, query: '' });
  };

  renderSnapshotYearSelector = () => {
    const { snapshotYear } = this.props;
    const currentYear = getCurrentRosterYear();
    const options = [
      { value: currentYear, label: `${currentYear}-${currentYear + 1}` },
      { value: currentYear - 1, label: `${currentYear - 1}-${currentYear}` },
    ];
    return (
      <Grid item>
        <Typography color="primary">Snapshot Year</Typography>
        <Select onChange={this.handleSnapshotYearSelect} value={snapshotYear}>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    );
  };

  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
  };

  renderAddModal = (tags) => {
    const { classes, handleCancelAdd } = this.props;
    const { tabValue } = this.state;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={handleCancelAdd}
      >
        <div className={classes.editModal}>
          <Typography variant="h6" id="modal-title">
            Add New Student
          </Typography>
          <Tabs value={tabValue} onChange={this.handleTabChange}>
            <Tab label="Student Info" />
          </Tabs>
          {tabValue === 0 && (
            <TabContainer>
              <StudentForm tags={tags} />
            </TabContainer>
          )}
        </div>
      </Modal>
    );
  };

  renderStudentRosterTable = () => {
    const { classes, avatars } = this.props;
    const { rowsPerPage, page, order, orderBy, searchedRoster } = this.state;

    const rosterTableHeaders = {
      name: 'Name',
      sexCode: 'Sex',
      dateOfBirth: 'Date of Birth',
      studentId: 'STUDENT-ID',
      tsdsId: 'TSDS Unique ID',
      localId: 'Local ID',
      gradeLevel: 'Grade Level',
      gradYear: 'Graduation Date',
      campusNumber: 'Campus Number',
      ethnicity: 'Ethnicity',
      ecoDis: 'Is Economically Disadvantaged?',
      spEd: 'Receiving Special Education Services?',
      lep: 'English Learner',
      enrollment: 'Enrollment',
      snapshot: 'Enrolled on Snapshot Day?',
      yearsInUS: 'Years in US Schools',
      asylumStatus: 'Is Asylee/ Refugee/ SIFE?',
      highlyMobile: 'Highly Mobile',
      monitor: 'Monitor',
      mentor: 'Mentor',
      acceleratedTesterMath: 'Accelerated Tester Math',
      acceleratedTesterReading: 'Accelerated Tester Reading',
      acceleratedTesterScience: 'Accelerated Tester Science',
    };

    const tableHeaders = Object.entries(rosterTableHeaders);
    return (
      <Paper className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableRow}>
              <TableCell key="op" align="center" className={classes.iconHead}>
                Options
              </TableCell>
              <TableCell style={{ width: 60 }} align="center" className={classes.iconHead}>
                Profile Picture
              </TableCell>
              {tableHeaders.map(([id, header], index) => (
                <TableCell
                  key={id}
                  align="center"
                  className={classes.head}
                  sortDirection={orderBy === id ? order : false}
                >
                  <Tooltip title={id === 'name' ? 'Sort By Last Name' : 'Sort'} placement="bottom-end" enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === id}
                      direction={order}
                      onClick={this.createSortHandler(id)}
                      style={{
                        whiteSpace: index === 0 ? 'nowrap' : '',
                        width: 1550 / tableHeaders.length,
                      }}
                    >
                      <div style={{ width: 16, margin: 4 }} />
                      {header}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ))}
              <TableCell
                key="testsHeader"
                align="center"
                className={classes.head}
                style={{
                  width: 150,
                }}
              >
                Tests
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {sortBy(searchedRoster, order, orderBy)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(([firebaseId, student]) => {
                const studentAvatar = getStudentAvatarUrl(student, avatars);
                return (
                  <Student
                    rosterTableHeaders={rosterTableHeaders}
                    key={firebaseId}
                    firebaseId={firebaseId}
                    student={student}
                    studentAvatar={studentAvatar}
                  />
                );
              })}
          </TableBody>
          <TableFooter className={classes.pagination}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={3}
                count={searchedRoster.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  };

  exportCsv = () => {
    const { handleRosterExport, snapshotYear } = this.props;
    this.setState({ downloaded: false });
    handleRosterExport(snapshotYear);
  };

  renderActionButtons = () => {
    const { roster, handleAddStudentRequest, loadingCSV } = this.props;
    const { uploadNewRoster } = this.state;
    const isEmptyRoster = !roster || !roster.length;
    if (!isEmptyRoster) {
      if (uploadNewRoster) {
        return (
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Button color="outlined" onClick={this.uploadNewRosterToggle}>
                Go back to roster list
              </Button>
            </Grid>
            {this.renderSnapshotYearSelector()}
          </Grid>
        );
      }
      return (
        <Grid container direction="row" justify="space-between" alignItems="center">
          {this.renderSnapshotYearSelector()}
          {this.renderSearchBar()}
          <Grid item>
            <Button color="outlined" large onClick={this.uploadNewRosterToggle}>
              Upload a New Roster
            </Button>
            <Button color="dark" large onClick={handleAddStudentRequest}>
              Add new student
            </Button>
            <ExportCSVButton title="Export CSV" onClick={this.exportCsv} loading={loadingCSV} />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container direction="row" justify="space-between" alignItems="center">
        {this.renderSnapshotYearSelector()}
      </Grid>
    );
  };

  render() {
    const {
      profile,
      user,
      status,
      classes,
      roster,
      loading,
      deletingStudent,
      editingStudent,
      deletingStudentId,
      editingStudentId,
      isAddingNewStudent,
      msg,
      uploadedRoster,
      avatars,
      snapshotYear,
      tags,
    } = this.props;

    const { uploadNewRoster } = this.state;
    const isEmptyRoster = !roster || !roster.length;
    if (isEmptyRoster && uploadedRoster) {
      return (
        <div className={classes.content}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 16,
              marginTop: '10%',
            }}
          >
            We are currently processing your roster. Please check back in a few minutes.
          </Typography>
        </div>
      );
    }
    if (loading || !profile || (isEmptyRoster && uploadedRoster) || !avatars) {
      return (
        <div className={classes.content}>
          <LoadingCircle classes={classes} />
        </div>
      );
    }
    return (
      <div className={classNames(classes.root, { [classes.coloredRoot]: isEmptyRoster || uploadNewRoster })}>
        <TitleBackButton title="Student Roster" />
        {!!msg && this.renderSnackbar()}
        {this.renderActionButtons()}
        {!isEmptyRoster && !uploadNewRoster && this.renderStudentRosterTable()}
        {(isEmptyRoster || uploadNewRoster) && (
          <RosterUploadInstructions
            isEmptyRoster={isEmptyRoster}
            user={user}
            status={status}
            onDrop={this.onDrop}
            onDropForDeleteRemaining={this.onDropForDeleteRemaining}
            onUploadClick={() => {}}
            onUploadClickForDeleteRemaining={() => {}}
            snapshotYear={snapshotYear}
            profile={profile}
          />
        )}
        {!!deletingStudentId && !isEmptyRoster && this.renderDeleteModal(deletingStudent)}
        {!!editingStudentId && !isEmptyRoster && this.renderEditModal(editingStudent, tags)}
        {!!isAddingNewStudent && !isEmptyRoster && this.renderAddModal(tags)}
      </div>
    );
  }
}

export default withStyles(styles)(StudentRoster);
