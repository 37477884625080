import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';

const styles = (theme) => ({
  root: {},
  menu: {},
  textField: {},
  formControl: {
    display: 'block',
  },
  finalTable: {
    width: 300,
  },
});

class D2bTable extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    report: PropTypes.object.isRequired,
  };

  render() {
    const { report, classes } = this.props;
    // <Table />
    // <TableBody />
    // <CustomTableCell />
    // <TableFooter />
    // <TableHead />
    // <TablePagination />
    // <TableRow />
    // <TableSortLabel />
    const { raw, scaled, letter } = report.d2b;
    return (
      <div id="reportD2b">
        {/* <p style={{ marginLeft: '939px', marginTop: '-82px' }}>
          Letter:
          {letter}
        </p>
        <p style={{ marginLeft: '1022px', marginTop: '-33px' }}>
        Scaled:
          {scaled}
        </p>
        <p style={{ marginLeft: '1122px', marginTop: '-33px', paddingBottom: '35px' }}>
        Raw:
          {raw}
        </p> */}
      </div>
    );
  }
}

export default withStyles(styles)(D2bTable);
