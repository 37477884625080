import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import Add from '@material-ui/icons/Add';
import { Grid, IconButton, Typography, Paper, InputBase, MenuItem, Menu, Fade, Modal } from '@material-ui/core';
import styles from './ManageOrgTags.styles';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import * as Fuse from 'fuse.js';
import TextInputField from '../../Auth/TextInputField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button } from '../../common';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

class ManageOrgTags extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    tags: PropTypes.object,
    addedTag: PropTypes.object,
    deletedTag: PropTypes.object,
    init: PropTypes.func,
    save: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    status: PropTypes.shape({
      success: PropTypes.bool,
      message: PropTypes.string,
    }),
  };
  constructor() {
    super();
    this.state = {
      currentInstance: this,
    };
  }
  state = {
    anchorEl: null,
    isDeleteModal: false,
    isSaveModal: false,
    order: 'asc',
    initSearchTags: false,
    initSearchTagsLoad: false,
    searchedTags: [],
    query: '',
    selectedTag: {},
    firebaseId: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.initSearchTagsLoad || props.addedTag.firebaseId || props.deletedTag.firebaseId) {
      if (props.addedTag && props.addedTag.firebaseId) {
        if (!props.tags) {
          props.tags = {};
        }
        props.tags[props.addedTag.firebaseId] = props.addedTag.tag;
        props.addedTag.firebaseId = null;
      }

      let hasValue = props.tags != null && Object.entries(props.tags).length > 0;
      if (hasValue) {
        if (props.deletedTag && props.deletedTag.firebaseId) {
          delete props.tags[props.deletedTag.firebaseId];
          props.deletedTag.firebaseId = null;
        }
        let temp = [];
        Object.entries(props.tags).forEach((item) => {
          let itemNew = item[1];
          itemNew.firebaseId = item[0];
          temp.push(itemNew);
        });

        return {
          ...state,
          initSearchTagsLoad: hasValue,
          order: 'desc',
          searchedTags: temp,
        };
      }
    }
    return state;
  }

  componentDidMount() {
    const { init } = this.props;
    init();
  }

  handleSort = (event) => {
    const { order } = this.state;
    let newOrder = 'desc';
    if (order === 'desc') {
      newOrder = 'asc';
    }
    this.setState({ order: newOrder });
  };

  onSearchChange = (e) => {
    this.setState({ query: e.target.value });
  };

  onSearchClick = (e) => {
    const options = {
      shouldSort: true,
      threshold: 0.05,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['name'],
    };
    const { tags } = this.props;
    const { query } = this.state;
    let searchedTagsTmp = Object.entries(tags).map((item) => {
      return item[1];
    });
    const fuse = new Fuse(searchedTagsTmp, options);
    if (query) {
      searchedTagsTmp = fuse.search(query); //add .map(i => searchedTagsTmp[i]) in case of primitve array
    }
    this.setState({ searchedTags: searchedTagsTmp, initSearchTags: !!query });
  };

  onSearchEnter = (e) => {
    if (e.key === 'Enter') {
      this.onSearchClick();
    }
  };
  onSortChange = () => (event) => {
    this.handleSort(event);
  };

  sortBy = (array, order) => {
    if (order == null) {
      order = 'asc';
    }

    if (order == null) {
      order = 'asc';
    }

    const orderConst = order === 'desc' ? 1 : -1;
    const orderByKey = 'name';
    if (array == null || array.length == 0) {
      return [];
    }
    if (array.length == 1) {
      return array;
    }
    return array.sort((a, b) => {
      let ele1 = a[orderByKey];
      let ele2 = b[orderByKey];
      if (!ele1 && !ele2) {
        return 0;
      }
      if (ele1 && !ele2) {
        return orderConst;
      }
      if (ele2 && !ele1) {
        return -orderConst;
      }
      if (typeof ele1 === 'string' || typeof ele2 === 'string') {
        return ele1.localeCompare(ele2) * orderConst;
      }
      return (ele1 - ele2) * orderConst;
    });

    /* for primitive array
    if(order == 'asc'){
      return array.sort().reverse();
      
    }else{
      return array.sort();
    }*/
  };

  handleSaveSubmit = (values) => {
    var data = this.props.save(this.state.firebaseId, values);
    this.handleCloseModal();
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleOpenNewModal = () => {
    this.setState({ isSaveModal: true, firebaseId: null, anchorEl: null });
  };
  handleOpenEditModal = (firebaseId) => {
    this.setState({ isSaveModal: true, firebaseId: firebaseId, anchorEl: null });
  };
  handleOpenDeleteModal = (firebaseId) => {
    this.setState({ isDeleteModal: true, firebaseId: firebaseId, anchorEl: null });
  };

  handleCloseModal = () => {
    this.setState({
      isDeleteModal: false,
      isSaveModal: false,
    });
  };

  handleConfirmDelete = () => {
    this.props.delete(this.state.firebaseId);
    this.handleCloseModal();
  };
  saveTagSchema = () => {
    return Yup.object().shape({
      name: Yup.string()
        .min(2, 'The Name should at least have 2 characters.')
        .required('Required.'),
    });
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  renderEditMenu(firebaseId) {
    const { anchorEl } = this.state;
    let menuId = 'fade-menu=button' + firebaseId;
    const open = anchorEl != null && anchorEl.id == menuId;
    return (
      <div>
        <IconButton
          id={menuId}
          aria-label="More"
          aria-owns={open ? 'fade-menu' + firebaseId : undefined}
          aria-haspopup="true"
          onClick={this.handleMenuClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu id={menuId} anchorEl={anchorEl} open={open} onClose={this.handleMenuClose} TransitionComponent={Fade}>
          <MenuItem key="Edit" onClick={() => this.handleOpenEditModal(firebaseId)}>
            Edit
          </MenuItem>
          <MenuItem key="Delete" onClick={() => this.handleOpenDeleteModal(firebaseId)}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }

  renderStatus = () => {
    const { classes, status } = this.props;
    const Icon = status.success ? CheckCircleIcon : ErrorIcon;
    if (status.message) {
      return (
        <div
          className={classNames(classes.statusMsg, {
            [classes.success]: status.success,
            [classes.error]: !status.success,
          })}
        >
          <Icon className={status.success ? classes.success : classes.error} />
          {status.message}
        </div>
      );
    }
    return null;
  };

  renderSearchBar = () => {
    const { classes } = this.props;
    return (
      <Paper>
        <InputBase
          fullWidth
          onKeyDown={this.onSearchEnter}
          onChange={this.onSearchChange}
          className={classes.input}
          placeholder="Name"
          inputProps={{ 'aria-label': 'Name' }}
          endAdornment={
            <IconButton className={classes.iconButton} aria-label="search" onClick={this.onSearchClick}>
              <SearchIcon />
            </IconButton>
          }
        />
      </Paper>
    );
  };
  renderSortIcon() {
    const { order } = this.state;
    if (order == 'asc') {
      return <ArrowUpwardIcon fontSize="small" />;
    } else {
      return <ArrowDownwardIcon fontSize="small" />;
    }
  }

  renderSaveTagModal() {
    const { classes, tags } = this.props;
    const { firebaseId } = this.state;

    const initialValues = {
      name: tags != null && firebaseId != null ? tags[firebaseId].name : '',
    };

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={this.handleCloseModal}
      >
        <div className={classes.modal}>
          <Formik
            key="saveTag"
            validateOnBlur
            enableReinitialize
            initialValues={initialValues}
            validationSchema={this.saveTagSchema}
            onSubmit={this.handleSaveSubmit}
          >
            {({ values, errors, touched }) => (
              <Form className={classes.form}>
                <Typography variant="h6" id="modal-title" gutterBottom>
                  {'Save Tag'}
                </Typography>

                <TextInputField
                  label="Name*"
                  id="name"
                  touched={touched.displayName}
                  error={errors.displayName}
                  autoComplete="name"
                  className={classes.inputField}
                  flexDirection="column"
                />

                <Grid container direction="row" justify="center" alignItems="center">
                  <Button large color="white" onClick={this.handleCloseModal}>
                    Cancel
                  </Button>
                  <Button submit large color="dark">
                    {'Save'}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }

  renderDeleteTagModal() {
    const { firebaseId } = this.state;
    const { classes, tags } = this.props;
    let msg = `Are you sure you want to delete ${tags[firebaseId].name}?`;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={this.handleCloseModal}
      >
        <div className={classNames(classes.modal, classes.smallModal)}>
          <Typography variant="h6" id="modal-title" gutterBottom>
            {msg}
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center">
            <Button color="white" onClick={this.handleCloseModal}>
              Cancel
            </Button>
            <Button color="dark" onClick={this.handleConfirmDelete}>
              Yes, I am sure.
            </Button>
          </Grid>
        </div>
      </Modal>
    );
  }

  render() {
    const { classes } = this.props;
    const { order, searchedTags, isSaveModal, isDeleteModal } = this.state;

    return (
      <React.Fragment>
        {!searchedTags && (
          <Typography variant="h6" gutterBottom>
            No Result
          </Typography>
        )}

        {this.renderSearchBar()}
        <p></p>

        <Grid container direction="column" justify="flex-start" alignItems="center">
          {this.renderStatus()}
          <Grid container direction="row" justify="space-between" alignItems="center" item>
            <div onClick={this.onSortChange()} key="TagName" style={{ background: '#f8ffff', cursor: 'pointer' }}>
              Tag Name
              {this.renderSortIcon()}
            </div>

            <div className={classNames(classes.cell, classes.iconCell, classes.headerCell)}>
              <IconButton onClick={this.handleOpenNewModal}>
                <Add />
              </IconButton>
            </div>
          </Grid>

          {searchedTags &&
            searchedTags.length > 0 &&
            this.sortBy(searchedTags, order).map((tag, index) => {
              return (
                <Grid container direction="row" justify="space-between" alignItems="center" key={index} item>
                  <div className={classNames(classes.cell, classes.smallCell)} key={index}>
                    {tag.name}
                  </div>
                  <div
                    className={classNames(classes.cell, classes.iconCell)}
                    style={{ background: 'rgba(255,255,255,0.0)' }}
                  >
                    {this.renderEditMenu(tag.firebaseId)}
                  </div>
                </Grid>
              );
            })}

          {isSaveModal && this.renderSaveTagModal()}
          {isDeleteModal && this.renderDeleteTagModal()}
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ManageOrgTags);
