import { connect } from 'react-redux';
import Component from './AuthLayout.component';

const mapStateToProps = (state) => ({
  profile: state.modules.Auth.auth.profile,
  error: state.modules.Auth.auth.error,
  loading: !!state.modules.Auth.auth.loadingMsg,
  loadingMsg: state.modules.Auth.auth.loadingMsg,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
