/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const SelectFilter = ({ name, filterLabel, classes, filterValue, handleChange, options }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={`pg-${name}`}>{filterLabel}</InputLabel>
      <Select
        value={filterValue}
        onChange={handleChange}
        inputProps={{
          name,
          id: `pg-${name}`,
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
SelectFilter.propTypes = {
  name: PropTypes.any,
  classes: PropTypes.any,
  filterValue: PropTypes.any,
  filterLabel: PropTypes.any,
  handleChange: PropTypes.any,
  options: PropTypes.any,
};

export default SelectFilter;
