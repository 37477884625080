import pdfjs from 'pdfjs-dist';

const isFYLI2016 = (file) =>
  new Promise((resolve) => {
    if (file.type !== 'application/pdf') {
      resolve(false);
      return;
    }
    pdfjs
      .getDocument(file.preview)
      .then((pdf) => {
        // console.log('pdf', pdf);
        // pdf.getMetadata().then((info) => {
        //   console.log('info', info);
        // });
        pdf.getPage(1).then((page) => {
          // console.log('page', page);
          page.getTextContent().then((content) => {
            // console.log('content', content);
            const lookingFor = [
              {
                re: /^C O N F I D E N T I A L$/,
                key: 'a',
              },
              {
                re: /^T E X A S {3}E D U C A T I O N {3}A G E N C Y$/,
                key: 'b',
              },
              {
                re: /^CLASS OF 2016 FIVE-YEAR EXTENDED LONGITUDINAL SUMMARY REPORT$/,
                key: 'c',
              },
              {
                re: /^District Name: .*$/,
                key: 'd',
              },
              {
                re: /^District No.: (\d{6})$/,
                key: 'e',
              },
            ];
            const foundThings = {
              a: 0,
              b: 0,
              c: 0,
              d: 0,
              e: 0,
            };
            content.items.forEach((item) => {
              lookingFor.forEach((looking) => {
                if (looking.re.test(item.str)) {
                  foundThings[looking.key] += 1;
                }
              });
            });
            // console.log('found', foundThings);
            if (!Object.values(foundThings).includes(0)) {
              resolve(true);
            }
            resolve(false);
          });
        });
      })
      .catch(() => {
        resolve(false);
      });
  });

export default isFYLI2016;
