import parse from 'csv-parse';
const STAARCustomCols = [
  'LocalId',
  'StudentId',
  'TestedGrade',
  'TestVersion',
  'Subject',
  'Language',
  'ScaleScore',
  'CampusNumber',
];

const isSTAARCustom = (file) =>
  new Promise((resolve) => {
    if (
      file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      file.type !== 'application/vnd.ms-excel' &&
      file.type !== 'text/csv'
    ) {
      //text/csv'
      resolve({ isValid: false });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      parse(text, {}, (err, output) => {
        if (err) {
          resolve({ isValid: false });
          return;
        }
        if (JSON.stringify(output[0].filter((x) => x)) === JSON.stringify(STAARCustomCols)) {
          resolve({ isValid: true });
        } else {
          resolve({ isValid: false });
        }
      });
    };
    reader.readAsText(file);
  });

export default isSTAARCustom;
