import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import { getStudentAvatarUrl } from '../../StudentRoster/+store/studentRoster';

const styles = (theme) => ({
  row: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  cell: {
    padding: 0,
  },
  lastName: {
    textTransform: 'uppercase',
    fontSize: '0.875rem',
  },
  firstName: {
    textTransform: 'lowercase',
    fontSize: '0.875rem',
    marginRight: '5px',
    '&:first-letter': { textTransform: 'uppercase' },
  },
  profilePicture: {
    height: 30,
    width: 30,
    borderRadius: 15,
  },
  studentDisplay: {
    display: 'flex',
  },
  profileContent: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
});

class WeightsRow extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    student: PropTypes.object.isRequired,
    weight: PropTypes.number.isRequired,
    avatars: PropTypes.object,
  };

  gradeColorScale = (weight) => {
    const width = weight / 7;
    const colors = '#EE9696';

    const style = {
      root: {
        backgroundColor: '#FFE0B1',
        width: '100%',
        marginRight: '20px',
      },
      progress: {
        width: width * 75 > 0 ? `${width * 100}%` : '7.5%',
        backgroundColor: weight > 0 ? colors : null,
      },
    };
    return (
      <div style={style.root}>
        <div style={style.progress}>&nbsp;</div>
      </div>
    );
  };

  getStudentProfilePicture = (photoUrl, profileCss = '') => {
    const { student, avatars } = this.props;
    const avatarUrl = getStudentAvatarUrl(student, avatars);
    const studentPhotoUrl = photoUrl && photoUrl.small ? photoUrl.small : avatarUrl;
    if (studentPhotoUrl) {
      return <img src={studentPhotoUrl} alt="Logo" className={profileCss} />;
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  };

  getTeacher = (type) => {
    const { student } = this.props;
    const studentTests =
      (student.currentYearTests && student.currentYearTests[0] && student.currentYearTests[0].tests) || [];
    let teacher;
    let types;
    if (type === 'math') {
      types = ['math', 'algebraOne', 'algebraTwo'];
    }
    if (type === 'reading') {
      types = ['reading', 'englishOne', 'englishTwo'];
    }
    for (let idx = 0; idx < studentTests.length; idx += 1) {
      if (types.includes(studentTests[idx].subject)) {
        teacher = studentTests[idx].teacher;
      }
    }
    return teacher || 'No Teacher Found';
  };

  render() {
    const { student, classes, weight } = this.props;

    let accountabilityWeight = 0;
    if (!isNaN(weight)) {
      accountabilityWeight = weight.toFixed(1);
    }

    return (
      <TableRow hover className={classes.row}>
        <TableCell key="name" align="left" className={classes.cell}>
          <Grid container alignItems="center">
            <div className={classes.studentDisplay}>
              {this.getStudentProfilePicture(student.photoUrl, classes.profilePicture)}
              <div className={classes.profileContent}>
                <Typography className={classes.firstName} color="primary">
                  {student.firstName}
                </Typography>
                <Typography className={classes.lastName} color="primary">
                  {student.lastName}
                </Typography>
              </div>
            </div>
          </Grid>
        </TableCell>
        <TableCell key="mathTeacher" align="left" className={classes.cell}>
          <Typography className={classes.lastName} color="primary">
            {this.getTeacher('math')}
          </Typography>
        </TableCell>
        <TableCell key="readingTeacher" align="left" className={classes.cell}>
          <Typography className={classes.lastName} color="primary">
            {this.getTeacher('reading')}
          </Typography>
        </TableCell>
        <TableCell key="weight" align="center" className={classes.cell}>
          <Grid container wrap="nowrap" alignItems="center">
            {this.gradeColorScale(accountabilityWeight)}
            <Typography className={classes.lastName} color="primary">
              {accountabilityWeight}
            </Typography>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(WeightsRow);
