import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Modal,
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  withStyles,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { LoadingCircle } from '../../common';
import { Assessments } from '../+store';

const styles = (theme) => ({
  deleteModal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
});

class DeleteModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    assessment: PropTypes.object,
    cancelDelete: PropTypes.func.isRequired,
    doDelete: PropTypes.func.isRequired,
    classes: PropTypes.any.isRequired,
    groupId: PropTypes.string,
    isGroup: PropTypes.bool,
  };

  render() {
    const { assessment, open, doDelete, cancelDelete, classes, isGroup, groupId } = this.props;
    return assessment ? (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={this.cancelDelete}
      >
        <div className={classes.deleteModal}>
          <Typography variant="h6" id="modal-title" gutterBottom>
            {`Are you sure you want to delete the ${assessment.name || 'Unknown'} ${isGroup ? 'group' : 'assessment'}?`}
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: 24 }}>
            <Button color="primary" variant="contained" onClick={cancelDelete} style={{ width: 96, marginRight: 8 }}>
              Cancel
            </Button>
            <Button color="inherit" onClick={isGroup ? doDelete(groupId) : doDelete(assessment)} style={{ width: 96 }}>
              Yes
            </Button>
          </Grid>
        </div>
      </Modal>
    ) : null;
  }
}

export default withStyles(styles)(DeleteModal);
