import moment from 'moment';

export const getCurrentYear = () => {
  if (moment().month() < 6) {
    return `${moment().year()}`;
  }
  return `${moment().year() + 1}`;
};

export const getPriorYear = () => {
  var cy = getCurrentYear();
  var py = `${Number(cy) - 1}`;
  // change Prior year to be 2019 instead of 2020
  if (py == 2020) {
    py = 2019;
  }
  return py;
};

export const getSchoolYearsModel = () => {
  var currentYear = new Date().getFullYear();
  var schoolsYeasModel = {};
  for (var i = 2019; i <= currentYear + 1; i++) {
    var key = i + '';
    var startYear = i - 1;
    var endYear = i;
    schoolsYeasModel[i] = startYear + '-' + endYear;
  }
  return schoolsYeasModel;
};

export const getSchoolYearsList = () => {
  var schoolYearsModel = getSchoolYearsModel();
  var schoolYearsList = [];
  Object.keys(schoolYearsModel).forEach(function(key) {
    schoolYearsList.push({ value: key, label: schoolYearsModel[key] });
  });
  return schoolYearsList;
};
