import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';

const styles = (theme) => ({});

const indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  ecoDis: 'Economically Disadvantaged',
  lep: 'EL',
  specialEdCurrent: 'Special Ed',
};

const CustomTableCell = withStyles((theme) => ({
  head: {
    padding: 8,
  },
  body: {
    padding: '4px 8px',
    textAlign: 'center',
    width: '6.6667%',
  },
}))(TableCell);

const RowLabelCell = withStyles((theme) => ({
  head: {
    padding: 8,
  },
  body: {
    padding: '4px 8px',
    textAlign: 'left',
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    height: 'unset',
  },
}))(TableRow);

const SubjectSubTable = ({ classes, subtableLabel, subtable }) => {
  if (!subtable) return null;
  console.log('subtable', subtable);
  return (
    <React.Fragment>
      <CustomTableRow>
        <RowLabelCell>{subtableLabel}</RowLabelCell>
        {Object.entries(indicators).map(([key1], index) => {
          if (index % 2) {
            return <CustomTableCell key={key1} style={{ background: 'rgba(255,255,255,0.4)' }} />;
          }
          return <CustomTableCell key={key1} />;
        })}
      </CustomTableRow>
      <CustomTableRow>
        <RowLabelCell>% Graduated</RowLabelCell>
        {Object.entries(indicators).map(([key1], index) => {
          if (index % 2) {
            return (
              <CustomTableCell style={{ background: 'rgba(255,255,255,0.4)' }} key={key1}>
                {subtable[key1].rate || '-'}
              </CustomTableCell>
            );
          }
          return <CustomTableCell key={key1}>{subtable[key1].rate || '-'}</CustomTableCell>;
        })}
      </CustomTableRow>
      <CustomTableRow>
        <RowLabelCell># Graduated</RowLabelCell>
        {Object.entries(indicators).map(([key1], index) => {
          if (index % 2) {
            return (
              <CustomTableCell style={{ background: 'rgba(255,255,255,0.4)' }} key={key1}>
                {subtable[key1].numerator || '-'}
              </CustomTableCell>
            );
          }
          return <CustomTableCell key={key1}>{subtable[key1].numerator || '-'}</CustomTableCell>;
        })}
      </CustomTableRow>
      <CustomTableRow>
        <RowLabelCell>Total Students in Class</RowLabelCell>
        {Object.entries(indicators).map(([key1], index) => {
          if (index % 2) {
            return (
              <CustomTableCell style={{ background: 'rgba(255,255,255,0.4)' }} key={key1}>
                {subtable[key1].denominator || '-'}
              </CustomTableCell>
            );
          }
          return <CustomTableCell key={key1}>{subtable[key1].denominator || '-'}</CustomTableCell>;
        })}
      </CustomTableRow>
    </React.Fragment>
  );
};
SubjectSubTable.propTypes = {
  subtable: PropTypes.object,
  classes: PropTypes.any,
  subtableLabel: PropTypes.string.isRequired,
};

export default withStyles(styles)(SubjectSubTable);
