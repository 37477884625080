import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Icon } from '@material-ui/core';
import { ExportCSVButton, LoadingCircle, TitleBackButton, makeCsv } from '../../common';
import styles from './FlightPlanTable.styles';
import { Subjects } from '../+store/flightPlan';
import { getStudentAvatarUrl } from '../../StudentRoster/+store/studentRoster';
import { saveAs } from 'file-saver';

const getStudentName = (step) => {
  if (step.student) {
    if (step.student.middleInitial) {
      return `${step.student.firstName} ${step.student.middleInitial} ${step.student.lastName}`;
    }
    return `${step.student.firstName} ${step.student.lastName}`;
  }
  return 'Student Name';
};

const getStudentLocalId = (step) => {
  if (step.student) {
    return `${step.student.localId}`;
  }
  return 'Student ID';
};

const getStudentProfilePicture = (student, avatars, profileCss = '') => {
  const avatarUrl = getStudentAvatarUrl(student, avatars);
  const studentPhotoUrl = student && student.photoUrl && student.photoUrl.small ? student.photoUrl.small : avatarUrl;
  if (studentPhotoUrl) {
    return <img src={studentPhotoUrl} alt="Logo" className={profileCss} />;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

const StepNames = {
  doesNotMeet: 'Does Not Meet',
  approaches: 'Approaches',
  meets: 'Meets',
  masters: 'Masters',
  noProgress: 'No Progress',
  expectedProgress: 'Expected Progress',
};

const getImpactBar = (weight) => {
  const step = Math.ceil(weight * 10);
  const circles = [
    { size: 2, color: '#13D852' },
    { size: 3, color: '#51FE85' },
    { size: 4, color: '#96FD9F' },
    { size: 5, color: '#AEFEA6' },
    { size: 6, color: '#CCF9C2' },
    { size: 7, color: '#FFF0C9' },
    { size: 8, color: '#F5DCD1' },
    { size: 9, color: '#FFC4C0' },
    { size: 10, color: '#F28D8D' },
    { size: 11, color: '#FF5656' },
  ];
  const inactive = '#737373';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      {circles.map((circle, index) => (
        <div
          key={index}
          style={{
            width: '10px',
            height: `${circle.size}px`,
            backgroundColor: index <= step - 1 ? circle.color : inactive,
          }}
        />
      ))}
    </div>
  );
};

const getAttainabilityBar = (weight) => {
  const step = Math.ceil(weight * 10);
  const circles = [
    { size: 2, color: '#13D852' },
    { size: 3, color: '#51FE85' },
    { size: 4, color: '#96FD9F' },
    { size: 5, color: '#AEFEA6' },
    { size: 6, color: '#CCF9C2' },
    { size: 7, color: '#FFF0C9' },
    { size: 8, color: '#F5DCD1' },
    { size: 9, color: '#FFC4C0' },
    { size: 10, color: '#F28D8D' },
    { size: 11, color: '#FF5656' },
  ];
  const inactive = '#737373';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      {circles.map((circle, index) => (
        <div
          key={index}
          style={{
            width: '10px',
            height: `${circle.size}px`,
            backgroundColor: index <= step - 1 ? circle.color : inactive,
          }}
        />
      ))}
    </div>
  );
};

const getAttainabilityBarOld = (weight) => {
  const step = Math.ceil(weight * 10);
  const steps = [
    '#EF9C9C',
    'linear-gradient(90deg, #EE9696 0%, #FFC4C0 51.93%), #EE9696',
    'linear-gradient(90deg, #EE9696 0%, #FFDCC9 91.16%), #EE9696',
    'linear-gradient(90deg, #EE9696 0%, #FFE6C9 76.8%), #EE9696',
    'linear-gradient(90deg, #EE9696 0%, #FFF0C9 64.64%), #EE9696',
    'linear-gradient(90deg, #EE9696 0%, #FFF6C9 60.22%, #C0F9C0 100%), #EE9696',
    'linear-gradient(90deg, #EE9696 0%, #FFF3C7 57.03%, #ABFFAC 100%), #EE9696',
    'linear-gradient(90deg, #EE9696 0%, #FFF5C8 51.38%, #9BFFA5 100%), #EE9696',
    'linear-gradient(90deg, #EE9696 0%, #FFF5C8 49.17%, #75FFA4 100%), #EE9696',
    'linear-gradient(90deg, #EE9696 0%, #FFF4C8 48.62%, #59FF92 100%), #EE9696',
  ];
  const style = {
    root: {
      backgroundColor: '#737373',
      width: '100px',
      height: '10px',
    },
    progress: {
      width: step > 0 ? `${step * 10}px` : 0,
      background: weight > 0 ? steps[step - 1] : null,
      height: '10px',
    },
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      <div style={style.root}>
        <div style={style.progress} />
      </div>
    </div>
  );
};

class FlightPlanTable extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    steps: PropTypes.array.isRequired,
    avatars: PropTypes.object,
  };

  exportCsv = async () => {
    const { steps } = this.props;

    const csvColumns = [
      'Step Id',
      'Local Id',
      'Student Name',
      'Test Subject',
      'Status Before',
      'Status After',
      'A-F Impact',
      'Attainability',
      'A-F Score',
    ];

    const data = steps
      .filter((step) => !!step.student)
      .slice(0, 1000)
      .map((step) => ({
        'Step Id': step.flightPlanScore + 1 || '-',
        'Student Name': getStudentName(step) || '-',
        'Local Id': getStudentLocalId(step) || '-',
        'Test Subject': Subjects[step.subject] || '-',
        'Status Before': StepNames[step.steps.current] || '-',
        'Status After': StepNames[step.steps.next] || '-',
        'A-F Impact': step.impact ? (step.impact * 100).toFixed(0) + '%' : '-',
        Attainability: step.attainability ? (step.attainability * 100).toFixed(0) + '%' : '-',
        'A-F Score': step.roundedROOS || '-',
      }));
    const output = await makeCsv(data, csvColumns);
    const blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
    if (blob) {
      saveAs(blob, 'FlightPlan.csv');
    }
  };

  componentDidMount() {}

  render() {
    const { classes, steps, avatars } = this.props;
    const columnWidth = `${85 / 5}%`;
    return (
      <React.Fragment>
        <TitleBackButton title="Flight Plan">
          {steps && steps.length && <ExportCSVButton title="Export CSV" onClick={this.exportCsv} />}
        </TitleBackButton>

        <div className={classes.header}>
          <div className={classes.headerRow}>
            <div className={classes.headerColumn} style={{ width: '5%' }}>
              <Typography className={classes.headerColumnText}>Step</Typography>
            </div>
            <div className={classes.headerColumn} style={{ width: '5%' }}>
              <Typography className={classes.headerColumnText}>Profile Picture</Typography>
            </div>
            <div className={classes.headerColumn} style={{ width: columnWidth }}>
              <Typography className={classes.headerColumnText}>Name</Typography>
            </div>
            <div className={classes.headerColumn} style={{ width: columnWidth }}>
              <Typography className={classes.headerColumnText}>Test Subject</Typography>
            </div>
            <div className={classes.headerColumn} style={{ width: columnWidth }}>
              <Typography className={classes.headerColumnText}>Status</Typography>
            </div>
            <div className={classes.headerColumn} style={{ width: columnWidth }}>
              <Typography className={classes.headerColumnText}>A-F Impact</Typography>
            </div>
            <div className={classes.headerColumn} style={{ width: columnWidth }}>
              <Typography className={classes.headerColumnText}>Attainability</Typography>
            </div>
            <div className={classes.headerColumn} style={{ width: '5%' }}>
              <Typography className={classes.headerColumnText}>A-F Score</Typography>
            </div>
          </div>
        </div>
        <div className={classes.body}>
          {steps &&
            steps
              .filter((step) => !!step.student)
              .slice(0, 1000)
              .map((step) => (
                <div key={`${step.studentId}:${step.subject}`} className={classes.bodyRow}>
                  <div className={classes.bodyColumn} style={{ width: '5%' }}>
                    <Typography className={classes.bodyColumnText}>{step.flightPlanScore + 1}</Typography>
                  </div>
                  <div className={classes.bodyColumn} style={{ width: '5%' }}>
                    {getStudentProfilePicture(step.student, avatars, classes.profilePicture)}
                  </div>
                  <div className={classes.bodyColumn} style={{ width: columnWidth }}>
                    <Typography className={classes.bodyColumnText}>{getStudentName(step)}</Typography>
                  </div>
                  <div className={classes.bodyColumn} style={{ width: columnWidth }}>
                    <Typography className={classes.bodyColumnText}>{Subjects[step.subject]}</Typography>
                  </div>
                  <div className={classes.bodyColumn1} style={{ width: columnWidth }}>
                    <Typography className={classes.bodyColumnText}>{StepNames[step.steps.current]}</Typography>
                    <Icon className={classes.arrowIcon}>arrow_forward</Icon>
                    <Typography className={classes.bodyColumnText}>{StepNames[step.steps.next]}</Typography>
                  </div>
                  <div className={classes.bodyColumn} style={{ width: columnWidth }}>
                    {getImpactBar(step.impact)}
                  </div>
                  <div className={classes.bodyColumn} style={{ width: columnWidth }}>
                    {getAttainabilityBar(step.attainability)}
                  </div>
                  <div className={classes.bodyColumn} style={{ width: '5%' }}>
                    <Typography className={classes.bodyColumnText}>{step.roundedROOS}</Typography>
                  </div>
                </div>
              ))}
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FlightPlanTable);
