import { connect } from 'react-redux';
import Component from './AtoFBase.component';
import { getAssessmentGroups, getAssessmentList } from '../../Assessments';
import { needsToBeFetched } from '../../Assessments/+store/assessments/assessments.selectors';
import { getSchools } from '../../Settings/+store/SchoolSettings';
import { getAvatars, initStudentsRoster } from '../../StudentRoster/+store/studentRoster';
import { getStudentsList } from '../+store/aToF';

const mapStateToProps = (state) => {
  const loadingAssessmentsMsg = state.modules.Assessments.assessments.loadingMsg;
  const loadingReportMsg = state.modules.AtoF.aToF.loadingMsg;
  const loadingReportFilterMsg =
    loadingReportMsg &&
    Object.values(loadingReportMsg)
      .filter((msg) => !!msg)
      .join('\n');
  const loadingMsg = loadingAssessmentsMsg || loadingReportFilterMsg;
  const loading = !!loadingMsg;
  return {
    profile: state.modules.Auth.auth.profile,
    needsInit: needsToBeFetched(state),
    loadingMsg,
    loading,
    status: state.modules.AtoF.aToF.status,
    assessmentList: state.modules.Assessments.assessments.assessmentList,
    reportData: state.modules.AtoF.aToF.reportData,
    assessmentGroups: state.modules.Assessments.groupAssessments.assessmentGroups,
    filters: state.modules.AtoF.aToF.filters,
    schoolList: state.modules.Settings.schoolSettings.schoolList,
    students: state.modules.StudentRoster.studentRoster.roster,
    avatars: state.modules.StudentRoster.studentRoster.avatars,
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: (profile) => {
    dispatch(getAssessmentList(profile));
  },
  getStudentRoster: (profile) => {
    dispatch(initStudentsRoster(profile));
  },
  getSchools: (profile) => {
    dispatch(getSchools(profile));
  },
  getAssessmentGroups: (profile) => {
    dispatch(getAssessmentGroups(profile));
  },
  getAvatars: () => {
    dispatch(getAvatars());
  },
  handleGetStudentsList: (orgId, path) => {
    dispatch(getStudentsList(orgId, path));
  },
});
const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
