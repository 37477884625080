export const CANCEL_UPLOAD = 'assessmentManager.cancelUpload';
export const cancelUpload = () => ({
  type: CANCEL_UPLOAD,
});

export const OPEN_UPLOAD_DIALOG = 'assessmentManager.openUploadDialog';
export const openUploadDialog = () => ({
  type: OPEN_UPLOAD_DIALOG,
});

export const CLOSE_UPLOAD_DIALOG = 'assessmentManager.closeUploadDialog';
export const closeUploadDialog = () => ({
  type: CLOSE_UPLOAD_DIALOG,
});

export const OPEN_CREATE_GROUP_DIALOG = 'assessmentManager.openCreateGroupDialog';
export const openCreateGroupDialog = () => ({
  type: OPEN_CREATE_GROUP_DIALOG,
});

export const CLOSE_CREATE_GROUP_DIALOG = 'assessmentManager.closeCreateGroupDialog';
export const closeCreateGroupDialog = () => ({
  type: CLOSE_CREATE_GROUP_DIALOG,
});

export default 'assessmentManager.actions.js';
