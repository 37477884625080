export const Grades = [
  {
    code: 'EE',
    label: 'Early Education',
  },
  {
    code: 'PK',
    label: 'Pre-Kindergarten',
  },
  {
    code: 'KG',
    label: 'Kindergarten',
  },
  {
    code: '01',
    label: '1st Grade',
  },
  {
    code: '02',
    label: '2nd Grade',
  },
  {
    code: '03',
    label: '3rd Grade',
  },
  {
    code: '04',
    label: '4th Grade',
  },
  {
    code: '05',
    label: '5th Grade',
  },
  {
    code: '06',
    label: '6th Grade',
  },
  {
    code: '07',
    label: '7th Grade',
  },
  {
    code: '08',
    label: '8th Grade',
  },
  {
    code: '09',
    label: '9th Grade',
  },
  {
    code: '10',
    label: '10th Grade',
  },
  {
    code: '11',
    label: '11th Grade',
  },
  {
    code: '12',
    label: '12th Grade',
  },
];

export default 'SchoolSettings.js';
