import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Component from './PgFilters.component';
import { updateFilters, applyFilters, resetFilters } from '../+store/progressGoals/progressGoals.actions';
import { getTeachers } from '../../common/+store/FilterBox';
import { initialPgFilters } from '../+store/progressGoals/progressGoals.reducers';
import { getCurrentRosterYear } from '../../StudentRoster/+store/studentRoster';
import { isMultipleCampusAdmin } from '../../common/utils';

const sortByOptions = [
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'studentId', label: 'Student ID' },
  { value: 'mathGoal', label: 'Math Progress Goal Score' },
  { value: 'readingGoal', label: 'Reading Progress Goal Score' },
];

const getStudentRoster = (state) => {
  const students = state.modules.StudentRoster.studentRoster.roster;
  const currentSnapshotYear = getCurrentRosterYear();
  return students && students[currentSnapshotYear] ? students[currentSnapshotYear] : {};
};
const getCampus = (state) => state.modules.ProgressGoals.progressGoals.stagedFilters.campus;
const selectTeacherOptions = createSelector(getStudentRoster, getCampus, (studentRoster, campus) =>
  getTeachers(studentRoster, ['math', 'reading', 'algebraOne', 'englishTwo'], campus)
);

const getSchoolList = (state) => state.modules.Settings.schoolSettings.schoolList;
const selectSchoolOptions = createSelector(getSchoolList, (schoolList) => {
  const schoolOptions = [];
  if (schoolList) {
    Object.entries(schoolList).forEach(([num, school]) => {
      schoolOptions.push({ value: num, label: school.name });
    });
  }
  return schoolOptions;
});

const studentIndicatorItems = [
  { name: 'ecoDis', label: 'Economically Disadvantaged' },
  { name: 'specialEdCurrent', label: 'Special Ed (Current)' },
  { name: 'specialEdFormer', label: 'Special Ed (Former)' },
  { name: 'lepCurrent', label: 'EL (Current)' },
  { name: 'lepMonitored', label: 'EL (Monitored)' },
  { name: 'continuouslyEnrolled', label: 'Continuously Enrolled' },
  { name: 'nonContinuouslyEnrolled', label: 'Non-Continuously Enrolled' },
  { name: 'highlyMobile', label: 'Highly Mobile' },
  { name: 'acceleratedTesterMath', label: 'Accelerated Tester Math' },
  { name: 'acceleratedTesterReading', label: 'Accelerated Tester Reading' },
  { name: 'acceleratedTesterScience', label: 'Accelerated Tester Science' },
];

const mapStateToProps = (state) => {
  const { stagedFilters, filters } = state.modules.ProgressGoals.progressGoals;
  const filtersChanged = JSON.stringify(stagedFilters) !== JSON.stringify(filters);
  const filterApplied = JSON.stringify(initialPgFilters) !== JSON.stringify(filters);
  const profile = state.modules.Auth.auth.profile;
  let filterLimitReached = false;
  let numFilters = 0;
  Object.entries(stagedFilters).forEach(([key, val]) => {
    if (!['campus', 'sortBy'].includes(key)) {
      if (val) numFilters += 1;
    }
  });
  if (profile && isMultipleCampusAdmin(profile)) {
    stagedFilters.campus = profile.campusNumber[0];
  }
  if (numFilters > 1) {
    filterLimitReached = true;
  }
  return {
    filterLimitReached,
    filtersChanged,
    filterApplied,
    filters,
    stagedFilters,
    teacherOptions: selectTeacherOptions(state),
    schoolOptions: selectSchoolOptions(state),
    sortByOptions,
    studentIndicatorItems,
    profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateFilters: (filter) => {
    dispatch(updateFilters(filter));
  },
  applyFilters: (filters) => {
    dispatch(applyFilters(filters));
  },
  resetFilters: () => {
    dispatch(resetFilters());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
