import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { asyncError, asyncFinish } from '../../../common/+store/index';
import {
  GET_SCHOOLS,
  UPDATE_SETTINGS,
  GET_DEFAULT_SETTINGS,
  getDefaultSettings,
  GET_GRADE_SPAN_TABLE,
  GET_CCMR_MANUAL,
  SAVE_CCMR_MANUAL,
  GET_CCMR_INDICATORS,
  GET_GraduationData_INDICATORS,
  GET_DISTRICT_OR_SCHOOL_DATA,
} from './SchoolSettings.actions';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import { getSchools, updateSettings, dbGetDefaultSettings, getGradeSpanTable } from './SchoolSettings.db';
import {
  getCCMRManual,
  saveCCMRManual,
  getCCMRIndicators,
  getGraduationDataIndicators,
  getDistrictOrSchoolData,
} from './SchoolSettings.api';
const getGradeSpanTableEpic = (action$) =>
  action$.pipe(
    ofType(GET_GRADE_SPAN_TABLE),
    switchMap((action) =>
      from(getGradeSpanTable()).pipe(commonApiCallFragment(action$, action, 'Get Grade Span Table'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            gradeSpanTable: results.response,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getSchoolsEpic = (action$) =>
  action$.pipe(
    ofType(GET_SCHOOLS),
    switchMap((action) =>
      from(getSchools(action.profile)).pipe(commonApiCallFragment(action$, action, 'Get School List'))
    ),
    switchMap((results) => {
      if (results.success) {
        const schools = results.response;
        const schoolIds = Object.keys(schools).filter((s) => s !== 'district');
        return of(
          asyncFinish(results.action.type, {
            schools,
          }),
          getDefaultSettings(results.action.profile, schoolIds)
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const updateSettingsEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_SETTINGS),
    switchMap((action) =>
      from(updateSettings(action.profile, action.schoolId, action.settings)).pipe(
        commonApiCallFragment(action$, action, 'Update School Settings')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return from(getSchools(results.action.profile)).pipe(
          commonApiCallFragment(action$, results.action, 'Update School Settings')
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        const schools = results.response;
        return of(
          asyncFinish(results.action.type, {
            schools,
            message: 'Successfully Updated',
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getDefaultSettingsEpic = (action$) =>
  action$.pipe(
    ofType(GET_DEFAULT_SETTINGS),
    switchMap((action) =>
      from(dbGetDefaultSettings(action.profile, action.schoolIds)).pipe(
        commonApiCallFragment(action$, action, 'Get Default Settings')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            schools: results.response,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getCCMRManualEpic = (action$) =>
  action$.pipe(
    ofType(GET_CCMR_MANUAL),
    switchMap((action) => from(getCCMRManual()).pipe(commonApiCallFragment(action$, action, 'Getting CCMR Manual'))),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(GET_CCMR_MANUAL, results.response.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const saveCCMRManualEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_CCMR_MANUAL),
    switchMap((action) =>
      from(saveCCMRManual(action.data)).pipe(commonApiCallFragment(action$, action, 'Save CCMR Manual'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(SAVE_CCMR_MANUAL, results.response.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getCCMRIndicatorsEpic = (action$) =>
  action$.pipe(
    ofType(GET_CCMR_INDICATORS),
    switchMap((action) =>
      from(getCCMRIndicators(action.data)).pipe(commonApiCallFragment(action$, action, 'Getting CCMR Indicators'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(GET_CCMR_INDICATORS, results.response.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getGraduationDataIndicatorsEpic = (action$) =>
  action$.pipe(
    ofType(GET_GraduationData_INDICATORS),
    switchMap((action) =>
      from(getGraduationDataIndicators(action.data)).pipe(
        commonApiCallFragment(action$, action, 'Getting GraduationData Indicators')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(GET_GraduationData_INDICATORS, results.response.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getDistrictOrSchoolDataEpic = (action$) =>
  action$.pipe(
    ofType(GET_DISTRICT_OR_SCHOOL_DATA),
    switchMap((action) =>
      from(getDistrictOrSchoolData(action.data)).pipe(
        commonApiCallFragment(action$, action, 'GET_DISTRICT_OR_SCHOOL_DATA')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(GET_DISTRICT_OR_SCHOOL_DATA, results.response.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const epics = combineEpics(
  getSchoolsEpic,
  updateSettingsEpic,
  getDefaultSettingsEpic,
  getGradeSpanTableEpic,
  getCCMRManualEpic,
  saveCCMRManualEpic,
  getCCMRIndicatorsEpic,
  getGraduationDataIndicatorsEpic,
  getDistrictOrSchoolDataEpic
);
export default epics;
