import { combineReducers } from 'redux';
import { GET_ACCOUNTABILITY_WEIGHTS } from './accountabilityWeight.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const initialStatus = {
  message: '',
  error: '',
};
const status = (state = initialStatus, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ACCOUNTABILITY_WEIGHTS) {
        return Object.assign({}, state, {
          message: action.data.message,
          error: '',
        });
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === GET_ACCOUNTABILITY_WEIGHTS) {
        return Object.assign({}, state, {
          message: '',
          error: action.error.message,
        });
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_ACCOUNTABILITY_WEIGHTS) {
        return 'Loading Weights ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_ACCOUNTABILITY_WEIGHTS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === GET_ACCOUNTABILITY_WEIGHTS) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const weights = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ACCOUNTABILITY_WEIGHTS) {
        return action.data.weights;
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
  loadingMsg,
  weights,
  needsInit,
});

export default 'accountabilityWeight.reducers.js';
