import * as React from 'react';
import PropTypes from 'prop-types';
import * as Fuse from 'fuse.js';
import { Typography, List, ListItem, ListItemText, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import classNames from 'classnames';
import styles from './TextInputField.styles';
import IconTextInputField from './IconTextInputField.component';

class TextInputField extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    inputExtraRowSize: PropTypes.number,
    inputExtraContainerSize: PropTypes.number,
    setFieldValue: PropTypes.func,
    searchOptions: PropTypes.array,
    searchKeys: PropTypes.array,
    autoComplete: PropTypes.string,
    handleFocus: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    classes: PropTypes.any,
    children: PropTypes.node,
    isDarkBorder: PropTypes.bool,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    rightIcon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      isDisabled: PropTypes.bool,
    }),
    leftIcon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      isDisabled: PropTypes.bool,
    }),
    flexDirection: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
    touched: false,
    error: null,
    inputExtraRowSize: 0,
    inputExtraContainerSize: 0,
    flexDirection: 'row',
  };

  state = {
    optionList: [],
    openOptions: false,
    selectedOption: '',
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleSelectOption = (option) => {
    const { setFieldValue, id } = this.props;
    const selectedOption = `${option.id} - ${option.name}`;
    setFieldValue(id, selectedOption);
    this.setState({ selectedOption });
    this.setState({ openOptions: false });
    document.removeEventListener('click', this.handleOutsideClick, false);
  };

  handleCloseOptions = () => {
    const { setFieldValue, id } = this.props;
    const { selectedOption } = this.state;
    this.setState({ openOptions: false });
    this.setState({ optionList: [] });
    if (selectedOption && setFieldValue) {
      setFieldValue(id, selectedOption);
    }
    document.removeEventListener('click', this.handleOutsideClick, false);
  };

  handleOutsideClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleCloseOptions();
  };

  handleChange = (e, fuse) => {
    const { setFieldValue, id } = this.props;
    const { openOptions } = this.state;
    const { value } = e.target;
    setFieldValue(id, value);
    if (value.length === 0 && openOptions) {
      this.setState({ openOptions: false });
      this.setState({ optionList: [] });
      document.removeEventListener('click', this.handleOutsideClick, false);
    } else if (fuse && (value.length > 2 || openOptions)) {
      const optionList = fuse.search(value);
      this.setState({ optionList });
      if (!openOptions) {
        document.addEventListener('click', this.handleOutsideClick, false);
        this.setState({ openOptions: true });
      }
    }
  };

  renderError = (errorMsg, style = null) => {
    const { classes, flexDirection } = this.props;
    return (
      <Typography
        className={classNames({
          [classes.errorMsg]: flexDirection !== 'column',
          [classes.columnErrorMsg]: flexDirection === 'column',
        })}
        style={style}
      >
        {errorMsg}
      </Typography>
    );
  };

  renderCancelCircle = () => (
    <Icon color="action" onClick={this.handleCloseOptions}>
      cancel
    </Icon>
  );

  renderOptionList = () => {
    const { classes } = this.props;
    const { optionList } = this.state;
    return (
      <List dense>
        {optionList.map((option, index) => (
          <ListItem
            key={index}
            className={classNames({
              [classes.option]: true,
              [classes.lastOption]: index === optionList.length - 1,
            })}
            onClick={() => this.handleSelectOption(option)}
          >
            <ListItemText primary={`${option.id} - ${option.name}`} />
          </ListItem>
        ))}
      </List>
    );
  };

  renderOptionComponent = () => {
    const { classes } = this.props;
    const { optionList } = this.state;
    const errorMsg =
      'Sorry, your input does not match an option in our database. Contact us to add the needed information.';
    let marginTop = '96px';
    if (optionList.length <= 1) {
      marginTop = '40px';
    }
    return (
      <div className={classes.optionsContainer} style={{ marginTop }}>
        {!optionList.length ? this.renderError(errorMsg, { width: '360px', padding: '8px' }) : this.renderOptionList()}
      </div>
    );
  };

  renderField = (isError) => {
    const {
      classes,
      id,
      type,
      autoComplete,
      handleFocus,
      handleBlur,
      setFieldTouched,
      rightIcon,
      leftIcon,
      isDarkBorder,
      isDisabled,
    } = this.props;
    const InputField = rightIcon || leftIcon ? IconTextInputField : Field;
    const dynamicProps =
      rightIcon || leftIcon
        ? {
            rightIcon,
            leftIcon,
            isError,
            isDarkBorder,
            isDisabled,
          }
        : {};
    let onBlurFocus = {};
    if (handleBlur && handleFocus && setFieldTouched) {
      onBlurFocus = {
        onBlur: () => {
          handleBlur();
          setFieldTouched(id, true);
        },
        onFocus: handleFocus,
      };
    }
    const InputFieldComponent = (
      <InputField
        name={id}
        id={id}
        type={type}
        className={classNames(classes.inputField, {
          [classes.inputFieldError]: isError,
          [classes.disabled]: isDisabled,
        })}
        autoComplete={autoComplete}
        {...dynamicProps}
        {...onBlurFocus}
      />
    );
    if (isDisabled) {
      return (
        <fieldset disabled className={classes.fieldset}>
          {InputFieldComponent}
        </fieldset>
      );
    }
    return InputFieldComponent;
  };

  renderCustomOnChangeField = (isError) => {
    const { classes, id, type, searchOptions, searchKeys, autoComplete } = this.props;
    const fuse = new Fuse(searchOptions, {
      tokenize: true,
      matchAllTokens: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: searchKeys,
    });
    return (
      <Field
        name={id}
        id={id}
        type={type}
        className={classNames(classes.inputField, { [classes.inputFieldError]: isError })}
        onChange={(e) => this.handleChange(e, fuse)}
        autoComplete={autoComplete}
      />
    );
  };

  render() {
    const {
      classes,
      label,
      error,
      touched,
      setFieldValue,
      inputExtraRowSize,
      inputExtraContainerSize,
      children,
      className,
      flexDirection,
    } = this.props;
    const isError = !!(error && error.trim()) && touched;
    const { openOptions } = this.state;
    const defaultInputField = label ? 470 : 370;
    const defaultContainerSize = label ? 660 : 600;
    const inputRowSize = defaultInputField + inputExtraRowSize;
    const inputContainerSize = defaultContainerSize + inputExtraContainerSize;
    const styleDynamic = { flexDirection };
    if (flexDirection !== 'column') {
      styleDynamic.width = `${inputContainerSize}px`;
    }
    return (
      <div
        className={classNames(classes.inputFieldContainer, className)}
        style={styleDynamic}
        ref={(node) => {
          this.node = node;
        }}
      >
        <div className={classes.inputFieldRow} style={{ width: `${inputRowSize}px` }}>
          {label && (
            <Typography className={classNames(classes.label, { [classes.labelError]: isError })}>{label}</Typography>
          )}
          <div>{setFieldValue ? this.renderCustomOnChangeField(isError) : this.renderField(isError)}</div>
        </div>
        {openOptions && this.renderCancelCircle()}
        {isError && this.renderError(error)}
        {setFieldValue && openOptions && this.renderOptionComponent()}
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(TextInputField);
