import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import bugsnagReact from '@bugsnag/plugin-react';
// import registerServiceWorker from './registerServiceWorker';

import { App } from './modules/App';
import store from './modules/App/+store/store';
import { bugsnagger } from './modules/App/+store/errors/errors.epics';
(typeof window !== 'undefined' ? window : {}).pdfjsWorker = require('pdfjs-dist/build/pdf.worker');

let ErrorBoundary;
if (process.env.NODE_ENV === 'production') {
  bugsnagger.bugsnagClient.use(bugsnagReact, React);
  ErrorBoundary = bugsnagger.bugsnagClient.getPlugin('react');
} else {
  ErrorBoundary = ({ children }) => children;
}

render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router>
        <Route path="" component={App} />
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
// registerServiceWorker();
