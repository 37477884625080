import { connect } from 'react-redux';

import Component from './StudentRoster.component';
import {
  initStudentsRoster,
  parseStudentDbToUi,
  uploadStudentRoster,
  getCurrentRosterYear,
} from './+store/studentRoster';
import {
  deleteStudentRequestCancel,
  deleteStudentConfirm,
  addStudentRequest,
  editStudentRequestCancel,
  addStudentRequestCancel,
  closeSnackbar,
  getAvatars,
  selectSnapshotYear,
  exportRoster,
} from './+store/studentRoster/studentRoster.actions';

const mapStateToProps = (state) => {
  let {
    roster,
    selectedSnapshotYear,
    selectedStudentToDelete,
    selectedStudentToEdit,
    rosterCSV,
    loadingCSV,
  } = state.modules.StudentRoster.studentRoster;
  const snapshotYear = selectedSnapshotYear || getCurrentRosterYear();
  roster = roster[snapshotYear];
  const deletingStudentId = selectedStudentToDelete;
  const editingStudentId = selectedStudentToEdit;
  const deletingStudent = deletingStudentId && roster ? roster[deletingStudentId] : null;
  const editingStudent = editingStudentId && roster ? roster[editingStudentId] : null;
  if (roster) {
    roster = Object.entries(roster).map(([id, student]) => [id, parseStudentDbToUi(student)]);
  } else {
    roster = [];
  }
  return {
    roster,
    snapshotYear,
    deletingStudentId,
    editingStudentId,
    deletingStudent,
    editingStudent,
    rosterCSV,
    loadingCSV,
    user: state.modules.Auth.auth.user,
    profile: state.modules.Auth.auth.profile,
    needsInit: state.modules.StudentRoster.studentRoster.needsInit,
    loadingMsg: state.modules.StudentRoster.studentRoster.loadingMsg,
    loading: !!state.modules.StudentRoster.studentRoster.loadingMsg,
    status: state.modules.StudentRoster.studentRoster.status,
    isAddingNewStudent: state.modules.StudentRoster.studentRoster.isAddingNewStudent,
    msg: state.modules.StudentRoster.studentRoster.msg,
    uploadedRoster: state.modules.StudentRoster.studentRoster.uploadedRoster,
    avatars: state.modules.StudentRoster.studentRoster.avatars,
    tags: state.modules.Settings.manageOrgTags.tags,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDrop: (accepted, rejected, user, profile, year) => {
    // dispatch(onRosterDrop(accepted, rejected, profile));
    dispatch(uploadStudentRoster(accepted, rejected, user, profile, year));
  },
  onDropForDeleteRemaining: (accepted, rejected, user, profile, year) => {
    // dispatch(onRosterDrop(accepted, rejected, profile));
    dispatch(uploadStudentRoster(accepted, rejected, user, profile, year, true));
  },
  getSelectedYearRoster: (user, year) => {
    dispatch(initStudentsRoster(user, year));
  },
  init: (user) => {
    dispatch(initStudentsRoster(user));
    dispatch(getAvatars());
  },
  cancelDelete: () => {
    dispatch(deleteStudentRequestCancel());
  },
  confirmDelete: (studentId, orgId, year) => {
    dispatch(deleteStudentConfirm(studentId, orgId, year));
  },
  handleAddStudentRequest: () => {
    dispatch(addStudentRequest());
  },
  handleCancelEdit: () => {
    dispatch(editStudentRequestCancel());
  },
  handleCancelAdd: () => {
    dispatch(addStudentRequestCancel());
  },
  handleMsgClose: () => {
    dispatch(closeSnackbar());
  },
  onSelectSnapshotYear: (year) => {
    dispatch(selectSnapshotYear(year));
  },
  handleRosterExport: (year) => {
    dispatch(exportRoster(year));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
