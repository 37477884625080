import moment from 'moment';
import { indicators, ethnicity, getTeachersForStudent } from '../../common/+store/FilterBox';
import { isRealNumber } from '../../common/utils';

export const headerColumns = {
  name: 'Student Name',
  ccmrPoints: 'CCMR Points',
};

export const mathColumns = {
  mathTSIA: 'TSI',
  mathACT: 'ACT',
  mathSAT: 'SAT',
  mathCP: 'College Prep',
};

export const readingColumns = {
  readingTSIA: 'TSI',
  readingACT: 'ACT',
  readingSAT: 'SAT',
  readingCP: 'College Prep',
};

export const editableHeaderColumns = {
  graduated: 'Graduated',
  apIb: 'AP/IB',
  dualCourseCredits: 'Dual Credit',
  IndCert: 'Ind Cert',
  asscoDeg: 'Assoc Deg',
  iep: 'IEP',
  armedForces: 'Armed Forces',
  alignedCTE: 'Aligned CTE (1/2 Pt)',
  onRamps: 'OnRamps',
  spedStudentsWithAdvancedDiplomaPlans: 'Adv Plan SpEd',
  levelOnelevelTwoCert: 'Level I / II Cert',
};

const editableKeys = Object.keys(editableHeaderColumns);

const objectify = (arr) => Object.assign(...arr.map(([k, v]) => ({ [k]: v })));

export const filterCCMRByRosterProperty = (
  ccmrData,
  students,
  filterBy = {},
  customFunctions = {},
  isMonitorFilter = null
) => {
  let res = {};
  if (filterBy != null && Object.values(filterBy).length > 0) {
    res = Object.entries(ccmrData).filter(([id, data]) =>
      Object.values(filterBy)
        .map((filters) => {
          const { values, type, key, byRoster, byCCMR } = filters;
          let student = students[id];
          if (
            isMonitorFilter == null ||
            (student && student.monitor == null && isMonitorFilter === false) ||
            (student && isMonitorFilter === student.monitor)
          ) {
            let item = byRoster ? students[id] : data;
            if (byRoster) {
              item.teachers = getTeachersForStudent(student);
            }

            if (byCCMR && byRoster) {
              item = Object.assign({}, item, {
                [byCCMR]: data[byCCMR],
              });
            }
            if (values.length === 0) return true;
            if (type === 'custom') {
              const filterFunc = customFunctions[key];
              return filterFunc ? filterFunc(item, values) : true;
            }
            if (type === 'boolean') {
              return !!values.filter((value) => key in item && item[key][value]).length;
            }
            if (key in item && item[key] && item[key].length > 0) {
              return values.some((r) => item[key].includes(r));
            }
            return key in item && values.includes(item[key]);
          }
        })
        .reduce((result, included) => result && included, true)
    );
  } else {
    res = Object.entries(ccmrData).filter(([id, data]) => {
      let studentObj = students[id];
      if (
        isMonitorFilter == null ||
        (studentObj && studentObj.monitor == null && isMonitorFilter === false) ||
        (studentObj && isMonitorFilter === studentObj.monitor)
      ) {
        return true;
      }
      return false;
    });
  }
  return res.length ? objectify(res) : {};
};

export const sortCCMRByRosterProperty = (ccmrData, students, key, reverse = false) => {
  function compare(a, b) {
    const order = reverse ? -1 : 1;
    let ele1 = a[1];
    let ele2 = b[1];
    if (key !== 'ccmrPoints') {
      ele1 = students[a[0]];
      ele2 = students[b[0]];
    }
    if (typeof ele1 === 'object' && typeof ele2 === 'object' && key in ele1 && key in ele2) {
      ele1 = ele1[key];
      ele2 = ele2[key];
    } else {
      if (typeof ele1 === 'object' && key in ele1) return order;
      if (typeof ele2 === 'object' && key in ele2) return -1 * order;
      return 0;
    }
    if (isRealNumber(ele1) && isRealNumber(ele2)) {
      ele1 = Number(ele1);
      ele2 = Number(ele2);
    }
    if (typeof ele1 === 'string' || typeof ele2 === 'string') {
      return ele1.localeCompare(ele2) * order;
    }
    return (ele1 - ele2) * order;
  }
  const dataArr = Object.entries(ccmrData).sort(compare);
  if (dataArr.length) {
    return objectify(dataArr);
  }
  return {};
};

const gradStatus = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const ccmrPointsOptions = [
  { value: 1, label: '1' },
  { value: 0.5, label: '0.5' },
  { value: 0, label: '0' },
];

const gradeLevelOptions = [
  { value: '9', label: '9th Grade' },
  { value: '10', label: '10th Grade' },
  { value: '11', label: '11th Grade' },
  { value: '12', label: '12th Grade' },
  { value: '13', label: '13th Grade' },
];

const sortOptions = {
  firstName: { label: 'First Name', reverse: false, keys: ['firstName'] },
  lastName: { label: 'Last Name', reverse: false, keys: ['lastName'] },
  gradeLevel: { label: 'Grade', reverse: true, keys: ['gradeLevel'] },
  ccmrPoints: { label: 'CCMR Points', reverse: true, keys: ['ccmrPoints'] },
  studentId: { label: 'Student ID', reverse: false, keys: ['studentId'] },
};

export const ccmrFilterBoxParams = {
  model: 'ccmrTracking',
  sortSection: { label: 'SORT BY', options: sortOptions },
  filterSections: [
    {
      id: 'gradStatus',
      filterKey: 'gradStatus',
      label: 'Graduation Status',
      values: gradStatus,
      type: 'custom',
      byRoster: true,
      byCCMR: 'graduated',
    },
    {
      id: 'gradeLevel',
      filterKey: 'gradeLevel',
      label: 'Grade',
      values: gradeLevelOptions,
      byRoster: true,
    },
    {
      id: 'ethnicity',
      filterKey: 'indicators',
      label: 'Ethnicity',
      values: ethnicity,
      type: 'boolean',
      byRoster: true,
    },
    {
      id: 'otherIndicators',
      filterKey: 'indicators',
      label: 'Student Indicators',
      values: indicators,
      type: 'boolean',
      byRoster: true,
    },
    {
      id: 'ccmrPoints',
      filterKey: 'ccmrPoints',
      label: 'CCMR Points',
      values: ccmrPointsOptions,
    },
  ],
};

export const didGraduate = (gradYear, graduated = null) => {
  if (typeof graduated === 'boolean') {
    return graduated;
  }
  const currentTexasDate = moment().utcOffset(-5);
  const curMonth = currentTexasDate.month() + 1;
  const curYear = currentTexasDate.year();
  if (!gradYear) {
    return false;
  }
  if (gradYear.length === 4) {
    const graduationYear = Number(gradYear);
    return curYear > graduationYear;
  }
  const graduationMonth = Number(gradYear.substring(0, 2));
  const graduationYear = Number(gradYear.substring(2));
  if (graduationYear === curYear) {
    return curMonth >= graduationMonth;
  }
  return curYear > graduationYear;
};

export const calcStudentCCMRPoint = (key, updatedStudent) => {
  const mathColumnsKeys = ['mathTSIA', 'mathACT', 'mathSAT', 'mathCP'];
  const readingColumnsKeys = ['readingTSIA', 'readingACT', 'readingSAT', 'readingCP'];
  let { math, reading } = updatedStudent;

  const isMathKeyUpdated = mathColumnsKeys.includes(key);
  const isReadingKeyUpdated = readingColumnsKeys.includes(key);
  if (isReadingKeyUpdated || isMathKeyUpdated) {
    if (isMathKeyUpdated) {
      if (mathColumnsKeys.some((mathKey) => updatedStudent[mathKey])) {
        math = true;
      } else if (mathColumnsKeys.some((mathKey) => !updatedStudent[mathKey])) {
        math = false;
      } else {
        math = null;
      }
    }

    if (isReadingKeyUpdated) {
      if (readingColumnsKeys.some((readingKey) => updatedStudent[readingKey])) {
        reading = true;
      } else if (readingColumnsKeys.some((readingKey) => !updatedStudent[readingKey])) {
        reading = false;
      } else {
        reading = null;
      }
    }
  }

  reading = reading || null;
  math = math || null;

  const isOtherCriteria = editableKeys.some(
    (criteria) => updatedStudent[criteria] && criteria !== 'alignedCTE' && criteria !== 'graduated'
  );
  let ccmrPoints = null;
  if (isOtherCriteria || (math && reading)) {
    ccmrPoints = 1;
  } else if (updatedStudent.alignedCTE) {
    ccmrPoints = 0.5;
  } else if (math != null || reading != null) {
    ccmrPoints = 0;
  }
  return {
    ...updatedStudent,
    reading,
    math,
    elaAndMath: math && reading,
    ccmrPoints: Number.isNaN(ccmrPoints) ? null : ccmrPoints,
  };
};

export const getUnknownStudentId = (unknownId, highSchoolStudents) => {
  if (!highSchoolStudents || !highSchoolStudents.length) return null;
  let firebaseId = null;
  const variables = ['studentId', 'localId', 'tsdsId'];
  const normalizedId = unknownId.toUpperCase().replace(/\W/g, '');
  highSchoolStudents.forEach(([id, student]) => {
    if (firebaseId) return;
    variables.forEach((v) => {
      if (firebaseId) return firebaseId;
      if (student[v] === normalizedId) {
        firebaseId = id;
      }
    });
  });
  return firebaseId;
};

export const getUnknownStudentIds = (unknownIds, rosterStudents) => {
  const highSchoolStudents = Object.entries(rosterStudents);
  const ids = [...new Set(unknownIds.trim().split(/[\r?\n,\s]+/))];
  const firebaseIds = ids.map((id) => [id, getUnknownStudentId(id, highSchoolStudents)]);
  const notFoundIds = [];
  const foundIds = firebaseIds.filter(([id, firebaseId]) => {
    if (!firebaseId) {
      notFoundIds.push(id);
    }
    return !!firebaseId;
  });
  return [foundIds, notFoundIds];
};
