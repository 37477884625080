import green from '@material-ui/core/colors/green';

export const styles = (theme) => ({
  root: {
    padding: '16px 36px',
  },
  coloredRoot: {},
  tableContainer: {
    marginTop: theme.spacing.unit * 3,
  },
  content: {
    margin: 'auto',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  deleteModal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  editModal: {
    position: 'absolute',
    width: theme.spacing.unit * 105,
    background: 'linear-gradient(0deg, rgba(175, 228, 236, 0.65) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;',
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 3,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    maxHeight: theme.spacing.unit * 90,
    overflowY: 'scroll',
  },
  head: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    flexShrink: 0,
    flexGrow: 0,
  },

  iconHead: {
    width: 100,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    flexShrink: 0,
    flexGrow: 0,
  },

  tableHead: {
    display: 'block',
    width: 1900,
  },

  tableRow: {
    display: 'flex',
    minHeight: '60px',
    width: '100%',
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    background: 'linear-gradient(0deg, rgba(175, 228, 236, 0.65) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;',
    display: 'block',
    overflowX: 'auto',
  },
  tableBody: {
    display: 'block',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 140px)',
    width: 1900,
  },
  pagination: {
    position: 'absolute',
    right: 0,
  },
  searchWrapper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing.unit,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
});

export default 'StudentRoster.styles.js';
