import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const RowLabelCell = withStyles((theme) => ({
  head: {
    padding: 8,
  },
  body: {
    padding: '4px 8px',
    textAlign: 'left',
  },
  footer: {
    padding: 8,
    textAlign: 'left',
  },
}))(TableCell);

export default RowLabelCell;
