import { db } from '../../../App/+store/firebase';

// const dbGet = path => db.ref(path).once('value').then(s => s.val());

export const getAssessmentChecklist = async (user) => {
  const orgId = user.organizationId;
  return db
    .ref(`newDatabase/organizations/${orgId}/files/assessmentChecklist`)
    .once('value')
    .then((snapshot) => snapshot.val());
};

export const getCcmrSource = async (user, schoolId = null) => {
  let dbRef = `newDatabase/organizations/${user.organizationId}`;
  if (schoolId) {
    dbRef += `/schools/${schoolId}`;
  } else {
    dbRef += '/district';
  }
  dbRef += '/ccmrSource';

  return db
    .ref(dbRef)
    .once('value')
    .then((snapshot) => snapshot.val());
};

export const updateAssessmentChecklist = async (checklist, user) => {
  const orgId = user.organizationId;
  return db.ref(`newDatabase/organizations/${orgId}/files/assessmentChecklist`).update(checklist);
};
export default 'assessementChecklist.db.js';
