import { createSelector } from 'reselect';

const getProfile = (state) => state.modules.Auth.auth.profile;
const getSchoolList = (state) => state.modules.Settings.schoolSettings.schoolList;

export const campusDistrictInfo = createSelector(getProfile, getSchoolList, (profile, schoolList) => {
  let info = null;
  let isDistrict = null;
  if (profile) {
    const districtCampusNumber = profile.role === 'districtAdmin' ? profile.districtNumber : profile.campusNumber;
    isDistrict = profile.role === 'districtAdmin';
    info = schoolList[districtCampusNumber] || null;
  }
  return {
    isDistrict,
    ...info,
  };
});

export default '';
