const isCsvGradData = (file) =>
  new Promise((resolve) => {
    if (file.type !== 'text/csv') {
      resolve(false);
      return;
    }
    console.log('here');
    const reader = new FileReader();
    reader.onload = () => {
      // console.log('content', content);
      const text = reader.result;
      const lookingFor = [
        {
          re: /^C O N F I D E N T I A L,+$/,
          key: 'a',
        },
        {
          re: /^TEXAS EDUCATION AGENCY,+$/,
          key: 'b',
        },
        {
          re: /^CLASS OF (\d{4}) ([A-Z-]+) (EXTENDED)? LONGITUDINAL SUMMARY REPORT,+$/,
          key: 'c',
        },
        {
          re: /^District Name: .*$/,
          key: 'd',
        },
        {
          re: /^District No.: (\d{6}),+$/,
          key: 'e',
        },
      ];
      const foundThings = {
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
      };
      const lines = text.split(/\r\n?|\n/);
      lines.forEach((line) => {
        lookingFor.forEach((looking) => {
          const results = looking.re.exec(line);
          if (results) {
            foundThings[looking.key] = results;
          }
        });
      });
      console.log('found', foundThings);
      if (!Object.values(foundThings).includes(0)) {
        console.log('foundThings', foundThings);
        resolve({
          year: foundThings.c[1],
          type: foundThings.c[2],
        });
      }
      resolve(false);
    };
    reader.readAsText(file);
  });

export default isCsvGradData;
