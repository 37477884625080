import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { asyncError, asyncFinish } from '../../common/+store/index';
import { GET_CCMR_TRACKING, UPDATE_CCMR_TRACKING, UPDATE_BULK_CCMR_TRACKING } from './ccmrTracking.actions';
import {
  updateCCMRTrackingData,
  updateBulkCCMRTrackingData,
  ccmrEndpoint,
  getCcmrTrackingData,
} from './ccmrTracking.api';
import { commonApiCallFragment } from '../../common/+store/utils/utils.api';

const getCCMRTrackingEpic = (action$) =>
  action$.pipe(
    ofType(GET_CCMR_TRACKING),
    switchMap((action) => {
      if (action.ignoreCache) {
        return from(
          ccmrEndpoint({
            jobName: 'updateFromRoster',
          })
        ).pipe(commonApiCallFragment(action$, action, 'Update Ccmr from Roster'));
      }
      return of({ success: true, action });
    }),
    switchMap((result) => {
      if (result.success) {
        return from(getCcmrTrackingData(result.action.profile)).pipe(
          commonApiCallFragment(action$, result.action, 'Get CCMR Tracking Data')
        );
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinish(result.action.type, {
            ccmrTrackingData: result.response,
          })
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const updateCCMRTrackingEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_CCMR_TRACKING),
    switchMap((action) =>
      from(updateCCMRTrackingData(action.orgId, action.firebaseId, action.studentData)).pipe(
        map((data) => ({ action, success: true, data })),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'Could not update student', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            firebaseId: results.action.firebaseId,
            studentData: results.action.studentData,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const updateBulkCCMRTrackingEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_BULK_CCMR_TRACKING),
    // switchMap(action => from(ccmrEndpoint({
    //   jobName: 'bulkEntry',
    // })).pipe(commonApiCallFragment(action$, action, 'Update Ccmr from Roster'))),
    switchMap((action) =>
      from(updateBulkCCMRTrackingData(action.orgId, action.studentsData)).pipe(
        map((data) => ({ action, success: true, data })),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'Could not bulk update students', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(results.action.type, { studentsData: results.action.studentsData }));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const epics = combineEpics(getCCMRTrackingEpic, updateCCMRTrackingEpic, updateBulkCCMRTrackingEpic);
export default epics;
