import firebase from 'firebase';
import { asyncStart, asyncFinish, asyncError, asyncProgress } from '../../../common';
import { Assessments, Validators, AssessmentTypes } from '../../../Assessments';
import { addAssessmentSet } from '../../../common/+store/database';
export const INIT_ASSESSMENTS = 'onboarding.assessmentsPage.init';
export const initAssessmentsPage = (user) => async (dispatch, getState) => {
  dispatch(asyncStart(INIT_ASSESSMENTS));
  const orgId = user.organizationId;
  await firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
    .orderByChild('name')
    .equalTo('onboarding')
    .once('value')
    .then((snapshot) => {
      const assessments = snapshot.val();
      if (assessments === null) {
        dispatch(
          asyncFinish(INIT_ASSESSMENTS, {
            status: null,
          })
        );
      } else {
        const assessmentSet = Object.values(assessments)[0];
        const status = {};
        Object.keys(Assessments).forEach((k) => {
          status[k] = {
            uploaded: !!assessmentSet.files[Assessments[k].shortName],
            error: '',
          };
        });
        dispatch(asyncFinish(INIT_ASSESSMENTS, { status }));
      }
    })
    .catch((err) => {
      dispatch(asyncError(INIT_ASSESSMENTS, 'Get assessments status from firebase db', err));
      // Todo: error messages
      throw err;
    });
};

const createFileUpdateObject = (user, assessments, assessmentType, file) => {
  const currentVersion = assessments[assessmentType].versions;
  const newFileKey = firebase
    .database()
    .ref()
    .child(`users/${user.uid}/onboarding/assessments/${assessmentType}`)
    .push().key;
  const storageKey = `users/${user.uid}/assessments/${assessmentType}/${newFileKey}`;
  return {
    updates: {
      [`users/${user.uid}/onboarding/assessments/${assessmentType}/versions`]: currentVersion + 1,
      [`users/${user.uid}/onboarding/assessments/${assessmentType}/files/v${currentVersion}`]: {
        storageKey,
        origName: file.name,
      },
    },
    storageKey,
  };
};

const createFirstFileObject = (user, assessmentType, file) => {
  const newFileKey = firebase
    .database()
    .ref()
    .child(`users/${user.uid}/onboarding/assessments/${assessmentType}`)
    .push().key;
  const storageKey = `users/${user.uid}/assessments/${assessmentType}/${newFileKey}`;
  return {
    updates: {
      [`users/${user.uid}/onboarding/assessments/${assessmentType}`]: {
        versions: 1,
        files: {
          v0: {
            storageKey,
            origName: file.name,
          },
        },
      },
    },
    storageKey,
  };
};

export const UPLOAD_ASSESSMENT = 'onboarding.assessmentsPage.upload';
const saveFile = async (user, assessmentType, file, dispatch) => {
  // firebase.database().ref(`users/${user.uid}/onboarding/assessments`).once('value').then((snapshot) => {
  //   const assessments = snapshot.val();
  //   let updates;
  //   let storageKey;
  //   if (assessments && assessments[assessmentType] && assessments[assessmentType].versions) {
  //     ({ updates, storageKey } = createFileUpdateObject(user, assessments, assessmentType, file));
  //   } else {
  //     ({ updates, storageKey } = createFirstFileObject(user, assessmentType, file));
  //   }
  const orgId = user.organizationId;
  const assessments = await firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
    .orderByChild('name')
    .equalTo('onboarding')
    .once('value')
    .then((snapshot) => snapshot.val());

  let assessmentSetKey = null;
  let assessmentSet = {
    name: 'onboarding',
    files: {},
  };
  if (assessments) {
    // assessment exist
    [[assessmentSetKey, assessmentSet]] = Object.entries(assessments);
  } else {
    assessmentSetKey = firebase
      .database()
      .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
      .push().key;
  }
  const owner = user.role === 'campusAdmin' ? user.campusNumber : 'district';
  const storageKey = `organizations/${orgId}/assessmentSets/${assessmentType}/${assessmentSetKey}`;
  assessmentSet.files[assessmentType] = {
    origName: file.name,
    storageKey,
  };

  // Save to storage
  // Points to the root reference
  const storageRef = firebase.storage().ref();
  const assessmentsRef = storageRef.child(storageKey);
  const metadata = {
    customMetadata: {
      type: assessmentType,
      organizationId: orgId,
      owner,
      ownerId: user.uid,
      assessmentSetId: assessmentSetKey,
    },
  };
  const uploadTask = assessmentsRef.put(file, metadata);
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          dispatch(
            asyncProgress(UPLOAD_ASSESSMENT, {
              progress,
              message: `${progress}% Paused`,
            })
          );
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          dispatch(
            asyncProgress(UPLOAD_ASSESSMENT, {
              progress,
              message: `${progress}%`,
            })
          );
          break;
        default:
          break;
      }
    },
    (error) => {
      dispatch(asyncError(UPLOAD_ASSESSMENT, 'Save to storage', error, { assessmentType }));
    },
    async () => {
      try {
        await addAssessmentSet(assessmentSet, assessmentSetKey, user);
        dispatch(asyncFinish(UPLOAD_ASSESSMENT, { assessmentType, uploaded: true }));
      } catch (err) {
        console.log('error', err);
        dispatch(asyncError(UPLOAD_ASSESSMENT, 'Update firebase db', err, { assessmentType }));
      }
    }
  );
};

export const assessmentsPageUpload = (user, file) => async (dispatch) => {
  dispatch(asyncStart(UPLOAD_ASSESSMENT));
  // Todo: validate file

  const validations = [
    {
      assessmentType: Assessments.CAF2018.shortName,
      validator: Validators.validateCAF2018,
    },
    {
      assessmentType: Assessments.FYLI2017.shortName,
      validator: Validators.validateFYLI2017,
    },
    {
      assessmentType: Assessments.FYLI2016.shortName,
      validator: Validators.validateFYLI2016,
    },
    {
      assessmentType: Assessments.SYLI2015.shortName,
      validator: Validators.validateSYLI2015,
    },
    {
      assessmentType: Assessments.CCMR2018.shortName,
      validator: Validators.validateCCMR2018,
    },
  ];

  let assessmentType = '';
  for (let i = 0; i < validations.length; i += 1) {
    try {
      const validation = validations[i];
      // eslint-disable-next-line
      await validation.validator(file);
      ({ assessmentType } = validation);
      break;
    } catch (err) {
      if (err.name !== 'ValidationError') {
        throw err;
      }
    }
  }
  // console.log('assessmentType', assessmentType);
  if (assessmentType) {
    saveFile(user, assessmentType, file, dispatch);
  } else {
    dispatch(
      asyncError(UPLOAD_ASSESSMENT, 'Validation error', new Error('Unrecognized or badly formatted file'), {
        assessmentType,
        uploaded: false,
      })
    );
  }
};

export const DROP_ERROR = 'onboarding.assessmentsPage.dropError';
export const onAssessmentDrop = (accepted, rejected, user) => (dispatch) => {
  if (accepted.length) {
    const file = accepted[0];
    dispatch(assessmentsPageUpload(user, file));
  } else {
    dispatch(
      asyncError(
        UPLOAD_ASSESSMENT,
        'Dropzone rejected file',
        {
          message:
            'There was an error uploading your files(s).  Please make sure you are uploading the correct file type',
        },
        { rejected }
      )
    );
  }
};
