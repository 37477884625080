import firebase from 'firebase';

export const onFileUpload = firebase.functions().httpsCallable('onFileUpload', {
  timeout: 600000,
});

export const onAssessmentFileDownload = firebase.functions().httpsCallable('onAssessmentFileDownload', {
  timeout: 120000,
});

export default 'assessments.api.js';
