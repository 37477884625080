import { connect } from 'react-redux';

import Component from './FlightPlanBase.component';
import { getAssessmentGroups, getAssessmentList } from '../../Assessments';
import { getSchools } from '../../Settings/+store/SchoolSettings';
import { selectFlightPlan } from '../+store/flightPlan/flightPlan.selectors';
import { initStudentsRoster, getAvatars } from '../../StudentRoster/+store/studentRoster';
import { updateDesiredScore, storeGroupBy } from '../+store/flightPlan/flightPlan.actions';

const mapStateToProps = (state) => {
  const loadingAssessmentsMsg = state.modules.Assessments.assessments.loadingMsg;
  const loadingReportMsg = state.modules.FlightPlan.flightPlan.loadingMsg;
  const schoolsNeedInit = state.modules.Settings.schoolSettings.needsInit;
  const loadingAssessmentGroups = state.modules.Assessments.groupAssessments.loadingMsg;
  const loadingStudents = state.modules.StudentRoster.studentRoster.loadingMsg;
  const { profile } = state.modules.Auth.auth;
  const loadingReportFiltersMSg =
    loadingReportMsg &&
    Object.values(loadingReportMsg)
      .filter((msg) => !!msg)
      .join('\n');
  const loadingMsg = loadingAssessmentsMsg || loadingReportFiltersMSg || loadingAssessmentGroups;
  const loading = !!loadingMsg || schoolsNeedInit;
  const { flightPlan } = state.modules.FlightPlan.flightPlan;
  return {
    profile,
    loadingMsg,
    loading,
    loadingStudents,
    students: state.modules.StudentRoster.studentRoster.roster,
    schools: state.modules.Settings.schoolSettings.schoolList,
    status: state.modules.AtoF.aToF.status,
    assessmentList: state.modules.Assessments.assessments.assessmentList,
    flightPlanData: flightPlan,
    flightPlanSteps: selectFlightPlan(state),
    currentROOS: state.modules.FlightPlan.flightPlan.currentROOS,
    desiredAFScore: state.modules.FlightPlan.flightPlan.desiredAFScore,
    reportError: state.modules.FlightPlan.flightPlan.reportError,
    maxAFScore: state.modules.FlightPlan.flightPlan.maxAFScore,
    groupByValue: state.modules.FlightPlan.flightPlan.groupByValue,
    assessmentGroups: state.modules.Assessments.groupAssessments.assessmentGroups,
    avatars: state.modules.StudentRoster.studentRoster.avatars,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentRoster: (profile) => {
    dispatch(initStudentsRoster(profile));
  },
  getAssessmentList: (profile) => {
    dispatch(getAssessmentList(profile));
  },
  getAvatars: () => {
    dispatch(getAvatars());
  },
  getSchools: (profile) => {
    dispatch(getSchools(profile));
  },
  updateDesiredAFScore: (value) => {
    dispatch(updateDesiredScore(value));
  },
  getAssessmentGroups: (profile) => {
    dispatch(getAssessmentGroups(profile));
  },
  storeGroupBy: (groupByValue) => {
    dispatch(storeGroupBy(groupByValue));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
