import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableCell, TableRow, TableBody, TableFooter } from '@material-ui/core';
import { CustomTableRow, CustomTableCell, RowLabelCell } from '../D3/Common/CustomTableComponents';
import EmptyCols from '../D3/Common/EmptyCols';

const styles = (theme) => ({
  root: {},
  menu: {},
  tsiReading: {
    background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(248, 220, 83, 1);',
  },
  tsiMath: {
    background: 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(92, 186, 243, 1);',
  },
  mathAndReading: {
    background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),  rgba(0, 172, 187, 1)',
  },
  otherCriteria: {
    background: 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(249, 128, 29, 1);',
  },
  total: {
    background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #E1E1E1;',
  },
  textField: {},
  formControl: {
    display: 'block',
  },
  finalTable: {
    width: 300,
  },
});

const MathReadingLabels = {
  tsia: 'TSI Assessment',
  act: 'ACT',
  sat: 'SAT',
  collegePrep: 'College Prep Course Credit',
  atLeastOne: 'At Least One Criteria',
};

const MathReadingCombined = {
  readingAndMath: 'TSI Math & TSI Reading',
};

const OtherCriteriaLabels = {
  apOrIb: 'AP/IB Exam',
  dualCourseCredits: 'Dual Course Credits',
  industryCert: 'Industry Certification',
  associatesDeg: 'Associates Degree',
  iep: 'Graduated with Completed IEP',
  armedForces: 'U.S. Armed Forces',
  alignedCteCoursework: 'CTE Coursework',
};

const TotalLabels = {
  graduated: 'Students Graduated',
  ccmr: 'CCMR Credit',
};

const MathReadingTable = ({ classes, tableData }) => (
  <Table className={classes.root}>
    <TableHead className={classes.tsiReading}>
      <CustomTableRow>
        <RowLabelCell>TSI Reading Criteria</RowLabelCell>
        <EmptyCols length={2} />
      </CustomTableRow>
    </TableHead>
    <TableBody className={classes.tsiReading}>
      {Object.entries(MathReadingLabels).map(([key, label]) => (
        <CustomTableRow key={key}>
          <RowLabelCell>{label}</RowLabelCell>
          <CustomTableCell>{tableData.reading[key].count}</CustomTableCell>
          <CustomTableCell>{`${tableData.reading[key].percent}%`}</CustomTableCell>
        </CustomTableRow>
      ))}
    </TableBody>
    <TableHead className={classes.tsiMath}>
      <CustomTableRow>
        <RowLabelCell>TSI Math Criteria</RowLabelCell>
        <EmptyCols length={2} />
      </CustomTableRow>
    </TableHead>
    <TableBody className={classes.tsiMath}>
      {Object.entries(MathReadingLabels).map(([key, label]) => (
        <CustomTableRow key={key}>
          <RowLabelCell>{label}</RowLabelCell>
          <CustomTableCell>{tableData.math[key].count}</CustomTableCell>
          <CustomTableCell>{`${tableData.math[key].percent}%`}</CustomTableCell>
        </CustomTableRow>
      ))}
    </TableBody>
    <TableHead className={classes.mathAndReading}>
      {Object.entries(MathReadingCombined).map(([key, label]) => (
        <CustomTableRow key={key}>
          <RowLabelCell>{label}</RowLabelCell>
          <CustomTableCell>{tableData[key].count}</CustomTableCell>
          <CustomTableCell>{`${tableData[key].percent}%`}</CustomTableCell>
        </CustomTableRow>
      ))}
    </TableHead>
    <TableHead className={classes.otherCriteria}>
      <CustomTableRow>
        <RowLabelCell>Other Criteria</RowLabelCell>
        <EmptyCols length={2} />
      </CustomTableRow>
    </TableHead>
    <TableBody className={classes.otherCriteria}>
      {Object.entries(OtherCriteriaLabels).map(([key, label]) => (
        <CustomTableRow key={key}>
          <RowLabelCell>{label}</RowLabelCell>
          <CustomTableCell>{tableData[key].count}</CustomTableCell>
          <CustomTableCell>{`${tableData[key].percent}%`}</CustomTableCell>
        </CustomTableRow>
      ))}
    </TableBody>
    <TableHead className={classes.total}>
      <CustomTableRow>
        <RowLabelCell>Totals</RowLabelCell>
        <EmptyCols length={2} />
      </CustomTableRow>
    </TableHead>
    <TableBody className={classes.total}>
      {Object.entries(TotalLabels).map(([key, label]) => (
        <CustomTableRow key={key}>
          <RowLabelCell>{label}</RowLabelCell>
          <CustomTableCell>{tableData[key].count}</CustomTableCell>
          <CustomTableCell>{`${tableData[key].percent}%`}</CustomTableCell>
        </CustomTableRow>
      ))}
    </TableBody>
  </Table>
);

class D1GradRate extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    domain: PropTypes.object.isRequired,
  };

  render() {
    const { domain, classes } = this.props;
    const { table, raw, scaled, letter } = domain;
    return (
      <div id="reportCCMR">
        {/* <p style={{ marginLeft: '939px', marginTop: '-82px' }}>
          Letter:
          {letter}
        </p>
        <p style={{ marginLeft: '1022px', marginTop: '-33px' }}>
        Scaled:
          {scaled}
        </p>
        <p style={{ marginLeft: '1122px', marginTop: '-33px', paddingBottom: '35px' }}>
        Raw:
          {raw}
        </p> */}
        <MathReadingTable tableData={table} classes={classes} />
      </div>
    );
  }
}

export default withStyles(styles)(D1GradRate);
