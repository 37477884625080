export const checkIfSuccess = (checklist) => {
  if (checklist.status != null && checklist.status) {
    // console.log('checklist', checklist, true);
    return true;
  }
  if (checklist.status != null) {
    // console.log('checklist', checklist, false);
    return false;
  }
  if (typeof checklist === 'boolean') {
    return checklist;
  }
  // console.log('checklist', checklist, 'continue');
  return Object.values(checklist).reduce((prev, curr) => {
    if (!prev || !curr) return false;
    // console.log('curr', curr);
    if (typeof curr === 'object') {
      return checkIfSuccess(curr);
    }
    return true;
  }, true);
};

export default '';
