import { connect } from 'react-redux';
import { getOrgTags, deleteOrgTag, saveOrgTag } from '../+store/ManageOrgTags';
import Component from './ManageOrgTags.component';

const mapStateToProps = (state) => ({
  status: state.modules.Settings.manageOrgTags.status,
  tags: state.modules.Settings.manageOrgTags.tags,
  addedTag: state.modules.Settings.manageOrgTags.addedTag,
  deletedTag: state.modules.Settings.manageOrgTags.deletedTag,
});

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    dispatch(getOrgTags());
  },
  save: (firebaseId, tag) => {
    dispatch(saveOrgTag({ firebaseId, tag }));
  },
  delete: (firebaseId) => {
    dispatch(deleteOrgTag({ firebaseId }));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
