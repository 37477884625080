import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Menu,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';
import { Button } from '../../common';
import { checkIfSuccess } from './AssessmentChecklist.utils';
import styles from './AssessmentChecklist.styles';

const StyledMenu = withStyles({})((props) => <Menu {...props} />);

const StyledExpansionSummary = withStyles({
  root: {
    minHeight: 36,
    '&$expanded': {
      minHeight: 36,
    },
  },
  expanded: {
    'content&$expanded': {
      margin: '8px 0 0 0',
    },
  },
  content: {
    '&$expanded': {
      margin: '8px 0 0 0',
    },
  },
  expandIcon: {
    padding: 0,
    paddingRight: 12,
    '&$expanded': {
      paddingRight: 0,
      paddingLeft: 12,
    },
  },
})(ExpansionPanelSummary);

const checkYearlyAssessmentChecklist = (subList) => Object.values(subList).every((s) => s.status);
class AssessmentChecklist extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.any.isRequired,
    checkListData: PropTypes.any.isRequired,
    ccmrSource: PropTypes.any.isRequired,
    classes: PropTypes.any.isRequired,
    initChecklist: PropTypes.func.isRequired,
    getCcmrSource: PropTypes.func.isRequired,
    profile: PropTypes.any.isRequired,
    route: PropTypes.any,
  };

  state = {
    anchorEl: null,
  };

  componentDidMount() {
    const { initChecklist, profile, loading, getCcmrSource } = this.props;
    if (profile && !loading) {
      initChecklist(profile);
      getCcmrSource(profile, profile.role === 'campusAdmin' ? profile.campusNumber : null);
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  checkCcmrTracking = (checkList, route, ccmrSource) => {
    if (route && !ccmrSource) {
      return checkList.slice(0, 1);
    }
    return checkList;
  };

  mapSublistKey = (key) => {
    switch (key) {
      case '2019':
        return '2019 Assessments';
      case 'roster':
        return 'Student Roster';
      case 'ccmrTracking':
        return 'CCMR Tracking';
      default:
        return key;
    }
  };

  checkIconType = (checkListValArr) => {
    if (!checkListValArr) {
      return <Icon style={{ color: '#FF5656', paddingRight: 12 }}>priority_high</Icon>;
    }
    return <Icon style={{ color: '#79f5ab', paddingRight: 12 }}>done</Icon>;
  };

  render() {
    const { checkListData, classes, route, ccmrSource } = this.props;
    const { anchorEl } = this.state;
    if (!checkListData.success) {
      return null;
    }
    const checklist = this.checkCcmrTracking(Object.entries(checkListData.checkList), route, ccmrSource);
    const checklistComplete = checkIfSuccess(checklist);
    return (
      <div className={classes.base}>
        <Button variant="outlined" color="white" onClick={this.handleClick} className={classes.button}>
          {this.checkIconType(checklistComplete)}
          <Typography>Assessment Checklist</Typography>
        </Button>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem className={classes.menuItem}>
            <Typography className={classes.header}>Assessment Checklist</Typography>
          </MenuItem>
          {checklist.map(([key, sublist]) => (
            <MenuItem key={key} className={classes.menuItem}>
              <ExpansionPanel className={classes.panel} defaultExpanded={!sublist.status}>
                <StyledExpansionSummary expandIcon={<ExpandMoreIcon className={classes.noPadding} />}>
                  <Icon
                    className={
                      sublist.status || checkYearlyAssessmentChecklist(sublist) ? classes.yesIcon : classes.noIcon
                    }
                  >
                    {sublist.status || checkYearlyAssessmentChecklist(sublist) ? 'check_circle' : 'cancel'}
                  </Icon>
                  <Typography className={classes.heading}>{this.mapSublistKey(key)}</Typography>
                </StyledExpansionSummary>
                <ExpansionPanelDetails className={classes.sublist}>
                  {!['settings', 'roster', 'ccmrTracking'].includes(key) &&
                    Object.entries(sublist).map(([assessmentType, assessment]) => (
                      <ExpansionPanel
                        className={classes.panel}
                        defaultExpanded={!assessment.status}
                        key={assessmentType}
                      >
                        <StyledExpansionSummary expandIcon={<ExpandMoreIcon />}>
                          <Icon className={assessment.status ? classes.yesIcon : classes.noIcon}>
                            {assessment.status ? 'check_circle' : 'cancel'}
                          </Icon>
                          <Typography className={classes.heading}>{assessment.name}</Typography>
                        </StyledExpansionSummary>
                        <ExpansionPanelDetails className={classes.sublist}>
                          {assessment.assessments ? (
                            Object.values(assessment.assessments).map((component, index) => (
                              <div className={classes.row} key={index}>
                                <Icon className={component.status ? classes.yesIcon : classes.noIcon}>
                                  {component.status ? 'check_circle' : 'cancel'}
                                </Icon>
                                <Typography>{component.name}</Typography>
                              </div>
                            ))
                          ) : (
                            <Typography>{assessment.name}</Typography>
                          )}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    ))}
                  {key === 'roster' && (
                    <div>
                      <div className={classes.row}>
                        <Icon className={sublist.file.status ? classes.yesIcon : classes.noIcon}>
                          {sublist.file.status ? 'check_circle' : 'cancel'}
                        </Icon>
                        <Typography>{sublist.file.name}</Typography>
                      </div>
                    </div>
                  )}
                  {key === 'ccmrTracking' && (
                    <div>
                      <div className={classes.row}>
                        <Icon className={sublist.name ? classes.yesIcon : classes.noIcon}>
                          {sublist.file.status ? 'check_circle' : 'cancel'}
                        </Icon>
                        <Typography>{sublist.file.name}</Typography>
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
    );
  }
}

export default withStyles(styles)(AssessmentChecklist);
