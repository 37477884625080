import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import FilterIcon from '@material-ui/icons/Tune';
import { Menu, IconButton } from '@material-ui/core';
import { TitleBackButton } from '../../common';
import { FilterBox } from '../../common/FilterBox';
import styles from './ScoreBoard.styles';
import AccessAlarm from '@material-ui/icons/AccessAlarm';

class CCMRTracking extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    ccmrTrackingData: PropTypes.object,
    filterBoxParams: PropTypes.object,
    handleEditStateChange: PropTypes.func.isRequired,
    handleIsMonitorStateChange: PropTypes.func,
    isEditing: PropTypes.bool.isRequired,
  };

  state = {
    showFilters: false,
    anchorEl: null,
    isMonitorFilter: null,
  };

  toggleFilters = () => {
    this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
  };

  calculateRawScore = () => {
    const { ccmrTrackingData } = this.props;
    const ccmrData = Object.values(ccmrTrackingData);
    const denominator = ccmrData.length;
    const numerator = ccmrData.reduce((prev, curr) => prev + ((curr ? Number(curr.ccmrPoints) : 0) || 0), 0);
    const raw = denominator ? ((numerator * 100) / denominator).toFixed(2) : 0;
    return { raw, numerator, denominator };
  };

  handleFiltersClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleFiltersClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render = () => {
    const { classes, filterBoxParams, handleEditStateChange, isEditing, handleIsMonitorStateChange } = this.props;
    const { model, sortSection, filterSections } = filterBoxParams;
    const { showFilters, anchorEl, isMonitorFilter } = this.state;

    const ccmrRawScore = this.calculateRawScore();
    return (
      <div className={classes.scoreBoardRoot}>
        <TitleBackButton title="CCMR Tracking">
          <div className={classes.rawScoreWrapper}>
            <div className={classes.editOnOffText}>
              CCMR Raw Score
              <strong className={classes.scoreWrapper}>{`${parseFloat(ccmrRawScore.raw)}%`}</strong>
            </div>
            <div className={classes.editOnOffText}>
              Total CCMR Points
              <strong className={classes.scoreWrapper}>{ccmrRawScore.numerator}</strong>
            </div>
            <div className={classes.editOnOffText}>
              Number of Students
              <strong className={classes.scoreWrapperNoBorder}>{ccmrRawScore.denominator}</strong>
            </div>
          </div>
          <div className={classes.iconsContainer}>
            <IconButton className={classes.iconContainer} onClick={this.handleFiltersClick} style={{ padding: 0 }}>
              <FilterIcon
                color="primary"
                fontSize="small"
                className={classNames({ [classes.editingIconColor]: showFilters })}
              />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleFiltersClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{
                list: {
                  margin: 0,
                },
              }}
            >
              <FilterBox
                model={model}
                filterSections={filterSections}
                sortSection={sortSection}
                style={{ margin: 16 }}
              />
            </Menu>
            <div
              className={classNames(classes.iconContainer, {
                [classes.editingIconContainer]: isEditing,
              })}
              onClick={handleEditStateChange}
            >
              <EditIcon
                color="primary"
                fontSize="small"
                className={classNames({ [classes.editingIconColor]: isEditing })}
              />
            </div>
          </div>
        </TitleBackButton>
        {isEditing && (
          <div className={classes.yesNoInfoDiv}>
            <div className={classes.yesNoInfoLabel}>Change Status By Clicking:</div>
            <div className={classes.yesLabel} style={{ width: 35, margin: 5 }}>
              Y
            </div>
            <div className={classes.noLabel} style={{ width: 35, margin: 5 }}>
              N
            </div>
            <div className={classes.dashLabel} style={{ width: 35, margin: 5 }}>
              -
            </div>
            <div style={{ width: 35, margin: 5 }}>
              <IconButton onClick={handleIsMonitorStateChange}>
                <AccessAlarm className={classes.icon} color="primary" title="Monitor" />
              </IconButton>
            </div>
          </div>
        )}
        {showFilters && (
          <div className={classes.filterContainer}>
            <FilterBox model={model} filterSections={filterSections} sortSection={sortSection} />
          </div>
        )}
      </div>
    );
  };
}

export default withStyles(styles)(CCMRTracking);
