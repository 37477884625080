import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import PersonAdd from '@material-ui/icons/PersonAdd';
import {
  Grid,
  IconButton,
  Typography,
  Paper,
  InputBase,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import styles from './ManageUsers.styles';
import User from './UserRow';
import UpdateAddUser from './UpdateAddUser';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import * as Fuse from 'fuse.js';

class ManageUsers extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    users: PropTypes.object,
    invitedUsers: PropTypes.object,
    init: PropTypes.func,
    status: PropTypes.shape({
      success: PropTypes.bool,
      message: PropTypes.string,
    }),
    profile: PropTypes.object.isRequired,
    handleDisableViewReportsForAllUsersEpic: PropTypes.func,
    handleEnableViewReportsForAllUsersEpic: PropTypes.func,
  };
  constructor() {
    super();
    this.state = {
      currentInstance: this,
    };

    this.handleEnableDisableViewReportsOptionChange = this.handleEnableDisableViewReportsOptionChange.bind(this);
  }
  state = {
    openInviteModal: false,
    order: 'asc',
    orderBy: 'displayName',
    initSearchUsers: false,
    initSearchUsersLoad: false,
    initSearchInvitedUsersLoad: false,
    searchedUsers: [],
    query: '',
  };

  componentDidMount() {
    const { init } = this.props;
    init();
  }

  mergeUsers(users, invitedUsers) {
    var searchedUsersTmp = [];
    if (users) {
      Object.entries(users).forEach((item) => {
        item[1].firebaseId = item[0];
        item[1].status = 'Active';
        //const num = user.role === 'campusAdmin' ? user.campusNumber : user.districtNumber;
        // item[1].role=num;
        searchedUsersTmp.push(item[1]);
      });
    }
    if (invitedUsers) {
      Object.entries(invitedUsers).forEach((item) => {
        item[1].firebaseId = item[0];
        item[1].isInvitedUser = true;
        item[1].status = 'Invited';
        searchedUsersTmp.push(item[1]);
      });
    }
    return searchedUsersTmp;
  }
  static getDerivedStateFromProps(props, state) {
    if (!state.initSearchUsersLoad || (!state.initSearchInvitedUsersLoad && (props.users || props.invitedUsers))) {
      var searchedUsersTmp = state.currentInstance.mergeUsers(props.users, props.invitedUsers);
      if (searchedUsersTmp != null && searchedUsersTmp.length > 0) {
        const defaultStatus = searchedUsersTmp
          ? searchedUsersTmp.every((user) => user.canViewAccountabilityReports)
          : false;

        return {
          ...state,
          initSearchInvitedUsersLoad: props.invitedUsers != null,
          initSearchUsersLoad: props.users != null,
          order: 'desc',
          orderBy: 'displayName',
          searchedUsers: searchedUsersTmp,
          enableDisableViewReportsValue: defaultStatus ? 'enable' : 'disable',
        };
      }
    }
    return state;
  }

  handleCloseModal = () => {
    this.setState({ openInviteModal: false });
  };

  handleOpenModal = () => {
    this.setState({ openInviteModal: true });
  };

  handleSort = (event, property) => {
    const { orderBy, order } = this.state;
    let newOrder = 'desc';
    if (orderBy === property && order === 'desc') {
      newOrder = 'asc';
    }
    this.setState({ order: newOrder, orderBy: property });
  };

  onSearchChange = (e) => {
    this.setState({ query: e.target.value });
  };

  onSearchClick = (e) => {
    const options = {
      shouldSort: true,
      threshold: 0.05,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['displayName', 'emailAddress', 'role', 'phoneNumber', 'status'],
    };
    const { users, invitedUsers } = this.props;

    const { query } = this.state;
    let searchedUsersTmp = this.mergeUsers(users, invitedUsers);
    const fuse = new Fuse(searchedUsersTmp, options);
    if (query) {
      searchedUsersTmp = fuse.search(query);
    }
    this.setState({ searchedUsers: searchedUsersTmp, initSearchUsers: !!query });
  };

  onSearchEnter = (e) => {
    if (e.key === 'Enter') {
      this.onSearchClick();
    }
  };
  onSortChange = (property) => (event) => {
    this.handleSort(event, property);
  };

  sortBy(array, order, orderBy) {
    if (order == null) {
      order = 'asc';
    }
    if (orderBy == null) {
      orderBy = 'displayName';
    }
    const orderConst = order === 'desc' ? 1 : -1;
    const orderByKey = orderBy;
    if (array == null || array.length == 0) {
      return [];
    }
    if (array.length == 1) {
      return array;
    }
    return array.sort((a, b) => {
      let ele1 = a[orderByKey];
      let ele2 = b[orderByKey];
      if (!ele1 && !ele2) {
        return 0;
      }
      if (ele1 && !ele2) {
        return orderConst;
      }
      if (ele2 && !ele1) {
        return -orderConst;
      }
      if (typeof ele1 === 'string' || typeof ele2 === 'string') {
        return ele1.localeCompare(ele2) * orderConst;
      }
      return (ele1 - ele2) * orderConst;
    });
  }

  renderStatus = () => {
    const { classes, status } = this.props;
    const Icon = status.success ? CheckCircleIcon : ErrorIcon;
    if (status.message) {
      return (
        <div
          className={classNames(classes.statusMsg, {
            [classes.success]: status.success,
            [classes.error]: !status.success,
          })}
        >
          <Icon className={status.success ? classes.success : classes.error} />
          {status.message}
        </div>
      );
    }
    return null;
  };

  renderSearchBar = () => {
    const { classes } = this.props;
    return (
      <Paper>
        <InputBase
          fullWidth
          onKeyDown={this.onSearchEnter}
          onChange={this.onSearchChange}
          className={classes.input}
          placeholder="Name or Email"
          inputProps={{ 'aria-label': 'Name or Email' }}
          endAdornment={
            <IconButton className={classes.iconButton} aria-label="search" onClick={this.onSearchClick}>
              <SearchIcon />
            </IconButton>
          }
        />
      </Paper>
    );
  };
  renderSortIcon(sortByField) {
    const { order, orderBy } = this.state;
    if (orderBy === sortByField) {
      if (order == 'asc') {
        return <ArrowUpwardIcon fontSize="small" />;
      } else {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
  }

  handleEnableDisableViewReportsOptionChange(event) {
    const { handleEnableViewReportsForAllUsersEpic, handleDisableViewReportsForAllUsersEpic } = this.props;
    this.setState({ enableDisableViewReportsValue: event.target.value });
    if (event.target.value === 'enable') {
      handleEnableViewReportsForAllUsersEpic();
    } else {
      handleDisableViewReportsForAllUsersEpic();
    }
  }

  render() {
    const { classes, profile } = this.props;
    const { openInviteModal, order, orderBy, searchedUsers, enableDisableViewReportsValue } = this.state;
    const userTableHeaders = ['Name', 'Email', 'Role', 'Phone', 'Status'];
    const userTableHeadersColumns = ['displayName', 'emailAddress', 'role', 'phoneNumber', 'status'];

    // if (!users && !invitedUsers) {
    //   return (
    //     <Typography variant="h6" gutterBottom>
    //       You are the only user in your organization. Start by inviting admins to your organization.
    //     </Typography>
    //   );
    // }
    return (
      <React.Fragment>
        {!searchedUsers && (
          <Typography variant="h6" gutterBottom>
            You are the only user in your organization. Start by inviting admins to your organization.
          </Typography>
        )}

        {this.renderSearchBar()}
        <p></p>

        <Grid container direction="column" justify="flex-start" alignItems="center">
          {this.renderStatus()}
          {profile && profile.isSuperAdmin === true && (
            <Grid container direction="row" justify="center" alignItems="center">
              <FormControl component="fieldset">
                <FormLabel component="legend">View Reports for All Users</FormLabel>
                <RadioGroup
                  aria-label="view reports for all users"
                  name="viewReports"
                  value={enableDisableViewReportsValue}
                  onChange={this.handleEnableDisableViewReportsOptionChange}
                  row
                >
                  <FormControlLabel value="enable" control={<Radio />} label="Enable" />
                  <FormControlLabel value="disable" control={<Radio />} label="Disable" />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {openInviteModal && <UpdateAddUser handleUpdateClose={this.handleCloseModal} />}
          <Grid container direction="row" justify="space-between" alignItems="center" item>
            {userTableHeaders.map((header, index) => (
              <div
                onClick={this.onSortChange(userTableHeadersColumns[index])}
                key={header}
                className={classNames(classes.cell, classes.headerCell, {
                  [classes.smallCell]: index === 3 || index === 4,
                  [classes.textCell]: index !== 3 && index !== 4,
                })}
                style={{ background: index % 2 ? '#effdff' : '#f8ffff', cursor: 'pointer' }}
              >
                {header}
                {this.renderSortIcon(userTableHeadersColumns[index])}
              </div>
            ))}
            <div className={classNames(classes.cell, classes.iconCell, classes.headerCell)}>
              <IconButton onClick={this.handleOpenModal}>
                <PersonAdd />
              </IconButton>
            </div>
          </Grid>
          {searchedUsers &&
            searchedUsers.length > 0 &&
            this.sortBy(searchedUsers, order, orderBy).map((user) => (
              <User
                profile={profile}
                isInvitedUser={user.isInvitedUser}
                key={user.firebaseId}
                firebaseId={user.firebaseId}
                user={user}
              />
            ))}
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ManageUsers);
