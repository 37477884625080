import { combineReducers } from 'redux';
import moment from 'moment';
import {
  DELETE_ASSESSMENT,
  GET_ASSESSMENT_GROUPS,
  ADD_ASSESSMENT_GROUP,
  UPDATE_ASSESSMENT_GROUP,
  DELETE_ASSESSMENT_GROUP,
  CHANGE_ASSESSMENT_GROUP_VISIBILITY,
} from './assessments.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const lastFetched = (state = moment(0), action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ASSESSMENT_GROUPS) {
        return moment();
      }
      return state;
    default:
      return state;
  }
};

const assessmentGroups = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ASSESSMENT_GROUPS || action.model === DELETE_ASSESSMENT) {
        return Object.assign({}, action.data.assessmentGroups);
      }
      if (action.model === DELETE_ASSESSMENT_GROUP) {
        delete state[action.data.assessmentGroupId];
        return Object.assign({}, state);
      }
      if (
        action.model === ADD_ASSESSMENT_GROUP ||
        action.model === UPDATE_ASSESSMENT_GROUP ||
        action.model === CHANGE_ASSESSMENT_GROUP_VISIBILITY
      ) {
        state[action.data.assessmentGroupId] = action.data.assessmentGroup;
        return Object.assign({}, state);
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_ASSESSMENT_GROUPS) {
        return 'Loading ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_ASSESSMENT_GROUPS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  lastFetched,
  assessmentGroups,
  loadingMsg,
});

export default 'studentsPage.reducers.js';
