import { ReportSectionKeys } from '../+store/aToF';
import { makeCsv } from '../../common/utils';

const indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  ecoDis: 'Economically Disadvantaged',
  lep: 'EL (Current & Monitored)',
  specialEdCurrent: 'Special Ed (Current)',
  specialEdFormer: 'Special Ed (Former)',
  continuouslyEnrolled: 'Continuously Enrolled',
  // nonContinuouslyEnrolled: 'Non-Continuously Enrolled',
  highlyMobile: 'Highly Mobile',
  highFocus: 'High Focus',
  acceleratedTesterMath: 'Accelerated Tester Math',
  acceleratedTesterReading: 'Accelerated Tester Reading',
  acceleratedTesterScience: 'Accelerated Tester Science',
};

const exportAsCsv = async (reportSection, report, districtCampusName) => {
  let blob = null;
  let fileName = null;
  if (report) {
    switch (reportSection) {
      case ReportSectionKeys.summary:
        if (report.summary) {
          const summaryColumns = ['Section', 'Raw Score', 'Scaled Score', 'Letter Grade'];
          fileName = 'Summary.csv';
          const summaryData = [
            {
              Section: 'Student Achievement',
              'Raw Score': report.d1.overall.raw,
              'Scaled Score': report.d1.overall.score,
              'Letter Grade': report.d1.overall.letter,
            },
            {
              Section: 'STAAR Performance',
              'Raw Score': report.d1.staar.raw,
              'Scaled Score': report.d1.staar.scaled,
              'Letter Grade': report.d1.staar.letter,
            },
          ];

          if (report.d1.ccmr) {
            summaryData.push({
              Section: 'College, Career and Military Readiness',
              'Raw Score': report.d1.ccmr.raw,
              'Scaled Score': report.d1.ccmr.scaled,
              'Letter Grade': report.d1.ccmr.letter,
            });
          }
          if (report.d1.gradRate) {
            summaryData.push({
              Section: 'Graduation Rate',
              'Raw Score': report.d1.gradRate.raw,
              'Scaled Score': report.d1.gradRate.scaled,
              'Letter Grade': report.d1.gradRate.letter,
            });
          }
          summaryData.push({
            Section: 'School Progress',
            'Raw Score': ' ',
            'Scaled Score': ' ',
            'Letter Grade': ' ',
          });
          summaryData.push({
            Section: 'Academic Growth',
            'Raw Score': report.d2a.raw,
            'Scaled Score': report.d2a.scaled,
            'Letter Grade': report.d2a.letter,
          });
          summaryData.push({
            Section: `Relative Performance (Eco Dis: ${report.d2b.ecoDis}%)`,
            'Raw Score': report.d2b.raw,
            'Scaled Score': report.d2b.scaled,
            'Letter Grade': report.d2b.letter,
          });
          summaryData.push({
            Section: 'Closing the Gaps',
            'Raw Score': report.d3.raw,
            'Scaled Score': report.d3.scaled,
            'Letter Grade': report.d3.letter,
          });
          summaryData.push({
            Section: 'Summary',
            'Raw Score': report.summary.raw,
            'Scaled Score': report.summary.score,
            'Letter Grade': report.summary.letter,
          });

          summaryData.push({
            Section: 'Campuses Weights',
            'Raw Score': ' ',
            'Scaled Score': ' ',
            'Letter Grade': ' ',
          });

          const output = await makeCsv(summaryData, summaryColumns);
          blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
          // saveAs(blob, fileName);
        }
        break;
      case ReportSectionKeys.d1staar:
        if (report.d1 && report.d1.staar) {
          const types = {
            all: 'All Subjects',
            reading: 'Reading',
            math: 'Math',
            writing: 'Writing',
            science: 'Science',
            socialStudies: 'Social Studies',
          };
          const staarColumns = ['Subject Status'].concat(Object.values(indicators));
          const staarData = [];
          Object.entries(types).forEach(([name, label]) => {
            // For Average
            const staarRow = {};
            staarRow['Subject Status'] = label.concat(' Average');
            Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
              if (report.d1.staar.table[name] && report.d1.staar.table[name].average) {
                staarRow[indicatorLabel] = report.d1.staar.table[name].average[indicatorName];
              } else {
                console.log('average ' + name + ' ' + indicatorName);
              }
            });
            staarData.push(Object.assign({}, staarRow));

            const amm = {
              approaches: 'Approaches',
              meets: 'Meets',
              masters: 'Masters',
            };
            Object.entries(amm).forEach(([ammName, ammLabel]) => {
              staarRow['Subject Status'] = label.concat(' % ').concat(ammLabel);
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (
                  report.d1.staar.table[name] &&
                  report.d1.staar.table[name].percent &&
                  report.d1.staar.table[name].percent[ammName]
                ) {
                  staarRow[indicatorLabel] = report.d1.staar.table[name].percent[ammName][indicatorName];
                } else {
                  console.log('percent' + name + ' ' + ammName + ' ' + indicatorName);
                }
              });
              staarData.push(Object.assign({}, staarRow));
            });

            Object.entries(amm).forEach(([ammName, ammLabel]) => {
              staarRow['Subject Status'] = label.concat(' # ').concat(ammLabel);
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (
                  report.d1.staar.table[name] &&
                  report.d1.staar.table[name].count &&
                  report.d1.staar.table[name].count[ammName]
                ) {
                  staarRow[indicatorLabel] = report.d1.staar.table[name].count[ammName][indicatorName];
                } else {
                  console.log('count' + name + ' ' + ammName + ' ' + indicatorName);
                }
              });
              staarData.push(Object.assign({}, staarRow));
            });

            staarRow['Subject Status'] = label.concat(' Total Tests');
            Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
              if (report.d1.staar.table[name] && report.d1.staar.table[name].total) {
                staarRow[indicatorLabel] = report.d1.staar.table[name].total[indicatorName];
              } else {
                console.log('total ' + name + ' ' + indicatorName);
              }
            });
            staarData.push(Object.assign({}, staarRow));
          });
          const output = await makeCsv(staarData, staarColumns);
          blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
          fileName = 'Staar Performance.csv';
        }
        break;
      case ReportSectionKeys.d2a:
        if (report.d2a) {
          const types = {
            all: 'All Subjects',
            reading: 'Reading',
            math: 'Math',
          };
          const d2aColumns = ['Subject Status'].concat(Object.values(indicators));
          const d2aData = [];
          Object.entries(types).forEach(([name, label]) => {
            const d2aRow = {};
            d2aRow['Subject Status'] = label.concat(' Number of Tests');
            Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
              d2aRow[indicatorLabel] = report.d2a.tables[name].totalTests[indicatorName];
            });
            d2aData.push(Object.assign({}, d2aRow));

            const points = {
              zeroPoint: 'Zero Point',
              halfPoint: 'Half Point',
              onePoint: 'One Point',
              total: 'Total Points',
            };
            Object.entries(points).forEach(([pointName, pointLabel]) => {
              d2aRow['Subject Status'] = label.concat(' # ').concat(pointLabel);
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                d2aRow[indicatorLabel] = report.d2a.tables[name].count[pointName][indicatorName];
              });
              d2aData.push(Object.assign({}, d2aRow));
            });

            Object.entries(points).forEach(([pointName, pointLabel]) => {
              d2aRow['Subject Status'] = label.concat(' % ').concat(pointLabel);
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                d2aRow[indicatorLabel] = report.d2a.tables[name].percent[pointName][indicatorName];
              });
              d2aData.push(Object.assign({}, d2aRow));
            });
          });
          const output = await makeCsv(d2aData, d2aColumns);
          blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
          fileName = 'Academic Growth.csv';
        }
        break;
      case ReportSectionKeys.d2b:
        if (report.d2b) {
          const d2bColumns = ['Raw Score', 'Scaled Score', 'Letter Grade'];
          const d2bData = [
            {
              'Raw Score': report.d2b.raw,
              'Scaled Score': report.d2b.scaled,
              'Letter Grade': report.d2b.letter,
            },
          ];
          const output = await makeCsv(d2bData, d2bColumns);
          blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
          fileName = 'Relative Performance.csv';
        }
        break;
      case ReportSectionKeys.d1ccmr:
        if (report.d1.ccmr) {
          const types = {
            reading: 'Reading',
            math: 'Math',
          };
          const ccmrColumns = ['Consideration', 'Count', 'Percent'];
          const ccmrData = [];
          let ccmrRow = {};
          Object.entries(types).forEach(([name, label]) => {
            ccmrRow.Consideration = `TSI ${label} Criteria: TSI Assesment`;
            ccmrRow.Count = report.d1.ccmr.table[name].tsia.count;
            ccmrRow.Percent = `% ${report.d1.ccmr.table[name].tsia.percent}`;
            ccmrData.push(Object.assign({}, ccmrRow));

            ccmrRow = {};
            ccmrRow.Consideration = `TSI ${label} Criteria: TSI ACT`;
            ccmrRow.Count = report.d1.ccmr.table[name].act.count;
            ccmrRow.Percent = `% ${report.d1.ccmr.table[name].act.percent}`;
            ccmrData.push(Object.assign({}, ccmrRow));

            ccmrRow = {};
            ccmrRow.Consideration = `TSI ${label} Criteria: TSI SAT`;
            ccmrRow.Count = report.d1.ccmr.table[name].sat.count;
            ccmrRow.Percent = `% ${report.d1.ccmr.table[name].sat.percent}`;
            ccmrData.push(Object.assign({}, ccmrRow));

            ccmrRow = {};
            ccmrRow.Consideration = `TSI ${label} Criteria: TSI College Prep Course Credit`;
            ccmrRow.Count = report.d1.ccmr.table[name].collegePrep.count;
            ccmrRow.Percent = `% ${report.d1.ccmr.table[name].collegePrep.percent}`;
            ccmrData.push(Object.assign({}, ccmrRow));

            ccmrRow = {};
            ccmrRow.Consideration = `TSI ${label} Criteria: TSI At Least One Criteria`;
            ccmrRow.Count = report.d1.ccmr.table[name].atLeastOne.count;
            ccmrRow.Percent = `% ${report.d1.ccmr.table[name].atLeastOne.percent}`;
            ccmrData.push(Object.assign({}, ccmrRow));
          });

          ccmrRow = {};
          ccmrRow.Consideration = 'TSI Math & TSI Reading';
          ccmrRow.Count = report.d1.ccmr.table.readingAndMath.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.readingAndMath.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Other Criteria: AP/IB Exam';
          ccmrRow.Count = report.d1.ccmr.table.apOrIb.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.apOrIb.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Other Criteria: Dual Course Credits';
          ccmrRow.Count = report.d1.ccmr.table.dualCourseCredits.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.dualCourseCredits.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Other Criteria: Industry Certification';
          ccmrRow.Count = report.d1.ccmr.table.industryCert.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.industryCert.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Other Criteria: Associates Degree';
          ccmrRow.Count = report.d1.ccmr.table.associatesDeg.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.associatesDeg.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Other Criteria: Graduated with Completed IEP';
          ccmrRow.Count = report.d1.ccmr.table.iep.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.iep.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Other Criteria: U.S Armed Forces';
          ccmrRow.Count = report.d1.ccmr.table.armedForces.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.armedForces.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Other Criteria: CTE Coursework';
          ccmrRow.Count = report.d1.ccmr.table.alignedCteCoursework.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.alignedCteCoursework.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Totals: Students Graduated';
          ccmrRow.Count = report.d1.ccmr.table.graduated.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.graduated.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));

          ccmrRow = {};
          ccmrRow.Consideration = 'Totals: CCMR Credir';
          ccmrRow.Count = report.d1.ccmr.table.ccmr.count;
          ccmrRow.Percent = `% ${report.d1.ccmr.table.ccmr.percent}`;
          ccmrData.push(Object.assign({}, ccmrRow));
          const output = await makeCsv(ccmrData, ccmrColumns);
          blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
          fileName = 'CCMR.csv';
        }
        break;
      case ReportSectionKeys.d1gradRate:
        if (report.d1.gradRate) {
          const gradIndicators = {
            all: 'All Students',
            black: 'African American',
            hispanic: 'Hispanic',
            white: 'White',
            nativeAmerican: 'American Indian',
            asian: 'Asian',
            pacificIslander: 'Pacific Islander',
            twoOrMoreRaces: 'Two or More Races',
            ecoDis: 'Economically Disadvantaged',
            lep: 'EL',
            specialEdCurrent: 'Special Ed',
          };
          const types = {
            fourYear: 'Four Year',
            fiveYear: 'Five Year',
            sixYear: 'Six Year',
          };
          const gradColumns = ['Year Consideration'].concat(Object.values(gradIndicators));
          const gradData = [];
          let gradRow = {};
          Object.entries(types).forEach(([name, label]) => {
            gradRow['Year Consideration'] = `${label}  Longitudinal Grade Rate: % Graduated`;
            Object.entries(gradIndicators).forEach(([indicatorName, indicatorLabel]) => {
              gradRow[indicatorLabel] = report.d1.gradRate.table[name][indicatorName].rate;
            });
            gradData.push(Object.assign({}, gradRow));

            gradRow = {};
            gradRow['Year Consideration'] = `${label}  Longitudinal Grade Rate: # Graduated`;
            Object.entries(gradIndicators).forEach(([indicatorName, indicatorLabel]) => {
              gradRow[indicatorLabel] = report.d1.gradRate.table[name][indicatorName].numerator;
            });
            gradData.push(Object.assign({}, gradRow));

            gradRow = {};
            gradRow['Year Consideration'] = `${label}  Longitudinal Grade Rate: Total Students in Class`;
            Object.entries(gradIndicators).forEach(([indicatorName, indicatorLabel]) => {
              gradRow[indicatorLabel] = report.d1.gradRate.table[name][indicatorName].denominator;
            });
            gradData.push(Object.assign({}, gradRow));
          });
          const output = await makeCsv(gradData, gradColumns);
          blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
          fileName = 'Grad Rate.csv';
        }
        break;
      case ReportSectionKeys.d3:
        if (report.d3) {
          const types = {
            reading: 'Reading',
            math: 'Math',
          };
          const totalsColumns = {
            pointsEarned: 'Component Points Earned',
            PointsPossible: 'Component Points Possible',
            percentScore: 'Component Percent Score',
          };
          const scoreColumns = {
            weight: 'Weight',
            score: 'Score',
          };
          const d3Columns = ['Consideration', 'Subject Status']
            .concat(Object.values(indicators))
            .concat(Object.values(totalsColumns))
            .concat(Object.values(scoreColumns));

          const d3Data = [];
          let d3Row = {};
          if (report.d3.tables.academicAchievement) {
            Object.entries(types).forEach(([name, label]) => {
              // Academic Achievement

              d3Row.Consideration = 'Academic Achievement';
              d3Row['Subject Status'] = label.concat('Achievement | # Met of Measured');
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (report.d3.tables.academicAchievement[name].eligible[indicatorName]) {
                  d3Row[
                    indicatorLabel
                  ] = `${report.d3.tables.academicAchievement[name].numerator[indicatorName]} of ${report.d3.tables.academicAchievement[name].denominator[indicatorName]}`;
                }
              });
              d3Data.push(Object.assign({}, d3Row));

              d3Row = {};
              d3Row.Consideration = 'Academic Achievement';
              d3Row['Subject Status'] = label.concat('Achievement | % Met of Measured');
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (report.d3.tables.academicAchievement[name].eligible[indicatorName]) {
                  d3Row[
                    indicatorLabel
                  ] = `% ${report.d3.tables.academicAchievement[name].rate[indicatorName]} (% ${report.d3.tables.academicAchievement[name].targets[indicatorName]})`;
                }
              });
              d3Row['Component Points Earned'] = `${report.d3.tables.academicAchievement[name].totalIndicators.met}`;

              d3Row['Component Points Possible'] = `${report.d3.tables.academicAchievement[name].totalIndicators
                .eligible * 4}`;

              d3Row[
                'Component Percent Score'
              ] = `${report.d3.tables.academicAchievement[name].totalIndicators.percent}%`;
              d3Data.push(Object.assign({}, d3Row));
            });
            d3Row = {};
            d3Row.Consideration = 'Academic Achievement';
            d3Row['Subject Status'] = 'Achievement Total';
            d3Row['Component Points Earned'] = `${report.d3.tables.academicAchievement.totalIndicators.met}`;

            d3Row['Component Points Possible'] = `${report.d3.tables.academicAchievement.totalIndicators.eligible}`;

            d3Row['Component Percent Score'] = `${report.d3.tables.academicAchievement.totalIndicators.percent}%`;
            d3Row.Weight = `${(report.d3.tables.academicAchievement.totalIndicators.weight * 100).toFixed(1)}%`;
            d3Row.Score = `${report.d3.tables.academicAchievement.totalIndicators.score}%`;
            d3Data.push(Object.assign({}, d3Row));
          }
          if (report.d3.tables.academicAchievement) {
            // Academic Growth
            Object.entries(types).forEach(([name, label]) => {
              d3Row = {};
              d3Row.Consideration = 'Academic Growth';
              d3Row['Subject Status'] = label.concat('Growth | # Met of Measured');
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (
                  report.d3.tables.academicGrowth &&
                  report.d3.tables.academicGrowth[name] &&
                  report.d3.tables.academicGrowth[name].eligible[indicatorName]
                ) {
                  d3Row[
                    indicatorLabel
                  ] = `${report.d3.tables.academicGrowth[name].numerator[indicatorName]} of ${report.d3.tables.academicGrowth[name].denominator[indicatorName]}`;
                }
              });
              d3Data.push(Object.assign({}, d3Row));

              d3Row = {};
              d3Row.Consideration = 'Academic Growth';
              d3Row['Subject Status'] = label.concat('Growth | % Met of Measured');
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (
                  report.d3.tables.academicGrowth &&
                  report.d3.tables.academicGrowth[name] &&
                  report.d3.tables.academicGrowth[name].eligible[indicatorName]
                ) {
                  d3Row[
                    indicatorLabel
                  ] = `% ${report.d3.tables.academicGrowth[name].rate[indicatorName]} (% ${report.d3.tables.academicGrowth[name].targets[indicatorName]})`;
                }
              });
              if (
                report.d3.tables.academicGrowth &&
                report.d3.tables.academicGrowth[name] &&
                report.d3.tables.academicGrowth[name].totalIndicators
              ) {
                d3Row['Component Points Earned'] = `${report.d3.tables.academicGrowth[name].totalIndicators.met}`;

                d3Row['Component Points Possible'] = ` ${report.d3.tables.academicGrowth[name].totalIndicators
                  .eligible * 4}`;

                d3Row['Component Percent Score'] = `${report.d3.tables.academicGrowth[name].totalIndicators.percent}%`;
              }
              d3Data.push(Object.assign({}, d3Row));
            });
            d3Row = {};
            d3Row.Consideration = 'Academic Growth';
            d3Row['Subject Status'] = 'Growth Total';

            if (report.d3.tables.academicGrowth && report.d3.tables.academicGrowth.totalIndicators) {
              d3Row['Component Points Earned'] = `${report.d3.tables.academicGrowth.totalIndicators.met}`;

              d3Row['Component Points Possible'] = `${report.d3.tables.academicGrowth.totalIndicators.eligible * 4}`;

              d3Row['Component Percent Score'] = `${report.d3.tables.academicGrowth.totalIndicators.percent}%`;
              d3Row.Weight = `${(report.d3.tables.academicGrowth.totalIndicators.weight * 100).toFixed(1)}%`;
              d3Row.Score = `${report.d3.tables.academicGrowth.totalIndicators.score}%`;
            }
            d3Data.push(Object.assign({}, d3Row));
          }
          if (report.d3.tables.telpas) {
            // English Learner Language Proficiency
            d3Row = {};
            d3Row.Consideration = 'English Learner Language Proficiency';
            d3Row['Subject Status'] = 'Telpas Progress | # Met of Measured';
            Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
              if (report.d3.tables.telpas.eligible[indicatorName]) {
                d3Row[
                  indicatorLabel
                ] = `${report.d3.tables.telpas.numerator[indicatorName]} of ${report.d3.tables.telpas.denominator[indicatorName]}`;
              }
            });
            d3Data.push(Object.assign({}, d3Row));
            d3Row = {};
            d3Row.Consideration = 'English Learner Language Proficiency';
            d3Row['Subject Status'] = 'Telpas Progress | % Met of Measured';
            Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
              if (report.d3.tables.telpas.eligible[indicatorName]) {
                d3Row[
                  indicatorLabel
                ] = `% ${report.d3.tables.telpas.rate[indicatorName]} (% ${report.d3.tables.telpas.targets[indicatorName]})`;
              }
            });
            d3Row['Component Points Earned'] = `${report.d3.tables.telpas.totalIndicators.met}`;

            d3Row['Component Points Possible'] = ` ${report.d3.tables.telpas.totalIndicators.eligible * 4}`;

            d3Row['Component Percent Score'] = `${report.d3.tables.telpas.totalIndicators.percent}%`;
            d3Data.push(Object.assign({}, d3Row));

            d3Row = {};
            d3Row.Consideration = 'English Learner Language Proficiency';
            d3Row['Subject Status'] = 'Telpas Total';
            d3Row['Component Points Earned'] = `${report.d3.tables.telpas.totalIndicators.met}`;

            d3Row['Component Points Possible'] = `${report.d3.tables.telpas.totalIndicators.eligible * 4}`;

            d3Row['Component Percent Score'] = `${report.d3.tables.telpas.totalIndicators.percent}%`;
            d3Row.Weight = `${(report.d3.tables.telpas.totalIndicators.weight * 100).toFixed(1)}%`;
            d3Row.Score = `${report.d3.tables.telpas.totalIndicators.score}%`;
            d3Data.push(Object.assign({}, d3Row));
          }

          if (report.d3.tables.schoolQuality) {
            // Student Achievement Domain
            d3Row = {};
            d3Row.Consideration = 'Student Achievement Domain';
            d3Row['Subject Status'] = 'Score (Target)';
            Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
              if (report.d3.tables.schoolQuality.eligible[indicatorName]) {
                d3Row[
                  indicatorLabel
                ] = `% ${report.d3.tables.schoolQuality.rate[indicatorName]} (% ${report.d3.tables.schoolQuality.targets[indicatorName]})`;
              }
            });
            d3Data.push(Object.assign({}, d3Row));
            const amm = {
              approaches: '% Approaches',
              meets: '% Meets',
              masters: '% Masters',
            };
            Object.entries(amm).forEach(([ammName, ammLabel]) => {
              d3Row = {};
              d3Row.Consideration = 'Student Achievement Domain';
              d3Row['Subject Status'] = ammLabel;
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (report.d3.tables.schoolQuality.eligible[indicatorName]) {
                  d3Row[indicatorLabel] = report.d3.tables.schoolQuality[ammName][indicatorName];
                }
              });
              d3Data.push(Object.assign({}, d3Row));
            });
            d3Row = {};
            d3Row.Consideration = 'Student Achievement Domain';
            d3Row['Subject Status'] = 'Total Tests';
            Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
              if (report.d3.tables.schoolQuality.eligible[indicatorName]) {
                d3Row[indicatorLabel] = report.d3.tables.schoolQuality.denominator[indicatorName];
              }
            });
            d3Data.push(Object.assign({}, d3Row));

            d3Row = {};
            d3Row.Consideration = 'Student Achievement Domain';
            d3Row['Subject Status'] = 'Achievement Total';
            d3Row['Component Points Earned'] = `${report.d3.tables.schoolQuality.totalIndicators.met}`;

            d3Row['Component Points Possible'] = `${report.d3.tables.schoolQuality.totalIndicators.eligible * 4}`;

            d3Row['Component Percent Score'] = `${report.d3.tables.schoolQuality.totalIndicators.percent}%`;
            d3Row.Weight = `${(report.d3.tables.schoolQuality.totalIndicators.weight * 100).toFixed(1)}%`;
            d3Row.Score = `${report.d3.tables.schoolQuality.totalIndicators.score}%`;
            d3Data.push(Object.assign({}, d3Row));
          }
          if (report.d3.tables.participation) {
            // Participation
            Object.entries(types).forEach(([name, label]) => {
              d3Row = {};
              d3Row.Consideration = 'Participation';
              d3Row['Subject Status'] = label.concat('Participation | # Met of Measured');
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (report.d3.tables.participation[name].eligible[indicatorName]) {
                  d3Row[
                    indicatorLabel
                  ] = `${report.d3.tables.participation[name].numerator[indicatorName]} of ${report.d3.tables.participation[name].denominator[indicatorName]}`;
                }
              });
              d3Data.push(Object.assign({}, d3Row));

              d3Row = {};
              d3Row.Consideration = 'Participation';
              d3Row['Subject Status'] = label.concat('Participation | % Met of Measured');
              Object.entries(indicators).forEach(([indicatorName, indicatorLabel]) => {
                if (report.d3.tables.participation[name].eligible[indicatorName]) {
                  d3Row[
                    indicatorLabel
                  ] = `% ${report.d3.tables.participation[name].rate[indicatorName]} (% ${report.d3.tables.participation[name].targets[indicatorName]})`;
                }
              });
              d3Row['Component Points Earned'] = `${report.d3.tables.participation[name].totalIndicators.met}`;

              d3Row['Component Points Possible'] = `${report.d3.tables.participation[name].totalIndicators.eligible *
                4}`;

              d3Row['Component Percent Score'] = `${report.d3.tables.participation[name].totalIndicators.percent}%`;
              d3Data.push(Object.assign({}, d3Row));
            });
            d3Row = {};
            d3Row.Consideration = 'Participation';
            d3Row['Subject Status'] = 'Participation Total';
            d3Row['Component Points Earned'] = `${report.d3.tables.participation.totalIndicators.met}`;

            d3Row['Component Points Possible'] = `${report.d3.tables.participation.totalIndicators.eligible * 4}`;

            d3Row['Component Percent Score'] = `${report.d3.tables.participation.totalIndicators.percent}%`;
            d3Row.Weight = `${(report.d3.tables.participation.totalIndicators.weight * 100).toFixed(1)}%`;
            d3Row.Score = `${report.d3.tables.participation.totalIndicators.score}%`;
            d3Data.push(Object.assign({}, d3Row));
          }
          const output = await makeCsv(d3Data, d3Columns);
          blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
          fileName = 'Closing Gaps.csv';
        }
        break;
      case ReportSectionKeys.campusesWeights:
        if (report.campusesWeights) {
          const campusesWeightsColumns = [
            'School',
            'School Name',
            'Enrollment',
            'Weight',
            'Student Achievement D1',
            'D1 Points',
            'Academic Growth D2A',
            'D2A Points',
            'Relative Performance D2B',
            'D2B Points',
            'Closing the Gaps D3',
            'D3 Points',
          ];
          fileName = 'campusesWeights.csv';

          const campusesWeightsList = [];
          Object.entries(report.campusesWeights).forEach((row) => {
            const campusWeightRecord = {};
            const rowData = row[1];
            campusWeightRecord[campusesWeightsColumns[0]] = rowData.campusNumber;
            campusWeightRecord[campusesWeightsColumns[1]] = rowData.campusName;
            campusWeightRecord[campusesWeightsColumns[2]] = rowData.grade3To12StudentsCount;
            campusWeightRecord[campusesWeightsColumns[3]] = rowData.studentsWeight.toFixed(2);
            campusWeightRecord[campusesWeightsColumns[4]] = rowData.scoreDomain1;
            campusWeightRecord[campusesWeightsColumns[5]] = rowData.pointsDomain1.toFixed(2);
            campusWeightRecord[campusesWeightsColumns[6]] = rowData.scoreDomain2a;
            campusWeightRecord[campusesWeightsColumns[7]] = rowData.pointsDomain2a.toFixed(2);
            campusWeightRecord[campusesWeightsColumns[8]] = rowData.scoreDomain2b;
            campusWeightRecord[campusesWeightsColumns[9]] = rowData.pointsDomain2b.toFixed(2);
            campusWeightRecord[campusesWeightsColumns[10]] = rowData.scoreDomain3;
            campusWeightRecord[campusesWeightsColumns[11]] = rowData.pointsDomain3.toFixed(2);
            campusesWeightsList.push(campusWeightRecord);
          });

          if (report.districtDomains) {
            const districtDomainsRecord = {};
            districtDomainsRecord[campusesWeightsColumns[0]] = report.districtDomains.districtNumber;
            districtDomainsRecord[campusesWeightsColumns[1]] = report.districtDomains.districtName;
            districtDomainsRecord[campusesWeightsColumns[2]] = report.districtDomains.grade3To12StudentsCount;
            districtDomainsRecord[campusesWeightsColumns[3]] = '';
            districtDomainsRecord[campusesWeightsColumns[4]] = report.districtDomains.totalPointsDomain1.toFixed(2);
            districtDomainsRecord[campusesWeightsColumns[5]] = '';
            districtDomainsRecord[campusesWeightsColumns[6]] = report.districtDomains.totalPointsDomain2a.toFixed(2);
            districtDomainsRecord[campusesWeightsColumns[7]] = '';
            districtDomainsRecord[campusesWeightsColumns[8]] = report.districtDomains.totalPointsDomain2b.toFixed(2);
            districtDomainsRecord[campusesWeightsColumns[9]] = '';
            districtDomainsRecord[campusesWeightsColumns[10]] = report.districtDomains.totalPointsDomain3.toFixed(2);
            districtDomainsRecord[campusesWeightsColumns[11]] = '';
            campusesWeightsList.push(districtDomainsRecord);
          }

          const output = await makeCsv(campusesWeightsList, campusesWeightsColumns);
          blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
        }
        break;
      default:
    }
  }
  return {
    blob,
    fileName: `${districtCampusName} - ${fileName}`,
  };
};

export default exportAsCsv;
