import { combineEpics } from 'redux-observable';

import { _reducer } from './aToF.reducers';
import _epics from './aToF.epics';

export const epics = combineEpics(_epics);
export { _reducer };
export * from './aToF.actions';
export * from './aToF.constants';
export * from './aToF.selectors';
