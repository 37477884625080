import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import { Form, Formik } from 'formik';
import TextInputField from '../../Auth/TextInputField';
import { Button } from '../../common/Button';
import { passwordStrengthGrade } from '../../common/utils';
import styles from '../Settings.styles';

class AccountSettings extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    profile: PropTypes.object,
    onSavePasswordClick: PropTypes.func,
    onSaveProfileClick: PropTypes.func,
    status: PropTypes.shape({
      success: PropTypes.bool,
      message: PropTypes.string,
    }),
  };

  state = {
    changePassword: false,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    newPasswordFocused: false,
  };

  handleChangePasswordClick = () => {
    this.setState({ changePassword: true });
  };

  handleChangePasswordCancelClick = () => {
    this.setState({ changePassword: false });
  };

  handleToggleShowCurrentPassword = () => {
    this.setState({ showCurrentPassword: !this.state.showCurrentPassword });
  };

  handleToggleShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  handleToggleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handlePasswordFocused = () => {
    this.setState({ newPasswordFocused: true });
  };

  handlePasswordBlurred = () => {
    this.setState({ newPasswordFocused: false });
  };

  renderStatus = () => {
    const { classes, status } = this.props;
    const Icon = status.success ? CheckCircleIcon : ErrorIcon;
    if (status.message) {
      return (
        <div
          className={classNames(classes.statusMsg, {
            [classes.success]: status.success,
            [classes.error]: !status.success,
          })}
        >
          <Icon className={status.success ? classes.success : classes.error} />
          {status.message}
        </div>
      );
    }
    return null;
  };

  renderEditAccount = () => {
    const { classes, profile, onSaveProfileClick } = this.props;
    const initialValues = {
      displayName: '',
      emailAddress: '',
      phoneNumber: '',
    };
    if (profile) {
      initialValues.displayName = profile.displayName;
      initialValues.emailAddress = profile.emailAddress;
      initialValues.phoneNumber = profile.phoneNumber;
    }
    return (
      <Formik
        key="editAccount"
        validateOnBlur
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          displayName: Yup.string()
            .min(5, 'Your name should at least have 5 characters.')
            .required('Required.'),
          emailAddress: Yup.string()
            .email('Invalid email')
            .required('Required.'),
          phoneNumber: Yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, 'Invalid Phone Number Format (XXX-XXX-XXXX).'),
        })}
        onSubmit={onSaveProfileClick}
      >
        {({ values, errors, touched }) => (
          <Form className={classes.form}>
            <Typography align="center" variant="h1" className={classes.accountSettingsTitle}>
              Account Information
            </Typography>
            {this.renderStatus()}
            <TextInputField
              isDisabled
              label="Email"
              id="emailAddress"
              type="email"
              touched={touched.emailAddress}
              error={errors.emailAddress}
              autoComplete="email"
              className={classes.accountInputField}
              flexDirection="column"
            />
            <TextInputField
              label="Name"
              id="displayName"
              touched={touched.displayName}
              error={errors.displayName}
              autoComplete="name"
              className={classes.accountInputField}
              flexDirection="column"
            />
            <TextInputField
              label="Phone"
              id="phoneNumber"
              touched={touched.phoneNumber}
              error={errors.phoneNumber}
              autoComplete="phone"
              className={classes.accountInputField}
              flexDirection="column"
            />
            <div className={classes.buttonRow}>
              <Button large color="dark" className={classes.button} onClick={this.handleChangePasswordClick}>
                Change Password
              </Button>
              <Button submit large color="white" className={classes.button}>
                {' '}
                Update Account{' '}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  handleOnClickChangePassword = (values, { resetForm }) => {
    const { onSavePasswordClick } = this.props;
    onSavePasswordClick(values);
    resetForm({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    });
  };

  renderChangePassword = () => {
    const { classes } = this.props;
    const { showCurrentPassword, showConfirmPassword, showNewPassword, newPasswordFocused } = this.state;
    return (
      <Formik
        key="changePassword"
        validateOnBlur
        initialValues={{
          currentPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required('Required.'),
          newPassword: Yup.string()
            .min(10, '')
            .matches(/^(?=.*[A-Z]).*$/, ' ')
            .matches(/^(?=.*[a-z]).*$/, ' ')
            .matches(/^(?=.*\d).*$/, ' ')
            .required('Required.'),
          newPasswordConfirm: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.')
            .required('Required.'),
        })}
        onSubmit={this.handleOnClickChangePassword}
      >
        {({ values, errors, touched, setFieldTouched }) => (
          <Form className={classes.form}>
            <Typography align="center" variant="h1" className={classes.accountSettingsTitle}>
              Change Password
            </Typography>
            {this.renderStatus()}
            <TextInputField
              label="Current"
              key="currentPassword"
              id="currentPassword"
              type={showCurrentPassword ? 'text' : 'password'}
              autoComplete="off"
              touched={touched.currentPassword}
              error={errors.currentPassword}
              rightIcon={{
                name: showCurrentPassword ? 'visibility_off' : 'visibility',
                onClick: this.handleToggleShowCurrentPassword,
                isDisabled: true,
              }}
              flexDirection="column"
              className={classes.accountInputField}
            />
            <TextInputField
              key="newPassword"
              label="New"
              id="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              touched={touched.newPassword}
              error={errors.newPassword}
              autoComplete="off"
              rightIcon={{
                name: showNewPassword ? 'visibility_off' : 'visibility',
                onClick: this.handleToggleShowNewPassword,
                isDisabled: true,
              }}
              handleFocus={this.handlePasswordFocused}
              handleBlur={this.handlePasswordBlurred}
              setFieldTouched={setFieldTouched}
              className={classes.accountInputField}
              flexDirection="column"
            />
            {(touched.newPassword || newPasswordFocused) && (
              <div className={classes.passwordStrengthContainer}>{passwordStrengthGrade(values.newPassword)}</div>
            )}
            <TextInputField
              key="newPasswordConfirm"
              label="Confirm"
              id="newPasswordConfirm"
              type={showConfirmPassword ? 'text' : 'password'}
              touched={touched.newPasswordConfirm}
              error={errors.newPasswordConfirm}
              autoComplete="off"
              rightIcon={{
                name: showConfirmPassword ? 'visibility_off' : 'visibility',
                onClick: this.handleToggleShowConfirmPassword,
                isDisabled: true,
              }}
              flexDirection="column"
              className={classes.accountInputField}
            />
            <div className={classes.buttonRow}>
              <Button large color="dark" className={classes.button} onClick={this.handleChangePasswordCancelClick}>
                Cancel
              </Button>
              <Button submit large color="white" className={classes.button}>
                Update Password
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    const { changePassword } = this.state;
    return changePassword ? this.renderChangePassword() : this.renderEditAccount();
  }
}

export default withStyles(styles)(AccountSettings);
