import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BulkAddIcon from '@material-ui/icons/PlaylistAdd';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { TableCell, TableHead, TableRow, IconButton, withStyles } from '@material-ui/core';
import { mathColumns, readingColumns, headerColumns, editableHeaderColumns } from '../+store';
import styles from './CCMRTrackingHeader.styles';
import { getCCMRColWidth, getRowWidth } from '../CCMRCommon/CCMRCommon.utils';

// export const CustomTableCell = withStyles(theme => ({
//   head: {
//     padding: 8,
//     textAlign: 'center',
//     fontFamily: 'Questrial',
//     fontStyle: 'normal',
//     fontWeight: 'normal',
//     fontSize: '12px',
//     lineHeight: '12px',
//     color: '#656565',
//   },
//   body: {
//     padding: '8px 4px',
//     fontFamily: 'Questrial',
//     fontStyle: 'normal',
//     fontWeight: 'normal',
//     fontSize: '15px',
//     lineHeight: '15px',
//     textAlign: 'center',
//     color: '#656565',
//   },

// }))(TableCell);

const CustomTableCell = ({ className, styles, children }) => (
  <div className={className} style={styles}>
    {children}
  </div>
);

const CCMRTrackingHeader = ({
  classes,
  editable,
  selectCCMRKey,
  expandMath,
  expandReading,
  toggleMathColumns,
  toggleReadingColumns,
  isEditing,
}) => {
  const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
  const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;
  const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  return (
    <div
      className={classNames(classes.headerRow, classes.headerStickySafari)}
      style={{
        minWidth: getRowWidth(isEditing, expandReading, expandMath),
        width: '100%',
      }}
    >
      <div
        className={classNames(classes.headerCell, classes.headerSticky, classes.headerStickySafari)}
        style={{
          width: 350,
          left: 0,
          zIndex: 13,
        }}
      >
        Student
      </div>
      <div
        className={classNames(classes.headerCell, classes.headerSticky, classes.headerStickySafari)}
        style={{
          width: 60,
          left: 350,
          zIndex: 13,
        }}
      >
        CCMR Points
      </div>
      {expandReading &&
        Object.entries(readingColumns).map(([key1, label1], index) => (
          <div
            key={key1}
            className={classNames(classes.headerCell, classes.headerSticky, classes.headerStickySafari)}
            style={{
              background: index % 2 ? '#f0f0ba' : '#f4e586',
              width: getCCMRColWidth(key1, isEditing),
            }}
          >
            {label1}
            {editable && (
              <IconButton aria-label="bulk-edit" onClick={() => selectCCMRKey(key1)} style={{ marginLeft: 4 }}>
                <BulkAddIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        ))}
      <div
        key="reading"
        className={classNames(classes.headerCell, classes.headerSticky, classes.headerStickySafari)}
        style={{
          background: '#f4e586',
          width: 100,
        }}
      >
        ELA
        <IconButton aria-label="bulk-edit" onClick={toggleReadingColumns} style={{ marginLeft: 4 }}>
          {expandReading ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
        </IconButton>
      </div>
      {expandMath &&
        Object.entries(mathColumns).map(([key1, label1], index) => (
          <div
            key={key1}
            className={classNames(classes.headerCell, classes.headerSticky, classes.headerStickySafari)}
            style={{
              background: index % 2 ? '#b4e2fa' : '#87cdf6',
              width: getCCMRColWidth(key1, isEditing),
            }}
          >
            {label1}
            {editable && (
              <IconButton aria-label="bulk-edit" onClick={() => selectCCMRKey(key1)} style={{ marginLeft: 4 }}>
                <BulkAddIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        ))}
      <div
        key="math"
        className={classNames(classes.headerCell, classes.headerSticky, classes.headerStickySafari)}
        style={{
          background: '#87cdf6',
          width: 100,
        }}
      >
        Math
        <IconButton aria-label="bulk-edit" onClick={toggleMathColumns} style={{ marginLeft: 4 }}>
          {expandMath ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
        </IconButton>
      </div>
      {Object.entries(editableHeaderColumns).map(([key1, label1], index) => (
        <div
          key={key1}
          className={classNames(classes.headerCell, classes.headerSticky, classes.headerStickySafari)}
          style={{
            background: index % 2 ? '#f8ffff' : '#effdff',
            width: getCCMRColWidth(key1, isEditing),
            flexGrow: 1,
          }}
        >
          {label1}
          {editable && (
            <IconButton aria-label="bulk-edit" onClick={() => selectCCMRKey(key1)} style={{ marginLeft: 4 }}>
              <BulkAddIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};

CCMRTrackingHeader.propTypes = {
  classes: PropTypes.any,
  editable: PropTypes.bool,
  selectCCMRKey: PropTypes.func,
  expandMath: PropTypes.bool,
  expandReading: PropTypes.bool,
  toggleMathColumns: PropTypes.func,
  toggleReadingColumns: PropTypes.func,
};

export default withStyles(styles)(CCMRTrackingHeader);
