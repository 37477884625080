import { asyncStart } from '../../../common';

export const GET_FLIGHT_PLAN = 'flightPlan.getFlightPlan';
export const getFlightPlan = (schoolYear, assessmentsInfo, campusDistrictNumber, filters, userType, option) => (
  dispatch
) => {
  dispatch(asyncStart(GET_FLIGHT_PLAN));
  dispatch({
    type: GET_FLIGHT_PLAN,
    schoolYear,
    assessmentsInfo,
    campusDistrictNumber,
    filters,
    userType,
    option,
  });
};

export const GET_FILTER_FIELDS = 'flightPlan.getFilterFields';
export const getFilterFields = (schoolYear, assessmentsInfo, campusDistrictNumber, filters) => (dispatch) => {
  dispatch(asyncStart(GET_FILTER_FIELDS));
  dispatch({
    type: GET_FILTER_FIELDS,
    schoolYear,
    assessmentsInfo,
    campusDistrictNumber,
    filters,
  });
};

export const UPDATE_FILTERS = 'flightPlan.updateFilters';
export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS,
  filters,
});

export const UPDATE_DESIRED_SCORE = 'flightPlan.updateDesiredScore';
export const updateDesiredScore = (score) => ({
  type: UPDATE_DESIRED_SCORE,
  score,
});

export const STORE_GROUP_BY_VALUE = 'flightPlan.storeGroupByValue';
export const storeGroupBy = (groupByValue) => ({
  type: STORE_GROUP_BY_VALUE,
  groupByValue,
});
