import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Modal, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../../common';
import styles from '../ManageUsers.styles';
import TextInputField from '../../../Auth/TextInputField';
import { passwordStrengthGrade } from '../../../common/utils';

class ResetPassword extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    user: PropTypes.object.isRequired,
    onResetPassword: PropTypes.func.isRequired,
    firebaseId: PropTypes.string.isRequired,
    handleChangePasswordClose: PropTypes.func.isRequired,
  };

  state = {
    showNewPassword: false,
    showConfirmPassword: false,
    newPasswordFocused: false,
  };

  handleResetPassword = (values, { resetForm }) => {
    const { onResetPassword, firebaseId, handleChangePasswordClose } = this.props;
    onResetPassword(firebaseId, values.newPassword);
    resetForm({
      newPassword: '',
      newPasswordConfirm: '',
    });
    handleChangePasswordClose();
  };

  handlePasswordFocused = () => {
    this.setState({ newPasswordFocused: true });
  };

  handlePasswordBlurred = () => {
    this.setState({ newPasswordFocused: false });
  };

  handleToggleShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  handleToggleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  render() {
    const { classes, user, handleChangePasswordClose } = this.props;
    const { showConfirmPassword, showNewPassword, newPasswordFocused } = this.state;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={handleChangePasswordClose}
      >
        <div className={classes.modal}>
          <Formik
            key="resetPassword"
            validateOnBlur
            initialValues={{
              newPassword: '',
              newPasswordConfirm: '',
            }}
            validationSchema={Yup.object().shape({
              newPassword: Yup.string()
                .min(10, '')
                .matches(/^(?=.*[A-Z]).*$/, ' ')
                .matches(/^(?=.*[a-z]).*$/, ' ')
                .matches(/^(?=.*\d).*$/, ' ')
                .required('Required.'),
              newPasswordConfirm: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.')
                .required('Required.'),
            })}
            onSubmit={this.handleResetPassword}
          >
            {({ values, errors, touched, setFieldTouched }) => (
              <Form className={classes.form}>
                <Typography variant="h6" id="modal-title" gutterBottom>
                  {`Reset Password (${user.emailAddress}}`}
                </Typography>
                <TextInputField
                  key="newPassword"
                  label="New"
                  id="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  touched={touched.newPassword}
                  error={errors.newPassword}
                  autoComplete="new-password"
                  rightIcon={{
                    name: showNewPassword ? 'visibility_off' : 'visibility',
                    onClick: this.handleToggleShowNewPassword,
                    isDisabled: true,
                  }}
                  handleFocus={this.handlePasswordFocused}
                  handleBlur={this.handlePasswordBlurred}
                  setFieldTouched={setFieldTouched}
                  className={classes.inputField}
                  flexDirection="column"
                />
                {(touched.newPassword || newPasswordFocused) && (
                  <div className={classes.passwordStrengthContainer}>{passwordStrengthGrade(values.newPassword)}</div>
                )}
                <TextInputField
                  key="newPasswordConfirm"
                  label="Confirm"
                  id="newPasswordConfirm"
                  type={showConfirmPassword ? 'text' : 'password'}
                  touched={touched.newPasswordConfirm}
                  error={errors.newPasswordConfirm}
                  autoComplete="new-password"
                  rightIcon={{
                    name: showConfirmPassword ? 'visibility_off' : 'visibility',
                    onClick: this.handleToggleShowConfirmPassword,
                    isDisabled: true,
                  }}
                  flexDirection="column"
                  className={classes.inputField}
                />
                <Grid container direction="row" justify="center" alignItems="center">
                  <Button large color="white" onClick={handleChangePasswordClose}>
                    Cancel
                  </Button>
                  <Button submit large color="dark">
                    Update Password
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ResetPassword);
