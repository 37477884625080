import { combineReducers } from 'redux';
import { CHANGE_PASSWORD, UPDATE_PROFILE } from './AccountSettings.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const initialStatus = {
  success: false,
  message: '',
};

const status = (state = initialStatus, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === CHANGE_PASSWORD || action.model === UPDATE_PROFILE) {
        return initialStatus;
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === CHANGE_PASSWORD || action.model === UPDATE_PROFILE) {
        return {
          success: false,
          message: action.error,
        };
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === CHANGE_PASSWORD) {
        return {
          success: true,
          message: 'Your password was successfully updated!',
        };
      }
      if (action.model === UPDATE_PROFILE) {
        return {
          success: true,
          message: 'Your profile was successfully updated!',
        };
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
});

export default 'AccountSettings.reducers.js';
