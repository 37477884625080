import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, TableHead, Table, TableCell, TableRow, TableBody } from '@material-ui/core';
const styles = (theme) => ({
  root: {},
  all: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #E1E1E1;',
  },
  reading: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(248, 220, 83, 0.7);',
  },
  math: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(92, 186, 243, 0.7);',
  },
  menu: {},
  textField: {},
  formControl: {
    display: 'block',
  },
  finalTable: {
    width: 300,
  },
  halfPoint: {
    background: '#F9D89E',
  },
  onePoint: {
    background: '#FFFDA9',
  },
  zeroPoint: {
    background: '#D2FBB5',
  },
  pointP: {
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
  },
  pointDiv: {
    width: '80%',
    height: '50px',
  },
  clickableCell: {
    background: 'rgba(0, 0, 0, 0.08)',
    padding: 0,
    '&:hover,&:focus': {
      background: 'rgba(0, 0, 0, 0.2)',
    },
  },
});

const performanceLevelTypes = {
  doesNotMeetTotal: 'Does not Meet Total',
  approachesTotal: 'Approaches Low Total',
  meetsTotal: 'Meets Total',
  mastersTotal: 'Masters Total',
};
const performanceLevelTypesPrior = {
  doesNotMeetTotal: 'Does not Meet Total',
};
const drawCountCell = (
  index,
  key,
  classes,
  profile,
  subtable,
  studentsListPath,
  handleGetStudentsList,
  priorYearPerformaceLevelKey,
  currentYearPerformaceLevelKey
) => {
  let value =
    subtable.count.acceleratedLearningTable[priorYearPerformaceLevelKey] &&
    subtable.count.acceleratedLearningTable[priorYearPerformaceLevelKey][currentYearPerformaceLevelKey];
  return (
    <TableCell
      style={index % 2 ? { background: 'rgba(255,255,255,0.4)' } : {}}
      key={
        'd2aAcceleratedLearningCell_' + key + '_' + priorYearPerformaceLevelKey + '_' + currentYearPerformaceLevelKey
      }
    >
      {studentsListPath ? (
        <Button
          className={classes.clickableCell}
          color="inherit"
          onClick={() =>
            handleGetStudentsList(
              profile.organizationId,
              `${profile.uid}/${studentsListPath}/${priorYearPerformaceLevelKey}/${currentYearPerformaceLevelKey}`
            )
          }
          disabled={!value}
        >
          {value}
        </Button>
      ) : (
        value
      )}
    </TableCell>
  );
};
//<p class="verticalrl"> Prior Year</p>
const D2AAcceleratedLearningTable = ({
  subjectName,
  key,
  classes,
  subtableLabel,
  subtable,
  studentsListPath,
  handleGetStudentsList,
  profile,
}) => {
  if (!subtable) return null;
  if (key == null) {
    key = subjectName;
  }

  return (
    <div>
      <Table key={'d2aAcceleratedLearningTable_' + key} className={classes[subjectName]}>
        <TableHead>
          <TableRow>
            <TableCell>{subtableLabel} Accelerated Learning Table</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Prior Year / Current Year</TableCell>
            {Object.entries(performanceLevelTypes).map(([performaceLevelKey, performaceLevelText], index) => (
              <TableCell key={'d2aAcceleratedLearningRow_' + key + '_' + performaceLevelKey}>
                {performaceLevelText}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(performanceLevelTypesPrior).map(
            ([priorYearPerformaceLevelKey, priorYearPerformaceLevelText], index) => (
              <TableRow key={'d2aAcceleratedLearningRow_' + key + '_' + priorYearPerformaceLevelKey}>
                <TableCell>{priorYearPerformaceLevelText}</TableCell>

                {Object.entries(
                  performanceLevelTypes
                ).map(([currentYearPerformaceLevelKey, currentYearPerformaceLevelText], index) =>
                  drawCountCell(
                    index,
                    key,
                    classes,
                    profile,
                    subtable,
                    studentsListPath,
                    handleGetStudentsList,
                    priorYearPerformaceLevelKey,
                    currentYearPerformaceLevelKey
                  )
                )}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
D2AAcceleratedLearningTable.propTypes = {
  subtable: PropTypes.object,
  classes: PropTypes.any,
  subtableLabel: PropTypes.string.isRequired,
  studentsListPath: PropTypes.string,
  handleGetStudentsList: PropTypes.func,
  rowComponents: PropTypes.array,
  profile: PropTypes.object,
  key: PropTypes.string,
};

export default withStyles(styles)(D2AAcceleratedLearningTable);
