import { connect } from 'react-redux';

import Component from './StudentsPage.component';
import { markCompleted } from '../+store';
import { initStudentsRoster, uploadStudentRoster } from '../../StudentRoster/+store/studentRoster';
import { Routes } from '../../App';

const mapStateToProps = (state, ownProps) => ({
  user: state.modules.Auth.auth.user,
  needsInit: state.modules.StudentRoster.studentRoster.needsInit,
  loadingMsg: state.modules.StudentRoster.studentRoster.loadingMsg,
  loading: !!state.modules.StudentRoster.studentRoster.loadingMsg,
  status: state.modules.StudentRoster.studentRoster.status,
  profile: state.modules.Auth.auth.profile,
  uploadProgressMsg: state.modules.StudentRoster.studentRoster.uploadProgressMsg,
});

const mapDispatchToProps = (dispatch) => ({
  onNextClick: (history, next) => {
    dispatch(markCompleted(Routes.studentUpload, next, history));
  },
  onDrop: (accepted, rejected, user, profile) => {
    dispatch(uploadStudentRoster(accepted, rejected, user, profile));
  },
  onDropForDeleteRemaining: (accepted, rejected, user, profile) => {
    dispatch(uploadStudentRoster(accepted, rejected, user, profile, true));
  },
  init: (user) => {
    dispatch(initStudentsRoster(user));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
