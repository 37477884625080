import { connect } from 'react-redux';
import queryString from 'query-string';
import Component from './ProgressGoals.component';
import { getAvatars, initStudentsRoster } from '../StudentRoster/+store/studentRoster';
import { getProgressGoals, applyFilters, exportProgressGoals } from './+store/progressGoals/progressGoals.actions';

const getIgnoreCache = () => {
  const parsedQuery = queryString.parse(window.location.search);
  return parsedQuery.cache === 'false' || false;
};

const mapStateToProps = (state) => {
  const { progressGoals, nextToken, filters, progressGoalsCSV, loadingCSV } = state.modules.ProgressGoals.progressGoals;
  const students = progressGoals ? Object.entries(progressGoals) : [];
  return {
    filters,
    nextToken,
    students,
    progressGoalsCSV,
    loadingCSV,
    profile: state.modules.Auth.auth.profile,
    profileUserType: state.modules.Auth.auth.profileUserType,
    username: state.modules.Auth.auth.user.uid,
    loading: state.modules.ProgressGoals.progressGoals.loadingMsg,
    errorMsg: state.modules.ProgressGoals.progressGoals.errorMsg,
    loadingMore: state.modules.ProgressGoals.progressGoals.loadingMoreMsg,
    avatars: state.modules.StudentRoster.studentRoster.avatars,
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: (user) => {
    dispatch(initStudentsRoster(user));
    dispatch(getAvatars());
  },
  getProgressGoals: (filters, nextToken) => {
    const { sortBy, ...otherFilters } = filters;
    dispatch(applyFilters(filters));
    dispatch(
      getProgressGoals({
        limit: 100,
        nextToken,
        ignoreCache: getIgnoreCache(),
        sortBy,
        filters: otherFilters,
      })
    );
  },
  recalculateProgressGoals: (filters) => {
    const { sortBy, ...otherFilters } = filters;
    dispatch(applyFilters(filters));
    dispatch(
      getProgressGoals({
        limit: 100,
        nextToken: null,
        ignoreCache: true,
        sortBy,
        filters: otherFilters,
      })
    );
  },
  exportProgressGoals: (filters) => {
    const { sortBy, ...otherFilters } = filters;
    dispatch(exportProgressGoals(sortBy, otherFilters));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
