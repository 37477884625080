import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { asyncFinish } from '../../../common/+store/index';
import { INIT_ASSESSMENT_CHECKLIST, GET_CCMR_SOURCE } from './assessementChecklist.actions';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import { getAssessmentChecklist, getCcmrSource } from './assessementChecklist.db';
import { getSchools } from '../../../Settings/+store/SchoolSettings/SchoolSettings.db';
import { checkListMapper, isCCMRandGradDataAvailable } from './assessementChecklist.mapper';

const initCheckListEpic = (action$) =>
  action$.pipe(
    ofType(INIT_ASSESSMENT_CHECKLIST),
    switchMap((action) =>
      from(getSchools(action.profile)).pipe(
        commonApiCallFragment(action$, action, 'Get School List', {
          profile: action.profile,
        })
      )
    ),
    switchMap((action) =>
      from(getAssessmentChecklist(action.profile)).pipe(
        commonApiCallFragment(action$, action, 'Get Checklist', {
          schools: action.response,
        })
      )
    ),
    switchMap((results) => {
      if (results.success) {
        const checkList = checkListMapper(results.response, isCCMRandGradDataAvailable(results.schools));
        return of(
          asyncFinish(results.action.action.type, {
            checkList,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );
const getCcmrSourceEpic = (action$) =>
  action$.pipe(
    ofType(GET_CCMR_SOURCE),
    switchMap((action) =>
      from(getCcmrSource(action.profile, action.schoolId)).pipe(
        commonApiCallFragment(action$, action, 'Get CCMR Source', {})
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            source: results.response,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const epics = combineEpics(initCheckListEpic, getCcmrSourceEpic);
export default epics;
