import { connect } from 'react-redux';

import Component from './AssessmentsPage.component';
import { Routes } from '../../App';
import { markCompleted } from '../+store';
import { getAssessmentList, validateAssessmentFile } from '../../Assessments/+store/assessments';

const mapStateToProps = (state) => ({
  status: state.modules.OnBoarding.assessmentsPage.status,
  loading: !!state.modules.Assessments.assessments.loadingMsg,
  loadingMsg: state.modules.Assessments.assessments.loadingMsg,
  needsInit: state.modules.OnBoarding.assessmentsPage.needsInit,
  user: state.modules.Auth.auth.user,
  profile: state.modules.Auth.auth.profile,
  dropError: state.modules.OnBoarding.assessmentsPage.dropError,
  uploadProgressMsg: state.modules.Assessments.assessmentManager.uploadProgressMsg,
});

const mapDispatchToProps = (dispatch) => ({
  onNextClick: (history, next) => {
    dispatch(markCompleted(Routes.assessments, next, history));
  },
  init: (profile) => {
    dispatch(getAssessmentList(profile));
  },
  onDrop: (accepted, rejected, profile) => {
    dispatch(validateAssessmentFile(accepted[0], profile));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
