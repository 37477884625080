import { asyncStart } from '../../../common';

export const INIT_ASSESSMENT_CHECKLIST = 'assessementCheckList.initCheckList';
export const initCheckList = (profile) => (dispatch) => {
  dispatch(asyncStart(INIT_ASSESSMENT_CHECKLIST));
  dispatch({ type: INIT_ASSESSMENT_CHECKLIST, profile });
};

export const GET_CCMR_SOURCE = 'assessementCheckList.getCcmrSource';
export const getCcmrSource = (profile, schoolId) => (dispatch) => {
  dispatch(asyncStart(GET_CCMR_SOURCE));
  dispatch({ type: GET_CCMR_SOURCE, profile, schoolId });
};
