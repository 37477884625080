import { combineReducers } from 'redux';
import {
  GET_CCMR_TRACKING,
  UPDATE_CCMR_TRACKING,
  UPDATE_BULK_CCMR_TRACKING,
  SELECT_CCMR_KEY,
  CANCEL_SELECT_CCMR_KEY,
  UPDATE_IS_MONITOR_Filter,
} from './ccmrTracking.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../common/index';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_CCMR_TRACKING) {
        return 'Loading CCMR Tracking Data ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_CCMR_TRACKING) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const bulkEntryKey = (state = null, action) => {
  if (action.type === SELECT_CCMR_KEY) {
    return action.key;
  }
  if (
    action.type === CANCEL_SELECT_CCMR_KEY ||
    (action.type === ASYNC_FINISH && action.model === UPDATE_BULK_CCMR_TRACKING)
  ) {
    return null;
  }
  return state;
};

const isMonitorFilter = (state = null, action) => {
  if (action.type === UPDATE_IS_MONITOR_Filter) {
    return action.value;
  }
  return state;
};

const ccmrTrackingData = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_CCMR_TRACKING) {
        console.log('action', action);
        return action.data.ccmrTrackingData;
      }
      if (action.model === UPDATE_CCMR_TRACKING) {
        return Object.assign({}, state, {
          [action.data.firebaseId]: Object.assign({}, state[action.data.firebaseId], action.data.studentData),
        });
      }
      if (action.model === UPDATE_BULK_CCMR_TRACKING) {
        return Object.assign({}, state, action.data.studentsData);
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  loadingMsg,
  ccmrTrackingData,
  bulkEntryKey,
  isMonitorFilter,
});

export default 'ccmrTracking.reducers.js';
