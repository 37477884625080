import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { Typography, Button, CircularProgress } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginLeft: '0.6875rem',
    marginRight: '0.6875rem',
    fontSize: '1rem',
  },
  saveAltButton: {
    fontSize: '1.25rem',
  },
});

class ExportCSVButton extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
  };

  render() {
    const { classes, title, onClick, loading } = this.props;
    return (
      <Button onClick={onClick} style={{ textDecoration: 'none' }}>
        <div className={classes.container}>
          {loading ? <CircularProgress size={20} /> : <SaveAlt color="primary" className={classes.saveAltButton} />}
          <Typography align="center" variant="h3" className={classes.title}>
            {title}
          </Typography>
        </div>
      </Button>
    );
  }
}

export default withStyles(styles)(ExportCSVButton);
