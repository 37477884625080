import { withStyles } from '@material-ui/core/styles';
import { TableRow } from '@material-ui/core';

const CustomTableRow = withStyles((theme) => ({
  root: {
    height: 'unset',
  },
}))(TableRow);

export default CustomTableRow;
