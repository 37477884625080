import firebase from 'firebase';
import { asyncFinish, asyncStart, asyncError } from '../../../common';
import { auth } from '../../../App/+store/firebase/firebase.actions';

export const UPDATE_PROFILE = 'accountSettings.updateProfile';
export const updateProfile = (values) => (dispatch) => {
  const { displayName, phoneNumber } = values;
  dispatch(asyncStart(UPDATE_PROFILE));
  dispatch({
    type: UPDATE_PROFILE,
    displayName,
    phoneNumber,
  });
};

export const CHANGE_PASSWORD = 'accountSettings.changePassword';
export const changePassword = (values) => (dispatch) => {
  const { currentPassword, newPassword } = values;
  dispatch(asyncStart(CHANGE_PASSWORD));
  const cred = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, currentPassword);
  auth.currentUser
    .reauthenticateWithCredential(cred)
    .then(() => {
      auth.currentUser
        .updatePassword(newPassword)
        .then(() => {
          dispatch(asyncFinish(CHANGE_PASSWORD));
        })
        .catch((error) => {
          dispatch(asyncError(CHANGE_PASSWORD, 'Signing In Failed', error.message));
        });
    })
    .catch(() => {
      dispatch(
        asyncError(
          CHANGE_PASSWORD,
          'Signing In Failed',
          'Sorry, the password you provided does not match your account.'
        )
      );
    });
};
