import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { isMultipleCampusAdmin } from '../utils';

const styles = (theme) => ({
  profileInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    marginLeft: 70,
    paddingLeft: 40,
  },
  profileInfoHomePageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'absolute',
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: '0.925rem',
    color: theme.palette.primary.main,
  },
  homePageTitle: {
    fontSize: '24px',
    lineHeight: '25px',
    color: theme.palette.primary.main,
  },
  description: {
    fontSize: '1.225rem',
    color: theme.palette.primary.main,
  },
  homePageDescription: {
    fontSize: '37px',
    lineHeight: '38px',
    color: theme.palette.primary.main,
  },
});

class UserInfoBanner extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    profile: PropTypes.object,
    initSchools: PropTypes.func.isRequired,
    initTags: PropTypes.func.isRequired,
    schoolList: PropTypes.object,
    isHomePage: PropTypes.bool,
    tags: PropTypes.object,
  };

  state = {
    isTagsLoaded: false,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit() {
    const { profile, schoolList, tags, initSchools, initTags } = this.props;
    const didSchoolListLoad = Object.keys(schoolList).length;
    const { isTagsLoaded } = this.state;
    if (profile) {
      if (!didSchoolListLoad) {
        initSchools(profile);
      }
      if (!isTagsLoaded) {
        initTags(profile);
        this.setState({ isTagsLoaded: true });
      }
    }
  }

  renderOneInfo = (title, description, marginRight = 70) => {
    const { classes, isHomePage } = this.props;
    return (
      <div className={classes.profileInfo} style={{ marginRight }}>
        <div className={isHomePage ? classes.homePageTitle : classes.title}>{_.startCase(title)}</div>
        <div className={isHomePage ? classes.homePageDescription : classes.description}>{_.startCase(description)}</div>
      </div>
    );
  };

  render() {
    const { classes, profile, schoolList, isHomePage, tags } = this.props;
    if (profile) {
      const isAuthMultipleCampusAdmin = isMultipleCampusAdmin(profile);
      const didSchoolListLoad = Object.keys(schoolList).length;

      const districtCampusTitle = profile.role === 'districtAdmin' ? 'District Name' : 'Campus Name';
      let districtCampusName =
        profile.role === 'districtAdmin'
          ? profile.districtNumber
          : `${isAuthMultipleCampusAdmin ? 'Multiple Campuses' : profile.campusNumber}`;

      if (didSchoolListLoad && !isAuthMultipleCampusAdmin) {
        districtCampusName =
          (profile.role === 'districtAdmin'
            ? schoolList[profile.districtNumber].name
            : schoolList[profile.campusNumber].name) || districtCampusName;
      }
      const marginInfo = isHomePage ? 100 : 70;
      return (
        <div className={isHomePage ? classes.profileInfoHomePageContainer : classes.profileInfoContainer}>
          {this.renderOneInfo(profile.role, profile.displayName, marginInfo)}
          {this.renderOneInfo(districtCampusTitle, districtCampusName, marginInfo)}
          {this.renderOneInfo('Tier', profile.userType, 0)}
        </div>
      );
    }
    return null;
  }
}

export default withStyles(styles)(UserInfoBanner);
