import { asyncStart, asyncFinish } from '../../../common';

export const REDIRECT = 'routes.redirect';
export const scheduleRedirect = (to) =>
  asyncStart(REDIRECT, 'Redirecting ...', {
    to,
  });

export const doRedirect = (history, to) => (dispatch) => {
  history.push(to);
  dispatch(
    asyncFinish(REDIRECT, {
      to,
    })
  );
};
