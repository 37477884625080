import { connect } from 'react-redux';
import queryString from 'query-string';

import Component from './AtoFFilters.component';
import { getReport, schoolListPlusDistrict, selectFilterFields } from '../+store/aToF';
import { updateFilters, getFilterFields } from '../+store/aToF/aToF.actions';
import { JobNames } from '../../FlightPlan/+store/flightPlan';
import { initStudentsRoster, getAvatars } from '../../StudentRoster/+store/studentRoster';

const getIgnoreCache = () => {
  const parsedQuery = queryString.parse(window.location.search);
  return parsedQuery.cache === 'false' || false;
};

const getIgnoreTests = () => {
  const parsedQuery = queryString.parse(window.location.search);
  return parsedQuery.tests === 'false' || false;
};

const getDebug = () => {
  const parsedQuery = queryString.parse(window.location.search);
  return parsedQuery.debug === 'true' || false;
};

const mapStateToProps = (state, ownProps) => {
  const { jobName, profile } = ownProps;
  let schoolList = schoolListPlusDistrict(state);
  if (jobName !== JobNames.aToF && profile.role === 'districtAdmin') {
    schoolList = schoolList.filter((school) => school[0] !== profile.districtNumber);
  }
  return {
    schoolList,
    status: state.modules.AtoF.aToF.status,
    assessmentList: state.modules.Assessments.assessments.assessmentList,
    filters: state.modules.AtoF.aToF.filters,
    reportError: state.modules.AtoF.aToF.reportError,
    waitMessage: state.modules.AtoF.aToF.waitMessage,
    filterFields: selectFilterFields(state),
    ignoreCache: getIgnoreCache(),
    ignoreTests: getIgnoreTests(),
    debug: getDebug(),
    profile: state.modules.Auth.auth.profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReport: ({
    schoolYear,
    assessmentsInfo,
    school,
    afFilters,
    option,
    ignoreCache,
    ignoreTests,
    debug,
    jobName,
    profile,
  }) => {
    dispatch(initStudentsRoster(profile, schoolYear - 1));

    dispatch(
      getReport({
        schoolYear,
        assessmentsInfo,
        campusDistrictNumber: school,
        filters: afFilters,
        option,
        ignoreCache,
        ignoreTests,
        debug,
        jobName,
      })
    );
  },
  updateFilters: (filters) => {
    dispatch(updateFilters(filters));
  },
  getFilterFields: (schoolYear, assessmentsInfo, campusDistrictNumber, filters) => {
    dispatch(getFilterFields(schoolYear, assessmentsInfo, campusDistrictNumber, filters));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
