const styles = (theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    height: '100%',
    position: 'relative',
    fontFamily: 'Questrial',
    fontStyle: 'normal',
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    margin: 'auto',
  },
  editOnOffDiv: {
    position: 'absolute',
    width: '103px',
    // height: '19px',
    left: '362px',
    top: '279px',
    // color: '#656565',
    display: 'flex',
    flexDirection: 'row',
  },
  switchOnOff: {
    alignItems: 'center',
    display: 'flex',
    width: '60px',
    // backgroundColor: 'red',
  },
  editOptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  scoreWrapperNoBorder: {
    marginLeft: 20,
    paddingRight: 20,
  },
  profilePicHeader: {
    background: '#e5fcff',
  },
  emptyHeaderCell: {
    background: '#e5fcff',
  },

  tableHeadFirefox: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  emptyCellHeaderSticky: {
    borderBottom: 'none',
    background: '#e5fcff',
  },
  emptyCellHeaderStickyChrome: {
    borderBottom: 'none',
    top: 0,
    zIndex: 11,
    position: 'sticky',
    background: '#e5fcff',
  },
  emptyCellHeaderStickyChromeTSI: {
    borderBottom: 'none',
    top: 0,
    zIndex: 10,
    position: 'sticky',
    background: '#e5fcff',
  },
  emptyCellHeaderStickySafariTSI: {
    borderBottom: 'none',
    top: 0,
    zIndex: 10,
    position: '-webkit-sticky',
    background: '#e5fcff',
  },
  emptyCellHeaderStickyChromeSecondHeader: {
    borderBottom: 'none',
    zIndex: 10,
    position: 'sticky',
    top: 0,
    background: '#e5fcff',
  },
  emptyCellHeaderStickySafariSecondHeader: {
    borderBottom: 'none',
    top: 0,
    zIndex: 10,
    position: '-webkit-sticky',
    background: '#e5fcff',
  },
  emptyCellHeaderStickyChromeThirdHeader: {
    borderBottom: 'none',
    zIndex: 10,
    position: 'sticky',
    top: 0,
    background: '#e5fcff',
  },
  emptyCellHeaderStickySafariThirdHeader: {
    borderBottom: 'none',
    top: 0,
    zIndex: 10,
    position: '-webkit-sticky',
    background: '#e5fcff',
  },

  stickyProfilePicSafariHeader: {
    borderBottom: 'none',
    position: '-webkit-sticky',
    top: 0,
    zIndex: 11,
    background: '#e5fcff',
  },
  stickyProfilePicHeader: {
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    zIndex: 11,
    background: '#e5fcff',
  },
  stickyDemographicsSafariHeader: {
    borderBottom: 'none',
    position: '-webkit-sticky',
    top: 0,
    left: 0,
    zIndex: 11,
    background: '#e5fcff',
  },
  stickyDemographicsHeader: {
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 11,
    background: '#e5fcff',
  },
  stickyCCMRColSafariHeader: {
    borderBottom: 'none',
    position: '-webkit-sticky',
    top: 0,
    left: 350,
    zIndex: 11,
    background: 'rgb(239, 253, 255)',
  },
  stickyCCMRColHeader: {
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    left: 350,
    zIndex: 11,
    background: 'rgb(239, 253, 255)',
  },
  stickyProfilePicSafariSecondHeader: {
    borderBottom: 'none',
    position: '-webkit-sticky',
    top: 0,
    zIndex: 11,
    background: '#e5fcff',
  },
  stickyProfilePicSecondHeader: {
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    zIndex: 11,
    background: '#e5fcff',
  },
  stickyDemographicsSafariSecondHeader: {
    borderBottom: 'none',
    position: '-webkit-sticky',
    top: 0,
    left: 0,
    zIndex: 11,
    background: '#e5fcff',
  },
  stickyDemographicsSecondHeader: {
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 11,
    background: '#e5fcff',
  },
  stickyCCMRColSafariSecondHeader: {
    borderBottom: 'none',
    position: '-webkit-sticky',
    top: 0,
    left: 350,
    zIndex: 11,
    background: 'rgb(239, 253, 255)',
  },
  stickyCCMRColSecondHeader: {
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    left: 350,
    zIndex: 11,
    background: 'rgb(239, 253, 255)',
  },
  stickyProfilePicSafariThirdHeader: {
    borderBottom: 'none',
    position: '-webkit-sticky',
    top: 0,
    zIndex: 11,
    background: '#d7faff',
  },
  stickyProfilePictThirdHeader: {
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    zIndex: 11,
    background: '#d7faff',
  },
  stickyDemographicsSafariThirdHeader: {
    borderBottom: 'none',
    position: '-webkit-sticky',
    top: 0,
    left: 0,
    zIndex: 11,
    background: '#f3feff',
  },
  stickyDemographicsThirdHeader: {
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 11,
    background: '#f3feff',
  },
  stickyCCMRColSafariThirdHeader: {
    borderBottom: 'none',
    fontWeight: 'bold',
    position: '-webkit-sticky',
    top: 0,
    left: 350,
    zIndex: 11,
    background: 'rgb(239, 253, 255)',
  },
  stickyCCMRColThirdHeader: {
    borderBottom: 'none',
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    left: 350,
    zIndex: 11,
    background: 'rgb(239, 253, 255)',
  },
  tableContainer: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  tableWrapper: {
    width: '100%',
    flexGrow: 0,
  },
  tableBody: {
    height: 'calc(100% - 60px)',
  },
});

export default styles;
