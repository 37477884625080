import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Icon,
  List,
  Typography,
  ListItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  div: {
    width: '200px',
    marginRight: '60px',
  },
  filterTitle: {
    border: 0,
    background: 'none',
    width: '100%',
    display: 'flex',
    height: 48,
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    padding: 0,
    '&:focus': {
      outline: 0,
    },
  },
  filterBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  icon: {
    marginRight: theme.spacing.unit,
    position: 'relative',
    top: 6,
  },
});

class FilterSection extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    filterLabel: PropTypes.any,
    listItems: PropTypes.any,
    listValues: PropTypes.any,
    handleChange: PropTypes.func,
  };

  state = {
    open: false,
  };

  toggleMenu = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleChecked = (name) => (e) => {
    const { handleChange } = this.props;
    handleChange(name)({ target: { value: e.target.checked } });
  };

  render() {
    const { filterLabel, listItems, classes, listValues } = this.props;
    const { open } = this.state;
    return (
      <Grid item className={classes.div}>
        <button
          type="button"
          onClick={this.toggleMenu}
          className={classes.filterTitle}
          style={{
            borderBottom: open ? 0 : '1px solid gray',
          }}
        >
          <Icon className={classes.icon} color="primary">
            {open ? 'remove' : 'add'}
          </Icon>
          <Typography color="primary">{filterLabel}</Typography>
        </button>
        {open && (
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {listItems.map(({ name, label }) => (
                <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      checked={listValues[name] || false}
                      onChange={this.handleChecked(name)}
                      value={name}
                      color="primary"
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(FilterSection);
