import { connect } from 'react-redux';

import Component from './StudentStaarForm.component';
import { getCurrentRosterYear } from '../+store/studentRoster/studentRoster.utils';
import {
  getStudentPriorStaarData,
  editStudentRequestCancel,
  setStudentPriorStaarData,
} from '../+store/studentRoster/studentRoster.actions';

const mapStateToProps = (state) => ({
  profile: state.modules.Auth.auth.profile,
  roster: state.modules.StudentRoster.studentRoster.roster,
  snapshotYear: state.modules.StudentRoster.studentRoster.selectedSnapshotYear,
  sid: state.modules.StudentRoster.studentRoster.selectedStudentToEdit,
  newStudent: state.modules.StudentRoster.studentRoster.isAddingNewStudent,
  user: state.modules.Auth.auth.profile,
  studentPriorStaarModel: state.modules.StudentRoster.studentRoster.studentPriorStaarModel,
  needsPriorStaarInit: state.modules.StudentRoster.studentRoster.needsPriorStaarInit,
  loadingMsg: state.modules.StudentRoster.studentRoster.loadingMsg,
  loading: !!state.modules.StudentRoster.studentRoster.loadingMsg,
  loadedStudentPriorStaarModel: state.modules.StudentRoster.studentRoster.loadedStudentPriorStaarModel,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { ...newStateProps } = stateProps;
  const studentId = newStateProps.sid;
  const { snapshotYear } = newStateProps;
  const year = snapshotYear || getCurrentRosterYear();
  let student = newStateProps.roster[year][newStateProps.sid];
  const { loadedStudentPriorStaarModel, profile, studentPriorStaarModel, loadingMsg, loading } = newStateProps;
  const priorSnapshotYear = year - 1;
  return {
    ...ownProps,
    profile,
    student,
    studentId,
    studentPriorStaarModel,
    loadedStudentPriorStaarModel,
    loadingMsg,
    loading,
    priorSnapshotYear,
    init: (studentId) => {
      dispatch(getStudentPriorStaarData(studentId, priorSnapshotYear));
    },
    handleCancel: () => {
      dispatch(editStudentRequestCancel());
    },
    handleSubmit: (staarModel) => {
      let dataModel = {
        studentId: studentId,
        student: student,
        snapshotYear: priorSnapshotYear,
        tests: staarModel.studentPriorStaarTests,
      };

      dispatch(setStudentPriorStaarData(dataModel));
    },
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);

export default Container;
