import { combineReducers } from 'redux';
import { GET_ORG_TAGS, SAVE_ORG_TAG, DELETE_ORG_TAG } from './ManageOrgTags.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const status = (state = initialStatus, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ORG_TAGS) {
        return Object.assign({}, state, {
          message: '',
          error: '',
        });
      }
      return state;
    case ASYNC_ERROR:
      return state;
    default:
      return state;
  }
};

const tags = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ORG_TAGS) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const addedTag = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === SAVE_ORG_TAG) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const deletedTag = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === DELETE_ORG_TAG) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const initialStatus = {
  success: false,
  message: '',
};

export const _reducer = combineReducers({
  tags,
  addedTag,
  deletedTag,
  status,
});

export default 'ManageOrgTags.reducers.js';
