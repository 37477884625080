const isSTAAR = (file, alt = false) =>
  new Promise((resolve) => {
    var assessmentYear = 0;
    if (file.type != 'text/plain') {
      resolve({ isValid: false });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      if (text.length < 4000) {
        resolve({ isValid: false });
        return;
      }
      const lines = text.split(/\r\n?|\n/);

      if (lines && lines.length > 0) {
        if (!/^-?\d+$/.test(lines[0].slice(2, 4))) {
          resolve({ isValid: false });
          return;
        } else {
          assessmentYear = lines[0].slice(2, 4);
        }
      }
      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        if (line) {
          if (
            line.length !== 4000 ||
            line[line.length - 1] !== '.' //||/^\s+$/.test(line.slice(164, 184))
            // to differentiate from last year's STAAR
          ) {
            // console.log('Base validation fail', line.slice(164, 184));
            resolve({ isValid: false });
            return;
          }
          // to differentiate from this year's STAAR from STAAR ALT
          if (!alt && file.name.toUpperCase().indexOf('ALT') != -1) {
            // !line.slice(131, 139).trim()
            // console.log('Doc num not alt', line.slice(131, 142));
            resolve({ isValid: false });
          }
          if (alt && file.name.toUpperCase().indexOf('ALT') == -1) {
            // line.slice(131, 142).trim()
            // console.log('Doc num alt', line.slice(131, 142));
            resolve({ isValid: false });
          }
          // More checks (probably not necessary)
          if (!alt && [line[413], line[767], line[1113], line[1515], line[1915]].includes('T')) {
            resolve({ isValid: false });
            return;
          }
          // if (alt && line.slice(2821, 3999).trim())
          //   resolve(false);
          //   return;
          //  }
        }
      }
      if (lines && lines.length > 0) {
        assessmentYear = lines[0].slice(2, 4);
      }

      resolve({
        isValid: true,
        assessmentYear: assessmentYear,
      });
    };
    reader.readAsText(file);
  });

export default isSTAAR;
