import { combineReducers } from 'redux';
import { GET_SCHOOLS, UPDATE_SETTINGS, GET_DEFAULT_SETTINGS } from './SchoolSettings/SchoolSettings.actions';
import { CHANGE_PASSWORD, UPDATE_PROFILE } from './AccountSettings/AccountSettings.actions';
import { GET_ORG_TAGS, SAVE_ORG_TAG, DELETE_ORG_TAG } from './ManageOrgTags/ManageOrgTags.actions';
import {
  GET_INVITED_USERS,
  GET_ORG_USERS,
  CANCEL_INVITE_USER,
  INVITE_USER_TO_ORG,
  ACTIVATE_ACCOUNT,
  DEACTIVATE_ACCOUNT,
  UPDATE_USER_PROFILE,
  RESET_USER_PASSWORD,
  RESEND_INVITE_USER,
} from './ManageUsers/ManageUsers.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../common';

const staticLoadingMsg = {
  [GET_SCHOOLS]: 'Initializing ...',
  [GET_DEFAULT_SETTINGS]: 'Getting defaults ...',
  [UPDATE_SETTINGS]: 'Updating settings ...',
  [CHANGE_PASSWORD]: 'Changing Password ...',
  [UPDATE_PROFILE]: 'Updating profile ...',
  [GET_INVITED_USERS]: "Getting Organization's Users ...",
  [GET_ORG_USERS]: "Getting Invited Organization's Users ...",
  [CANCEL_INVITE_USER]: 'Canceling Invite ...',
  [INVITE_USER_TO_ORG]: 'Inviting User ...',
  [ACTIVATE_ACCOUNT]: "Activating User's Account ...",
  [DEACTIVATE_ACCOUNT]: "Deactivating User's Account ...",
  [UPDATE_USER_PROFILE]: "Updating User's Profile ...",
  [RESET_USER_PASSWORD]: "Resetting User's Password ...",
  [RESEND_INVITE_USER]: "Resending User's Invitation ...",
  [GET_ORG_TAGS]: "Getting Organization's Tag ...",
  [SAVE_ORG_TAG]: "Add Organization's Tag ...",
  [DELETE_ORG_TAG]: "Delete Organization's Tag ...",
};

const loadingMsg = (state = [], action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model && staticLoadingMsg[action.model]) {
        state.push(staticLoadingMsg[action.model]);
        return state.slice();
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (
        [
          GET_SCHOOLS,
          GET_DEFAULT_SETTINGS,
          UPDATE_SETTINGS,
          CHANGE_PASSWORD,
          UPDATE_PROFILE,
          GET_INVITED_USERS,
          GET_ORG_USERS,
          CANCEL_INVITE_USER,
          INVITE_USER_TO_ORG,
          ACTIVATE_ACCOUNT,
          DEACTIVATE_ACCOUNT,
          UPDATE_USER_PROFILE,
          RESET_USER_PASSWORD,
          RESEND_INVITE_USER,
          GET_ORG_TAGS,
          SAVE_ORG_TAG,
          DELETE_ORG_TAG,
        ].includes(action.model)
      ) {
        return state.filter((msg) => msg !== staticLoadingMsg[action.model]);
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  loadingMsg,
});

export default 'Settings.reducers.js';
