import { connect } from 'react-redux';
import Component from './CCMRBulkEntry.component';

import { updateBulkCCMRTrackingData, cancelSelectCCMRKey } from '../+store';
import { getCurrentRosterYear } from '../../StudentRoster/+store/studentRoster';

const mapStateToProps = (state) => {
  let students = state.modules.StudentRoster.studentRoster.roster;
  const currentSnapshotYear = getCurrentRosterYear();
  students = students && students[currentSnapshotYear];
  return {
    students,
    profile: state.modules.Auth.auth.profile,
    ccmrData: state.modules.CCMRTracking.ccmrTracking.ccmrTrackingData,
    bulkEntryKey: state.modules.CCMRTracking.ccmrTracking.bulkEntryKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateBulkCCMRTrackingData: (profile, studentsData) => {
    dispatch(updateBulkCCMRTrackingData(profile.organizationId, studentsData));
  },
  cancelSelectCCMRKey: () => {
    dispatch(cancelSelectCCMRKey());
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
