import { asyncStart } from '../../../common';
import { JobNames, GET_FLIGHT_PLAN } from '../../../FlightPlan/+store/flightPlan';

export const GET_REPORT = 'aToF.getReport';
export const getReport = ({
  schoolYear,
  assessmentsInfo,
  campusDistrictNumber,
  filters,
  option,
  ignoreCache,
  ignoreTests,
  debug,
  jobName,
}) => (dispatch) => {
  let model;
  if (jobName === JobNames.aToF) {
    model = GET_REPORT;
  } else {
    model = GET_FLIGHT_PLAN;
  }
  dispatch(asyncStart(model, null, { campusDistrictNumber }));
  dispatch({
    type: model,
    schoolYear,
    assessmentsInfo,
    campusDistrictNumber,
    filters,
    option,
    ignoreCache,
    ignoreTests,
    debug,
    jobName,
  });
};

export const GET_FILTER_FIELDS = 'aToF.getFilterFields';
export const getFilterFields = (schoolYear, assessmentsInfo, campusDistrictNumber, filters) => (dispatch) => {
  dispatch(asyncStart(GET_FILTER_FIELDS));
  dispatch({
    type: GET_FILTER_FIELDS,
    schoolYear,
    assessmentsInfo,
    campusDistrictNumber,
    filters,
  });
};

export const UPDATE_FILTERS = 'aToF.updateFilters';
export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS,
  filters,
});

export const CLOSE_STUDENTS_LIST = 'aToF.closeStudentsList';
export const closeStudentsList = () => ({
  type: CLOSE_STUDENTS_LIST,
});

export const GET_STUDENTS_LIST = 'aToF.getStudentsList';
export const getStudentsList = (orgId, path) => (dispatch) => {
  dispatch(asyncStart(GET_STUDENTS_LIST));
  dispatch({
    type: GET_STUDENTS_LIST,
    orgId,
    path,
  });
};
