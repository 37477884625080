import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AuthLayout from '../AuthLayout';
import TextInputField from '../TextInputField';
import { Button } from '../../common/Button';
import { Routes } from '../../App';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('This field is required.'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match.')
    .required('This field is required.'),
});

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '110px',
    zIndex: 10,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '550px',
    alignSelf: 'flex-start',
  },
  button: {
    marginRight: 0,
    textTransform: 'capitalize',
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    marginBottom: theme.spacing.unit,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

class ResetPassword extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
  };

  render() {
    const { classes } = this.props;
    return (
      <AuthLayout title="Reset Your Password">
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          validationSchema={ResetPasswordSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className={classes.form}>
              <TextInputField
                inputExtraContainerSize={80}
                inputExtraRowSize={80}
                id="password"
                label="Password"
                type="password"
                touched={touched.password}
                error={errors.password}
              />
              <TextInputField
                inputExtraContainerSize={80}
                inputExtraRowSize={80}
                id="passwordConfirm"
                label="Confirm Password"
                type="password"
                touched={touched.passwordConfirm}
                error={errors.passwordConfirm}
              />
              <div className={classes.buttonRow}>
                <Link key="sign-in" to={`/${Routes.signIn}`} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.link}>Back to Sign In</Typography>
                </Link>
                <Button submit large color="white" className={classes.button}>
                  {' '}
                  Reset Password{' '}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    );
  }
}

export default withStyles(styles)(ResetPassword);
