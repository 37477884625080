export const AssessmentTypes = {
  CAF2018: 'CAF2018',
  FYLI2017: 'FYLI2017',
  FYLI2016: 'FYLI2016',
  SYLI2015: 'SYLI2015',
  FYLI2018: 'FYLI2018',
  F5YLI2017: 'F5YLI2017',
  SYLI2016: 'SYLI2016',
  CCMR2018: 'CCMR2018',
  CCMR2019: 'CCMR2019',
  Interim: 'Interim',
  ComboAssessment: 'ComboAssessment',
  DMAC2018: 'DMAC2018',
  STAAR2019: 'STAAR2019',
  STAARALT2019: 'STAARALT2019',
  TELPAS2019: 'TELPAS2019',
  TELPASALT2019: 'TELPASALT2019',
  EOC2019: 'EOC2019',
  EOCALT2019: 'EOCALT2019',
  SAT2019: 'SAT2019',
  ACT2019: 'ACT2019',
  STAAR2021: 'STAAR2021',
  STAAR2021Custom: 'STAAR2021Custom',
  STAARALT2021: 'STAARALT2021',
  EOC2021: 'EOC2021',
  EOCALT2021: 'EOCALT2021',
  EOC2021Custom: 'EOC2021Custom',
  TELPAS2021: 'TELPAS2021',
  TELPASALT2021: 'TELPASALT2021',
  EOC2021: 'EOC2021',
  EOCALT2021: 'EOCALT2021',
  DMACInterim: 'DMACInterim',
  Other: 'Other',

  STAAR: 'STAAR',
  STAARALT: 'STAARALT',
  TELPAS: 'TELPAS',
  TELPASALT: 'TELPASALT',
  EOC: 'EOC',
  EOCALT: 'EOCALT',
  CCMR: 'CCMR',
  CCMRCSV: 'CCMRCSV',
  FYLI: 'FYLI',
  F5YLI: 'F5YLI',
  SYLI: 'SYLI',
  STAARCustom: 'STAARCustom',
};

export const OtherAssessmentTypes = {
  SAT2019: 'SAT2019',
  ACT2019: 'ACT2019',
  DMACInterim: 'DMACInterim',
  ComboAssessment: 'ComboAssessment',
  Other: 'Other',
};

export const getDynamicAssessmentModel = (assessmentName) => {
  if (assessmentName != null && assessmentName != undefined && assessmentName.length > 0) {
    if (Assessments[assessmentName]) {
      return Assessments[assessmentName];
    } else {
      var assesmentYear = assessmentName.substring(assessmentName.length - 4, assessmentName.length);
      var assementNameWithoutYear = assessmentName.replace(assesmentYear, '');
      var prefix = assesmentYear;
      var suffix = '';
      if (assementNameWithoutYear == 'STAAR') {
        suffix = 'STAAR Grades 3-8 Report';
      } else if (assementNameWithoutYear == 'STAARCustom') {
        suffix = 'STAAR Custom Grades Report';
      } else if (assementNameWithoutYear == 'STAARALT') {
        suffix = 'STAAR Alternate 2 Grades 3-8 Report';
      } else if (assementNameWithoutYear == 'TELPAS') {
        suffix = 'TELPAS Report';
      } else if (assementNameWithoutYear == 'TELPASALT') {
        suffix = 'TELPAS Alternate Report';
      } else if (assementNameWithoutYear == 'EOC') {
        suffix = 'STAAR End of Course Report';
      } else if (assementNameWithoutYear == 'EOCALT') {
        suffix = 'STAAR Alternate End of Course Report';
      } else if (assementNameWithoutYear == 'CCMR') {
        suffix = 'College, Career, and Military Readiness (CCMR) Student Listing';
      } else if (assementNameWithoutYear == 'CCMRCSV') {
        prefix = '';
        suffix = 'College, Career, and Military Readiness (CCMR) Student Listing CSV' + assesmentYear;
      } else if (assementNameWithoutYear == 'FYLI') {
        // Previous Grade Year 4
        prefix = '';
        suffix = 'Four-Year Longitudinal Information for ' + assesmentYear;
      } else if (assementNameWithoutYear == 'F5YLI') {
        // Previous Grade Year 5
        prefix = '';
        suffix = 'Five-Year Longitudinal Information for ' + assesmentYear;
      } else if (assementNameWithoutYear == 'SYLI') {
        // Previous Grade Year 6
        prefix = '';
        suffix = 'Six-Year Longitudinal Information for ' + assesmentYear;
      } else {
        suffix = assementNameWithoutYear;
      }
      var result = {
        shortName: assessmentName,
        name: prefix + ' ' + suffix,
        unique: true,
      };
      return result;
    }
  }
  var result = {
    shortName: '',
    name: '',
    unique: false,
  };
  return result;
};
export const Assessments = {
  [AssessmentTypes.STAAR2021]: {
    shortName: AssessmentTypes.STAAR2021,
    name: '2021 STAAR Grades 3-8 Report',
    unique: true,
  },
  [AssessmentTypes.STAAR2021Custom]: {
    shortName: AssessmentTypes.STAAR2021Custom,
    name: '2021 STAAR Grades 3-8 Report',
    unique: true,
  },
  [AssessmentTypes.STAARALT2021]: {
    shortName: AssessmentTypes.STAARALT2021,
    name: '2021 STAAR Alternate 2 Grades 3-8 Report',
    unique: true,
  },
  [AssessmentTypes.TELPAS2021]: {
    shortName: AssessmentTypes.TELPAS2021,
    name: '2021 TELPAS Report',
    unique: true,
  },
  [AssessmentTypes.TELPASALT2021]: {
    shortName: AssessmentTypes.TELPASALT2021,
    name: '2021 TELPAS Alternate Report',
    unique: true,
  },
  [AssessmentTypes.EOC2021]: {
    shortName: AssessmentTypes.EOC2021,
    name: '2021 STAAR End of Course Report',
    unique: true,
  },
  [AssessmentTypes.EOCALT2021]: {
    shortName: AssessmentTypes.EOCALT2021,
    name: '2021 STAAR Alternate End of Course Report',
    unique: true,
  },
  [AssessmentTypes.STAAR2019]: {
    shortName: AssessmentTypes.STAAR2019,
    name: '2019 STAAR Grades 3-8 Report',
    unique: true,
  },
  [AssessmentTypes.STAARALT2019]: {
    shortName: AssessmentTypes.STAARALT2019,
    name: '2019 STAAR Alternate 2 Grades 3-8 Report',
    unique: true,
  },
  [AssessmentTypes.TELPAS2019]: {
    shortName: AssessmentTypes.TELPAS2019,
    name: '2019 TELPAS Report',
    unique: true,
  },
  [AssessmentTypes.TELPASALT2019]: {
    shortName: AssessmentTypes.TELPASALT2019,
    name: '2019 TELPAS Alternate Report',
    unique: true,
  },
  [AssessmentTypes.CAF2018]: {
    shortName: AssessmentTypes.CAF2018,
    name: '2018 Consolidated Accountability File',
    unique: true,
  },
  [AssessmentTypes.FYLI2017]: {
    shortName: AssessmentTypes.FYLI2017,
    name: 'Four-Year Longitudinal Information for 2017',
    unique: true,
  },
  [AssessmentTypes.FYLI2016]: {
    shortName: AssessmentTypes.FYLI2016,
    name: 'Five-Year Longitudinal Information for 2016',
    unique: true,
  },
  [AssessmentTypes.SYLI2015]: {
    shortName: AssessmentTypes.SYLI2015,
    name: 'Six-Year Longitudinal Information for 2015',
    unique: true,
  },
  [AssessmentTypes.FYLI2018]: {
    shortName: AssessmentTypes.FYLI2018,
    name: 'Four-Year Longitudinal Information for 2018',
    unique: true,
  },
  [AssessmentTypes.F5YLI2017]: {
    shortName: AssessmentTypes.F5YLI2017,
    name: 'Five-Year Longitudinal Information for 2017',
    unique: true,
  },
  [AssessmentTypes.SYLI2016]: {
    shortName: AssessmentTypes.SYLI2016,
    name: 'Six-Year Longitudinal Information for 2016',
    unique: true,
  },
  [AssessmentTypes.CCMR2018]: {
    shortName: AssessmentTypes.CCMR2018,
    name: '2018 College, Career, and Military Readiness (CCMR) Student Listing',
    unique: true,
  },
  [AssessmentTypes.CCMR2019]: {
    shortName: AssessmentTypes.CCMR2019,
    name: '2019 College, Career, and Military Readiness (CCMR) Student Listing',
    unique: true,
  },
  [AssessmentTypes.Interim]: {
    shortName: AssessmentTypes.Interim,
    name: 'Interim Assessment',
    unique: false,
  },
  [AssessmentTypes.ComboAssessment]: {
    shortName: AssessmentTypes.ComboAssessment,
    name: 'MAP Combo Assessment File',
    unique: false,
  },
  [AssessmentTypes.DMAC2018]: {
    shortName: AssessmentTypes.DMAC2018,
    name: '2018 DMAC Export',
    unique: true,
  },
  [AssessmentTypes.STAAR2019]: {
    shortName: AssessmentTypes.STAAR2019,
    name: '2019 STAAR Grades 3–8 Report',
    unique: true,
  },
  [AssessmentTypes.STAARALT2019]: {
    shortName: AssessmentTypes.STAARALT2019,
    name: '2019 STAAR Alternate 2 Grades 3–8 Report',
    unique: true,
  },
  [AssessmentTypes.EOC2019]: {
    shortName: AssessmentTypes.EOC2019,
    name: '2019 STAAR End of Course Report',
    unique: true,
  },
  [AssessmentTypes.EOCALT2019]: {
    shortName: AssessmentTypes.EOCALT2019,
    name: '2019 STAAR Alternate End of Course Report',
    unique: true,
  },
  [AssessmentTypes.ACT2019]: {
    shortName: AssessmentTypes.ACT2019,
    name: '2019 ACT Report',
    unique: true,
  },
  [AssessmentTypes.SAT2019]: {
    shortName: AssessmentTypes.SAT2019,
    name: '2019 Scholastic Aptitude Test (SAT)',
    unique: true,
  },
  [AssessmentTypes.Other]: {
    shortName: AssessmentTypes.Other,
    name: 'Other File Type',
    unique: false,
  },
  [AssessmentTypes.DMACInterim]: {
    shortName: AssessmentTypes.DMACInterim,
    name: 'DMAC Interim Assessment Export',
    unique: false,
  },
};

export default 'Assessments.types.js';
