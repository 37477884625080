import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    padding: 0,
  },
  iconSize: {
    fontSize: '20px',
  },
});

class CheckboxControl extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    status: PropTypes.any.isRequired,
    handleChange: PropTypes.func,
  };

  render() {
    const { classes, status, handleChange } = this.props;
    if (handleChange) {
      return (
        <Checkbox
          className={classes.root}
          checked={status}
          icon={<CheckBoxOutlineBlankIcon className={classes.iconSize} />}
          checkedIcon={<CheckBoxIcon className={classes.iconSize} />}
          onChange={handleChange}
          color="primary"
        />
      );
    }
    return (
      <Checkbox
        className={classes.root}
        checked={status}
        icon={<CheckBoxOutlineBlankIcon className={classes.iconSize} />}
        checkedIcon={<CheckBoxIcon className={classes.iconSize} />}
        color="primary"
      />
    );
  }
}

export default withStyles(styles)(CheckboxControl);
