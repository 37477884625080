import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableCell, TableRow, TableBody, Paper } from '@material-ui/core';
import TableContainer from '@material-ui/core/Table';

const styles = {
  table: {
    borderCollapse: 'collapse',
  },
  cell: {
    border: '1px solid black',
  },
  yellowCell: {
    // Student Achievement #F0CA4D
    backgroundColor: '#F0CA4D',
    border: '1px solid black',
  },
  greenCell: {
    // School Progress Part A (Academic Growth) #92C83E
    backgroundColor: '#92C83E',
    border: '1px solid black',
  },
  redCell: {
    // School Progress Part B (Relative Performance) #F7786B
    backgroundColor: '#F7786B',
    border: '1px solid black',
  },
  purpleCell: {
    // Closing the Gaps #7834A4
    backgroundColor: '#7834A4',
    border: '1px solid black',
    color: 'white',
  },
  grayCell: {
    backgroundColor: 'gray',
  },
  centerCell: {
    textAlign: 'center',
    verticalAlign: 'middle',
    border: '1px solid black',
  },
  headerCell: {
    textAlign: 'center',
    verticalAlign: 'middle',
    border: '1px solid black',
    fontWeight: 'bold',
    width: '15%',
  },
  headerCellFirstCell: {
    width: '25%',
  },

  headerCellHidden: {
    border: '0px',
    height: '0px',
  },
  headerCellFirstCellHidden: {
    border: '0px',
    height: '0px',
  },
  headerRowHidden: {
    border: '0px',
    height: '0px',
  },
};

class SummaryDetails extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    report: PropTypes.object.isRequired,
    onChangeReportSection: PropTypes.func,
    isFreeAccount: PropTypes.bool,
    districtOrCampusName: PropTypes.string.isRequired,
    districtOrCampusNumber: PropTypes.string.isRequired,
    hideHeader: PropTypes.bool.isRequired,
    schoolYear: PropTypes.number.isRequired,
  };

  getMaxOfThree(a, b, c) {
    // Filter out NaN values
    const numbers = [a, b, c].filter((num) => !isNaN(num));

    // If all values are NaN, return NaN
    if (numbers.length === 0) {
      return NaN;
    }

    // Find the maximum value among the non-NaN numbers
    return Math.max(...numbers);
  }

  getMaxOfThreeColor(d1Score, d2aScaled, d2bScaled, classes) {
    // Student Achievement #F0CA4D  d1Score
    // School Progress Part A (Academic Growth) #92C83E d2aScaled
    // School Progress Part B (Relative Performance) #F7786B d2aScaled
    // Closing the Gaps #7834A4

    // Filter out NaN values
    const numbers = [d1Score, d2aScaled, d2bScaled].filter((num) => !isNaN(num));

    // If all values are NaN, return NaN
    if (numbers.length === 0) {
      return NaN;
    }

    // Find the maximum value among the non-NaN numbers
    var max = Math.max(...numbers);
    if (max == d1Score) {
      return classes.yellowCell;
    } else if (max == d2aScaled) {
      return classes.greenCell;
    } else if (max == d2bScaled) {
      return classes.redCell;
    }
  }

  render() {
    const {
      report,
      classes,
      isFreeAccount,
      onChangeReportSection,
      hideHeader,
      districtOrCampusName,
      districtOrCampusNumber,
      schoolYear,
    } = this.props;

    return (
      <TableContainer component={Paper}>
        <Table aria-label="score table">
          <TableHead>
            {!hideHeader && (
              <TableRow>
                <TableCell className={`${classes.headerCell} ${classes.headerCellFirstCell}`}>Domain</TableCell>
                <TableCell className={classes.headerCell}>Scaled Score</TableCell>
                <TableCell className={classes.headerCell}>Better of Student Achievement or School Progress</TableCell>
                <TableCell className={classes.headerCell}>Weight</TableCell>
                <TableCell className={classes.headerCell}>Overall Score</TableCell>
                <TableCell className={classes.headerCell}>{schoolYear ? schoolYear : ''} Overall Rating</TableCell>
              </TableRow>
            )}
            {hideHeader && (
              <TableRow className={classes.headerRowHidden}>
                <TableCell
                  className={`${classes.headerCell} ${classes.headerCellFirstCell} ${classes.headerCellFirstCellHidden}`}
                ></TableCell>
                <TableCell className={`${classes.headerCell} ${classes.headerCellHidden}`}></TableCell>
                <TableCell className={`${classes.headerCell} ${classes.headerCellHidden}`}></TableCell>
                <TableCell className={`${classes.headerCell} ${classes.headerCellHidden}`}></TableCell>
                <TableCell className={`${classes.headerCell} ${classes.headerCellHidden}`}></TableCell>
                <TableCell className={`${classes.headerCell} ${classes.headerCellHidden}`}></TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell colSpan={6} className={classes.headerCell}>
                ({districtOrCampusNumber}) - {districtOrCampusName}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.yellowCell}>Student Achievement</TableCell>
              <TableCell className={classes.yellowCell}>
                {report && report.d1 && report.d1.overall ? report.d1.overall.score : ''}
              </TableCell>
              <TableCell
                rowSpan={3}
                className={`${classes.centerCell} ${this.getMaxOfThreeColor(
                  report.d1.overall.score,
                  report.d2a.scaled,
                  report.d2b.scaled,
                  classes
                )}`}
              >
                {this.getMaxOfThree(report.d1.overall.score, report.d2a.scaled, report.d2b.scaled)}
              </TableCell>
              <TableCell rowSpan={3} className={classes.centerCell}>
                {'70%'}
              </TableCell>
              <TableCell rowSpan={4} className={classes.centerCell}>
                {report && report.summary ? report.summary.score : ''}
              </TableCell>
              <TableCell rowSpan={4} className={classes.centerCell}>
                {report && report.summary ? report.summary.letter : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.greenCell}>School Progress, Part A (Academic Growth)</TableCell>
              <TableCell className={classes.greenCell}>{report && report.d2a ? report.d2a.scaled : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.redCell}>School Progress, Part B (Relative Performance)</TableCell>
              <TableCell className={classes.redCell}>{report && report.d2b ? report.d2b.scaled : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.purpleCell}>Closing the Gaps</TableCell>
              <TableCell className={classes.purpleCell}> {report && report.d3 ? report.d3.scaled : ''}</TableCell>
              <TableCell className={classes.grayCell}> </TableCell>
              <TableCell className={classes.centerCell}>{'30%'}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles)(SummaryDetails);
