import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthLayout from '../AuthLayout';
import TextInputField from '../TextInputField';
import SelectInputField from '../SelectInputField';
import { Button } from '../../common/Button';
import { Routes } from '../../App';
import { LoadingCircle } from '../../common/LoadingCircle';
import { passwordStrengthGrade } from '../../common/utils';
import { isMultipleCampusAdmin } from '../../common';

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '110px',
    zIndex: 10,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '550px',
    alignSelf: 'flex-start',
  },
  button: {
    marginRight: 0,
    textTransform: 'capitalize',
  },
  signInLink: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    marginBottom: theme.spacing.unit,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  signInContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '110px',
  },
  passwordStrengthContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -105,
  },
});

class SignUp extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    match: PropTypes.object.isRequired,
    districtList: PropTypes.array,
    campusList: PropTypes.array,
    init: PropTypes.func.isRequired,
    authenticated: PropTypes.bool,
    msg: PropTypes.string,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    signUpCodeInfo: PropTypes.object,
    redirectToSignUp: PropTypes.bool,
    doRedirect: PropTypes.func,
    history: PropTypes.object.isRequired,
  };

  state = {
    passwordFocused: false,
  };

  componentDidMount() {
    const { match, authenticated, init, history, redirectToSignUp, doRedirect } = this.props;
    const signUpCode = match.params.code || null;
    if (redirectToSignUp) {
      doRedirect(history, Routes.signUp);
    }
    if (authenticated) {
      init(signUpCode);
    }
  }

  componentDidUpdate(prevProps) {
    const { match, authenticated, init, redirectToSignUp, doRedirect, history } = this.props;
    const signUpCode = match.params.code || null;
    if (redirectToSignUp) {
      doRedirect(history, `/${Routes.signUp}/`);
    }
    if (authenticated && !prevProps.authenticated) {
      init(signUpCode);
    }
  }

  handlePasswordFocused = () => {
    this.setState({ passwordFocused: true });
  };

  handlePasswordBlurred = () => {
    this.setState({ passwordFocused: false });
  };

  handleSubmit = (values) => {
    const { onSubmit, match } = this.props;
    let campusNumber = values.campusNumber || null;
    if (campusNumber && typeof campusNumber !== 'string' && campusNumber.length === 1) {
      campusNumber = campusNumber[0];
    }
    const userData = {
      campusNumber,
      districtNumber: values.district.split(' - ')[0],
      displayName: values.displayName,
      role: values.role,
      phoneNumber: values.phoneNumber,
      password: values.password,
      emailAddress: values.emailAddress,
    };
    const signUpCode = match.params.code || null;
    if (signUpCode) {
      userData.signUpCode = signUpCode;
    }
    onSubmit(userData);
  };

  SignUpSchema = () => {
    const { districtList } = this.props;
    const districtIds = districtList.map(({ id, name }) => `${id} - ${name}`);

    return Yup.object().shape({
      displayName: Yup.string()
        .min(5, 'Your name should at least have 5 characters.')
        .required('Required.'),
      emailAddress: Yup.string()
        .email('Invalid email')
        .required('Required.'),
      password: Yup.string()
        .min(10, '')
        .matches(/^(?=.*[A-Z]).*$/, ' ')
        .matches(/^(?=.*[a-z]).*$/, ' ')
        .matches(/^(?=.*\d).*$/, ' ')
        .required('Required.'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match.')
        .required('Required.'),
      role: Yup.string().required('Required.'),
      phoneNumber: Yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, 'Invalid Phone Number Format (XXX-XXX-XXXX).'),
      district: Yup.string()
        .when('role', (other, schema) =>
          other === 'districtAdmin' ? schema.required('Select a district from the list.') : schema
        )
        .oneOf(districtIds, 'Select a district from the list.'),
      campusNumber: Yup.array().when('role', (other, schema) =>
        other === 'campusAdmin' ? schema.required('Select a campus from the list.') : schema
      ),
    });
  };

  render() {
    const { classes, districtList, campusList, msg, loading, signUpCodeInfo, match } = this.props;
    const { passwordFocused } = this.state;
    let initialValues = {
      displayName: '',
      emailAddress: '',
      password: '',
      passwordConfirm: '',
      phoneNumber: '',
      role: '',
      district: '',
      campusNumber: [],
    };
    if (signUpCodeInfo && districtList && campusList) {
      const districtObject = districtList.find((district) => district.id === signUpCodeInfo.districtNumber);
      const district = districtObject ? `${districtObject.id} - ${districtObject.name}` : '';
      initialValues = {
        displayName: signUpCodeInfo.displayName || '',
        emailAddress: signUpCodeInfo.emailAddress || '',
        password: '',
        passwordConfirm: '',
        phoneNumber: signUpCodeInfo.phoneNumber || '',
        role: signUpCodeInfo.role || 'districtAdmin',
        district,
        campusNumber: isMultipleCampusAdmin(signUpCodeInfo)
          ? signUpCodeInfo.campusNumber
          : [signUpCodeInfo.campusNumber] || [],
      };
    }
    const signUpCode = match.params.code || null;
    const isLoading = loading || (signUpCode && !signUpCodeInfo);
    const title =
      initialValues.role === 'campusAdmin'
        ? `Join CoPilot as a Campus Admin (${initialValues.campusNumber
            .map((campus) => {
              const res = campusList.find((c) => c.id === campus);
              return res ? `${res.name} - ${res.id}` : campus;
            })
            .join(', ')})`
        : `Join CoPilot as a District Admin (${initialValues.district})`;
    return (
      <AuthLayout title={signUpCode && initialValues.role ? title : 'Create Your Account'}>
        {isLoading && <LoadingCircle msg={msg} classes={classes} />}
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={initialValues}
          validationSchema={this.SignUpSchema}
          onSubmit={this.handleSubmit}
        >
          {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
            <Form className={classes.form} style={{ display: isLoading ? 'none' : 'block' }}>
              <TextInputField
                inputExtraContainerSize={180}
                inputExtraRowSize={80}
                id="displayName"
                label="Your Name*"
                touched={touched.displayName}
                error={errors.displayName}
                autoComplete="name"
              />
              {!signUpCode && (
                <TextInputField
                  inputExtraContainerSize={180}
                  inputExtraRowSize={80}
                  id="emailAddress"
                  label="Email*"
                  type="email"
                  touched={touched.emailAddress}
                  error={errors.emailAddress}
                  autoComplete="email"
                />
              )}
              <TextInputField
                inputExtraContainerSize={180}
                inputExtraRowSize={80}
                id="password"
                label="Password*"
                type="password"
                touched={touched.password}
                error={errors.password}
                autoComplete="new-password"
                handleFocus={this.handlePasswordFocused}
                handleBlur={this.handlePasswordBlurred}
                setFieldTouched={setFieldTouched}
              />
              {(touched.password || passwordFocused) && (
                <div className={classes.passwordStrengthContainer}>{passwordStrengthGrade(values.password)}</div>
              )}
              <TextInputField
                inputExtraContainerSize={180}
                inputExtraRowSize={80}
                id="passwordConfirm"
                label="Confirm Password*"
                type="password"
                touched={touched.passwordConfirm}
                error={errors.passwordConfirm}
                autoComplete="new-password"
              />
              <TextInputField
                inputExtraContainerSize={180}
                inputExtraRowSize={80}
                id="phoneNumber"
                label="Phone #"
                touched={touched.phoneNumber}
                error={errors.phoneNumber}
                autoComplete="phone"
              />
              {!signUpCode && (
                <SelectInputField
                  label="Educational Role*"
                  id="role"
                  inputExtraContainerSize={180}
                  inputExtraRowSize={80}
                  touched={touched.role}
                  error={errors.role}
                  options={[
                    { value: 'districtAdmin', label: 'District Admin' },
                    { value: 'campusAdmin', label: 'Campus Admin' },
                  ]}
                />
              )}
              {districtList && !signUpCode && (
                <TextInputField
                  inputExtraContainerSize={180}
                  inputExtraRowSize={80}
                  id="district"
                  label="District*"
                  touched={touched.district}
                  error={errors.district}
                  setFieldValue={setFieldValue}
                  searchOptions={districtList}
                  searchKeys={['id', 'name']}
                  autoComplete="district"
                />
              )}
              {values.district && values.role === 'campusAdmin' && campusList && !signUpCode && (
                <SelectInputField
                  multi
                  inputExtraContainerSize={180}
                  inputExtraRowSize={80}
                  label="Campus*"
                  id="campusNumber"
                  flexDirection="row"
                  touched={touched.campusNumber}
                  error={errors.campusNumber}
                  className={classes.inputField}
                  accessLabelName="name"
                  accessValueName="id"
                  options={campusList.filter((campus) => campus.id.substring(0, 6) === values.district.split(' - ')[0])}
                />
              )}
              <div className={classes.buttonRow}>
                <Button submit large color="white" className={classes.button}>
                  Create User
                </Button>
              </div>
              <div className={classes.signInContainer}>
                <Typography gutterBottom> or </Typography>
                <Link key="sign-in" to={`/${Routes.signIn}`} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.signInLink}>Sign In</Typography>
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    );
  }
}

export default withStyles(styles)(SignUp);
