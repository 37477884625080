import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { apiCalculateProgressGoals, queryProgressGoals } from './progressGoals.api';
import { GET_PROGRESS_GOALS, CALCULATE_PROGRESS_GOALS, EXPORT_PROGRESS_GOALS } from './progressGoals.actions';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import { asyncFinish } from '../../../common';

const calculateProgressGoalsEpic = (action$) =>
  action$.pipe(
    ofType(CALCULATE_PROGRESS_GOALS),
    switchMap((action) =>
      from(
        apiCalculateProgressGoals({
          ignoreCache: action.ignoreCache,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Get Progress Goals'))
    ),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinish(result.action.type, result.response.data));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const getProgressGoalsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROGRESS_GOALS),
    switchMap((action) => {
      const { sortBy, limit, nextToken, filters } = action;
      return from(
        queryProgressGoals({
          ignoreCache: action.ignoreCache,
          model: 'progressGoals',
          sortBy,
          limit,
          nextToken,
          filters,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Get Progress Goals'));
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinish(result.action.type, {
            data: result.response.data,
            action: result.action,
            stagedFilters: result.action.stagedFilters,
          })
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const exportProgressGoalsEpic = (action$) =>
  action$.pipe(
    ofType(EXPORT_PROGRESS_GOALS),
    switchMap((action) => {
      const { sortBy, filters } = action;
      return from(queryProgressGoals({ model: 'progressGoalsExportCsv', sortBy, filters })).pipe(
        commonApiCallFragment(action$, action, 'Export Progress Goals')
      );
    }),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinish(result.action.type, { data: result.response.data }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const epics = combineEpics(getProgressGoalsEpic, calculateProgressGoalsEpic, exportProgressGoalsEpic);
export default epics;
