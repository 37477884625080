export const Indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  ecoDis: 'Economically Disadvantaged',
  lep: 'EL (Current & Monitored)',
  specialEdCurrent: 'Special Ed (Current)',
  specialEdFormer: 'Special Ed (Former)',
  continuouslyEnrolled: 'Continuously Enrolled',
  nonContinuouslyEnrolled: 'Non-Continuously Enrolled',
  highlyMobile: 'Highly Mobile',
  acceleratedTesterMath: 'Accelerated Tester Math',
  acceleratedTesterReading: 'Accelerated Tester Reading',
  acceleratedTesterScience: 'Accelerated Tester Science',
};

export const JobNames = {
  aToF: 'aToF',
  flightPlan: 'flightPlan',
  testsOnly: 'testsOnly',
  filterFieldsOnly: 'filterFieldsOnly',
};

export const Subjects = {
  algebraOne: 'Algebra I',
  biology: 'Biology',
  englishOne: 'English I',
  englishTwo: 'English II',
  math: 'Mathematics',
  reading: 'Reading',
  science: 'Science',
  socialStudies: 'Social Studies',
  usHistory: 'US History',
  writing: 'Writing',
};

export const Grades = {
  1: '1st Grade',
  2: '2nd Grade',
  3: '3rd Grade',
  4: '4th Grade',
  5: '5th Grade',
  6: '6th Grade',
  7: '7th Grade',
  8: '8th Grade',
  EOC: 'EOC',
};

export default 'flightPlan.constants.js';
