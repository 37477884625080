import { combineReducers } from 'redux';
import { _reducer as filterBox } from './FilterBox';

export * from './async';
export * from './database';
export * from './constants';
export * from './utils';

export const reducer = combineReducers({
  filterBox,
});
