const styles = (theme) => ({
  inputFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing.unit,
    position: 'relative',
  },
  inputFieldRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputField: {
    width: '370px',
    paddingLeft: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    height: theme.spacing.unit * 4,
    background: theme.palette.white,
    border: `1px solid ${theme.palette.white}`,
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
    },
    color: theme.palette.primary.main,
    fontFamily: ['"Questrial"', 'sans-serif'].join(','),
    fontSize: '0.875rem',
  },
  label: {
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
  },
  labelIcon: {
    fontSize: 24,
  },
  labelError: {
    color: theme.palette.error.dark,
  },
  errorMsg: {
    color: theme.palette.error.dark,
    fontSize: '0.875rem',
    marginLeft: theme.spacing.unit,
    width: 200,
  },
  columnErrorMsg: {
    color: theme.palette.error.dark,
    fontSize: '0.875rem',
    marginLeft: 100,
    marginTop: 5,
  },
  optionsContainer: {
    maxHeight: theme.spacing.unit * 20,
    position: 'absolute',
    overflow: 'scroll',
    background: theme.palette.white,
    borderRadius: theme.spacing.unit,
    zIndex: 8,
    marginLeft: '180px',
    border: `1px solid ${theme.palette.primary.light}`,
  },
  option: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  },
  lastOption: {
    borderBottom: 'transparent',
  },
  inputFieldError: {
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.dark,
  },
  iconTextInputFieldWrapper: {
    width: '370px',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    height: theme.spacing.unit * 4.5,
    background: theme.palette.white,
    border: `1px solid ${theme.palette.white}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  darkBorder: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  iconTextInputFieldWrapperError: {
    borderColor: theme.palette.error.dark,
  },
  iconTextInputField: {
    width: '320px',
    paddingLeft: theme.spacing.unit,
    height: theme.spacing.unit * 4,
    border: '0px',
    fontFamily: ['"Questrial"', 'sans-serif'].join(','),
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
    },
  },
  iconTextInputFieldError: {
    color: theme.palette.error.dark,
  },
  fieldset: {
    border: 0,
    margin: 0,
    padding: 0,
    '& input': {
      background: 'transparent',
      cursor: 'not-allowed',
    },
  },
  disabled: {
    background: 'transparent',
    cursor: 'not-allowed',
    border: 'transparent',
  },
});

export default styles;
