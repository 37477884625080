import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, MenuItem, FormControl, Grid } from '@material-ui/core';
import { AssessmentTypes } from '../../Assessments';
import { Subjects, Indicators, Grades } from '../+store/aToF/aToF.constants';
import MultiSelectControl from './MultiSelect.component';
import { UserTypes } from '../../common';
import { getCurrentYear, getSchoolYearsModel } from '../../common/+store/utils';
import { JobNames } from '../../FlightPlan/+store/flightPlan';
import CircularSelect from './CircularSelect.component';

const styles = (theme) => ({
  root: {
    marginRight: 8,
  },
  menu: {},
  textField: {
    width: '100%',
  },
  formControl: {
    minWidth: 218,
    marginRight: 8,
  },
  filterControl: {
    minWidth: 158,
    marginRight: 8,
  },
  multiSelectFormControl: {
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    minWidth: 218,
  },
  multiSelectFilterControl: {
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    minWidth: 158,
  },
  warningText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const schoolYears = getSchoolYearsModel();
// const schoolYears = {
//   2022: '2021-2022',
//   2021: '2020-2021',
//   2020: '2019-2020',
//   2019: '2018-2019',
// };

const scoreType = {
  average: 'Average',
  best: 'Best',
  mostRecent: 'Most Recent',
};

const makeAssessmentInfo = (assessmentSource, assessmentList) => {
  let assessmentsInfo = [];
  if (assessmentSource) {
    assessmentsInfo = assessmentSource
      .map((aso) => assessmentList.find((a) => a.id === aso))
      .filter((a) => !!a)
      .map((a) => ({
        id: a.id,
        type: a.type,
      }));
  }
  return assessmentsInfo;
};

class AtoFFilters extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    status: PropTypes.shape({
      error: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
    getReport: PropTypes.func.isRequired,
    assessmentList: PropTypes.array.isRequired,
    schoolList: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    updateFilters: PropTypes.func.isRequired,
    // filterFields: PropTypes.object.isRequired,
    getFilterFields: PropTypes.func.isRequired,
    filterFields: PropTypes.object,
    reportError: PropTypes.any,
    waitMessage: PropTypes.any,
    assessmentGroups: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    ignoreCache: PropTypes.bool.isRequired,
    ignoreTests: PropTypes.bool.isRequired,
    debug: PropTypes.bool.isRequired,
    jobName: PropTypes.string.isRequired,
    orientation: PropTypes.string,
  };

  state = {
    option: null,
    isUpdated: true,
    // isFilterFetched: false,
  };
  filterFieldParams = '';
  componentDidMount() {
    const { schoolList, filters, updateFilters } = this.props;
    const { school } = filters;
    if (schoolList.length > 0 && !school) {
      const cachedFilters = JSON.parse(localStorage.getItem('aToFFilters'));
      if (cachedFilters) {
        updateFilters(Object.assign({}, cachedFilters));
        if (cachedFilters.assessmentSource && this.showAssessmentCombineOption(cachedFilters.assessmentSource)) {
          this.setState({ option: 'best' });
        } else {
          this.setState({ option: null });
        }
      } else {
        updateFilters(
          Object.assign({}, filters, {
            school: schoolList[0][0],
            schoolYear: getCurrentYear(),
            assessmentType: 'individual',
          })
        );
      }
    }
  }

  componentDidUpdate() {
    const { schoolList, filters, updateFilters } = this.props;
    const { school } = filters;
    if (schoolList.length > 0 && !school) {
      const cachedFilters = JSON.parse(localStorage.getItem('aToFFilters'));
      if (cachedFilters) {
        updateFilters(
          Object.assign({}, cachedFilters, {
            subject: ['all'],
            grade: ['all'],
            teacher: ['all'],
            studentGroup: ['all'],
          })
        );
        if (cachedFilters.assessmentSource && this.showAssessmentCombineOption(cachedFilters.assessmentSource)) {
          this.setState({ option: 'best' });
        } else {
          this.setState({ option: null });
        }
      } else {
        updateFilters(
          Object.assign({}, filters, {
            school: schoolList[0][0],
            schoolYear: getCurrentYear(),
            assessmentType: 'individual',
            subject: ['all'],
            grade: ['all'],
            teacher: ['all'],
            studentGroup: ['all'],
          })
        );
      }
    }
  }

  handleSelectTeacher = (teacher) => {
    const { filters, updateFilters } = this.props;
    const updatedFilters = Object.assign({}, filters, {
      teacher: [teacher],
    });
    if (JSON.stringify(updatedFilters) !== JSON.stringify(filters)) {
      updateFilters(updatedFilters);
      this.setState({ isUpdated: true });
    }
  };

  renderTeacherInstantSelector = () => {
    const { filters, filterFields } = this.props;
    const { teacher } = filters;
    let { teachers } = filterFields;
    teachers = teachers.length !== 0 ? [{ value: 'all', label: 'All Teachers' }, ...teachers] : teachers;
    if (!!teachers.length && teacher && teacher.length === 1 && teacher[0] !== 'all') {
      return <CircularSelect options={teachers} selectedOption={teacher[0]} onSelect={this.handleSelectTeacher} />;
    }
    return null;
  };

  renderFilterRow = () => {
    const { classes, filters, filterFields, profile, reportError } = this.props;

    const { assessmentSource, school, subject, grade, teacher, studentGroup, schoolYear } = filters;
    const { isUpdated } = this.state;
    let { subjects, grades, teachers } = filterFields;
    subjects = subjects.length !== 0 ? [{ value: 'all', label: 'All Subjects' }, ...subjects] : subjects;
    grades = grades.length !== 0 ? [{ value: 'all', label: 'All Grades' }, ...grades] : grades;
    teachers = teachers.length !== 0 ? [{ value: 'all', label: 'All Teachers' }, ...teachers] : teachers;
    const updateButton = null;

    if (
      schoolYear &&
      school &&
      school.length > 6 &&
      profile.userType === UserTypes.firstClass &&
      !!assessmentSource.length &&
      (subjects.length || teachers.length || grades.length)
    ) {
      return (
        <Grid container direction="row">
          {!!subjects.length && (
            <MultiSelectControl
              handleChange={this.handleChange('subject')}
              value={subject || []}
              label="Subject"
              getSelected={(s) => (s === 'all' ? 'All Subjects' : Subjects[s])}
              options={subjects}
              helpText="Filter by subject"
              formControlClass={classes.multiSelectFilterControl}
              id="aToFSubjectFilter"
            />
          )}
          {!!teachers.length && (
            <MultiSelectControl
              handleChange={this.handleChange('teacher')}
              value={teacher || []}
              label="Teacher"
              getSelected={(s) => (s === 'all' ? 'All Teachers' : s)}
              options={teachers}
              helpText="Filter by teacher"
              formControlClass={classes.multiSelectFilterControl}
              id="aToFTeacherFilter"
            />
          )}
          {!!grades.length && (
            <MultiSelectControl
              handleChange={this.handleChange('grade')}
              value={grade || []}
              label="Grade"
              getSelected={(s) => (s === 'all' ? 'All Grades' : Grades[s])}
              options={grades}
              helpText="Filter by grade"
              formControlClass={classes.multiSelectFilterControl}
              id="aToFGradeFilter"
            />
          )}
          <MultiSelectControl
            handleChange={this.handleChange('studentGroup')}
            value={studentGroup || []}
            label="Student Group"
            getSelected={(s) => Indicators[s]}
            options={Object.entries(Indicators).map(([value, label]) => ({
              value,
              label,
            }))}
            helpText="Filter by student group"
            formControlClass={classes.multiSelectFilterControl}
            id="aToFStudentGroupFilter"
          />
          {/* { updateButton } */}
        </Grid>
      );
    }

    return updateButton;
  };

  getFiltersFromBackend = (isInit = false) => {
    const { filters, getFilterFields, assessmentList, assessmentGroups } = this.props;
    const { assessmentSource, school, assessmentType } = filters;
    let assessmentSourceList = assessmentSource;
    if (assessmentType === 'group') {
      assessmentSourceList = assessmentSource in assessmentGroups ? assessmentGroups[assessmentSource].assessments : [];
    }
    const assessmentsInfo = makeAssessmentInfo(assessmentSourceList, assessmentList);
    if (assessmentsInfo.length && school) {
      const { subject, grade, teacher, studentGroup, schoolYear } = filters;
      const afFilters = {
        subjects: subject && !isInit ? subject : [],
        grades: grade && !isInit ? grade : [],
        teachers: teacher && !isInit ? teacher : [],
        studentGroups: studentGroup && !isInit ? studentGroup : [],
      };
      const filterFieldParams = `${JSON.stringify(assessmentsInfo)}:${school}`;
      if (filterFieldParams !== this.filterFieldParams) {
        this.filterFieldParams = filterFieldParams;
        getFilterFields(schoolYear, assessmentsInfo, school, afFilters);
      }
    }
  };

  onSubmit = () => {
    const {
      getReport,
      assessmentList,
      filters,
      assessmentGroups,
      ignoreCache,
      ignoreTests,
      debug,
      jobName,
      profile,
    } = this.props;
    let { assessmentSource } = filters;
    const { school, subject, grade, teacher, studentGroup, schoolYear, assessmentType } = filters;
    const {
      option,
      // isFilterFetched,
    } = this.state;
    // Todo: add validation errors
    const afFilters = {
      subjects: subject || [],
      grades: grade || [],
      teachers: teacher || [],
      studentGroups: studentGroup || [],
    };
    if (assessmentType === 'group') {
      assessmentSource = assessmentSource in assessmentGroups ? assessmentGroups[assessmentSource].assessments : [];
    }
    const assessmentsInfo = makeAssessmentInfo(assessmentSource, assessmentList);
    if (assessmentsInfo && assessmentsInfo.length > 0 && school) {
      localStorage.setItem(
        'aToFFilters',
        JSON.stringify(
          {
            school: filters.school,
            schoolYear: filters.schoolYear,
            assessmentType: filters.assessmentType,
            assessmentSource: filters.assessmentSource,
          },
          null,
          2
        )
      );
      // this.getFiltersFromBackend(!isFilterFetched);
      getReport({
        schoolYear,
        assessmentsInfo,
        school,
        afFilters,
        option,
        ignoreCache,
        ignoreTests,
        debug,
        jobName,
        profile,
      });
      this.setState({
        isUpdated: false,
        // isFilterFetched: true,
      });
    } else {
      console.log('Validation error!');
    }
  };

  isComboOrInterim = (assessmentId) => {
    const { assessmentList } = this.props;
    const assessment = assessmentList.find((a) => a.id === assessmentId);
    const assessmentType = assessment ? assessment.type : null;
    return (
      !assessmentType ||
      [AssessmentTypes.ComboAssessment, AssessmentTypes.Interim, AssessmentTypes.DMACInterim].includes(assessmentType)
    );
  };

  showAssessmentCombineOption = (assessmentSourceList) =>
    Array.isArray(assessmentSourceList) ? assessmentSourceList.every(this.isComboOrInterim) : false;

  filterOutComboOrStaar = (selectedAssessmentList) => {
    if (Array.isArray(selectedAssessmentList)) {
      const currentAssessmentId = selectedAssessmentList[selectedAssessmentList.length - 1];
      const isComboOrInterim = this.isComboOrInterim(currentAssessmentId);
      return selectedAssessmentList.filter((id) => isComboOrInterim === this.isComboOrInterim(id));
    }
    return selectedAssessmentList;
  };

  handleChange = (name) => (event) => {
    const { filters, updateFilters, filterFields } = this.props;
    const { subjects, grades, teachers } = filterFields;
    if (event.target.value === 'No Selection') {
      event.target.value = null;
    }
    let updatedFilters = filters;
    let filteredAssessmentSourceList;
    switch (name) {
      case 'school':
        updatedFilters = Object.assign({}, filters, {
          school: event.target.value,
          schoolYear: filters.schoolYear || getCurrentYear(),
          assessmentType: filters.assessmentType || 'individual',
          assessmentSource: filters.assessmentSource || [],
          subject: filters.subject || ['all'],
          grade: filters.grade || ['all'],
          teacher: filters.teacher || ['all'],
          studentGroup: filters.studentGroup || ['all'],
        });
        break;
      case 'schoolYear':
        updatedFilters = Object.assign({}, filters, {
          schoolYear: event.target.value,
          assessmentType: 'individual',
          assessmentSource: [],
          subject: ['all'],
          grade: ['all'],
          teacher: ['all'],
          studentGroup: ['all'],
        });
        break;
      case 'assessmentType':
        updatedFilters = Object.assign({}, filters, {
          assessmentType: event.target.value,
          assessmentSource: event.target.value === 'group' ? '' : [],
          subject: ['all'],
          grade: ['all'],
          teacher: ['all'],
          studentGroup: ['all'],
        });
        break;
      case 'assessmentSource':
        filteredAssessmentSourceList = this.filterOutComboOrStaar(event.target.value);
        updatedFilters = Object.assign({}, filters, {
          assessmentSource: filteredAssessmentSourceList,
          subject: ['all'],
          grade: ['all'],
          teacher: ['all'],
          studentGroup: ['all'],
        });
        if (this.showAssessmentCombineOption(filteredAssessmentSourceList)) {
          this.setState({ option: 'best' });
        } else {
          this.setState({ option: null });
        }
        break;
      case 'option':
        const option = event.target.value;
        if (this.state.option !== option) {
          this.setState({ isUpdated: true });
        }
        this.setState({ option });
        break;
      default: {
        let { value } = event.target;
        const { length } = value;
        let allOptionsLength = 0;
        if (name === 'studentGroup') {
          allOptionsLength = Object.keys(Indicators).length - 1;
        } else if (name === 'teacher') {
          allOptionsLength = Object.keys(teachers).length;
        } else if (name === 'grade') {
          allOptionsLength = Object.keys(grades).length;
        } else if (name === 'subject') {
          allOptionsLength = Object.keys(subjects).length;
        }
        if (value.includes('all') && value[length - 1] !== 'all') {
          value = value.filter((e) => e !== 'all');
        } else if (value[length - 1] === 'all' || length === allOptionsLength) {
          value = ['all'];
        }
        updatedFilters = Object.assign({}, filters, {
          [name]: value,
        });
      }
    }
    if (JSON.stringify(updatedFilters) !== JSON.stringify(filters)) {
      updateFilters(updatedFilters);
      this.setState({ isUpdated: true });
    }
  };

  render() {
    const {
      classes,
      assessmentList,
      schoolList,
      filters,
      reportError,
      waitMessage,
      assessmentGroups,
      orientation,
      jobName,
      profile,
    } = this.props;

    const { assessmentSource, school, schoolYear, assessmentType } = filters;
    const { option, isUpdated } = this.state;
    let assessmentSources = [];

    if (assessmentType === 'individual') {
      if (schoolYear === '2018') {
        assessmentSources = assessmentList
          .filter((a) => [AssessmentTypes.CAF2018, AssessmentTypes.DMAC2018].includes(a.type))
          .map((source) => ({
            value: source.id,
            label: source.name,
          }));
      } else if (schoolYear === '2021') {
        //2021
        if (jobName === JobNames.aToF) {
          const staarAssessments = [
            AssessmentTypes.STAAR2021,
            AssessmentTypes.STAARALT2021,
            AssessmentTypes.TELPAS2021,
            AssessmentTypes.TELPASALT2021,
            AssessmentTypes.EOC2021,
            AssessmentTypes.EOCALT2021,
          ];
          assessmentSources = assessmentList
            .filter((a) => staarAssessments.includes(a.type))
            .map((source) => ({
              value: source.id,
              label: source.name,
            }));
        }
        const interimSources = assessmentList
          .filter((a) => {
            if (
              [AssessmentTypes.ComboAssessment, AssessmentTypes.Interim, AssessmentTypes.DMACInterim].includes(a.type)
            ) {
              if (
                moment(a.administrationDate || a.date).isBefore(moment('2021-07-01')) &&
                moment(a.administrationDate || a.date).isAfter(moment('2019-07-01'))
              ) {
                return true;
              }
            }
            return false;
          })
          .map((source) => ({
            value: source.id,
            label: `${source.name} (${moment(source.administrationDate || source.date).format('D MMM YYYY')})`,
          }));
        interimSources.forEach((s) => {
          assessmentSources.push(s);
        });
      } else if (schoolYear === '2019') {
        if (jobName === JobNames.aToF) {
          const staarAssessments = [
            AssessmentTypes.STAAR2019,
            AssessmentTypes.STAARALT2019,
            AssessmentTypes.TELPAS2019,
            AssessmentTypes.TELPASALT2019,
            AssessmentTypes.EOC2019,
            AssessmentTypes.EOCALT2019,
          ];
          assessmentSources = assessmentList
            .filter((a) => staarAssessments.includes(a.type))
            .map((source) => ({
              value: source.id,
              label: source.name,
            }));
        }
        const interimSources = assessmentList
          .filter((a) => {
            if (
              [AssessmentTypes.ComboAssessment, AssessmentTypes.Interim, AssessmentTypes.DMACInterim].includes(a.type)
            ) {
              if (
                moment(a.administrationDate || a.date).isBefore(moment('2019-07-01')) &&
                moment(a.administrationDate || a.date).isAfter(moment('2018-07-01'))
              ) {
                return true;
              }
            }
            return false;
          })
          .map((source) => ({
            value: source.id,
            label: `${source.name} (${moment(source.administrationDate || source.date).format('D MMM YYYY')})`,
          }));
        interimSources.forEach((s) => {
          assessmentSources.push(s);
        });
      } else {
        if (jobName === JobNames.aToF) {
          const staarAssessments = [
            AssessmentTypes.STAAR + schoolYear,
            AssessmentTypes.STAARALT + schoolYear,
            AssessmentTypes.TELPAS + schoolYear,
            AssessmentTypes.TELPASALT + schoolYear,
            AssessmentTypes.EOC + schoolYear,
            AssessmentTypes.EOCALT + schoolYear,
          ];
          assessmentSources = assessmentList
            .filter((a) => staarAssessments.includes(a.type))
            .map((source) => ({
              value: source.id,
              label: source.name,
            }));
        }
        const interimSources = assessmentList
          .filter((a) => {
            if (
              [AssessmentTypes.ComboAssessment, AssessmentTypes.Interim, AssessmentTypes.DMACInterim].includes(a.type)
            ) {
              if (
                moment(a.administrationDate || a.date).isBefore(moment(schoolYear + '-07-01')) &&
                moment(a.administrationDate || a.date).isAfter(moment(schoolYear - 1 + '-07-01'))
              ) {
                return true;
              }
            }
            return false;
          })
          .map((source) => ({
            value: source.id,
            label: `${source.name} (${moment(source.administrationDate || source.date).format('D MMM YYYY')})`,
          }));
        interimSources.forEach((s) => {
          assessmentSources.push(s);
        });
      }
      ///
    } else if (assessmentType === 'group') {
      assessmentSources = Object.entries(assessmentGroups)
        .filter(([, group]) => group.year === schoolYear)
        .map(([groupId, source]) => ({
          value: groupId,
          label: source.name,
        }));
    }

    // sort list
    if (assessmentSources != null) {
      assessmentSources = assessmentSources.sort(function(a, b) {
        return a.label.localeCompare(b.label);
      });
    }

    let updateButton = null;
    if ((schoolYear && !!assessmentSource.length && school && isUpdated) || reportError) {
      updateButton = (
        <Button
          color="primary"
          variant="contained"
          style={{
            marginTop: orientation === 'horizontal' ? 16 : 0,
            height: '56px',
            width: '200px',
          }}
          onClick={this.onSubmit}
        >
          Get Report
        </Button>
      );
    }

    return (
      <form
        className={classes.root}
        onSubmit={this.onSubmit}
        style={{
          flexDirection: orientation === 'horizontal' ? 'row' : 'column',
          width: orientation === 'horizontal' ? '' : '100%',
        }}
      >
        {orientation === 'vertical' && updateButton}
        <Grid container direction="column" justify="center" alignItems="flex-start">
          <div
            className={classes.filterRow}
            style={{
              width: orientation === 'horizontal' ? '' : '100%',
            }}
          >
            <Grid container direction="row">
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  label="School"
                  className={classes.textField}
                  value={school || ''}
                  onChange={this.handleChange('school')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  helperText="Select a school"
                  margin="normal"
                >
                  {schoolList.map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              {school && (
                <FormControl className={classes.formControl}>
                  <TextField
                    select
                    label="School Year"
                    className={classes.textField}
                    value={schoolYear || ''}
                    onChange={this.handleChange('schoolYear')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please select a school year"
                    margin="normal"
                  >
                    {Object.entries(schoolYears).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              )}
              {schoolYear && (
                <FormControl className={classes.formControl}>
                  <TextField
                    select
                    label="Assessment Source Type"
                    className={classes.textField}
                    value={assessmentType || ''}
                    onChange={this.handleChange('assessmentType')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please select an assessment source type"
                    margin="normal"
                  >
                    <MenuItem key="group" value="group">
                      A Custom Assessment Group
                    </MenuItem>
                    <MenuItem key="individual" value="individual">
                      Individual Assessment(s)
                    </MenuItem>
                  </TextField>
                </FormControl>
              )}
              {assessmentType === 'individual' && (
                <FormControl className={classes.formControl}>
                  <MultiSelectControl
                    id="aToFAssessmentSourceFilter"
                    handleChange={this.handleChange('assessmentSource')}
                    value={assessmentSource || []}
                    label="Individual Assessment Source"
                    getSelected={(s) => {
                      const a = assessmentList.find((a) => a.id === s);
                      return a ? a.name : '';
                    }}
                    options={assessmentSources}
                    helpText="Please select one or more assessment sources"
                    formControlClass={classes.multiSelectFormControl}
                  >
                    {!assessmentSources.length && (
                      <MenuItem disabled value="">
                        {`None available for ${schoolYears[schoolYear]}`}
                      </MenuItem>
                    )}
                  </MultiSelectControl>
                </FormControl>
              )}
              {assessmentSource && assessmentSource.length >= 2 && this.showAssessmentCombineOption(assessmentSource) && (
                <FormControl className={classes.formControl} style={{ width: '20%' }}>
                  <TextField
                    select
                    label="Options"
                    className={classes.textField}
                    value={option || ''}
                    onChange={this.handleChange('option')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Select which test should be included in the report for students with multiple tests in the same subject"
                    margin="normal"
                  >
                    {Object.entries(scoreType).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              )}
              {assessmentType === 'group' && (
                <FormControl className={classes.formControl}>
                  <TextField
                    select
                    label="Group Assessment Source"
                    className={classes.textField}
                    value={assessmentSource || ''}
                    onChange={this.handleChange('assessmentSource')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please select one group assessment source"
                    margin="normal"
                  >
                    {assessmentSources.map((source) => (
                      <MenuItem key={source.value} value={source.value}>
                        {source.label}
                      </MenuItem>
                    ))}
                    {!assessmentSources.length && (
                      <MenuItem disabled value="">
                        {`None available for ${schoolYears[schoolYear]}`}
                      </MenuItem>
                    )}
                  </TextField>
                </FormControl>
              )}
            </Grid>
          </div>
          {this.renderFilterRow()}
          {((schoolYear && !!assessmentSource.length && school) || reportError) && this.renderTeacherInstantSelector()}
          {orientation === 'horizontal' && updateButton}
          {reportError && <p className={classes.warningText}> {reportError} </p>}
          {waitMessage && <p className={classes.warningText}> {waitMessage} </p>}
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(AtoFFilters);
