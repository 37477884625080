import { asyncStart } from '../../common/index';

export const GET_CCMR_TRACKING = 'ccmrTracking.getCCMRTrackingData';
export const getCCMRTrackingData = (profile, ignoreCache) => (dispatch) => {
  dispatch(asyncStart(GET_CCMR_TRACKING));
  dispatch({
    type: GET_CCMR_TRACKING,
    profile,
    ignoreCache,
  });
};

export const UPDATE_CCMR_TRACKING = 'ccmrTracking.updateCCMRTrackingData';
export const updateCCMRTrackingData = (orgId, firebaseId, studentData) => (dispatch) => {
  dispatch(asyncStart(UPDATE_CCMR_TRACKING));
  dispatch({
    type: UPDATE_CCMR_TRACKING,
    orgId,
    firebaseId,
    studentData,
  });
};

export const SELECT_CCMR_KEY = 'ccmrTracking.selectCCMRKey';
export const selectCCMRKey = (key) => (dispatch) => {
  dispatch({
    type: SELECT_CCMR_KEY,
    key,
  });
};

export const CANCEL_SELECT_CCMR_KEY = 'ccmrTracking.cancelSelectCCMRKey';
export const cancelSelectCCMRKey = () => (dispatch) => {
  dispatch({ type: CANCEL_SELECT_CCMR_KEY });
};

export const UPDATE_BULK_CCMR_TRACKING = 'ccmrTracking.updateBulkCCMRTrackingData';
export const updateBulkCCMRTrackingData = (orgId, studentsData) => (dispatch) => {
  dispatch(asyncStart(UPDATE_BULK_CCMR_TRACKING));
  dispatch({
    type: UPDATE_BULK_CCMR_TRACKING,
    orgId,
    studentsData,
  });
};

export const UPDATE_IS_MONITOR_Filter = 'ccmrTracking.updateIsMonitorFilter';
export const updateIsMonitorFilter = (val) => (dispatch) => {
  dispatch({
    type: UPDATE_IS_MONITOR_Filter,
    value: val,
  });
};
