import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, SvgIcon, Typography } from '@material-ui/core';
import CCMRTrackerIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import GroupIcon from '@material-ui/icons/Group';
import FlightIcon from '@material-ui/icons/Flight';
import GradeIcon from '@material-ui/icons/Grade';
import { Routes } from '../App/App.routes';
import { Logo } from '../common/Logo';

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 180,
  },
  logoIcon: {
    width: 955,
    height: 384,
    opacity: 0.07,
  },
  logoTitle: {
    color: theme.palette.primary.main,
    fontSize: '2.25rem',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItem: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
  },
  menuItemContainer: {
    textAlign: 'center',
    width: theme.spacing.unit * 13,
  },
  menuContainer: {
    width: '80%',
    marginTop: 60,
    alignSelf: 'center',
  },
});

function WeightsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 44 32" width="44" height="32" {...props}>
      <path d="M7.49 5.07001L0 22.88C0 22.88 7.95 28.48 15.52 22.64L7.49 5.07001Z" fill="#656565" />
      <path d="M35.2 5.07001L27.71 22.88C27.71 22.88 35.66 28.48 43.23 22.64L35.2 5.07001Z" fill="#656565" />
      <path d="M41.1099 0.25H2.40991V2.81H41.1099V0.25Z" fill="#656565" />
      <path d="M30.02 29.27H12.78V31.73H30.02V29.27Z" fill="#656565" />
      <path d="M23 1.53003H20.22V31.55H23V1.53003Z" fill="#656565" />
    </SvgIcon>
  );
}

class HomePage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    campusDistrictInfo: PropTypes.any,
  };

  componentDidMount() {
    /*const script = document.createElement('script');
    script.src =
      'https://atxanka.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/zca20r/b/11/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=af8bcc66';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: (showCollectorDialog) => {
          document.getElementById('feedbackBtn').onclick = (e) => {
            e.preventDefault();
            showCollectorDialog();
          };
        },
      };
    };*/
  }

  componentDidUpdate() {
    if (document.getElementById('atlwdg-trigger')) {
      document.getElementById('atlwdg-trigger').setAttribute('style', 'z-index: 10;');
    }
  }

  renderMenuItem = (key, title, Icon, link) => {
    const { classes } = this.props;
    return (
      <Link key={key} to={link} style={{ textDecoration: 'none' }}>
        <Grid item className={classes.menuItemContainer}>
          <Icon color="primary" fontSize="large" />
          <Typography align="center" gutterBottom className={classes.menuItem}>
            {title}
          </Typography>
        </Grid>
      </Link>
    );
  };

  render() {
    const { classes, campusDistrictInfo } = this.props;

    const icons = {
      studentRoster: GroupIcon,
      weights: WeightsIcon,
      flightPlan: FlightIcon,
      progressGoals: GradeIcon,
      reports: AssessmentOutlinedIcon,
      ccmrTracking: CCMRTrackerIcon,
      assessments: AssignmentIcon,
      settings: SettingsIcon,
    };

    let menuItems;
    if (
      campusDistrictInfo &&
      (campusDistrictInfo.isDistrict || (campusDistrictInfo.gradeEnd && Number(campusDistrictInfo.gradeEnd) >= 9))
    ) {
      menuItems = {
        studentRoster: 'Student Roster',
        reports: 'A-F Reports',
        weights: 'Accountability Weights',
        progressGoals: 'Progress Goals',
        flightPlan: 'Flight Plan',
        ccmrTracking: 'CCMR Tracking',
        assessments: 'Assessment Manager',
        settings: 'Settings',
      };
    } else {
      menuItems = {
        studentRoster: 'Student Roster',
        reports: 'A-F Reports',
        weights: 'Accountability Weights',
        progressGoals: 'Progress Goals',
        flightPlan: 'Flight Plan',
        assessments: 'Assessment Manager',
        settings: 'Settings',
      };
    }

    const links = {
      studentRoster: `/${Routes.studentRoster}`,
      weights: `/${Routes.weights}`,
      flightPlan: `/${Routes.flightPlan}`,
      progressGoals: `/${Routes.staar}`,
      reports: `/${Routes.aToF}`,
      ccmrTracking: `/${Routes.ccmrTracking}`,
      assessments: `/${Routes.assessments}`,
      settings: `/${Routes.settings}`,
    };

    return (
      <div className={classes.root}>
        <Logo
          isHomePage
          wrapperClassName={classes.logoWrapper}
          logoClassName={classes.logoIcon}
          titleClassName={classes.logoTitle}
        />
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.menuContainer}
        >
          {Object.entries(menuItems).map(([key, title]) => this.renderMenuItem(key, title, icons[key], links[key]))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(HomePage);
