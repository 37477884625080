import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, Typography } from '@material-ui/core';

const LoadingCircle = ({ classes, msg }) => (
  <Grid container direction="column" justify="center" alignItems="center">
    <CircularProgress className={classes.progress} />
    {msg && <Typography style={{ marginTop: '12px' }}>{msg}</Typography>}
  </Grid>
);
LoadingCircle.propTypes = {
  classes: PropTypes.any,
  msg: PropTypes.string,
};

export default LoadingCircle;
