import * as React from 'react';
import PropTypes from 'prop-types';
import { TableFooter } from '@material-ui/core';
import { CustomTableRow, CustomTableCell, RowLabelCell } from '../CustomTableComponents';
import EmptyCols from '../EmptyCols';
import { formatNumberOrDash } from '../utils';

const TotalIndicator = ({ footerClass, title, totalIndicators, indicatorsLength }) => (
  <TableFooter className={footerClass}>
    <CustomTableRow>
      <RowLabelCell>{title}</RowLabelCell>
      <EmptyCols length={indicatorsLength} />
      <CustomTableCell>{formatNumberOrDash(totalIndicators.met)}</CustomTableCell>
      <CustomTableCell>{formatNumberOrDash(totalIndicators.eligible * 4)}</CustomTableCell>
      <CustomTableCell>{formatNumberOrDash(totalIndicators.percent, '%')}</CustomTableCell>
      <CustomTableCell>{formatNumberOrDash(totalIndicators.weight, '%')}</CustomTableCell>
      <CustomTableCell>{formatNumberOrDash(totalIndicators.score, '%')}</CustomTableCell>
    </CustomTableRow>
  </TableFooter>
);

TotalIndicator.propTypes = {
  totalIndicators: PropTypes.shape({
    score: PropTypes.number,
    weight: PropTypes.number,
    percent: PropTypes.number,
    met: PropTypes.number,
    eligible: PropTypes.number,
  }),
  indicatorsLength: PropTypes.number,
  footerClass: PropTypes.any,
  title: PropTypes.string,
};

export default TotalIndicator;
