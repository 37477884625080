import { catchError, map, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';

import { asyncError } from '../async';
import { scheduleRedirect } from '../../../App/+store/routes';
import { Routes } from '../../../App';

export const API_REQUEST_ERROR = 'apiRequest.error';
const apiRequestError = (action, error) => ({
  type: API_REQUEST_ERROR,
  error: {
    code: error.code,
    message: error.message,
  },
  action,
});

export const apiErrorHandlerEpicFragment = (action) => (source) =>
  source.pipe(
    catchError((error) => {
      switch (error.code) {
        case 'not-found':
          return of({
            success: false,
            actions: [apiRequestError(action, error), asyncError(action.type, `api: ${error.code}`, error)],
          });
        case 'unauthenticated':
          return of({
            success: false,
            actions: [
              scheduleRedirect(`/${Routes.signIn}`),
              apiRequestError(action, error),
              asyncError(action.type, `api: ${error.code}`, error),
            ],
          });
        default:
          throw error;
      }
    })
  );

export const CANCEL_API_REQUEST = 'apiRequest.cancel';
export const cancelApiRequest = () => ({ type: CANCEL_API_REQUEST });
export const cancelOnAction = (action$) => action$.pipe(ofType(CANCEL_API_REQUEST));

export const commonApiCallFragment = (action$, action, errorLabel, incoming = {}) => (source) =>
  source.pipe(
    map((response) => ({
      ...incoming,
      action,
      success: true,
      response,
    })),
    takeUntil(cancelOnAction(action$)),
    apiErrorHandlerEpicFragment(action),
    catchError((error) =>
      of({
        success: false,
        action: asyncError(action.type, errorLabel, error),
      })
    )
  );

export default 'utils.api.js';
