import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import styles from './PgFilters.styles';
import { gradeLevels, ethnicity } from '../../common/+store/FilterBox';
import SelectFilter from './SelectFilter';
import ListFilter from './ListFilter';
// import PgFilters from './PgFilters';

class PgFilters extends Component {
  static propTypes = {
    classes: PropTypes.object,
    updateFilters: PropTypes.func,
    teacherOptions: PropTypes.any,
    schoolOptions: PropTypes.any,
    sortByOptions: PropTypes.any,
    studentIndicatorItems: PropTypes.any,
    applyFilters: PropTypes.any,
    stagedFilters: PropTypes.any,
    filtersChanged: PropTypes.any,
    filterApplied: PropTypes.any,
    resetFilters: PropTypes.any,
    profile: PropTypes.any,
    filterLimitReached: PropTypes.any,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit = () => {};

  handleChange = (name) => (e) => {
    const { updateFilters } = this.props;
    updateFilters({
      [name]: e.target.value,
    });
  };

  applyFilters = () => {
    const { stagedFilters, applyFilters } = this.props;
    applyFilters(stagedFilters);
  };

  render() {
    const {
      classes,
      teacherOptions,
      schoolOptions,
      sortByOptions,
      studentIndicatorItems,
      stagedFilters,
      filtersChanged,
      filterApplied,
      resetFilters,
      profile,
      filterLimitReached,
    } = this.props;
    return (
      <div className={classes.root}>
        <form className={classes.root} autoComplete="off">
          {filtersChanged && !filterLimitReached && (
            <div className={classes.submitBtn}>
              <Button variant="contained" type="button" onClick={this.applyFilters}>
                Apply Filters
              </Button>
            </div>
          )}
          {profile && profile.role === 'districtAdmin' && filterLimitReached && (
            <Typography color="error" className={classes.filterLimitReached}>
              In addition to the campus filter only one other filter can be selected at a time
            </Typography>
          )}
          {profile && profile.role !== 'districtAdmin' && filterLimitReached && (
            <Typography color="error" className={classes.filterLimitReached}>
              Only one filter can be selected at a time
            </Typography>
          )}
          <SelectFilter
            name="sortBy"
            classes={classes}
            filterValue={stagedFilters.sortBy}
            filterLabel="Sort By"
            handleChange={this.handleChange('sortBy')}
            options={sortByOptions}
          />
          <Typography className={classes.filtersTitle}>Filters</Typography>
          {profile && (profile.role === 'districtAdmin' || schoolOptions.length > 1) && (
            <SelectFilter
              name="campus"
              classes={classes}
              filterValue={stagedFilters.campus}
              filterLabel="Campus"
              handleChange={this.handleChange('campus')}
              options={schoolOptions}
            />
          )}
          <SelectFilter
            name="grade"
            classes={classes}
            filterValue={stagedFilters.grade}
            filterLabel="Grade"
            handleChange={this.handleChange('grade')}
            options={gradeLevels}
          />
          <SelectFilter
            name="ethnicity"
            classes={classes}
            filterValue={stagedFilters.ethnicity}
            filterLabel="Ethnicity"
            handleChange={this.handleChange('ethnicity')}
            options={ethnicity}
          />
          <ListFilter
            filterLabel="Student Indicators"
            listItems={studentIndicatorItems}
            listValues={stagedFilters}
            handleChange={this.handleChange}
          />
          <SelectFilter
            name="teacher"
            classes={classes}
            filterValue={stagedFilters.teacher}
            filterLabel="Filter by Teacher"
            handleChange={this.handleChange('teacher')}
            options={teacherOptions}
          />
          {filterApplied && (
            <div className={classes.submitBtn} style={{ marginTop: 16 }}>
              <Button variant="contained" type="button" onClick={resetFilters}>
                Reset Filters
              </Button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(PgFilters);
