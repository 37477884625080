import { combineReducers } from 'redux';

import { ASYNC_START, ASYNC_ERROR, ASYNC_FINISH } from '../../../common';
import {
  AUTH_INIT,
  AUTH_GET_PROFILE,
  AUTH_SIGN_IN,
  GET_CAMPUS_DISTRICT_LIST,
  AUTH_SIGN_UP,
  AUTH_FORGET_PASSWORD,
  AUTH_FORGET_PASSWORD_UNSET,
  GET_SIGN_UP_CODE_INFO,
} from './auth.actions';
import { UPDATE_PROFILE } from '../../../Settings/+store/AccountSettings';

const authenticated = (state = false, action) => {
  switch (action.type) {
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === AUTH_INIT) {
        return true;
      }
      return state;
    default:
      return state;
  }
};

const resetEmailSent = (state = false, action) => {
  if (action.type === AUTH_FORGET_PASSWORD_UNSET) return false;
  return action.type === ASYNC_FINISH && action.model === AUTH_FORGET_PASSWORD;
};

const signUpMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_CAMPUS_DISTRICT_LIST) {
        return 'Getting Campus and District List ...';
      }
      if (action.model === AUTH_SIGN_UP) {
        return 'Creating your account ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_CAMPUS_DISTRICT_LIST || action.model === AUTH_SIGN_UP) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = 'Authenticating ...', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === AUTH_INIT) {
        return 'Authenticating ...';
      }
      if (action.model === AUTH_SIGN_IN) {
        return 'Verifying your credentials ...';
      }
      if (action.model === AUTH_FORGET_PASSWORD) {
        return 'Verifying your email ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (
        [AUTH_INIT, AUTH_SIGN_UP, AUTH_SIGN_IN, GET_CAMPUS_DISTRICT_LIST, AUTH_FORGET_PASSWORD].includes(action.model)
      ) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const user = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === AUTH_INIT) {
        return action.data.user;
      }
      if (action.model === AUTH_SIGN_IN) {
        return action.data.user;
      }
      if (action.model === UPDATE_PROFILE) {
        const { displayName, phoneNumber } = action.data;
        return Object.assign({}, state, { displayName, phoneNumber });
      }
      return state;
    default:
      return state;
  }
};

const profile = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === AUTH_GET_PROFILE || action.model === AUTH_SIGN_UP) {
        return action.data.profile;
      }
      if (action.model === UPDATE_PROFILE) {
        const { displayName, phoneNumber } = action.data;
        return Object.assign({}, state, { displayName, phoneNumber });
      }
      return state;
    default:
      return state;
  }
};

const uniqueCampuses = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_CAMPUS_DISTRICT_LIST) {
        return action.data.schools;
      }
      return state;
    default:
      return state;
  }
};

const uniqueDistricts = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_CAMPUS_DISTRICT_LIST) {
        return action.data.districts;
      }
      return state;
    default:
      return state;
  }
};

const error = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
    case ASYNC_FINISH:
      return null;
    case ASYNC_ERROR:
      if (action.model === AUTH_SIGN_IN) {
        return action.error;
      }
      if (action.model === AUTH_SIGN_UP) {
        if (action.error.code === 'auth/email-already-in-use') {
          return 'Another user with that email already exists';
        }
        return action.error.message || 'There was an error creating your account';
      }
      if (action.model === AUTH_FORGET_PASSWORD) {
        if (action.error.code === 'auth/user-not-found') {
          return 'There is no user record corresponding to this email.';
        }
        return action.error.message || 'There was an error resetting your account';
      }
      if (action.model === GET_SIGN_UP_CODE_INFO) {
        return action.error.message || 'The sign up code you provided does not match any of our records.';
      }
      return state;
    default:
      return state;
  }
};

const signUpCodeInfo = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
    case ASYNC_ERROR:
      if (action.model === GET_SIGN_UP_CODE_INFO) {
        return null;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === GET_SIGN_UP_CODE_INFO) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const redirectToSignUp = (state = false, action) =>
  action.type === ASYNC_ERROR && action.model === GET_SIGN_UP_CODE_INFO;

export const _reducer = combineReducers({
  loadingMsg,
  user,
  profile,
  error,
  uniqueCampuses,
  uniqueDistricts,
  authenticated,
  signUpMsg,
  resetEmailSent,
  signUpCodeInfo,
  redirectToSignUp,
});

export default 'auth.reducers.js';
