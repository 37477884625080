import parse from 'csv-parse';

const comboAssessmentCols_old = [
  'TermName',
  'DistrictName',
  'SchoolName',
  'StudentLastName',
  'StudentFirstName',
  'StudentMI',
  'StudentID',
  'StudentDateOfBirth',
  'StudentEthnicGroup',
  'StudentGender',
  'Grade',
  'MeasurementScale',
  'Discipline',
  'NormsReferenceData',
  'WISelectedAYFall',
  'WISelectedAYWinter',
  'WISelectedAYSpring',
  'WIPreviousAYFall',
  'WIPreviousAYWinter',
  'WIPreviousAYSpring',
  'TestType',
  'TestName',
  'TestStartDate',
  'TestDurationMinutes',
  'TestRITScore',
  'TestStandardError',
  'TestPercentile',
  'FallToFallProjectedGrowth',
  'FallToFallObservedGrowth',
  'FallToFallObservedGrowthSE',
  'FallToFallMetProjectedGrowth',
  'FallToFallConditionalGrowthIndex',
  'FallToFallConditionalGrowthPercentile',
  'FallToWinterProjectedGrowth',
  'FallToWinterObservedGrowth',
  'FallToWinterObservedGrowthSE',
  'FallToWinterMetProjectedGrowth',
  'FallToWinterConditionalGrowthIndex',
  'FallToWinterConditionalGrowthPercentile',
  'FallToSpringProjectedGrowth',
  'FallToSpringObservedGrowth',
  'FallToSpringObservedGrowthSE',
  'FallToSpringMetProjectedGrowth',
  'FallToSpringConditionalGrowthIndex',
  'FallToSpringConditionalGrowthPercentile',
  'WinterToWinterProjectedGrowth',
  'WinterToWinterObservedGrowth',
  'WinterToWinterObservedGrowthSE',
  'WinterToWinterMetProjectedGrowth',
  'WinterToWinterConditionalGrowthIndex',
  'WinterToWinterConditionalGrowthPercentile',
  'WinterToSpringProjectedGrowth',
  'WinterToSpringObservedGrowth',
  'WinterToSpringObservedGrowthSE',
  'WinterToSpringMetProjectedGrowth',
  'WinterToSpringConditionalGrowthIndex',
  'WinterToSpringConditionalGrowthPercentile',
  'SpringToSpringProjectedGrowth',
  'SpringToSpringObservedGrowth',
  'SpringToSpringObservedGrowthSE',
  'SpringToSpringMetProjectedGrowth',
  'SpringToSpringConditionalGrowthIndex',
  'SpringToSpringConditionalGrowthPercentile',
  'RITtoReadingScore',
  'RITtoReadingMin',
  'RITtoReadingMax',
  'Goal1Name',
  'Goal1RitScore',
  'Goal1StdErr',
  'Goal1Range',
  'Goal1Adjective',
  'Goal2Name',
  'Goal2RitScore',
  'Goal2StdErr',
  'Goal2Range',
  'Goal2Adjective',
  'Goal3Name',
  'Goal3RitScore',
  'Goal3StdErr',
  'Goal3Range',
  'Goal3Adjective',
  'Goal4Name',
  'Goal4RitScore',
  'Goal4StdErr',
  'Goal4Range',
  'Goal4Adjective',
  'Goal5Name',
  'Goal5RitScore',
  'Goal5StdErr',
  'Goal5Range',
  'Goal5Adjective',
  'Goal6Name',
  'Goal6RitScore',
  'Goal6StdErr',
  'Goal6Range',
  'Goal6Adjective',
  'Goal7Name',
  'Goal7RitScore',
  'Goal7StdErr',
  'Goal7Range',
  'Goal7Adjective',
  'Goal8Name',
  'Goal8RitScore',
  'Goal8StdErr',
  'Goal8Range',
  'Goal8Adjective',
  'TestStartTime',
  'PercentCorrect',
  'ProjectedProficiencyStudy1',
  'ProjectedProficiencyLevel1',
  'ProjectedProficiencyStudy2',
  'ProjectedProficiencyLevel2',
  'ProjectedProficiencyStudy3',
  'ProjectedProficiencyLevel3',
  'AccommodationCategory',
  'Accommodations',
  'TypicalFallToFallGrowth',
  'TypicalFallToWinterGrowth',
  'TypicalFallToSpringGrowth',
  'TypicalWinterToWinterGrowth',
  'TypicalWinterToSpringGrowth',
  'TypicalSpringToSpringGrowth',
  'ProjectedProficiencyStudy4',
  'ProjectedProficiencyLevel4',
  'ProjectedProficiencyStudy5',
  'ProjectedProficiencyLevel5',
  'ProjectedProficiencyStudy6',
  'ProjectedProficiencyLevel6',
  'ProjectedProficiencyStudy7',
  'ProjectedProficiencyLevel7',
  'ProjectedProficiencyStudy8',
  'ProjectedProficiencyLevel8',
  'ProjectedProficiencyStudy9',
  'ProjectedProficiencyLevel9',
  'ProjectedProficiencyStudy10',
  'ProjectedProficiencyLevel10',
];

const comboAssessmentCols_new = [
  'TermName',
  'DistrictName',
  'District_StateID',
  'SchoolName',
  'School_StateID',
  'StudentLastName',
  'StudentFirstName',
  'StudentMI',
  'StudentID',
  'Student_StateID',
  'StudentDateOfBirth',
  'StudentEthnicGroup',
  'NWEAStandard_EthnicGroup',
  'StudentGender',
  'Grade',
  'NWEAStandard_Grade',
  'Subject',
  'Course',
  'NormsReferenceData',
  'WISelectedAYFall',
  'WISelectedAYWinter',
  'WISelectedAYSpring',
  'WIPreviousAYFall',
  'WIPreviousAYWinter',
  'WIPreviousAYSpring',
  'TestType',
  'TestName',
  'TestStartDate',
  'TestStartTime',
  'TestDurationMinutes',
  'TestRITScore',
  'TestStandardError',
  'TestPercentile',
  'AchievementQuintile',
  'PercentCorrect',
  'RapidGuessingPercentage',
  'FallToFallProjectedGrowth',
  'FallToFallObservedGrowth',
  'FallToFallObservedGrowthSE',
  'FallToFallMetProjectedGrowth',
  'FallToFallConditionalGrowthIndex',
  'FallToFallConditionalGrowthPercentile',
  'FallToFallGrowthQuintile',
  'FallToWinterProjectedGrowth',
  'FallToWinterObservedGrowth',
  'FallToWinterObservedGrowthSE',
  'FallToWinterMetProjectedGrowth',
  'FallToWinterConditionalGrowthIndex',
  'FallToWinterConditionalGrowthPercentile',
  'FallToWinterGrowthQuintile',
  'FallToSpringProjectedGrowth',
  'FallToSpringObservedGrowth',
  'FallToSpringObservedGrowthSE',
  'FallToSpringMetProjectedGrowth',
  'FallToSpringConditionalGrowthIndex',
  'FallToSpringConditionalGrowthPercentile',
  'FallToSpringGrowthQuintile',
  'WinterToWinterProjectedGrowth',
  'WinterToWinterObservedGrowth',
  'WinterToWinterObservedGrowthSE',
  'WinterToWinterMetProjectedGrowth',
  'WinterToWinterConditionalGrowthIndex',
  'WinterToWinterConditionalGrowthPercentile',
  'WinterToWinterGrowthQuintile',
  'WinterToSpringProjectedGrowth',
  'WinterToSpringObservedGrowth',
  'WinterToSpringObservedGrowthSE',
  'WinterToSpringMetProjectedGrowth',
  'WinterToSpringConditionalGrowthIndex',
  'WinterToSpringConditionalGrowthPercentile',
  'WinterToSpringGrowthQuintile',
  'SpringToSpringProjectedGrowth',
  'SpringToSpringObservedGrowth',
  'SpringToSpringObservedGrowthSE',
  'SpringToSpringMetProjectedGrowth',
  'SpringToSpringConditionalGrowthIndex',
  'SpringToSpringConditionalGrowthPercentile',
  'SpringToSpringGrowthQuintile',
  'LexileScore',
  'LexileMin',
  'LexileMax',
  'QuantileScore',
  'QuantileMin',
  'QuantileMax',
  'Goal1Name',
  'Goal1RitScore',
  'Goal1StdErr',
  'Goal1Range',
  'Goal1Adjective',
  'Goal2Name',
  'Goal2RitScore',
  'Goal2StdErr',
  'Goal2Range',
  'Goal2Adjective',
  'Goal3Name',
  'Goal3RitScore',
  'Goal3StdErr',
  'Goal3Range',
  'Goal3Adjective',
  'Goal4Name',
  'Goal4RitScore',
  'Goal4StdErr',
  'Goal4Range',
  'Goal4Adjective',
  'Goal5Name',
  'Goal5RitScore',
  'Goal5StdErr',
  'Goal5Range',
  'Goal5Adjective',
  'Goal6Name',
  'Goal6RitScore',
  'Goal6StdErr',
  'Goal6Range',
  'Goal6Adjective',
  'Goal7Name',
  'Goal7RitScore',
  'Goal7StdErr',
  'Goal7Range',
  'Goal7Adjective',
  'Goal8Name',
  'Goal8RitScore',
  'Goal8StdErr',
  'Goal8Range',
  'Goal8Adjective',
  'AccommodationCategories',
  'Accommodations',
  'TypicalFallToFallGrowth',
  'TypicalFallToWinterGrowth',
  'TypicalFallToSpringGrowth',
  'TypicalWinterToWinterGrowth',
  'TypicalWinterToSpringGrowth',
  'TypicalSpringToSpringGrowth',
  'ProjectedProficiencyStudy1',
  'ProjectedProficiencyLevel1',
  'ProjectedProficiencyStudy2',
  'ProjectedProficiencyLevel2',
  'ProjectedProficiencyStudy3',
  'ProjectedProficiencyLevel3',
  'ProjectedProficiencyStudy4',
  'ProjectedProficiencyLevel4',
  'ProjectedProficiencyStudy5',
  'ProjectedProficiencyLevel5',
  'ProjectedProficiencyStudy6',
  'ProjectedProficiencyLevel6',
  'ProjectedProficiencyStudy7',
  'ProjectedProficiencyLevel7',
  'ProjectedProficiencyStudy8',
  'ProjectedProficiencyLevel8',
  'ProjectedProficiencyStudy9',
  'ProjectedProficiencyLevel9',
  'ProjectedProficiencyStudy10',
  'ProjectedProficiencyLevel10',
];

const isComboAssessmentFile = (file) =>
  new Promise((resolve) => {
    if (file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
      //text/csv'
      resolve(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      parse(text, {}, (err, output) => {
        if (err) {
          resolve(false);
          return;
        }
        if (
          JSON.stringify(output[0].filter((x) => x)) === JSON.stringify(comboAssessmentCols_new) ||
          JSON.stringify(output[0].filter((x) => x)) === JSON.stringify(comboAssessmentCols_old)
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    };
    reader.readAsText(file);
  });

export default isComboAssessmentFile;
