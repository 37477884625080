import { connect } from 'react-redux';
import { updateUserProfile, inviteUser } from '../../+store/ManageUsers';
import Component from './UpdateAddUser.component';

const mapStateToProps = (state) => {
  const schools = state.modules.Settings.schoolSettings.schoolList;
  const schoolList = Object.values(schools)
    .filter((school) => !!school.campusNumber)
    .map((school) => ({ value: school.campusNumber, label: `${school.name} - ${school.campusNumber}` }));
  return {
    schoolList,
    profile: state.modules.Auth.auth.profile,
    invitedUsers: state.modules.Settings.manageUsers.invitedUsers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateProfile: (userUid, userData) => {
    dispatch(updateUserProfile(userUid, userData));
  },
  onInviteUser: (userData) => {
    dispatch(inviteUser(userData));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
