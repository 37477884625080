import { connect } from 'react-redux';

import Component from './ForgotPassword.component';
import { forgetPassword } from '../+store/auth/auth.actions';

const mapStateToProps = (state) => ({
  resetEmailSent: state.modules.Auth.auth.resetEmailSent,
});

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (values) => {
    dispatch(forgetPassword(values));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
