export const SORT_BY = 'common.FilterBox.sortBy';
export const sortBy = (sortByField, model, sortByKeys, sortReverse = false) => (dispatch) => {
  dispatch({
    type: SORT_BY,
    sortByField,
    model,
    sortByKeys,
    sortReverse,
  });
};

export const UPDATE_FILTER_OPTIONS = 'common.FilterBox.filterOptions.update';
export const updateFilterOptions = (
  key,
  filterType,
  filterKey,
  value,
  model,
  isAdding = true,
  byRoster = false,
  byCCMR = null
) => (dispatch) => {
  dispatch({
    type: UPDATE_FILTER_OPTIONS,
    filterType,
    filterKey,
    key,
    value,
    model,
    isAdding,
    byRoster,
    byCCMR,
  });
};

export const RESET_FILTERS = 'common.FilterBox.reset';
export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const IS_MONITOR_FILTER = 'common.FilterBox.isMonitorFilter';
export const isMonitorFilter = () => ({
  type: IS_MONITOR_FILTER,
});
