import { connect } from 'react-redux';

import Component from './Student.component';
import {
  changeProfilePicture,
  deleteStudentRequest,
  editStudentRequest,
} from '../+store/studentRoster/studentRoster.actions';

const mapStateToProps = (state) => ({
  state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  handleEdit: () => {
    dispatch(editStudentRequest(ownProps.firebaseId));
  },
  handleDelete: () => {
    dispatch(deleteStudentRequest(ownProps.firebaseId));
  },
  handleEditProfilePicture: () => {
    dispatch(editStudentRequest(ownProps.firebaseId));
    dispatch(changeProfilePicture(true));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);

export default Container;
