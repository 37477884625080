import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { _reducer as progressGoals, epics as progressGoalsEpics } from './progressGoals';

export const reducer = combineReducers({
  progressGoals,
});

export const epics = combineEpics(progressGoalsEpics);
