import firebase from 'firebase';

export const getAvatarsUrl = async () =>
  firebase
    .database()
    .ref('newDatabase/dataStructure/avatarsUrl')
    .once('value')
    .then((snapshot) => snapshot.val());

export const addStudent = async (orgId, student, year) =>
  firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/students/${year}`)
    .push(student);

export const updateStudent = async (orgId, studentId, student, year) =>
  firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/students/${year}/${studentId}`)
    .update(student);

export const deleteStudent = async (orgId, studentId, year) =>
  firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/students/${year}`)
    .update({
      [studentId]: null,
    });

export const setStudentStaarTests = firebase.functions().httpsCallable('setStudentStaarTests');
export const getStudentStaarTests = firebase.functions().httpsCallable('getStudentStaarTests');

export const onFileUpload = firebase.functions().httpsCallable('onFileUpload');
export const onChangeProfilePicture = firebase
  .functions()
  .httpsCallable('onChangeProfilePicture-onChangeProfilePicture');

export const queryRosterApi = firebase.functions().httpsCallable('rosterApi');

export default 'studentRoster.api.js';
