import { combineReducers } from 'redux';
import { UPLOAD_ROSTER, INIT_ROSTER } from './rosterPage.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_PROGRESS, ASYNC_START } from '../../../common';

const initialRosterObj = {
  uploaded: false,
  error: '',
};

const status = (state = initialRosterObj, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ROSTER) {
        return Object.assign({}, state, {
          uploaded: action.data.uploaded,
          error: '',
        });
      }
      if (action.model === INIT_ROSTER) {
        return Object.assign({}, state, {
          uploaded: !!action.data.uploaded,
          error: '',
        });
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === UPLOAD_ROSTER) {
        return Object.assign({}, state, {
          uploaded: action.data.uploaded,
          error: action.error,
        });
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === INIT_ROSTER) {
        return 'Initializing ...';
      }
      if (action.model === UPLOAD_ROSTER) {
        return 'Saving ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === INIT_ROSTER || action.model === UPLOAD_ROSTER) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === INIT_ROSTER || action.model === UPLOAD_ROSTER) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const uploadProgressMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_PROGRESS:
      if (action.model === UPLOAD_ROSTER) {
        return action.data.message;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ROSTER) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
  loadingMsg,
  needsInit,
  uploadProgressMsg,
});

export default 'rosterPage.reducers.js';
