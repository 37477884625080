import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getStudentAvatarUrl } from '../../StudentRoster/+store/studentRoster';
import StudentRow from './StudentRow.component';
import { getCurrentYear, getPriorYear } from '../../common/+store/utils';

const styles = (theme) => ({
  row: {
    height: 75,
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  table: {
    height: '100%',
  },
  content: {
    margin: 'auto',
  },
  textField: {
    width: '280px',
    marginRight: 8,
  },
  tableBody: {
    overflowY: 'scroll',
    width: '100%',
  },
  header: {
    display: 'flex',
  },
  tableHeadFirefox: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#f1fdff',
  },
  tableHeadSafari: {
    position: '-webkit-sticky',
    top: 0,
    zIndex: 1,
    background: '#f1fdff',
  },
  headCellChrome: {
    fontSize: '1.5rem',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 0,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#f1fdff',
  },
  headCell: {
    fontSize: '1.5rem',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 0,
  },
  largeText: {
    fontSize: '1.5rem',
  },
  smallTextChrome: {
    padding: 0,
    fontSize: '18px',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#f1fdff',
  },
  smallText: {
    padding: 0,
    fontSize: '18px',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#f1fdff',
  },
  tableRow: {
    background: '#f1fdff',
  },
  smallTextEmpty: {
    width: '30px',
    padding: 0,
    fontSize: '18px',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  smallTextIconChrome: {
    width: '15px',
    padding: 0,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#f1fdff',
  },
  smallTextIcon: {
    width: '15px',
    padding: 0,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  tableContainer: {
    flex: 1,
    margin: `0 ${theme.spacing.unit * 3}px`,
  },
  button: {
    margin: theme.spacing.unit,
    float: 'right',
  },
  headerSticky: {
    position: 'sticky',
    background: '#f7feff',
    top: 0,
  },
  headerStickySafari: {
    position: '-webkit-sticky',
  },
  subjectHeader: {
    fontSize: 24,
  },
  yearHeader: {
    fontSize: 18,
  },
});

class ProgressGoalsList extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    students: PropTypes.any.isRequired,
    view: PropTypes.any.isRequired,
    avatars: PropTypes.object,
    loading: PropTypes.any,
    onLoadMore: PropTypes.func,
  };

  showMore = (event) => {
    const { students } = this.props;
    const node = event.currentTarget;
    if (students.length && node) {
      node.addEventListener(
        'scroll',
        () => {
          if (node.scrollTop + node.clientHeight >= node.scrollHeight - 70) {
            this.loadMoreItems();
          }
        },
        true
      );
    }
  };

  loadMoreItems() {
    const { onLoadMore, loading } = this.props;
    if (loading) {
      return;
    }
    onLoadMore();
  }

  render() {
    const { students, classes, view, avatars, loading } = this.props;
    const subjectName = {
      math: 'Mathematics',
      reading: 'Reading',
    };
    return (
      <div className={classes.tableBody} onScroll={(event) => this.showMore(event)}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classNames(classes.subjectHeader, classes.headerSticky, classes.headerStickySafari)}
                align="left"
              >
                {subjectName.math}
              </TableCell>
              <TableCell
                className={classNames(classes.yearHeader, classes.headerSticky, classes.headerStickySafari)}
                align="center"
              >
                {`${getPriorYear()} Score`}
              </TableCell>
              <TableCell className={classNames(classes.headerSticky, classes.headerStickySafari)} align="center" />
              <TableCell
                className={classNames(classes.yearHeader, classes.headerSticky, classes.headerStickySafari)}
                align="center"
              >
                {`${getCurrentYear()} Goal`}
              </TableCell>
              <TableCell
                className={classNames(classes.subjectHeader, classes.headerSticky, classes.headerStickySafari)}
                align="left"
              >
                {subjectName.reading}
              </TableCell>
              <TableCell
                className={classNames(classes.yearHeader, classes.headerSticky, classes.headerStickySafari)}
                align="center"
              >
                {`${getPriorYear()} Score`}
              </TableCell>
              <TableCell className={classNames(classes.headerSticky, classes.headerStickySafari)} align="center" />
              <TableCell
                className={classNames(classes.yearHeader, classes.headerSticky, classes.headerStickySafari)}
                align="center"
              >
                {`${getCurrentYear()} Goal`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map(([studentId, studentInfo]) => (
              <TableRow hover key={studentId} className={classes.row}>
                <StudentRow
                  student={studentInfo}
                  view={view}
                  subject="math"
                  avatarUrl={getStudentAvatarUrl(studentInfo, avatars)}
                />
                <StudentRow
                  student={studentInfo}
                  view={view}
                  subject="reading"
                  avatarUrl={getStudentAvatarUrl(studentInfo, avatars)}
                />
              </TableRow>
            ))}
            <TableRow hover className={classes.row}>
              <TableCell key="name" align="left" colSpan={6} className={classes.cell}>
                {loading && <Typography color="primary">Loading more students ...</Typography>}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}
export default withStyles(styles)(ProgressGoalsList);
