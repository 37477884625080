import { connect } from 'react-redux';
import { changePassword, updateProfile } from '../+store/AccountSettings';
import Component from './AccountSettings.component';

const mapStateToProps = (state) => ({
  profile: state.modules.Auth.auth.profile,
  status: state.modules.Settings.accountSettings.status,
});

const mapDispatchToProps = (dispatch) => ({
  onSavePasswordClick: (values) => {
    dispatch(changePassword(values));
  },
  onSaveProfileClick: (values) => {
    dispatch(updateProfile(values));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
