import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
} from '@material-ui/core';

import CustomTableCell from '../CustomTableComponents/CustomTableCell.component';

const styles = (theme) => ({
  emptyCell: {},
});

const indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  ecoDis: 'Economically Disadvantaged',
  lep: 'EL (Current & Monitored)',
  specialEdCurrent: 'Special Ed (Current)',
  specialEdFormer: 'Special Ed (Former)',
  continuouslyEnrolled: 'Continuously Enrolled',
  // nonContinuouslyEnrolled: 'Non-Continuously Enrolled',
  highlyMobile: 'Highly Mobile',
  highFocus: 'High Focus',
  acceleratedTesterMath: 'Accelerated Tester Math',
  acceleratedTesterReading: 'Accelerated Tester Reading',
  acceleratedTesterScience: 'Accelerated Tester Science',
};
const indicatorsLength = Object.keys(indicators).length;

const totalsColumns = {
  pointsEarned: 'Component Points Earned',
  PointsPossible: 'Component Points Possible',
  percentScore: 'Component Percent Score',
};
const totalsColumnsLength = Object.keys(totalsColumns).length;

const scoreColumns = {
  weight: 'Weight',
  score: 'Score',
};
const scoreColumnsLength = Object.keys(scoreColumns).length;

const RowLabelCell = withStyles((theme) => ({
  head: {
    padding: 8,
  },
  body: {
    padding: '4px 8px',
    textAlign: 'left',
  },
  footer: {
    padding: 8,
    textAlign: 'left',
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    height: 'unset',
  },
}))(TableRow);

const EmptyCols = ({ length, classes }) => (
  <React.Fragment>
    {[...Array(length)].map((noop, index) => (
      <CustomTableCell key={index} className={classes.emptyCell} />
    ))}
  </React.Fragment>
);
EmptyCols.propTypes = {
  length: PropTypes.number.isRequired,
  classes: PropTypes.any,
};

export default withStyles(styles)(EmptyCols);
