export const getValue = (obj, path, key, schoolTypeConversion) => {
  const parts = path.split('.');
  parts.reverse();
  let subObj = obj;
  while (parts.length) {
    subObj = subObj[parts.pop()];
    if (!subObj) return '';
  }

  if (
    schoolTypeConversion &&
    path == 'targets' &&
    subObj[key] &&
    subObj[key][schoolTypeConversion] &&
    subObj[key][schoolTypeConversion].t3
  ) {
    return subObj[key][schoolTypeConversion].t3.toFixed(0);
  }
  return subObj[key];
};

export default 'AtoF/Common/utils.js';
