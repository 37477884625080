import { connect } from 'react-redux';

import Component from './WelcomePage.component';
import { Routes } from '../../App';
import { markCompleted } from '../+store';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
  onNextClick: (history, next) => {
    dispatch(markCompleted(Routes.welcome, next, history));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
