import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  withStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import { CloudUpload, CheckCircle, Cancel, HelpOutline } from '@material-ui/icons';

const UploadCell = ({ onClick, classes }) => (
  <TableCell className={classes.tableCell} align="right">
    <Button color="primary" onClick={onClick} className={classes.uploadButton}>
      <CloudUpload className={classes.uploadIcon} />
    </Button>
  </TableCell>
);

const styles = (theme) => ({
  table: {
    minWidth: 700,
  },
  button: {},
  tableWrapper: {
    marginBottom: theme.spacing.unit * 6,
  },
  smallFont: {
    fontSize: '0.75rem',
  },
  mediumFont: {
    fontSize: '0.875rem',
  },
  largeFont: {
    fontSize: '1rem',
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
  checkCircle: {
    color: 'limegreen',
  },
  cancel: {
    color: 'crimson',
  },
  tableCell: {
    // paddingRight: '24px'
    padding: '12px 12px 12px 12px',
  },
  firstCol: {
    width: '40%',
  },
  showMeButton: {
    display: 'block',
  },
  refButton: {
    display: 'block',
  },
  uploadButton: {
    display: 'block',
  },
});

const HelpTable = (props) => {
  const { classes, uploaded, onUploadClick } = props;
  return (
    <React.Fragment>
      <Typography align="center" gutterBottom className={classNames(classes.bold, classes.largeFont)}>
        All Schools
      </Typography>
      <Paper className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} align="left">
                Name
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Filetype
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Source
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Help
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Upload
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell} alight="left">
                <Grid container wrap="nowrap">
                  <Grid item xs>
                    2018 Consolidated Accountability File
                  </Grid>
                  <Grid item>
                    {uploaded.CAF2018 ? (
                      <CheckCircle className={classes.checkCircle} />
                    ) : (
                      <Cancel className={classes.cancel} />
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                .txt
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Button href="https://tx-toms.ets.org/" target="_blank" className={classes.refButton}>
                  TAMS
                </Button>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Button
                  href="https://s3-us-west-1.amazonaws.com/copilotdrive/ShowMeCAF.pdf"
                  target="_blank"
                  className={classes.showMeButton}
                >
                  <HelpOutline className={classes.showMeIcon} />
                </Button>
              </TableCell>
              <UploadCell classes={classes} onClick={onUploadClick} />
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Typography align="center" gutterBottom className={classNames(classes.bold, classes.largeFont)}>
        Secondary Schools and Districts
      </Typography>
      <Paper className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} align="left">
                Name
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Filetype
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Source
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Help
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Upload
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell} alight="left">
                <Grid container wrap="nowrap">
                  <Grid item xs>
                    Four-Year Longitudinal Information for 2017
                  </Grid>
                  <Grid item>
                    {uploaded.FYLI2017 ? (
                      <CheckCircle className={classes.checkCircle} />
                    ) : (
                      <Cancel className={classes.cancel} />
                    )}
                  </Grid>
                </Grid>
                <Grid container wrap="nowrap">
                  <Grid item xs>
                    Five-Year Longitudinal Information for 2016
                  </Grid>
                  <Grid item>
                    {uploaded.FYLI2016 ? (
                      <CheckCircle className={classes.checkCircle} />
                    ) : (
                      <Cancel className={classes.cancel} />
                    )}
                  </Grid>
                </Grid>
                <Grid container wrap="nowrap">
                  <Grid item xs>
                    Six-Year Longitudinal Information for 2015
                  </Grid>
                  <Grid item>
                    {uploaded.SYLI2015 ? (
                      <CheckCircle className={classes.checkCircle} />
                    ) : (
                      <Cancel className={classes.cancel} />
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                .pdf
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Button
                  href="https://rptsvr1.tea.texas.gov/cgi/gateway/sasse/adq/res/index.html"
                  target="_blank"
                  className={classes.refButton}
                >
                  TEASE
                </Button>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Button
                  href="https://s3-us-west-1.amazonaws.com/copilotdrive/ShowMeGrad.pdf"
                  target="_blank"
                  className={classes.showMeButton}
                >
                  <HelpOutline className={classes.showMeIcon} />
                </Button>
              </TableCell>
              <UploadCell classes={classes} onClick={onUploadClick} />
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell} alight="left">
                <Grid container wrap="nowrap">
                  <Grid item xs>
                    2018 College, Career, and Military Readiness (CCMR) Student Listing
                  </Grid>
                  <Grid item>
                    {uploaded.CCMR2018 ? (
                      <CheckCircle className={classes.checkCircle} />
                    ) : (
                      <Cancel className={classes.cancel} />
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                .xls
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Button
                  href="https://rptsvr1.tea.texas.gov/cgi/gateway/sasse/adq/res/index.html"
                  target="_blank"
                  className={classes.refButton}
                >
                  TEASE
                </Button>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Button
                  href="https://s3-us-west-1.amazonaws.com/copilotdrive/ShowMeCCMR.pdf"
                  target="_blank"
                  className={classes.showMeButton}
                >
                  <HelpOutline className={classes.showMeIcon} />
                </Button>
              </TableCell>
              <UploadCell classes={classes} onClick={onUploadClick} />
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};
HelpTable.propTypes = {
  classes: PropTypes.any.isRequired,
  uploaded: PropTypes.shape({
    CAF2018: PropTypes.bool.isRequired,
    FYLI2017: PropTypes.bool.isRequired,
    FYLI2016: PropTypes.bool.isRequired,
    SYLI2015: PropTypes.bool.isRequired,
    CCMR2018: PropTypes.bool.isRequired,
  }).isRequired,
  onUploadClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(HelpTable);
