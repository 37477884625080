import { connect } from 'react-redux';

import Component from './SchoolSettings.component';
import { updateSettings } from '../+store/SchoolSettings/SchoolSettings.actions';
import { getDistrictOrSchoolData } from '../+store/SchoolSettings/SchoolSettings.actions';

const mapStateToProps = (state) => {
  const { assessmentList } = state.modules.Assessments.assessments;
  const isCCMRFileExists = !!assessmentList.find(
    (assessment) => assessment && assessment.type && assessment.type.includes('CCMR')
  );

  return {
    status: state.modules.Settings.schoolSettings.status,
    schoolList: state.modules.Settings.schoolSettings.schoolList,
    gradeSpanTable: state.modules.Settings.schoolSettings.gradeSpanTable,
    defaultSettings: state.modules.Settings.schoolSettings.defaultSettings,
    profile: state.modules.Auth.auth.profile,
    needsInit: state.modules.Settings.schoolSettings.needsInit,
    needsUpdate: state.modules.Settings.schoolSettings.needsUpdate,
    loadingMsg: state.modules.Settings.settings.loadingMsg,
    loading: !!state.modules.Settings.settings.loadingMsg,
    isCCMRFileExists,
    districtOrSchoolData: state.modules.Settings.schoolSettings.districtOrSchoolData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (profile, schoolId, settings) => {
    dispatch(updateSettings(profile, schoolId, settings));
  },
  getDistrictOrSchoolData: (data) => {
    dispatch(getDistrictOrSchoolData(data));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
