import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '../../Checkbox';

const styles = () => ({
  item: {
    width: 'auto',
    paddingLeft: 0,
  },
});

class FilterItem extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    item: PropTypes.object.isRequired,
    handleFilterOptionsChange: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
  };

  handleChecked = (e) => {
    const { handleFilterOptionsChange, onChange } = this.props;
    handleFilterOptionsChange(e.target.checked);
    if (onChange) onChange();
  };

  render() {
    const { item, selected, classes } = this.props;
    return (
      <ListItem color="primary" className={classes.item}>
        <Checkbox handleChange={this.handleChecked} status={selected} />
        <Typography color="primary">{item.label}</Typography>
      </ListItem>
    );
  }
}

export default withStyles(styles)(FilterItem);
