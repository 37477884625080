import { asyncStart } from '../../../common';

export const GET_ORG_USERS = 'ManageUsers.getOrganizationUsers';
export const getOrganizationUsers = () => (dispatch) => {
  dispatch(asyncStart(GET_ORG_USERS));
  dispatch({ type: GET_ORG_USERS });
};

export const GET_INVITED_USERS = 'ManageUsers.getInvitedUsers';
export const getInvitedUsers = () => (dispatch) => {
  dispatch(asyncStart(GET_INVITED_USERS));
  dispatch({ type: GET_INVITED_USERS });
};

export const INVITE_USER_TO_ORG = 'ManageUsers.inviteUser';
export const inviteUser = (userData) => (dispatch) => {
  dispatch(asyncStart(INVITE_USER_TO_ORG));
  dispatch({
    type: INVITE_USER_TO_ORG,
    userData,
  });
};

export const CANCEL_INVITE_USER = 'ManageUsers.cancelInvite';
export const cancelInvite = (signUpCode) => (dispatch) => {
  dispatch(asyncStart(CANCEL_INVITE_USER));
  dispatch({
    type: CANCEL_INVITE_USER,
    signUpCode,
  });
};

export const ACTIVATE_ACCOUNT = 'ManageUsers.activateAccount';
export const activateAccount = (userUid) => (dispatch) => {
  dispatch(asyncStart(ACTIVATE_ACCOUNT));
  dispatch({ type: ACTIVATE_ACCOUNT, userUid });
};

export const DEACTIVATE_ACCOUNT = 'ManageUsers.deactivateAccount';
export const deactivateAccount = (userUid) => (dispatch) => {
  dispatch(asyncStart(DEACTIVATE_ACCOUNT));
  dispatch({ userUid, type: DEACTIVATE_ACCOUNT });
};

export const RESET_USER_PASSWORD = 'ManageUsers.resetUserPassword';
export const resetUserPassword = (userUid, password) => (dispatch) => {
  dispatch(asyncStart(RESET_USER_PASSWORD));
  dispatch({
    userUid,
    password,
    type: RESET_USER_PASSWORD,
  });
};

export const UPDATE_USER_PROFILE = 'ManageUsers.updateUserProfile';
export const updateUserProfile = (userUid, userData) => (dispatch) => {
  dispatch(asyncStart(UPDATE_USER_PROFILE));
  dispatch({
    userUid,
    userData,
    type: UPDATE_USER_PROFILE,
  });
};

export const RESEND_INVITE_USER = 'ManageUsers.resendInvite';
export const resendInvite = (signUpCode) => (dispatch) => {
  dispatch(asyncStart(RESEND_INVITE_USER));
  dispatch({
    type: RESEND_INVITE_USER,
    signUpCode,
  });
};

export const Enable_ViewReports_For_All_Users = 'ManageUsers.enableViewReportsForAllUsers';
export const enableViewReportsForAllUsers = (val) => (dispatch) => {
  dispatch(asyncStart(Enable_ViewReports_For_All_Users));
  dispatch({
    type: Enable_ViewReports_For_All_Users,
    val,
  });
};
