import { combineReducers } from 'redux';
import moment from 'moment';
import {
  GET_FLIGHT_PLAN,
  UPDATE_FILTERS,
  GET_FILTER_FIELDS,
  UPDATE_DESIRED_SCORE,
  STORE_GROUP_BY_VALUE,
} from './flightPlan.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const loadingMsg = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_FLIGHT_PLAN) {
        return Object.assign({}, state, {
          report: 'Loading Flight Plan ...',
        });
      }
      if (action.model === GET_FILTER_FIELDS) {
        return Object.assign({}, state, {
          filters: 'Loading Filter Fields...',
        });
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_FLIGHT_PLAN) {
        return Object.assign({}, state, {
          report: null,
        });
      }
      if (action.model === GET_FILTER_FIELDS) {
        return Object.assign({}, state, {
          filters: null,
        });
      }
      return state;
    default:
      return state;
  }
};

const flightPlan = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_FLIGHT_PLAN && action.data.flightPlan) {
        return action.data.flightPlan.flightPlanTable || null;
      }
      return state;
    default:
      return state;
  }
};

const reportError = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_FLIGHT_PLAN) {
        return (action.data.flightPlan && action.data.flightPlan.reportError) || null;
      }
      return state;
    default:
      return state;
  }
};

const filterFields = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_FILTER_FIELDS) {
        return Object.assign({}, action.data.filterFields);
      }
      return state;
    default:
      return state;
  }
};

const filters = (
  state = {
    assessmentSource: [],
    school: null,
    subject: [],
    grade: [],
    teacher: [],
    studentGroup: [],
    schoolYear: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

const desiredAFScore = (state = 70, action) => {
  switch (action.type) {
    case UPDATE_DESIRED_SCORE:
      return action.score;
    case ASYNC_START:
    case ASYNC_ERROR:
      if (action.model === GET_FLIGHT_PLAN) {
        return 70;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === GET_FLIGHT_PLAN) {
        if (action.data.flightPlan && action.data.flightPlan.currentRoundedAF) {
          const current = action.data.flightPlan.currentRoundedAF;
          if (current < 60) {
            return 60;
          }
          if (current < 70) {
            return 70;
          }
          if (current < 80) {
            return 80;
          }
          if (current < 90) {
            return 90;
          }
          if (current < 100) {
            return 100;
          }
        }
      }
      return state;
    default:
      return state;
  }
};

const groupByValue = (state = null, action) => {
  switch (action.type) {
    case STORE_GROUP_BY_VALUE:
      return action.groupByValue;
    default:
      return state;
  }
};

const currentROOS = (state = 0, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_FLIGHT_PLAN && action.data.flightPlan) {
        return action.data.flightPlan.currentRoundedAF || 0;
      }
      return state;
    default:
      return state;
  }
};

const maxAFScore = (state = 100, action) => {
  switch (action.type) {
    case ASYNC_START:
    case ASYNC_ERROR:
      if (action.model === GET_FLIGHT_PLAN) {
        return 100;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === GET_FLIGHT_PLAN) {
        if (action.data.flightPlan && action.data.flightPlan.flightPlanTable) {
          const steps = action.data.flightPlan.flightPlanTable;
          return Math.max(...steps.map((s) => s.roundedROOS));
        }
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  loadingMsg,
  flightPlan,
  filters,
  filterFields,
  reportError,
  desiredAFScore,
  maxAFScore,
  groupByValue,
  currentROOS,
});

export default 'studentsPage.reducers.js';
