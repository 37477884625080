import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { asyncError, asyncFinish } from '../../../common/+store/index';
import { GET_ACCOUNTABILITY_WEIGHTS } from './accountabilityWeight.actions';
import { getAccountabilityWeight } from './accountabilityWeight.api';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';

const getAccountabilityWeightEpic = (action$) =>
  action$.pipe(
    ofType(GET_ACCOUNTABILITY_WEIGHTS),
    switchMap((action) =>
      from(
        getAccountabilityWeight({
          campusDistrictNumber: action.campusDistrictNumber,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Get Accountability Weights'))
    ),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinish(result.action.type, {
            weights: result.response.data,
          })
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const epics = combineEpics(getAccountabilityWeightEpic);
export default epics;
