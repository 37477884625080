// Mapping between tag names and css default values lookup tables. This allows to exclude default values in the result.
const defaultStylesByTagName = {};

// Styles inherited from style sheets will not be rendered for elements with these tag names
const noStyleTags = {
  BASE: true,
  HEAD: true,
  HTML: true,
  META: true,
  NOFRAME: true,
  NOSCRIPT: true,
  PARAM: true,
  SCRIPT: true,
  STYLE: true,
  TITLE: true,
};

// This list determines which css default values lookup tables are precomputed at load time
// Lookup tables for other tag names will be automatically built at runtime if needed
const tagNames = [
  'A',
  'ABBR',
  'ADDRESS',
  'AREA',
  'ARTICLE',
  'ASIDE',
  'AUDIO',
  'B',
  'BASE',
  'BDI',
  'BDO',
  'BLOCKQUOTE',
  'BODY',
  'BR',
  'BUTTON',
  'CANVAS',
  'CAPTION',
  'CENTER',
  'CITE',
  'CODE',
  'COL',
  'COLGROUP',
  'COMMAND',
  'DATALIST',
  'DD',
  'DEL',
  'DETAILS',
  'DFN',
  'DIV',
  'DL',
  'DT',
  'EM',
  'EMBED',
  'FIELDSET',
  'FIGCAPTION',
  'FIGURE',
  'FONT',
  'FOOTER',
  'FORM',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'HEAD',
  'HEADER',
  'HGROUP',
  'HR',
  'HTML',
  'I',
  'IFRAME',
  'IMG',
  'INPUT',
  'INS',
  'KBD',
  'KEYGEN',
  'LABEL',
  'LEGEND',
  'LI',
  'LINK',
  'MAP',
  'MARK',
  'MATH',
  'MENU',
  'META',
  'METER',
  'NAV',
  'NOBR',
  'NOSCRIPT',
  'OBJECT',
  'OL',
  'OPTION',
  'OPTGROUP',
  'OUTPUT',
  'P',
  'PARAM',
  'PRE',
  'PROGRESS',
  'Q',
  'RP',
  'RT',
  'RUBY',
  'S',
  'SAMP',
  'SCRIPT',
  'SECTION',
  'SELECT',
  'SMALL',
  'SOURCE',
  'SPAN',
  'STRONG',
  'STYLE',
  'SUB',
  'SUMMARY',
  'SUP',
  'SVG',
  'TABLE',
  'TBODY',
  'TD',
  'TEXTAREA',
  'TFOOT',
  'TH',
  'THEAD',
  'TIME',
  'TITLE',
  'TR',
  'TRACK',
  'U',
  'UL',
  'VAR',
  'VIDEO',
  'WBR',
];
const computeDefaultStyleByTagName = (tagName) => {
  const defaultStyle = {};
  const element = document.body.appendChild(document.createElement(tagName));
  const computedStyle = getComputedStyle(element);
  for (let i = 0; i < computedStyle.length; i++) {
    defaultStyle[computedStyle[i]] = computedStyle[computedStyle[i]];
  }
  document.body.removeChild(element);
  return defaultStyle;
};

const getDefaultStyleByTagName = (tag) => {
  const tagName = tag.toUpperCase();
  if (!defaultStylesByTagName[tagName]) {
    defaultStylesByTagName[tagName] = computeDefaultStyleByTagName(tagName);
  }
  return defaultStylesByTagName[tagName];
};

const realStyle = (_elem, _style) => {
  let computedStyle = null;
  if (typeof _elem.currentStyle !== 'undefined') {
    computedStyle = _elem.currentStyle;
  } else {
    computedStyle = document.defaultView.getComputedStyle(_elem, null);
  }

  return _style ? computedStyle[_style] : computedStyle;
};

const copyComputedStyle = (src, destination, recursively) => {
  destination.style.cssText = document.defaultView.getComputedStyle(src, '').cssText;

  if (recursively) {
    const vSrcElements = src.getElementsByTagName('*');
    const vDstElements = destination.getElementsByTagName('*');

    for (let i = vSrcElements.length; i--; ) {
      const vSrcElement = vSrcElements[i];
      const vDstElement = vDstElements[i];
      //          console.log(i + " >> " + vSrcElement + " :: " + vDstElement);
      vDstElement.style.cssText = document.defaultView.getComputedStyle(vSrcElement, '').cssText;
    }
  }
};

const serializeWithStyles = (elementToBeSerialized) => {
  // Precompute the lookup tables.
  for (let i = 0; i < tagNames.length; i++) {
    if (!noStyleTags[tagNames[i]]) {
      defaultStylesByTagName[tagNames[i]] = computeDefaultStyleByTagName(tagNames[i]);
    }
  }

  if (elementToBeSerialized.nodeType !== Node.ELEMENT_NODE) {
    throw new TypeError();
  }
  const cssTexts = [];
  const elements = elementToBeSerialized.querySelectorAll('*');
  for (let i = 0; i < elements.length; i++) {
    const e = elements[i];
    if (!noStyleTags[e.tagName]) {
      const computedStyle = getComputedStyle(e);
      const defaultStyle = getDefaultStyleByTagName(e.tagName);
      cssTexts[i] = e.style.cssText;
      // console.log(computedStyle);
      for (let ii = 0; ii < computedStyle.length; ii++) {
        const cssPropName = computedStyle[ii];
        if (computedStyle[cssPropName] !== defaultStyle[cssPropName]) {
          e.style[cssPropName] = computedStyle[cssPropName];
        }
      }
    }
    e.style['font-family'] = 'sans-serif';
  }
  const result = elementToBeSerialized.outerHTML;
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.cssText = cssTexts[i];
  }
  return result;
};

export { serializeWithStyles, copyComputedStyle };
