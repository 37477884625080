export const ReportSectionKeys = {
  d1staar: 'd1staar',
  d1ccmr: 'd1ccmr',
  d1gradRate: 'd1gradRate',
  d2a: 'd2a',
  d2b: 'd2b',
  d3: 'd3',
  summary: 'summary',
  summaryDetails: 'summaryDetails',
  campusesWeights: 'campusesWeights',
};

export const ReportSections = {
  d1staar: 'Student Achievement: STAAR Performance',
  d1ccmr: 'Student Achievement: College, Career and Military Readiness',
  d1gradRate: 'Student Achievment: Graduation Rate',
  d2a: 'School Progress: Academic Growth',
  d2b: 'School Progress: Relative Performance',
  d3: 'Closing the Gaps',
  summary: 'Summary',
  summaryDetails: 'Summary Details',
  campusesWeights: 'Campuses Weights',
};

export const ReportSectionsForFreeUsers = {
  summary: 'Summary',
  summaryDetails: 'Summary Details',
};

export const Indicators = {
  all: 'All Student Groups',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  lep: 'EL (Current & Monitored)',
  specialEdFormer: 'Special Ed (Former)',
  continuouslyEnrolled: 'Continuously Enrolled',
  highFocus: 'High Focus',
  highlyMobile: 'Highly Mobile',
  acceleratedTesterMath: 'Accelerated Tester Math',
  acceleratedTesterReading: 'Accelerated Tester Reading',
  acceleratedTesterScience: 'Accelerated Tester Science',
};

export const JobNames = {
  aToF: 'aToF',
  flightPlan: 'flightPlan',
  testsOnly: 'testsOnly',
  filterFieldsOnly: 'filterFieldsOnly',
};

export const Subjects = {
  algebraOne: 'Algebra I',
  biology: 'Biology',
  englishOne: 'English I',
  englishTwo: 'English II',
  math: 'Mathematics',
  reading: 'Reading',
  science: 'Science',
  socialStudies: 'Social Studies',
  usHistory: 'US History',
  writing: 'Writing',
};

export const Grades = {
  1: '1st Grade',
  2: '2nd Grade',
  3: '3rd Grade',
  4: '4th Grade',
  5: '5th Grade',
  6: '6th Grade',
  7: '7th Grade',
  8: '8th Grade',
  9: '9th Grade',
  10: '10th Grade',
  11: '11th Grade',
  12: '12th Grade',
  13: '13th Grade',
  EOC: 'EOC',
};

export default 'aToF.constants.js';
