import { combineEpics, ofType } from 'redux-observable';
import { switchMap, filter, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { asyncFinish, asyncError } from '../../../common/+store/index';
import { GET_REPORT, GET_FILTER_FIELDS, GET_STUDENTS_LIST } from './aToF.actions';
import { getAToFAndFlightPlan, getStudentsListApi } from './aToF.api';
import { AssessmentTypes } from '../../../Assessments/+store/assessments/assessments.types';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import { JobNames } from './aToF.constants';
import { GET_FLIGHT_PLAN } from '../../../FlightPlan/+store/flightPlan';

const isInterim = (assessmentsInfo) => {
  let result = false;
  assessmentsInfo.forEach((a) => {
    if ([AssessmentTypes.Interim, AssessmentTypes.ComboAssessment, AssessmentTypes.DMACInterim].includes(a.type)) {
      result = true;
    }
  });
  return result;
};

const getStudentsListEpic = (action$) =>
  action$.pipe(
    ofType(GET_STUDENTS_LIST),
    switchMap((action) =>
      from(getStudentsListApi(action.orgId, action.path)).pipe(
        commonApiCallFragment(action$, action, 'Get Filter Fields')
      )
    ),
    switchMap((result) => {
      if (result.success) {
        console.log(result);
        return of(
          asyncFinish(result.action.type, {
            students: result.response,
          })
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const getReportEpic = (action$) =>
  action$.pipe(
    filter((action) => [GET_REPORT, GET_FLIGHT_PLAN].includes(action.type)),
    switchMap((action) =>
      from(
        getAToFAndFlightPlan({
          schoolYear: action.schoolYear,
          assessmentsInfo: action.assessmentsInfo,
          isInterim: isInterim(action.assessmentsInfo) || false,
          campusDistrictNumber: action.campusDistrictNumber,
          filters: action.filters,
          option: action.option,
          // jobName: JobNames.aToF,
          jobName: action.jobName,
          ignoreCache: action.ignoreCache,
          ignoreTests: action.ignoreTests,
          debug: action.debug,
          returnStudentLists: action.jobName === JobNames.aToF,
        })
      ).pipe(
        commonApiCallFragment(action$, action, 'getAToFAndFlightPlan', {
          action,
        }),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'getAToFAndFlightPlan', error),
          })
        )
      )
    ),
    switchMap((result) => {
      if (result.success) {
        const { jobName } = result.action;
        if (jobName === JobNames.aToF) {
          var returnedData = result && result.response && result.response.data ? result.response.data : null;
          return of(
            asyncFinish(result.action.type, {
              errorMsg: returnedData && returnedData.errorMsg,
              errorName: returnedData && returnedData.errorName,
              jobName: result.action.jobName,
              message: returnedData && returnedData.msg,
              report: returnedData && returnedData.report,
              allCampusReports: returnedData && returnedData.allCampusReports,
              districtOrCampusName: returnedData && returnedData.districtOrCampusName,
              districtOrCampusNumber: returnedData && returnedData.districtOrCampusNumber,
              schoolYear: returnedData && returnedData.schoolYear,
              reportError: returnedData && returnedData.reportError,
              filterFields: returnedData && returnedData.filterFields,
              originalAction: result.action,
            })
          );
        }
        if (jobName === JobNames.flightPlan) {
          var returnedData = result && result.response && result.response.data ? result.response.data : null;
          return of(
            asyncFinish(result.action.type, {
              errorMsg: returnedData.errorMsg,
              errorName: returnedData.errorName,
              jobName: result.action.jobName,
              flightPlan: returnedData,
              reportError: returnedData.reportError,
              filterFields: returnedData.filterFields,
              originalAction: result.action,
            })
          );
        }
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const getFilterFieldsEpic = (action$) =>
  action$.pipe(
    ofType(GET_FILTER_FIELDS),
    switchMap((action) =>
      from(
        getAToFAndFlightPlan({
          schoolYear: action.schoolYear,
          assessmentsInfo: action.assessmentsInfo,
          isInterim: isInterim(action.assessmentsInfo),
          campusDistrictNumber: action.campusDistrictNumber,
          filters: action.filters,
          jobName: JobNames.filterFieldsOnly,
        })
      ).pipe(commonApiCallFragment(action$, action, 'Get Filter Fields'))
    ),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinish(result.action.type, {
            message: result.response.data.msg,
            filterFields: result.response.data.filterFields,
          })
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const epics = combineEpics(getReportEpic, getFilterFieldsEpic, getStudentsListEpic);
export default epics;
