import { connect } from 'react-redux';
import Component from './App.component';
import { authInit, authTeardown } from '../Auth';
import { doRedirect } from './+store/routes/routes.actions';
import { firebaseInit } from './+store/firebase/firebase.actions';

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.modules.Auth.auth.user,
  user: state.modules.Auth.auth.user,
  profile: state.modules.Auth.auth.profile,
  redirect: state.modules.App.routes.redirect,
});

const mapDispatchToProps = (dispatch) => ({
  appInit: () => {
    dispatch(firebaseInit());
    dispatch(authInit());
  },
  appTeardown: () => {
    dispatch(authTeardown());
  },
  doRedirect: (history, to) => {
    dispatch(doRedirect(history, to));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
