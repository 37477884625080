import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Table, TableBody, Grid, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { LoadingCircle } from '../common';
import styles from './CCMRTracking.styles';
import CCMRTrackingHeader from './CCMRTrackingHeader';
import CCMRTrackingStudent from './CCMRTrackingStudent';
import CCMRBulkEntry from './CCMRBulkEntry';
import ScoreBoard from './ScoreBoard';

class CCMRTracking extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    init: PropTypes.func.isRequired,
    getSchools: PropTypes.func.isRequired,
    getCCMRTrackingData: PropTypes.func.isRequired,
    classes: PropTypes.any,
    students: PropTypes.object,
    ccmrTrackingData: PropTypes.object,
    filterBoxParams: PropTypes.object,
    loading: PropTypes.bool,
    loadingMsg: PropTypes.string,
    loadingRoster: PropTypes.bool,
    updateCCMRTrackingData: PropTypes.func.isRequired,
    schoolList: PropTypes.object,
    avatars: PropTypes.object,
    selectCCMRKey: PropTypes.func.isRequired,
    bulkEntryKey: PropTypes.string,
    ignoreCache: PropTypes.bool,
    updateIsMonitorFilter: PropTypes.func,
  };

  state = {
    checked: false,
    isLoadedBefore: false,
    items: 20,
    loadingState: false,
    expandReading: false,
    expandMath: false,
    isMonitorFilter: null,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  toggleReadingColumns = () => {
    this.setState((prevState) => ({ expandReading: !prevState.expandReading }));
  };

  toggleMathColumns = () => {
    this.setState((prevState) => ({ expandMath: !prevState.expandMath }));
  };

  handleEditStateChange = () => {
    this.setState((prevState) => ({ checked: !prevState.checked }));
  };

  handleIsMonitorStateChange = () => {
    this.setState((prevState) => ({ isMonitorFilter: !prevState.isMonitorFilter }));
    this.props.updateIsMonitorFilter(!this.state.isMonitorFilter);
  };

  shouldInit = () => {
    const { profile, init, getSchools, getCCMRTrackingData, ignoreCache } = this.props;
    const { isLoadedBefore } = this.state;
    if (profile && !isLoadedBefore) {
      this.setState({ isLoadedBefore: true });
      init(profile);
      getSchools(profile);
      getCCMRTrackingData(profile, ignoreCache);
    }
  };

  showMore = (steps, event) => {
    const node = event.currentTarget;
    if (node) {
      node.addEventListener(
        'scroll',
        () => {
          if (node.scrollTop + node.clientHeight >= node.scrollHeight - 60) {
            this.loadMoreItems(steps);
          }
        },
        true
      );
    }
  };

  loadMoreItems(steps) {
    const { loadingState, items } = this.state;
    if (loadingState) {
      return;
    }
    const numStudents = Object.entries(steps).length;
    if (items < numStudents) {
      this.setState({ loadingState: true });
      setTimeout(() => {
        this.setState({ items: items + 10, loadingState: false });
      }, 1000);
    } else {
      this.setState({ loadingState: false });
    }
  }
  getSchoolName(students, schoolList, firebaseId) {
    let schoolName = '';
    if (students != null && schoolList != null && firebaseId != null) {
      let student = students[firebaseId];
      if (student && student.campusNumber) {
        let school = schoolList[student.campusNumber];
        if (school) {
          schoolName = school.name;
          return schoolName;
        }
      }
      console.log('error in school name for student firbaeId');
      console.log(firebaseId);
    }
  }

  render = () => {
    const {
      classes,
      students,
      filterBoxParams,
      profile,
      loading,
      loadingRoster,
      loadingMsg,
      updateCCMRTrackingData,
      ccmrTrackingData,
      schoolList,
      avatars,
      selectCCMRKey,
      bulkEntryKey,
    } = this.props;
    const { checked, items, loadingState, expandMath, expandReading } = this.state;

    if (!avatars || !profile || loadingRoster || loading) {
      return (
        <div className={classes.content}>
          <LoadingCircle classes={classes} msg={loadingMsg} />
        </div>
      );
    }

    const isEmpty = !students || Object.keys(students).length === 0;
    const doesGradStudentsExist = !ccmrTrackingData || Object.keys(ccmrTrackingData).length === 0;
    return (
      <div className={classes.root}>
        <ScoreBoard
          ccmrTrackingData={ccmrTrackingData}
          filterBoxParams={filterBoxParams}
          handleEditStateChange={this.handleEditStateChange}
          handleIsMonitorStateChange={this.handleIsMonitorStateChange}
          isEditing={checked}
        />
        {bulkEntryKey && <CCMRBulkEntry />}
        {isEmpty && <div>Please upload a roster for this school year to use the CCMR Tracker</div>}
        {doesGradStudentsExist && (
          <div>
            No student found with these criteria. Try selecting different filters or uploading a different roster.
          </div>
        )}
        {!isEmpty && !doesGradStudentsExist && (
          <div className={classes.tableContainer} onScroll={(event) => this.showMore(ccmrTrackingData, event)}>
            <CCMRTrackingHeader
              editable={checked}
              selectCCMRKey={selectCCMRKey}
              expandMath={expandMath}
              expandReading={expandReading}
              toggleMathColumns={this.toggleMathColumns}
              toggleReadingColumns={this.toggleReadingColumns}
              isEditing={checked}
            />
            <div className={classes.tableBody}>
              {Object.entries(ccmrTrackingData)
                .slice(0, items)
                .map(
                  ([firebaseId, ccmrData], index) =>
                    ccmrData &&
                    students[firebaseId] && (
                      <CCMRTrackingStudent
                        avatars={avatars}
                        key={index}
                        profile={profile}
                        editable={checked}
                        firebaseId={firebaseId}
                        student={students[firebaseId]}
                        ccmrData={ccmrData}
                        updateCCMRTrackingData={updateCCMRTrackingData}
                        expandMath={expandMath}
                        expandReading={expandReading}
                        school={this.getSchoolName(students, schoolList, firebaseId)}
                      />
                    )
                )}
              <div style={{ height: 60, position: 'sticky', left: 0 }}>
                {loadingState ? <p className="loading">Loading more students...</p> : null}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
}

export default withStyles(styles)(CCMRTracking);
