import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import {
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import CustomButton from '../../common/Button/Button.component';

const styles = (theme) => {};

class CircularSelect extends React.PureComponent {
  static propTypes = {
    selectedOption: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  handleSelectNext = () => {
    const { selectedOption, options, onSelect } = this.props;
    const currentIndex = options.findIndex((o) => o.value === selectedOption);
    const nextIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;
    onSelect(options[nextIndex].value);
  };

  handleSelectPrev = () => {
    const { selectedOption, options, onSelect } = this.props;
    const currentIndex = options.findIndex((o) => o.value === selectedOption);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
    onSelect(options[prevIndex].value);
  };

  render() {
    const { selectedOption, options, onSelect } = this.props;
    const currentIndex = options.findIndex((o) => o.value === selectedOption);
    const firstCurrentOptionsIndex = currentIndex - 2 >= 0 ? currentIndex - 2 : 0;
    const lastCurrentOptionsIndex =
      firstCurrentOptionsIndex + 6 <= options.length ? firstCurrentOptionsIndex + 6 : options.length;
    const currentOptions = options.slice(firstCurrentOptionsIndex, lastCurrentOptionsIndex);
    return (
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        {firstCurrentOptionsIndex !== 0 && (
          <CustomButton noMargin onClick={this.handleSelectPrev}>
            <NavigateBefore />
          </CustomButton>
        )}
        {currentOptions.map((option) => (
          <CustomButton
            noMargin
            key={option.value}
            color={option.value === selectedOption ? 'outlined' : 'transparent'}
            onClick={() => onSelect(option.value)}
          >
            {option.label}
          </CustomButton>
        ))}
        {lastCurrentOptionsIndex !== options.length && (
          <CustomButton noMargin onClick={this.handleSelectNext}>
            <NavigateNext />
          </CustomButton>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(CircularSelect);
