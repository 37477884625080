import { connect } from 'react-redux';
import Component from './PrivateRoute.component';

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.modules.Auth.auth.user,
  notSure: !!state.modules.Auth.auth.loadingMsg,
  loadingMsg: state.modules.Auth.auth.loadingMsg,
});

const mapDispatchToProps = () => ({});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
