import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: '0.6875rem',
    marginRight: '0.6875rem',
    fontSize: '1.125rem',
  },
  backButton: {
    fontSize: '1.75rem',
  },
});

class TitleBackButton extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
  };

  render() {
    const { classes, title, children } = this.props;
    return (
      <div className={classes.root}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <div className={classes.container}>
            <ArrowBack color="primary" className={classes.backButton} />
            <Typography align="center" variant="h1" className={classes.title}>
              {title}
            </Typography>
          </div>
        </Link>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(TitleBackButton);
