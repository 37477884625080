import * as React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, Typography, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import classNames from 'classnames';

const styles = (theme) => ({
  inputFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing.unit,
  },
  inputFieldRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputField: {
    minWidth: '370px',
    paddingLeft: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    height: theme.spacing.unit * 4,
    background: theme.palette.white,
    border: `1px solid ${theme.palette.white}`,
    color: theme.palette.primary.main,
    fontFamily: ['"Questrial"', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
      backgroundColor: theme.palette.white,
    },
  },
  inputFieldError: {
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.dark,
  },
  label: {
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
  },
  labelError: {
    color: theme.palette.error.dark,
  },
  errorMsg: {
    color: theme.palette.error.dark,
    fontSize: '0.875rem',
    marginLeft: theme.spacing.unit,
  },
});

class SelectInputField extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    error: PropTypes.string,
    touched: PropTypes.bool,
    inputExtraRowSize: PropTypes.number,
    inputExtraContainerSize: PropTypes.number,
    flexDirection: PropTypes.string,
    className: PropTypes.string,
    multi: PropTypes.bool,
    accessValueName: PropTypes.string,
    accessLabelName: PropTypes.string,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    touched: false,
    error: null,
    inputExtraRowSize: 0,
    inputExtraContainerSize: 0,
    accessValueName: 'value',
    accessLabelName: 'label',
    isDisabled: false,
  };

  render() {
    const {
      classes,
      label,
      id,
      touched,
      error,
      options,
      inputExtraRowSize,
      inputExtraContainerSize,
      flexDirection,
      className,
      multi,
      accessValueName,
      accessLabelName,
      isDisabled,
    } = this.props;

    const defaultInputField = label ? 470 : 370;
    const defaultContainerSize = label ? 660 : 600;
    const inputRowSize = defaultInputField + inputExtraRowSize;
    const inputContainerSize = defaultContainerSize + inputExtraContainerSize;
    const styleDynamic = { flexDirection };
    if (flexDirection !== 'column') {
      styleDynamic.width = `${inputContainerSize}px`;
    }

    const isError = !!error && touched;
    const dynamicMultiProps = {};
    if (multi) {
      dynamicMultiProps.renderValue = (selected) =>
        !selected || !selected.length
          ? 'Select an option'
          : selected
              .map((selectedOption) => {
                const option = options.find((option) => option[accessValueName] === selectedOption);
                return option ? option[accessLabelName] : null;
              })
              .filter((c) => c)
              .join(', ');
    }

    return (
      <div className={classNames(classes.inputFieldContainer, className)} style={styleDynamic}>
        <div className={classes.inputFieldRow} style={{ width: `${inputRowSize}px` }}>
          <Typography className={classNames(classes.label, { [classes.labelError]: isError })}>{label}</Typography>
          <Field
            name={id}
            id={id}
            render={({ field }) => (
              <Select
                {...field}
                disableUnderline
                className={classNames(classes.inputField, { [classes.inputFieldError]: isError })}
                displayEmpty
                multiple={multi}
                {...dynamicMultiProps}
                disabled={isDisabled}
              >
                <MenuItem value="" disabled>
                  Select an option
                </MenuItem>
                {options.map((option, index) => (
                  <MenuItem key={index} value={option[accessValueName]}>
                    {multi && (
                      <Checkbox
                        disableRipple
                        color="primary"
                        checked={field.value.indexOf(option[accessValueName]) > -1}
                      />
                    )}
                    {option[accessLabelName]}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
        {isError && <Typography className={classes.errorMsg}>{error}</Typography>}
      </div>
    );
  }
}

export default withStyles(styles)(SelectInputField);
