import commoneStyles from '../CCMRCommon/CCMRCommon.styles';

const styles = (theme) => ({
  ...commoneStyles(theme),
  studentRoot: {
    display: 'flex',
    height: 60,
  },
  rowCell: {
    padding: 8,
    textAlign: 'center',
    color: '#656565',
    fontSize: '15px',
    lineHeight: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    height: '100%',
    flexShrink: 0,
    width: 70,
    borderBottom: '1px solid rgba(115, 115, 115, 0.15)',
  },
  rowSticky: {
    position: 'sticky',
    zIndex: 11,
    background: '#f3feff',
  },
  rowStickySafari: {
    position: '-webkit-sticky',
  },
  tableButton: {
    minWidth: 0,
    width: '100%',
  },
});

export default styles;
