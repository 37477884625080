import { getSchool, getSchoolsInDistrict, getDistrict } from './constants.api';
import { db } from '../../../App/+store/firebase/firebase.actions';
import { getMultipleCampusData, isMultipleCampusAdmin } from '../../utils';

export const getUser = async (firebaseId) =>
  db
    .ref(`newDatabase/users/${firebaseId}`)
    .once('value')
    .then((snapshot) => snapshot.val());

export async function createUser(firebaseId, user) {
  if (!user.organizationId) {
    let campuses = null;
    let district = null;
    let districtNumber = null;
    if (user.role === 'campusAdmin') {
      if (isMultipleCampusAdmin(user)) {
        user.campusNumber.map(async (campusNumber) => {
          const campus = await getSchool(campusNumber);
          campuses[user.campusNumber] = campus;
          districtNumber = campus.districtNumber;
        });
      } else {
        const campus = await getSchool(user.campusNumber);
        districtNumber = campus.districtNumber;
        campuses = {
          [user.campusNumber]: campus,
        };
      }
      user.districtNumber = districtNumber;
      district = await getDistrict(user.districtNumber);
    } else {
      district = await getDistrict(user.districtNumber);
      campuses = await getSchoolsInDistrict(user.districtNumber);
    }
    const orgId = await db.ref('newDatabase/organizations/').push().key;
    user.organizationId = orgId;
    await db.ref(`newDatabase/users/${firebaseId}`).set(user);
    await db.ref(`newDatabase/organizations/${orgId}`).update({
      metadata: {
        userType: 'firstClass',
      },
      districtNumber: user.districtNumber,
      schools: campuses,
      district,
    });
  } else {
    await db.ref(`newDatabase/users/${firebaseId}`).set(user);
  }
  return firebaseId;
}

export async function updateUser(user, firebaseId) {
  if (user.uid === firebaseId) {
    return db.ref(`newDatabase/users/${firebaseId}`).update(user);
  }
  return null;
}

export async function addRoster(roster, user) {
  const orgId = user.organizationId;
  const owner = user.role === 'campusAdmin' ? user.campusNumber : 'district';
  return db.ref(`newDatabase/organizations/${orgId}/files/rosters`).update({
    [owner]: roster,
  });
}

export async function addAssessmentSet(assessmentSet, assessmentSetKey, user) {
  console.log(assessmentSet, assessmentSetKey, user);
  const orgId = user.organizationId;
  if (user.role === 'campusAdmin') {
    assessmentSet.owner = user.campusNumber;
  } else {
    assessmentSet.owner = 'district';
  }
  const fileKeys = Object.keys(assessmentSet.files);
  fileKeys.forEach((fileName) => (assessmentSet.files[fileName].ownerId = user.uid));
  return db.ref(`newDatabase/organizations/${orgId}/files/assessmentSets/${assessmentSetKey}`).update(assessmentSet);
}

export async function getStudents(user, year, campusNumber = null) {
  let students = null;
  const orgId = user.organizationId;
  if (user.role === 'campusAdmin') {
    if (isMultipleCampusAdmin(user)) {
      const multiCampusStudents = await getMultipleCampusData(
        user,
        `newDatabase/organizations/${orgId}/students/${year}`
      );
      students = {};
      Object.values(multiCampusStudents).map((campusStudents) => {
        students = { ...students, ...campusStudents };
      });
    } else {
      students = await db
        .ref(`newDatabase/organizations/${orgId}/students/${year}`)
        .orderByChild('campusNumber')
        .equalTo(user.campusNumber)
        .once('value')
        .then((snapshot) => snapshot.val());
    }
  } else if (campusNumber) {
    students = await db
      .ref(`newDatabase/organizations/${orgId}/students/${year}`)
      .orderByChild('campusNumber')
      .equalTo(campusNumber)
      .once('value')
      .then((snapshot) => snapshot.val());
  } else {
    students = await db
      .ref(`newDatabase/organizations/${orgId}/students/${year}`)
      .once('value')
      .then((snapshot) => snapshot.val());
  }
  return students;
}

function getUnknownStudentId(firstName, lastName, unknownId, rosterStudents) {
  let firebaseId = null;
  const variables = ['studentId', 'localId', 'tsdsId'];
  let count = variables.length - 1;
  while (!firebaseId && count >= 0) {
    const [id] = Object.entries(rosterStudents).find(
      ([firebaseId, s]) =>
        s[variables[count]] === unknownId &&
        firstName.toLowerCase() === s.firstName.toLowerCase() &&
        lastName.toLowerCase() === s.lastName.toLowerCase()
    );
    firebaseId = id;
    count -= 1;
  }
  return firebaseId;
}

function doesStudentExist(student, students) {
  let firebaseId = null;
  const variables = ['studentId', 'localId', 'tsdsId'];
  let count = variables.length - 1;
  while (!firebaseId && count >= 0) {
    const [id] = Object.entries(students).find(([firebaseId, s]) => s[variables[count]] === student[variables[count]]);
    firebaseId = id;
    count -= 1;
  }
  return firebaseId;
}

export async function addAssessmentDataStudents(user, students) {
  /*
   *  user: Object
   *  students: list of Student Object
   * */
  const orgId = user.organizationId;
  return db.ref(`newDatabase/organizations/${orgId}/assessmentData/students`).update(students);
}

export default 'database.api.js';
