import { combineReducers } from 'redux';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';
import {
  GET_PROGRESS_GOALS,
  UPDATE_FILTERS,
  APPLY_FILTERS,
  RESET_FILTERS,
  EXPORT_PROGRESS_GOALS,
} from './progressGoals.actions';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_PROGRESS_GOALS && !action.data.nextToken) {
        return 'Loading Progress Goals ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_PROGRESS_GOALS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingMoreMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_PROGRESS_GOALS && action.data.nextToken) {
        return 'Loading More ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_PROGRESS_GOALS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const errorMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === GET_PROGRESS_GOALS) {
        if (action.error && action.error.code === 'internal') {
          return 'An error occurred';
        }
        return action.error || 'An error occurred';
      }
      return state;
    case ASYNC_START:
      if (action.model === GET_PROGRESS_GOALS) {
        return '';
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === GET_PROGRESS_GOALS) {
        if (action.data.data.code) {
          return action.data.data.message;
        }
        return '';
      }
      return state;
    default:
      return state;
  }
};

const progressGoals = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_PROGRESS_GOALS && action.data && action.data.data && action.data.data.goals) {
        if (action.data.action.nextToken) {
          return {
            ...state,
            ...action.data.data.goals,
          };
        }
        return action.data.data.goals || {};
      }
      if (action.model === GET_PROGRESS_GOALS && action.data && action.data.data) {
        if (action.data.action.nextToken) {
          return state;
        }
        return null;
      }
      return state;
    default:
      return state;
  }
};

const nextToken = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_PROGRESS_GOALS && action.data && action.data.data) {
        return action.data.data.nextToken || null;
      }
      return state;
    default:
      return state;
  }
};

export const initialPgFilters = {
  grade: '',
  teacher: '',
  ethnicity: '',
  campus: '',
  sortBy: '',
  ecoDis: '',
  specialEdCurrent: '',
  specialEdFormer: '',
  lepCurrent: '',
  lepMonitored: '',
  continuouslyEnrolled: '',
  nonContinuouslyEnrolled: '',
};
const filters = (state = initialPgFilters, action) => {
  switch (action.type) {
    case APPLY_FILTERS:
      return action.filters;
    case RESET_FILTERS:
      return initialPgFilters;
    default:
      return state;
  }
};

const stagedFilters = (state = initialPgFilters, action) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return {
        ...state,
        ...action.filter,
      };
    case RESET_FILTERS:
      return initialPgFilters;
    default:
      return state;
  }
};

const progressGoalsCSV = (state = null, action) => {
  if (action.type === ASYNC_FINISH && action.model === EXPORT_PROGRESS_GOALS) {
    return action.data.data;
  }
  if (action.type === ASYNC_START) {
    return null;
  }
  return state;
};

const loadingCSV = (state = false, action) => {
  if (action.model === EXPORT_PROGRESS_GOALS) {
    return action.type === ASYNC_START;
  }
  return state;
};

export const _reducer = combineReducers({
  loadingMsg,
  errorMsg,
  progressGoals,
  loadingMoreMsg,
  nextToken,
  filters,
  stagedFilters,
  progressGoalsCSV,
  loadingCSV,
});

export default 'progressGoals.reducers.js';
