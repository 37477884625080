import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  withStyles,
  IconButton,
  Divider,
  Modal,
  Icon,
} from '@material-ui/core';
import * as Yup from 'yup';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ethnicityOption,
  elOptions,
  enrollmentOptions,
  spEdOptions,
  yearsInUsOptions,
  yesNoOptions,
  studentInfoLabels,
  testSubjects,
  testVersions,
  languages,
  sexOptions,
  gradeOptions,
} from '../+store/studentRoster/studentRoster.utils';
import { Button, Dropzone, LoadingCircle } from '../../common';

const styles = (theme) => ({
  button: {
    marginLeft: 0,
  },
  subtitle: {
    marginTop: 3 * theme.spacing.unit,
  },
  deleteIcon: {
    height: `${theme.spacing.unit * 6}px`,
    alignSelf: 'center',
  },
  profilePicture: {
    height: 200,
    width: 200,
    borderRadius: 110,
  },
  editModal: {
    position: 'absolute',
    width: theme.spacing.unit * 70,
    background: 'linear-gradient(0deg, rgba(175, 228, 236, 0.65) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;',
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 3,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    maxHeight: theme.spacing.unit * 90,
  },
  dropzone: {
    border: '1px solid #656565',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  dropzoneWrapper: {
    height: '300px',
    marginBottom: '36px',
  },
  dropzoneText: {
    fontSize: '24px',
    color: '#a9a9a9',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  pictureButton: {
    backgroundColor: 'transparent',
    border: 'none',
    height: 200,
    width: 200,
    padding: 0,
    borderRadius: 100,
    margin: 0,
    marginRight: 15,
    marginTop: 15,
    position: 'relative',
    '&:hover': {
      background: 'transparent',
    },
  },
  pictureButtonOverlay: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    height: 200,
    width: 200,
    borderRadius: 100,
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },
  noIcon: {
    color: '#656565',
    fontSize: 100,
    height: '200px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    border: '1px solid #656565',
  },
  uploadLoading: {
    padding: 15,
  },
  uploadLoadingMsg: {
    marginTop: 5,
  },
  autoCompleteRoot: {
    width: 500,
    '& > * + *': {
      marginTop: 3,
    },
  },
});

class StudentForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    student: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleChangePP: PropTypes.func.isRequired,
    handlePPEditClose: PropTypes.func.isRequired,
    isProfilePictureModalOpen: PropTypes.bool,
    profile: PropTypes.object,
    onDrop: PropTypes.func.isRequired,
    uploadPP: PropTypes.bool,
    uploadPPMsg: PropTypes.string,
    studentAvatar: PropTypes.string,
    tags: PropTypes.object,
  };
  Object;
  state = {
    picHover: false,
  };

  renderField = (fieldName, isDisabled = false) => (
    <Grid
      key={fieldName}
      container
      direction="column"
      justify="space-between"
      alignItems="flex-start"
      item
      xs={12}
      sm={4}
    >
      <Field
        key={fieldName}
        name={fieldName}
        render={({ field, form: { errors } }) => (
          <TextField
            {...field}
            id={fieldName}
            label={studentInfoLabels[fieldName]}
            margin="normal"
            error={!!errors[fieldName]}
            disabled={isDisabled}
          />
        )}
      />
      <Typography variant="caption" gutterBottom color="error" align="left" style={{ paddingRight: '16px' }}>
        <ErrorMessage key={`err${fieldName}`} name={fieldName} />
      </Typography>
    </Grid>
  );

  renderFieldWithLabel = (fieldName, isDisabled = false) => (
    <Grid container spacing={24} key={fieldName} style={{ textAlign: 'left' }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" gutterBottom>
          {studentInfoLabels[fieldName]}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          key={fieldName}
          name={fieldName}
          render={({ field, form: { errors } }) => (
            <TextField {...field} id={fieldName} margin="normal" error={!!errors[fieldName]} disabled={isDisabled} />
          )}
        />
        <Typography variant="caption" gutterBottom color="error" align="left" style={{ paddingRight: '16px' }}>
          <ErrorMessage key={`err${fieldName}`} name={fieldName} />
        </Typography>
      </Grid>
    </Grid>
  );

  renderSelectField = (fieldName, options) => (
    <Grid container spacing={24} key={fieldName} style={{ textAlign: 'left' }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" gutterBottom>
          {studentInfoLabels[fieldName]}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={fieldName}
          render={({ field }) => (
            <Select {...field}>
              {Object.entries(options).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
    </Grid>
  );

  renderMultiCheckboxField = (fieldName, list, selectedItems) => {
    const { classes } = this.props;
    let listEntries = [];
    if (list) {
      // listEntries = Object.entries(list);
      listEntries = Object.entries(list).sort(([, a], [, b]) => {
        return ('' + a.name).localeCompare(b.name);
      });
      // .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    }
    return (
      <Grid container spacing={24} key={fieldName} style={{ textAlign: 'left' }}>
        {listEntries == null ||
          (listEntries.length == 0 && <div>If tags is not loaded,please add them to the organization.</div>)}
        {listEntries && listEntries.length > 0 && (
          <FieldArray
            name={fieldName}
            render={(arrayHelpers) => (
              <div>
                {listEntries.map((item) => (
                  <label key={item[0]}>
                    <input
                      name={fieldName}
                      type="checkbox"
                      value={item[0]}
                      checked={selectedItems && selectedItems.includes(item[0])}
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(item[0]);
                        } else {
                          if (selectedItems) {
                            const idx = selectedItems.indexOf(item[0]);
                            arrayHelpers.remove(idx);
                          }
                        }
                      }}
                    />
                    <span>{item[1].name}</span>
                  </label>
                ))}
              </div>
            )}
          />
        )}
      </Grid>
    );
  };

  renderTests = (values) => {
    const grades = ['3', '4', '5', '6', '7', '8'];
    const elemSubjects = ['reading', 'math', 'socialStudies', 'science']; //, 'writing'
    const { classes } = this.props;
    return (
      <FieldArray
        name="tests"
        render={(arrayHelpers) => (
          <Grid container direction="column" justify="center" alignItems="flex-start">
            {values.tests.map((test, index) => (
              <Grid container spacing={24} key={index} style={{ textAlign: 'left' }}>
                <Grid item xs={6} sm={3}>
                  <Field
                    key={`tests.${index}.teacher`}
                    name={`tests.${index}.teacher`}
                    render={({ field }) => (
                      <TextField {...field} id={`tests.${index}.teacher`} label="Teacher's Name/ID" margin="normal" />
                    )}
                  />
                </Grid>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                  key={`tests.${index}.subjectContainer`}
                  item
                  xs={6}
                  sm={2}
                  style={{ textAlign: 'left' }}
                >
                  <Typography variant="caption">Test Subject</Typography>
                  <Field
                    name={`tests.${index}.subject`}
                    render={({ field }) => (
                      <Select {...field} displayEmpty>
                        <MenuItem value="" disabled>
                          Subject
                        </MenuItem>
                        {Object.entries(testSubjects).map(([key, value]) => (
                          <MenuItem key={`tests.${index}.subject.${key}`} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                  key={`tests.${index}.testVersionContainer`}
                  item
                  xs={4}
                  sm={2}
                  style={{ textAlign: 'left' }}
                >
                  <Typography variant="caption">Test Version</Typography>
                  <Field
                    name={`tests.${index}.testVersion`}
                    render={({ field }) => (
                      <Select {...field} displayEmpty>
                        <MenuItem value="" disabled>
                          Select a version
                        </MenuItem>
                        {Object.entries(testVersions).map(([key, value]) => (
                          <MenuItem key={`tests.${index}.testVersion.${key}`} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                  key={`tests.${index}.languageContainer`}
                  item
                  xs={4}
                  sm={2}
                  style={{ textAlign: 'left' }}
                >
                  <Typography variant="caption">Test Language</Typography>
                  <Field
                    name={`tests.${index}.language`}
                    render={({ field }) => (
                      <Select {...field} displayEmpty>
                        <MenuItem value="" disabled>
                          Select a language
                        </MenuItem>
                        {Object.entries(languages).map(([key, value]) => (
                          <MenuItem key={`tests.${index}.language.${key}`} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                {elemSubjects.includes(test.subject) && (
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    key={`tests.${index}.testedGradeContainer`}
                    item
                    xs={4}
                    sm={2}
                    style={{ textAlign: 'left' }}
                  >
                    <Typography variant="caption">Tested Grade</Typography>
                    <Field
                      name={`tests.${index}.testedGrade`}
                      render={({ field }) => (
                        <Select {...field} displayEmpty>
                          <MenuItem value="" disabled>
                            Select tested grade
                          </MenuItem>
                          {grades.map((grade) => (
                            <MenuItem key={`tests.${index}.testedGrade.${grade}`} value={grade}>
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Grid>
                )}
                <IconButton onClick={() => arrayHelpers.remove(index)} className={classes.deleteIcon} disableRipple>
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            ))}
            {values.tests.length < 5 && (
              <Button
                color="light"
                className={classes.button}
                onClick={() =>
                  arrayHelpers.push({
                    subject: '',
                    teacher: '',
                    language: 'english',
                    testVersion: 'staar',
                    testedGrade: '',
                  })
                }
              >
                Add New Test
              </Button>
            )}
          </Grid>
        )}
      />
    );
  };

  picOnMouseEnter = () => {
    this.setState({
      picHover: true,
    });
  };

  picOnMouseLeave = () => {
    this.setState({
      picHover: false,
    });
  };

  renderProfilePicture = () => {
    const { classes, handleChangePP, student, studentAvatar } = this.props;
    const { picHover } = this.state;
    const photoUrl = student && student.photoUrl && student.photoUrl.big ? student.photoUrl.big : null;
    return (
      <Grid
        key="profilepicture"
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        margin="normal"
        item
        xs={12}
        sm={4}
      >
        <Button onClick={handleChangePP} color="dark" className={classes.pictureButton}>
          <div onMouseEnter={this.picOnMouseEnter} onMouseLeave={this.picOnMouseLeave}>
            <div className={classes.pictureButtonOverlay} style={{ display: picHover ? 'flex' : 'none' }}>
              Upload Photo
            </div>
            {photoUrl || studentAvatar ? (
              <img src={photoUrl || studentAvatar} alt="Logo" className={classes.profilePicture} />
            ) : (
              <Icon className={classes.noIcon}>person</Icon>
            )}
          </div>
        </Button>
      </Grid>
    );
  };

  onSelectFromComputerClick = () => {
    this.dropZoneRef.open();
  };

  onDrop = (accepted, rejected) => {
    const { onDrop, profile } = this.props;
    if (rejected && rejected.length) {
      this.setState({
        errorMsgs: rejected.map((f) => `${f.name} could not be processed`),
      });
    }
    if (accepted && accepted.length) {
      onDrop(accepted, profile);
    }
  };

  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  renderEditModal = (student) => {
    const { handlePPEditClose, classes, uploadPP, uploadPPMsg } = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={handlePPEditClose}
      >
        <div className={classes.editModal}>
          <Typography variant="h6" id="modal-title">
            {`Change Profile Picture ${student.firstName} ${student.lastName} (
            ${student.studentId || student.tsdsId || student.localId})`}
          </Typography>
          {uploadPP ? (
            <div className={classes.root}>
              <div className={classes.uploadLoading}>
                <LoadingCircle classes={classes} />
                <div className={classes.uploadLoadingMsg}>{uploadPPMsg}</div>
              </div>
            </div>
          ) : (
            <Dropzone
              disableClick
              accept={['image/png', 'image/jpeg']}
              ref={this.saveDropZoneRef}
              onDrop={this.onDrop}
              multiple={false}
              style={{ height: 300 }}
            >
              <div className={classes.dropzone}>
                <div className={classes.dropzoneText}>Drag your photo here or</div>
                <Button variant="contained" color="dark" onClick={this.onSelectFromComputerClick}>
                  Select from Computer
                </Button>
              </div>
            </Dropzone>
          )}
        </div>
      </Modal>
    );
  };

  render() {
    const StudentSchema = Yup.object().shape({
      studentId: Yup.string().matches(
        /^(S|[0-8]).+$/,
        'Invalid Format: The first ' + 'character must be "S" or “0"-"8"'
      ),
      tsdsId: Yup.string().matches(
        /^[1-9][0-9]{9}$/,
        'Invalid Format: Field must ' + 'contain 10 digits and not start with zero.'
      ),
      localId: Yup.string()
        .matches(/^[0-9]+$/, 'Invalid Format: Field must contain digits only. ')
        .max(9, 'Field can have 9 digits at most.'),
      firstName: Yup.string().matches(
        /^[a-zA-Z\-'`’ ]{1,30}$/,
        'Invalid Format: This is the student’s first name and can be from 1 to 10\n' +
          'alphabetic characters. Do not use periods. Do not use any\n' +
          'punctuation other than a hyphen, a space, or a single\n' +
          'apostrophe'
      ),
      middleName: Yup.string().matches(
        /^[a-zA-Z\-'`’ ]{0,30}$/,
        'Invalid Format: This is the student’s middle name and can be from 1 to 10\n' +
          'alphabetic characters. Do not use periods. Do not use any\n' +
          'punctuation other than a hyphen, a space, or a single\n' +
          'apostrophe'
      ),
      lastName: Yup.string().matches(
        /^[a-zA-Z\-'`’ ]{1,30}$/,
        'Invalid Format: This is the student’s last name and can be from 1 to 20\n' +
          'alphabetic characters. Do not use periods. Do not use any\n' +
          'punctuation other than a hyphen, a space, or a single\n' +
          'apostrophe'
      ),
      campusNumber: Yup.string().matches(/^[0-9]{9}$/, 'Field must contain 9 digits.'),
    });
    const { student, handleSubmit, handleCancel, classes, isProfilePictureModalOpen, profile, tags } = this.props;
    return (
      <div>
        <Formik initialValues={student} validationSchema={StudentSchema} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <Typography variant="h6" color="primary" align="left" gutterBottom className={classes.subtitle}>
                Student Information
              </Typography>
              <Divider variant="fullWidth" />
              {isProfilePictureModalOpen ? this.renderEditModal(values) : ''}
              <div style={{ display: 'flex' }}>
                {this.renderProfilePicture()}
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  wrap="wrap"
                  style={{ marginBottom: '24px' }}
                >
                  {['studentId', 'tsdsId', 'localId', 'firstName', 'middleName', 'lastName'].map((field) =>
                    this.renderField(field)
                  )}
                  {this.renderField('campusNumber', profile.role !== 'districtAdmin')}
                  <Grid
                    key="gradYear"
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="flex-start"
                    item
                    xs={12}
                    sm={4}
                  >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        margin="normal"
                        openTo="year"
                        views={['year', 'month']}
                        label="Graduation Date"
                        value={values.gradYear}
                        labelFunc={(date) => (date ? date.format('MM/YYYY') : '')}
                        onChange={(e) => setFieldValue('gradYear', e)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    key="dateOfBirth"
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="flex-start"
                    item
                    xs={12}
                    sm={4}
                  >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        margin="normal"
                        label="Date of Birth"
                        value={values.dateOfBirth}
                        labelFunc={(date) => (date ? date.format('MM/DD/YYYY') : '')}
                        onChange={(e) => setFieldValue('dateOfBirth', e)}
                        maxDate={moment()}
                        disableFuture
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </div>
              {this.renderSelectField('gradeLevel', gradeOptions)}
              {this.renderSelectField('sexCode', sexOptions)}
              {this.renderSelectField('ethnicity', ethnicityOption)}
              {this.renderSelectField('ecoDis', yesNoOptions)}
              {this.renderSelectField('highlyMobile', yesNoOptions)}
              {this.renderSelectField('acceleratedTesterMath', yesNoOptions)}
              {this.renderSelectField('acceleratedTesterReading', yesNoOptions)}
              {this.renderSelectField('acceleratedTesterScience', yesNoOptions)}
              {this.renderSelectField('monitor', yesNoOptions)}
              {this.renderSelectField('spEd', spEdOptions)}
              {this.renderSelectField('lep', elOptions)}
              {this.renderSelectField('enrollment', enrollmentOptions)}
              {this.renderSelectField('snapshot', yesNoOptions)}
              {this.renderSelectField('yearsInUS', yearsInUsOptions)}
              {this.renderSelectField('asylumStatus', yesNoOptions)}
              {this.renderFieldWithLabel('mentor')}
              <Typography variant="h6" color="primary" align="left" gutterBottom className={classes.subtitle}>
                Current Tests
              </Typography>
              <Divider variant="fullWidth" />
              {this.renderTests(values)}
              <Typography variant="h6" color="primary" align="left" gutterBottom className={classes.subtitle}>
                Tags
              </Typography>
              {this.renderMultiCheckboxField('tags', tags, values.tags)}
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <Button submit color="dark" className={classes.button}>
                  Save Student
                </Button>
                <Button color="outlined" onClick={handleCancel} className={classes.button}>
                  Cancel
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withStyles(styles)(StudentForm);
