import { connect } from 'react-redux';

import Component from './SignIn.component';
import { signIn, unsetForgetPassword } from '../+store/auth/auth.actions';

const mapStateToProps = (state) => ({
  state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  handleSignIn: (values) => {
    dispatch(signIn(values, ownProps.history));
  },
  unset: () => {
    dispatch(unsetForgetPassword());
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);

export default Container;
