import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthLayout from '../AuthLayout';
import TextInputField from '../TextInputField';
import { Button } from '../../common/Button';
import { Routes } from '../../App';

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  password: Yup.string().required('Please enter your password.'),
});

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '210px',
    zIndex: 10,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '370px',
    alignSelf: 'flex-start',
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    marginRight: 0,
    textTransform: 'capitalize',
  },
  forgotPassLink: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    marginBottom: theme.spacing.unit,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  signUpLink: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  capsLockError: {
    color: theme.palette.error.dark,
    fontSize: '0.875rem',
    marginLeft: theme.spacing.unit,
  },
});

class SignIn extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    handleSignIn: PropTypes.func.isRequired,
    unset: PropTypes.func.isRequired,
  };

  state = {
    passwordFocused: false,
    showPassword: false,
  };

  componentDidMount() {
    this.props.unset();
  }

  handlePasswordFocused = () => {
    this.setState({ passwordFocused: true });
  };

  handlePasswordBlurred = () => {
    this.setState({ passwordFocused: false });
  };

  handleToggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { classes, handleSignIn } = this.props;
    const { passwordFocused, showPassword } = this.state;
    return (
      <AuthLayout title="Sign In">
        <Formik
          validateOnBlur
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={SignInSchema}
          onSubmit={handleSignIn}
        >
          {({ errors, touched, setFieldTouched }) => (
            <Form className={classes.form}>
              <TextInputField
                id="email"
                type="email"
                touched={touched.email}
                error={errors.email}
                autoComplete="email"
                leftIcon={{ name: 'email' }}
              />
              <TextInputField
                id="password"
                type={showPassword ? 'text' : 'password'}
                touched={touched.password}
                error={errors.password}
                autoComplete="new-password"
                handleFocus={this.handlePasswordFocused}
                handleBlur={this.handlePasswordBlurred}
                setFieldTouched={setFieldTouched}
                rightIcon={{
                  name: showPassword ? 'visibility_off' : 'visibility',
                  onClick: this.handleToggleShowPassword,
                  isDisabled: true,
                }}
                leftIcon={{ name: 'lock' }}
              >
                <ReactIsCapsLockActive>
                  {(active) => (
                    <div className={classes.capsLockError}>
                      {active && passwordFocused && !errors.password ? 'Caps Lock is on' : ''}
                    </div>
                  )}
                </ReactIsCapsLockActive>
              </TextInputField>
              <div className={classes.buttonRow}>
                <div>
                  <Link key="forgot-password" to={`/${Routes.forgotPassword}`} style={{ textDecoration: 'none' }}>
                    <Typography className={classes.forgotPassLink}>Forgot Password?</Typography>
                  </Link>
                  {/* <Link key="sign-up" to={`/${Routes.signUp}`} style={{ textDecoration: 'none' }}>
                    <Typography className={classes.signUpLink}>Sign Up</Typography>
                  </Link> */}
                </div>
                <Button submit large color="white" className={classes.button}>
                  {' '}
                  Sign In{' '}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    );
  }
}

export default withStyles(styles)(SignIn);
