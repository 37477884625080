import { combineReducers } from 'redux';
import { REDIRECT } from './routes.actions';
import { ASYNC_FINISH, ASYNC_START } from '../../../common';
import { AUTH_SIGN_UP } from '../../../Auth';
import { Routes } from '../../App.routes';

const redirect = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === REDIRECT) {
        return action.data.to;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === REDIRECT) {
        return '';
      }
      if (action.model === AUTH_SIGN_UP) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  redirect,
});

export default 'studentsPage.reducers.js';
