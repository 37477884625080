import parse from 'csv-parse';

const interimColumns = [
  'Student ID',
  'TSDS Unique ID',
  'Local ID',
  'Test Subject',
  'Grade Level Tested',
  'Language',
  'Score',
];
const isInterim = (file) =>
  new Promise((resolve) => {
    if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      resolve(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      parse(text, {}, (err, output) => {
        if (err) {
          resolve(false);
          return;
        }
        if (JSON.stringify(output[0].filter((x) => x)) === JSON.stringify(interimColumns)) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    };
    reader.readAsText(file);
  });

export default isInterim;
