import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { _reducer as auth, _epics as authEpics } from './auth';

export const reducer = combineReducers({
  auth,
});

export const epics = combineEpics(authEpics);

export * from './auth/auth.actions';
