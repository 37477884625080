import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableBody } from '@material-ui/core';
import { CustomTableRow, CustomTableCell, RowLabelCell } from '../Common/CustomTableComponents';
import EmptyCols from '../Common/EmptyCols';
import SubComponentRows from '../Common/SubComponentRows';
import { Indicators } from '../../+store/aToF';
import TotalIndicator from '../Common/TotalIndicator';

const styles = (theme) => ({
  root: {
    // background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(249, 128, 29, 0.6);',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F9801D;',
  },
  body: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F9801D;',
  },
  footer: {
    // background: '#C4C4C4;',
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
});

const indicatorsLength = Object.keys(Indicators).length;

const totalsColumns = {
  pointsEarned: 'Component Points Earned',
  PointsPossible: 'Component Points Possible',
  percentScore: 'Component Percent Score',
};
const totalsColumnsLength = Object.keys(totalsColumns).length;

const scoreColumns = {
  weight: 'Weight',
  score: 'Score',
};
const scoreColumnsLength = Object.keys(scoreColumns).length;

const Ccmr = ({ classes, component, schoolTypeConversion, showAllTargets }) => {
  if (!component) return null;
  return (
    <Table className={classes.root}>
      <TableHead>
        <CustomTableRow>
          <CustomTableCell />
          {Object.entries(Indicators).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
          {Object.entries(totalsColumns).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
          {Object.entries(scoreColumns).map(([key, label]) => (
            <CustomTableCell key={key}>{label}</CustomTableCell>
          ))}
        </CustomTableRow>
      </TableHead>
      <TableBody>
        <CustomTableRow>
          <RowLabelCell>College, Career, and Military Readiness Performance (CCMR)</RowLabelCell>
          <EmptyCols length={indicatorsLength + totalsColumnsLength + scoreColumnsLength} />
        </CustomTableRow>
        <SubComponentRows
          label="CCMR"
          totalsColumnsLength={totalsColumnsLength}
          scoreColumnsLength={scoreColumnsLength}
          counts={component.numerator}
          totals={component.denominator}
          percents={component.rate}
          targets={component.targets}
          totalIndicators={component.totalIndicators}
          eligibles={component.eligible}
          metTargets={component.metTarget}
          schoolTypeConversion={schoolTypeConversion}
          componentIndicators={component.componentIndicators}
          showAllTargets={showAllTargets}
        />
      </TableBody>
      <TotalIndicator
        footerClass={classes.footer}
        title="CCMR Total"
        indicatorsLength={indicatorsLength}
        totalIndicators={component.totalIndicators}
      />
    </Table>
  );
};
Ccmr.propTypes = {
  component: PropTypes.object,
  classes: PropTypes.any,
  schoolTypeConversion: PropTypes.string,
  showAllTargets: PropTypes.bool,
};

export default withStyles(styles)(Ccmr);
