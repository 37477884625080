import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon, Table, TableCell, TableRow, TableBody } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import { getStudentAvatarUrl } from '../../../StudentRoster/+store/studentRoster';

const styles = (theme) => ({
  root: {
    textAlign: 'left',
    width: '100%',
  },
  desc: {
    minWidth: '135px',
    textAlign: 'center',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    float: 'right',
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  description: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
    marginRight: 5,
  },
  success: {
    color: theme.palette.success.main,
    marginRight: 5,
  },
  textValueContainer: {
    width: '100%',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  trueFalseValueContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

const textValues = {
  campusName: 'Campus',
  gradeLevel: 'Grade Level',
  ethnicity: 'Ethnicity',
  localId: 'Local ID',
  studentId: 'Student ID',
};

const trueFalseValues = {
  contEnrl: 'Continuously Enrolled',
  econ: 'Economically Disadvantaged',
  spedFsped: 'Special Education (Current/Former)',
  el: 'English Learner',
};

class StudentDemographics extends React.PureComponent {
  static propTypes = {
    student: PropTypes.any.isRequired,
    classes: PropTypes.any,
    avatars: PropTypes.object,
    isExpanded: PropTypes.bool.isRequired,
    setIsExpanded: PropTypes.func.isRequired,
  };

  adapt = (label) => {
    if (label === undefined || label === null) return '-';
    if (typeof label === 'boolean') {
      return label ? 'Yes' : 'No';
    }
    return label;
  };

  renderDemographics = () => {
    const { student, classes } = this.props;
    console.log('student', student);
    return (
      <div className={classes.wrapper}>
        {Object.entries(textValues).map(([key, label], index) => (
          <div key={index} className={classes.textValueContainer}>
            <div className={classes.title}>{label}</div>
            <div className={classes.description}>{this.adapt(student[key])}</div>
          </div>
        ))}
        {Object.entries(trueFalseValues).map(([key, label], index) => (
          <div key={index} className={classes.trueFalseValueContainer}>
            {student[key] ? <CheckCircleIcon className={classes.success} /> : <ErrorIcon className={classes.error} />}
            <div className={classes.title}>{label}</div>
          </div>
        ))}
      </div>
    );
  };

  getStudentProfilePicture = (photoUrl) => {
    const { student, avatars, setIsExpanded } = this.props;
    const avatarUrl = getStudentAvatarUrl(student, avatars);
    const studentPhotoUrl = photoUrl || avatarUrl;
    if (studentPhotoUrl) {
      return (
        <img
          src={studentPhotoUrl}
          alt="Logo"
          style={{
            height: '30px',
            width: '30px',
            borderRadius: '15px',
            flexShrink: 0,
          }}
        />
      );
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  };

  render() {
    const { student, classes, isExpanded, setIsExpanded } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.name}>
          {this.getStudentProfilePicture(student.photoUrl)}
          <div style={{ textAlign: 'left', width: '100%', paddingLeft: 16 }}>{student.name}</div>
          <Button size="small" color="primary" onClick={setIsExpanded} className={classes.button}>
            <Icon>{isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
          </Button>
        </div>
        {isExpanded && this.renderDemographics()}
      </div>
    );
  }
}

export default withStyles(styles)(StudentDemographics);
