import { connect } from 'react-redux';

import Component from './AppHeaderFooter.component';
import { signOut } from '../Auth/+store/auth';

const mapStateToProps = (state) => ({
  user: state.modules.Auth.auth.user,
  profile: state.modules.Auth.auth.profile,
  notSure: !!state.modules.Auth.auth.loadingMsg,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: (user) => {
    localStorage.clear();
    dispatch(signOut(user));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
