import * as React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { getSchoolYearsList } from '../../common/+store/utils';
import {
  Typography,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Grid,
  IconButton,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AssessmentTypes } from '..';
import DeleteModal from '../DeleteModal';

export const styles = (theme) => ({
  dropzone: {
    border: '1px solid #656565',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  tableWrapper: {
    marginBottom: '24px',
    background: 'unset',
  },
  headerGrid: {
    alignItems: 'center',
  },
  button: {
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  form: {
    width: '95%',
    padding: theme.spacing.unit * 3,
  },
  tableCell: {
    padding: '4px 24px',
    textTransform: 'capitalize',
  },
  tableCellOption: {
    padding: 0,
  },
});

const AssessmentOption = (props) => {
  const { option, setFieldValue, field, assessments } = props;
  const handleChange = (e) => {
    if (e.target.checked) {
      setFieldValue(field, [...assessments, option.value]);
    } else {
      setFieldValue(
        field,
        assessments.filter((e) => e !== option.value)
      );
    }
  };
  return (
    <MenuItem key={option.value} value={option.value}>
      <Checkbox style={{ color: '#60b9c5' }} checked={assessments.includes(option.value)} onChange={handleChange} />
      <ListItemText primary={option.label} />
    </MenuItem>
  );
};

class AssessmentGroupManager extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    needsInit: PropTypes.bool.isRequired,
    assessmentList: PropTypes.array.isRequired,
    onCreateAssessmentGroupClick: PropTypes.func.isRequired,
    getAssessmentGroups: PropTypes.func.isRequired,
    assessmentGroups: PropTypes.object.isRequired,
    assessmentGroupCreateOpen: PropTypes.bool.isRequired,
    handleAddAssessmentGroup: PropTypes.func.isRequired,
    handleEditAssessmentGroup: PropTypes.func.isRequired,
    handleGroupDelete: PropTypes.func.isRequired,
    canDelete: PropTypes.func.isRequired,
    renderOwner: PropTypes.func.isRequired,
    onAssessmentGroupVisibilityBySuperAdminOnlyChange: PropTypes.func.isRequired,
  };

  state = {
    errorMsgs: [],
    isDeleteModalOpen: false,
    groupId: null,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate(prevProps, prevState) {
    const { assessmentGroups, assessmentGroupCreateOpen } = this.props;
    this.shouldInit();
    if (
      (prevState.isDeleteModalOpen && !(prevState.groupId in assessmentGroups)) ||
      (prevProps.assessmentGroupCreateOpen && !assessmentGroupCreateOpen)
    ) {
      this.setState({
        isDeleteModalOpen: false,
        groupId: null,
      });
    }
  }

  deleteIcon = (assessmentGroupId, assessmentGroup) => {
    const { canDelete } = this.props;
    const isActive = canDelete(assessmentGroup.owner);
    return (
      <IconButton onClick={this.deleteButtonClicked(assessmentGroupId)} disabled={!isActive}>
        <DeleteIcon color={isActive ? 'error' : 'disabled'} />
      </IconButton>
    );
  };

  editIcon = (assessmentGroupId, assessmentGroup) => {
    const { canDelete } = this.props;
    const isActive = canDelete(assessmentGroup.owner);
    return (
      <IconButton
        style={{ marginLeft: '3px' }}
        onClick={this.editButtonClicked(assessmentGroupId)}
        disabled={!isActive}
      >
        <EditIcon color={isActive ? 'primary' : 'disabled'} />
      </IconButton>
    );
  };

  deleteButtonClicked = (groupId) => () => {
    this.setState({
      isDeleteModalOpen: true,
      groupId,
    });
  };

  editButtonClicked = (groupId) => () => {
    this.props.onCreateAssessmentGroupClick();
    this.setState({ groupId });
  };

  handleDelete = (assessmentGroup) => () => {
    const { profile, handleGroupDelete } = this.props;
    handleGroupDelete(profile, assessmentGroup);
  };

  cancelDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
      groupId: null,
    });
  };

  getInterimAssessment = (assessmentList, startDate, endDate) =>
    assessmentList
      .filter((a) => {
        if ([AssessmentTypes.ComboAssessment, AssessmentTypes.Interim, AssessmentTypes.DMACInterim].includes(a.type)) {
          if (
            moment(a.administrationDate).isBefore(moment(endDate)) &&
            moment(a.administrationDate).isAfter(moment(startDate))
          ) {
            return true;
          }
        }
        return false;
      })
      .map((source) => ({
        value: source.id,
        label: `${source.name} (${moment(source.date).format('D MMM YYYY')})`,
      }));

  getYearAssessmentSources = (schoolYear) => {
    const { assessmentList } = this.props;
    let assessmentSources = [];
    if (schoolYear === '2020') {
      assessmentSources = this.getInterimAssessment(assessmentList, '2019-07-01', '2020-07-01');
    } else if (schoolYear === '2019') {
      const interimAssessments = this.getInterimAssessment(assessmentList, '2018-07-01', '2019-07-01');
      assessmentSources = assessmentList
        .filter((a) =>
          [
            AssessmentTypes.STAAR2019,
            AssessmentTypes.STAARALT2019,
            AssessmentTypes.TELPAS2019,
            AssessmentTypes.TELPASALT2019,
            AssessmentTypes.EOC2019,
            AssessmentTypes.EOCALT2019,
          ].includes(a.type)
        )
        .map((source) => ({
          value: source.id,
          label: source.name,
        }));
      assessmentSources.concat(interimAssessments);
    } else if (schoolYear === '2021') {
      const interimAssessments = this.getInterimAssessment(assessmentList, '2019-07-01', '2021-07-01');
      assessmentSources = assessmentList
        .filter((a) =>
          [
            AssessmentTypes.STAAR2021,
            AssessmentTypes.STAARALT2021,
            AssessmentTypes.TELPAS2021,
            AssessmentTypes.TELPASALT2021,
            AssessmentTypes.EOC2021,
            AssessmentTypes.EOCALT2021,
          ].includes(a.type)
        )
        .map((source) => ({
          value: source.id,
          label: source.name,
        }));
      assessmentSources.concat(interimAssessments);
    } else {
      const interimAssessments = this.getInterimAssessment(
        assessmentList,
        schoolYear - 1 + '-07-01',
        schoolYear + '-07-01'
      );
      assessmentSources = assessmentList
        .filter((a) =>
          [
            AssessmentTypes.STAAR + schoolYear,
            AssessmentTypes.STAARALT + schoolYear,
            AssessmentTypes.TELPAS + schoolYear,
            AssessmentTypes.TELPASALT + schoolYear,
            AssessmentTypes.EOC + schoolYear,
            AssessmentTypes.EOCALT + schoolYear,
          ].includes(a.type)
        )
        .map((source) => ({
          value: source.id,
          label: source.name,
        }));
      assessmentSources.concat(interimAssessments);
    }
    return assessmentSources;
  };

  shouldInit() {
    const { profile, needsInit, loading, getAssessmentGroups } = this.props;
    if (profile && needsInit && !loading) {
      getAssessmentGroups(profile);
    }
  }

  handleAssessmentGroupVisibilityBySuperAdminOnlyChange = async (assessmentGroup, assessmentGroupId, isChecked) => {
    const { profile, onAssessmentGroupVisibilityBySuperAdminOnlyChange } = this.props;
    try {
      assessmentGroup.id = assessmentGroupId;
      assessmentGroup.isVisibleBySuperAdminOnly = isChecked;
      onAssessmentGroupVisibilityBySuperAdminOnlyChange(profile, assessmentGroup);
    } catch (error) {
      console.error('Error on change assessment visibility', error);
    }
  };

  renderField = (label, fieldName, helperText = null, options = null, disabled) => (
    <Grid container spacing={24} key={fieldName}>
      <Grid key={fieldName} container direction="column" justify="space-between" alignItems="flex-start" item>
        {options ? (
          <Field
            name={fieldName}
            render={({ field, form: { errors, touched } }) => (
              <TextField
                {...field}
                disabled={disabled}
                select
                label={label}
                helperText={!!errors[fieldName] && touched[fieldName] ? null : helperText}
                margin="normal"
                variant="outlined"
                error={!!errors[fieldName] && touched[fieldName]}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ) : (
          <Field
            key={fieldName}
            name={fieldName}
            render={({ field, form: { errors, touched } }) => (
              <TextField
                variant="outlined"
                {...field}
                disabled={disabled}
                id={fieldName}
                label={label}
                margin="normal"
                helperText={!!errors[fieldName] && touched[fieldName] ? null : helperText}
                error={!!errors[fieldName] && touched[fieldName]}
              />
            )}
          />
        )}
        <Typography variant="caption" gutterBottom color="error" align="left" style={{ paddingRight: '16px' }}>
          <ErrorMessage key={`err${fieldName}`} name={fieldName} />
        </Typography>
      </Grid>
    </Grid>
  );

  renderCreateGroupModal = () => {
    const {
      classes,
      onCloseAssessmentGroupClick,
      handleAddAssessmentGroup,
      profile,
      assessmentGroups,
      handleEditAssessmentGroup,
    } = this.props;
    const { groupId } = this.state;
    const currentGroup = groupId in assessmentGroups ? assessmentGroups[groupId] : null;
    const currentGroupName = currentGroup ? currentGroup.name : null;
    const groupNames = Object.values(assessmentGroups)
      .map((group) => group.name.trim())
      .filter((name) => name !== currentGroupName);
    const initialValues = currentGroup || {
      year: '',
      name: '',
      assessments: [],
    };

    const AssessmentGroupSchema = Yup.object().shape({
      name: Yup.string()
        .trim()
        .required('This field is required.')
        .notOneOf(groupNames, 'An assessment group with this name already exists.'),
      year: Yup.string().required('This field is required.'),
      assessments: Yup.array().required('Select at least one assessment.'),
    });
    const schoolYears = getSchoolYearsList();

    // const schoolYears = [
    //   {
    //     value: '2019',
    //     label: '2018-2019',
    //   },
    //   {
    //     value: '2020',
    //     label: '2019-2020',
    //   },
    //   {
    //     value: '2021',
    //     label: '2020-2021',
    //   },
    //   {
    //     value: '2022',
    //     label: '2021-2022',
    //   },
    // ];

    return (
      <React.Fragment>
        <Button onClick={onCloseAssessmentGroupClick}>
          <Icon className={classes.leftIcon}> chevron_left </Icon>
          Back
        </Button>
        <div className={classes.dropzone}>
          <Formik
            initialValues={initialValues}
            validationSchema={AssessmentGroupSchema}
            onSubmit={(values) =>
              groupId ? handleEditAssessmentGroup(profile, groupId, values) : handleAddAssessmentGroup(profile, values)
            }
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form className={classes.form}>
                <Grid container alignItems="flex-start" direction="row">
                  <Grid item xs={12} sm={6}>
                    <Typography variant="button" gutterBottom>
                      {`${groupId ? 'Update' : 'Create'} Assessments Group`}
                    </Typography>
                    {this.renderField('Year', 'year', 'The Assessment Group Year', schoolYears, !!groupId)}
                    {values.year.length
                      ? this.renderField('Group Name', 'name', 'A Unique Assessment Group Name')
                      : null}
                    {values.year.length ? (
                      <Button type="submit" variant="contained" color="primary" className={classes.button}>
                        {groupId ? 'Update Group' : 'Create Group'}
                      </Button>
                    ) : null}
                  </Grid>
                  {values.year.length ? (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        Assessments List
                      </Typography>
                      {this.getYearAssessmentSources(values.year).map((option) => (
                        <AssessmentOption
                          key={option.value}
                          option={option}
                          setFieldValue={setFieldValue}
                          field="assessments"
                          assessments={values.assessments}
                        />
                      ))}
                      {this.getYearAssessmentSources(values.year).length === 0 && (
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="error"
                          align="left"
                          style={{ paddingRight: '16px' }}
                        >
                          {`None available for ${values.year}.`}
                        </Typography>
                      )}
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="error"
                        align="left"
                        style={{ paddingRight: '16px' }}
                      >
                        <ErrorMessage key="errAssessmentList" name="assessments" />
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  };

  renderAssessmentGroups = () => {
    const {
      profile,
      classes,
      assessmentGroups,
      onCreateAssessmentGroupClick,
      assessmentList,
      renderOwner,
    } = this.props;
    const groups = Object.entries(assessmentGroups);

    return (
      <React.Fragment>
        <Grid container className={classes.headerGrid}>
          <Grid item xs>
            <Typography> Assessment Groups </Typography>
          </Grid>
          <Button variant="contained" color="primary" className={classes.button} onClick={onCreateAssessmentGroupClick}>
            Create Assessment Group <Icon className={classes.rightIcon}> playlist_add </Icon>
          </Button>
        </Grid>
        {groups.length ? (
          <Paper className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center"> Options </TableCell>
                  <TableCell className={classes.tableCell} style={{ width: '30%' }} align="left">
                    Group Name
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Created By
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Assessments
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Year
                  </TableCell>
                  {profile && (profile.type == 'superAdmin' || profile.isSuperAdmin == true) && (
                    <TableCell className={classes.tableCell} align="center">
                      Super Admin Only
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map(([firebaseId, assessmentGroup]) => {
                  let assessmentListNames = [];
                  if (assessmentGroup && assessmentGroup.assessments) {
                    assessmentListNames = assessmentGroup.assessments
                      .map((assessmentId) => {
                        const curAssessment = assessmentList.find((a) => a.id === assessmentId);
                        return curAssessment ? curAssessment.name : null;
                      })
                      .filter((a) => !!a);
                  }
                  return (
                    <TableRow key={firebaseId}>
                      <TableCell className={classes.tableCellOption}>
                        {this.deleteIcon(firebaseId, assessmentGroup)} {this.editIcon(firebaseId, assessmentGroup)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <Typography> {assessmentGroup.name} </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <Typography> {renderOwner(assessmentGroup.owner)} </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {assessmentListNames.length ? (
                          assessmentListNames.map((a, index) => <Typography key={index}> {a} </Typography>)
                        ) : (
                          <Typography> - </Typography>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Typography> {assessmentGroup.year} </Typography>
                      </TableCell>

                      {profile && (profile.type == 'superAdmin' || profile.isSuperAdmin == true) && (
                        <TableCell className={classes.tableCell} align="center">
                          <Typography>
                            <Checkbox
                              checked={assessmentGroup.isVisibleBySuperAdminOnly == true}
                              onChange={(e) =>
                                this.handleAssessmentGroupVisibilityBySuperAdminOnlyChange(
                                  assessmentGroup,
                                  firebaseId,
                                  e.target.checked
                                )
                              }
                              color="primary"
                            />
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Typography> No Assessment Groups Created Yet! </Typography>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { assessmentGroupCreateOpen, assessmentGroups } = this.props;
    const { groupId, isDeleteModalOpen } = this.state;
    return (
      <React.Fragment>
        <DeleteModal
          open={isDeleteModalOpen}
          assessment={groupId ? assessmentGroups[groupId] : null}
          cancelDelete={this.cancelDelete}
          doDelete={this.handleDelete}
          groupId={groupId}
          isGroup
        />
        {assessmentGroupCreateOpen ? this.renderCreateGroupModal() : this.renderAssessmentGroups()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AssessmentGroupManager);
