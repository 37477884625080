import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  mathColumns,
  readingColumns,
  headerColumns,
  editableHeaderColumns,
  didGraduate,
  calcStudentCCMRPoint,
} from '../+store';
// import { CustomTableCell } from '../CCMRTrackingHeader/CCMRTrackingHeader.component';
import StudentDemographics from './StudentDemographics';

import styles from './CCMRTrackingStudent.styles';
import { getCCMRColWidth, getRowWidth } from '../CCMRCommon/CCMRCommon.utils';

const CustomTableCell = ({ className, styles, children }) => (
  <div className={className} style={styles}>
    {children}
  </div>
);

class CCMRTrackingStudent extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    student: PropTypes.object.isRequired,
    firebaseId: PropTypes.string.isRequired,
    ccmrData: PropTypes.object.isRequired,
    school: PropTypes.string.isRequired,
    updateCCMRTrackingData: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    avatars: PropTypes.object,
    classes: PropTypes.any,
    expandMath: PropTypes.bool,
    expandReading: PropTypes.bool,
  };

  state = {
    isExpanded: false,
  };

  setIsExpanded = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  adapt = (label) => {
    if (label === undefined || label === null) return '-';
    if (typeof label === 'boolean') {
      return label ? 'Y' : 'N';
    }
    return label;
  };

  getYesOrNoValue = (bool) => {
    const { classes } = this.props;
    let className = classes.noLabel;
    if (bool) {
      className = classes.yesLabel;
    }
    return className;
  };

  updateStudent = (key) => () => {
    const { profile, ccmrData, firebaseId, updateCCMRTrackingData } = this.props;
    let value = null;
    if (ccmrData[key] == null) {
      value = true;
    } else if (ccmrData[key]) {
      value = false;
    }
    let updatedStudent = { ...ccmrData, [key]: value };
    updatedStudent = calcStudentCCMRPoint(key, updatedStudent);
    updateCCMRTrackingData(profile, firebaseId, updatedStudent);
  };

  getEthnicity = (indicators) => {
    if (indicators.hispanic) {
      return 'Hispanic';
    }
    if (indicators.nativeAmerican) {
      return 'Native American';
    }
    if (indicators.asian) {
      return 'Asian';
    }
    if (indicators.black) {
      return 'Black';
    }
    if (indicators.pacificIslander) {
      return 'PacificIslander';
    }
    if (indicators.white) {
      return 'White';
    }
    return 'Two or More Races';
  };

  getStudentRosterInfo = () => {
    const { student, school, ccmrData } = this.props;
    if (student) {
      return {
        studentId: student.studentId,
        localId: student.localId,
        tsdsId: student.tsdsId,
        sexCode: student.sexCode,
        photoUrl: student.photoUrl ? student.photoUrl.small : null,
        name: `${student.firstName} ${student.middleName} ${student.lastName}`,
        campusName: school,
        ethnicity: this.getEthnicity(student.indicators),
        econ: student.indicators.ecoDis,
        highlyMobile: student.indicators.highlyMobile,
        acceleratedTesterMath: student.indicators.acceleratedTesterMath,
        acceleratedTesterReading: student.indicators.acceleratedTesterReading,
        acceleratedTesterScience: student.indicators.acceleratedTesterScience,
        el: student.indicators.lepCurrent || student.indicators.lepMonitored,
        spedFsped: student.indicators.specialEdCurrent || student.indicators.specialEdFormer,
        contEnrl: student.indicators.continuouslyEnrolled,
        gradStats: didGraduate(student.gradYear, ccmrData.graduated),
        gradeLevel: student.gradeLevel,
        avatarNumber: student.avatarNumber,
      };
    } else {
      return {};
    }
  };

  render() {
    const { editable, ccmrData, classes, avatars, expandMath, expandReading } = this.props;
    const { isExpanded } = this.state;
    const rosterInfo = this.getStudentRosterInfo();
    return (
      <div
        className={classes.studentRoot}
        style={{
          height: isExpanded ? 240 : 60,
          minWidth: getRowWidth(editable, expandReading, expandMath),
          width: '100%',
        }}
      >
        <div
          className={classNames(classes.rowCell, classes.rowSticky, classes.rowStickySafari)}
          style={{
            width: 350,
            left: 0,
            overflow: 'auto',
            alignItems: isExpanded ? 'flex-start' : 'center',
          }}
        >
          <StudentDemographics
            student={rosterInfo}
            avatars={avatars}
            setIsExpanded={this.setIsExpanded}
            isExpanded={isExpanded}
          />
        </div>
        <div
          className={classNames(classes.rowCell, classes.rowSticky, classes.rowStickySafari)}
          style={{
            width: 60,
            left: 350,
          }}
        >
          {this.adapt(ccmrData.ccmrPoints, 'ccmrPoints')}
        </div>
        {expandReading &&
          Object.keys(readingColumns).map((key, index) => (
            <div
              key={key}
              className={classNames(classes.rowCell)}
              style={{
                width: getCCMRColWidth(key, editable),
                background: index % 2 ? 'rgba(248, 220, 83, 0.4)' : 'rgba(248, 220, 83, 0.7)',
              }}
            >
              <Button
                className={classNames(classes.tableButton, {
                  [this.getYesOrNoValue(ccmrData[key])]: typeof ccmrData[key] === 'boolean',
                })}
                color="inherit"
                onClick={this.updateStudent(key)}
                disabled={!editable}
              >
                {this.adapt(ccmrData[key])}
              </Button>
            </div>
          ))}
        <div
          className={classNames(classes.rowCell)}
          style={{
            width: 100,
            background: 'rgba(248, 220, 83, 0.7)',
          }}
        >
          <Button
            disabled
            className={classNames(classes.tableButton, {
              [this.getYesOrNoValue(ccmrData.reading)]: typeof ccmrData.reading === 'boolean',
            })}
            color="inherit"
          >
            {this.adapt(ccmrData.reading)}
          </Button>
        </div>
        {expandMath &&
          Object.keys(mathColumns).map((key, index) => (
            <div
              key={key}
              className={classNames(classes.rowCell)}
              style={{
                width: getCCMRColWidth(key, editable),
                background: index % 2 ? 'rgba(92, 186, 243, 0.4)' : 'rgba(92, 186, 243, 0.4)',
              }}
            >
              <Button
                className={classNames(classes.tableButton, {
                  [this.getYesOrNoValue(ccmrData[key])]: typeof ccmrData[key] === 'boolean',
                })}
                color="inherit"
                onClick={this.updateStudent(key)}
                disabled={key === 'math' || !editable}
              >
                {this.adapt(ccmrData[key])}
              </Button>
            </div>
          ))}
        <div
          className={classNames(classes.rowCell)}
          style={{
            width: 100,
            background: 'rgba(92, 186, 243, 0.4)',
          }}
        >
          <Button
            disabled
            className={classNames(classes.tableButton, {
              [this.getYesOrNoValue(ccmrData.math)]: typeof ccmrData.math === 'boolean',
            })}
            color="inherit"
          >
            {this.adapt(ccmrData.math)}
          </Button>
        </div>
        {Object.keys(editableHeaderColumns).map((key, index) => (
          <div
            key={key}
            className={classNames(classes.rowCell)}
            style={{
              width: getCCMRColWidth(key, editable),
              background: index % 2 ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0.0)',
              flexGrow: 1,
            }}
          >
            <Button
              className={classNames(classes.tableButton, {
                [this.getYesOrNoValue(ccmrData[key])]: typeof ccmrData[key] === 'boolean',
              })}
              color="inherit"
              onClick={this.updateStudent(key)}
              disabled={!editable}
            >
              {this.adapt(ccmrData[key])}
            </Button>
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(CCMRTrackingStudent);
