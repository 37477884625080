const styles = (theme) => ({
  root: {
    fontFamily: 'Questrial',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  submitBtn: {
    height: 48,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filtersTitle: {
    marginTop: 16,
    fontSize: 18,
  },
  filterLimitReached: {
    fontSize: 16,
  },
});

export default styles;
