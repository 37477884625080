const styles = (theme) => ({
  root: {
    padding: '16px 36px',
    height: '100%',
  },
  table: {},
  background: {
    height: '100%',
  },
  content: {
    margin: 'auto',
  },
  textField: {
    width: '280px',
    marginRight: 8,
  },
  tableRow: {
    position: 'sticky',
  },
  header: {
    display: 'flex',
  },
  headCellChrome: {
    fontSize: '1.5rem',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 0,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#f4fdff',
  },
  studentTable: {
    height: '100%',
    overflow: 'auto',
  },
  headCell: {
    fontSize: '1.5rem',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 0,
  },
  largeText: {
    fontSize: '1.5rem',
  },
  head: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    flexShrink: 0,
    flexGrow: 0,
  },
  smallText: {
    fontSize: '0.875rem',
    marginLeft: '2px',
  },
  tableContainer: {
    flex: 1,
    margin: `0 ${theme.spacing.unit * 3}px`,
  },
  tableHeadFirefox: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#fbffff',
  },
  tableHeadSafari: {
    position: '-webkit-sticky',
    top: 0,
    zIndex: 1,
    background: '#fbffff',
  },
  button: {
    margin: theme.spacing.unit,
    float: 'right',
  },
  mountains: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
  },
  left: {
    width: 200,
    marginRight: 24,
  },
  mainContainer: {
    display: 'flex',
    height: 'calc(100% - 52px)',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default styles;
