import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, TableHead, Table, TableCell, TableRow, TableBody } from '@material-ui/core';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {},
  all: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #E1E1E1;',
  },
  reading: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(248, 220, 83, 0.7);',
  },
  math: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(92, 186, 243, 0.7);',
  },
  menu: {},
  textField: {},
  formControl: {
    display: 'block',
  },
  finalTable: {
    width: 300,
  },
  halfPoint: {
    background: '#F9D89E',
  },
  onePoint: {
    background: '#FFFDA9',
  },
  zeroPoint: {
    background: '#D2FBB5',
  },
  pointP: {
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
  },
  pointDiv: {
    width: '80%',
    height: '50px',
  },
  clickableCell: {
    background: 'rgba(0, 0, 0, 0.08)',
    padding: 0,
    '&:hover,&:focus': {
      background: 'rgba(0, 0, 0, 0.2)',
    },
  },
});

const performanceLevelTypes = {
  doesNotMeet: 'Does not Meet Low',
  doesNotMeetHigh: 'Does not Meet High',
  approaches: 'Approaches Low',
  approachesHigh: 'Approaches High',
  meets: 'Meets',
  masters: 'Masters',
};
const pointsTypes = {
  zeroPoint: 'Zero Point',
  halfPoint: 'Half Point',
  onePoint: 'One Point',
};
const drawCountCell = (
  index,
  key,
  classes,
  profile,
  subtable,
  studentsListPath,
  handleGetStudentsList,
  priorYearPerformaceLevelKey,
  currentYearPerformaceLevelKey
) => {
  let value =
    subtable.count.annualGrowthTable[priorYearPerformaceLevelKey] &&
    subtable.count.annualGrowthTable[priorYearPerformaceLevelKey][currentYearPerformaceLevelKey];
  return (
    <TableCell
      style={index % 2 ? { background: 'rgba(255,255,255,0.4)' } : {}}
      key={'d2aAnnualGrowthCell_' + key + '_' + priorYearPerformaceLevelKey + '_' + currentYearPerformaceLevelKey}
    >
      {studentsListPath ? (
        <Button
          className={classes.clickableCell}
          color="inherit"
          onClick={() =>
            handleGetStudentsList(
              profile.organizationId,
              `${profile.uid}/${studentsListPath}/${priorYearPerformaceLevelKey}/${currentYearPerformaceLevelKey}`
            )
          }
          disabled={!value}
        >
          {value}
        </Button>
      ) : (
        value
      )}
    </TableCell>
  );
};
//<p class="verticalrl"> Prior Year</p>
const D2AAnnualGrowthTable = ({
  subjectName,
  key,
  classes,
  subtableLabel,
  subtable,
  studentsListPath,
  handleGetStudentsList,
  profile,
}) => {
  if (!subtable) return null;
  if (key == null) {
    key = subjectName;
  }
  return (
    <div>
      <Table key={'d2aAnnualGrowthTable_' + key} className={classes[subjectName]}>
        <TableHead>
          <TableRow>
            <TableCell>{subtableLabel} Annual Growth Table</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Prior Year / Current Year</TableCell>
            {Object.entries(performanceLevelTypes).map(([performaceLevelKey, performaceLevelText], index) => (
              <TableCell key={'d2aAnnualGrowthColumn_' + key + '_' + performaceLevelKey}>
                {performaceLevelText}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(performanceLevelTypes).map(
            ([priorYearPerformaceLevelKey, priorYearPerformaceLevelText], index) => (
              <TableRow key={'d2aAnnualGrowthRow_' + key + '_' + priorYearPerformaceLevelKey}>
                <TableCell>{priorYearPerformaceLevelText}</TableCell>

                {Object.entries(
                  performanceLevelTypes
                ).map(([currentYearPerformaceLevelKey, currentYearPerformaceLevelText], index) =>
                  drawCountCell(
                    index,
                    key,
                    classes,
                    profile,
                    subtable,
                    studentsListPath,
                    handleGetStudentsList,
                    priorYearPerformaceLevelKey,
                    currentYearPerformaceLevelKey
                  )
                )}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      {/* <Table>
        <TableRow>
          {Object.entries(pointsTypes).map(([key1, label1], index) => (
            <TableCell>
              <div className={classNames(classes[key1],classes.pointDiv)}>
                <p className={classes.pointP}>{label1}</p>
              </div>
            </TableCell>
          ))}
        </TableRow>

      </Table> */}
    </div>
  );
};
D2AAnnualGrowthTable.propTypes = {
  subtable: PropTypes.object,
  classes: PropTypes.any,
  subtableLabel: PropTypes.string.isRequired,
  studentsListPath: PropTypes.string,
  handleGetStudentsList: PropTypes.func,
  rowComponents: PropTypes.array,
  profile: PropTypes.object,
  key: PropTypes.string,
};

export default withStyles(styles)(D2AAnnualGrowthTable);
