import firebase from 'firebase';

export const getOrgUsers = firebase.functions().httpsCallable('getOrgUsers');
export const inviteUserToOrg = firebase.functions().httpsCallable('inviteUserToOrg');
export const getInvitedUsers = firebase.functions().httpsCallable('getInvitedUsers');
export const cancelUserInvite = firebase.functions().httpsCallable('cancelUserInvite');
export const updateDeleteUser = firebase.functions().httpsCallable('updateDeleteUser');
export const resendUserInvite = firebase.functions().httpsCallable('resendUserInvite');
export const enableViewReportsForAllUsers = firebase.functions().httpsCallable('enableViewReportsForAllUsers');
export default 'ManageUsers.api.js';
