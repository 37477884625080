import pdfjs from 'pdfjs-dist';
function ValidationError(msg) {
  this.message = msg;
  this.name = 'ValidationError';
}

export const validateCAF2018 = (file) =>
  new Promise((resolve, reject) => {
    if (file.type != 'text/plain') {
      reject(new ValidationError('Invalid file type'));
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      console.log(text.length);
      if (text.length < 2700) {
        reject(new ValidationError('Invalid file format'));
      }
      const lines = text.split(/\r\n?|\n/);
      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        if (line && (line.slice(0, 4) !== '2018' || line.length !== 2700 || line[line.length - 1] !== '.')) {
          reject(new ValidationError('Invalid file format'));
        }
      }
      resolve();
    };
    reader.readAsText(file);
  });

export const validateFYLI2017 = (file) =>
  new Promise((resolve, reject) => {
    if (file.type !== 'application/pdf') {
      reject(new ValidationError('Invalid file type'));
      return;
    }
    pdfjs
      .getDocument(file.preview)
      .then((pdf) => {
        // console.log('pdf', pdf);
        // pdf.getMetadata().then((info) => {
        //   console.log('info', info);
        // });
        pdf.getPage(1).then((page) => {
          // console.log('page', page);
          page.getTextContent().then((content) => {
            // console.log('content', content);
            const lookingFor = [
              {
                re: /^C O N F I D E N T I A L$/,
                key: 'a',
              },
              {
                re: /^T E X A S {3}E D U C A T I O N {3}A G E N C Y$/,
                key: 'b',
              },
              {
                re: /^CLASS OF 2017 FOUR-YEAR LONGITUDINAL SUMMARY REPORT$/,
                key: 'c',
              },
              {
                re: /^District Name: .*$/,
                key: 'd',
              },
              {
                re: /^District No.: (\d{6})$/,
                key: 'e',
              },
            ];
            const foundThings = {
              a: 0,
              b: 0,
              c: 0,
              d: 0,
              e: 0,
            };
            content.items.forEach((item) => {
              lookingFor.forEach((looking) => {
                if (looking.re.test(item.str)) {
                  foundThings[looking.key] += 1;
                }
              });
            });
            // console.log('found', foundThings);
            if (!Object.values(foundThings).includes(0)) {
              resolve();
            }
            reject(new ValidationError("This doesn't appear to be the right file"));
          });
        });
      })
      .catch((err) => {
        console.error(err);
        reject(Error('Invalid file format'));
      });
  });

export const validateFYLI2016 = (file) =>
  new Promise((resolve, reject) => {
    if (file.type !== 'application/pdf') {
      reject(new ValidationError('Invalid file type'));
      return;
    }
    pdfjs
      .getDocument(file.preview)
      .then((pdf) => {
        // console.log('pdf', pdf);
        // pdf.getMetadata().then((info) => {
        //   console.log('info', info);
        // });
        pdf.getPage(1).then((page) => {
          // console.log('page', page);
          page.getTextContent().then((content) => {
            // console.log('content', content);
            const lookingFor = [
              {
                re: /^C O N F I D E N T I A L$/,
                key: 'a',
              },
              {
                re: /^T E X A S {3}E D U C A T I O N {3}A G E N C Y$/,
                key: 'b',
              },
              {
                re: /^CLASS OF 2016 FIVE-YEAR EXTENDED LONGITUDINAL SUMMARY REPORT$/,
                key: 'c',
              },
              {
                re: /^District Name: .*$/,
                key: 'd',
              },
              {
                re: /^District No.: (\d{6})$/,
                key: 'e',
              },
            ];
            const foundThings = {
              a: 0,
              b: 0,
              c: 0,
              d: 0,
              e: 0,
            };
            content.items.forEach((item) => {
              lookingFor.forEach((looking) => {
                if (looking.re.test(item.str)) {
                  foundThings[looking.key] += 1;
                }
              });
            });
            // console.log('found', foundThings);
            if (!Object.values(foundThings).includes(0)) {
              resolve();
            }
            reject(new ValidationError("This doesn't appear to be the right file"));
          });
        });
      })
      .catch((err) => {
        console.error(err);
        reject(Error('Invalid file format'));
      });
  });

export const validateSYLI2015 = (file) =>
  new Promise((resolve, reject) => {
    if (file.type !== 'application/pdf') {
      reject(new ValidationError('Invalid file type'));
      return;
    }
    pdfjs
      .getDocument(file.preview)
      .then((pdf) => {
        // console.log('pdf', pdf);
        // pdf.getMetadata().then((info) => {
        //   console.log('info', info);
        // });
        pdf.getPage(1).then((page) => {
          // console.log('page', page);
          page.getTextContent().then((content) => {
            // console.log('content', content);
            const lookingFor = [
              {
                re: /^C O N F I D E N T I A L$/,
                key: 'a',
              },
              {
                re: /^T E X A S {3}E D U C A T I O N {3}A G E N C Y$/,
                key: 'b',
              },
              {
                re: /^CLASS OF 2015 SIX-YEAR EXTENDED LONGITUDINAL SUMMARY REPORT$/,
                key: 'c',
              },
              {
                re: /^District Name: .*$/,
                key: 'd',
              },
              {
                re: /^District No.: (\d{6})$/,
                key: 'e',
              },
            ];
            const foundThings = {
              a: 0,
              b: 0,
              c: 0,
              d: 0,
              e: 0,
            };
            content.items.forEach((item) => {
              lookingFor.forEach((looking) => {
                if (looking.re.test(item.str)) {
                  foundThings[looking.key] += 1;
                }
              });
            });
            // console.log('found', foundThings);
            if (!Object.values(foundThings).includes(0)) {
              resolve();
            }
            reject(new ValidationError("This doesn't appear to be the right file"));
          });
        });
      })
      .catch((err) => {
        console.error(err);
        reject(Error('Invalid file format'));
      });
  });

export const validateCCMR2018 = (file) =>
  new Promise((resolve, reject) => {
    if (file.type !== 'application/vnd.ms-excel') {
      reject(new ValidationError('Invalid file type'));
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      resolve();
      // const text = reader.result;
      // const workbook = xlsx.read(text, { type: 'array' });
      // // console.log('workbook', workbook);

      // const expectedHeader = [
      //   'C O N F I D E N T I A L',
      //   'TEXAS EDUCATION AGENCY',
      //   '2018 College, Career, and Military Readiness Student Listing',
      //   'District and Campus Student Listing',
      // ];
      // if (JSON.stringify(workbook.Strings.slice(0, 4).map(c => c.r))
      // === JSON.stringify(expectedHeader)) {
      //   resolve();
      // }
      // reject(new ValidationError("This doesn't appear to be the right file"));
    };
    reader.readAsArrayBuffer(file);
  });

export const Validators = {
  validateCAF2018,
  validateCCMR2018,
  validateFYLI2016,
  validateFYLI2017,
  validateSYLI2015,
};

export default 'Assessments.validation.js';
