import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  List,
  ListItem,
  Grid,
  withStyles,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  ListItemIcon,
} from '@material-ui/core';
import classNames from 'classnames';

import { ExpandLess, ExpandMore, Lens } from '@material-ui/icons';
import { BackSkipNext } from '../common';
import { Routes } from '../../App';

const styles = (theme) => ({
  collapse: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing.unit * 6,
  },
  cursor: {
    cursor: 'pointer',
  },
  smallFont: {
    fontSize: '0.75rem',
  },
  mediumFont: {
    fontSize: '0.875rem',
  },
  largeFont: {
    fontSize: '1rem',
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
});

class WelcomePage extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onNextClick: PropTypes.func.isRequired,
    classes: PropTypes.any.isRequired,
  };

  state = {
    step: 0,
    open: {
      'Student Identification': false,
      Demographics: false,
      'TELPAS Info': false,
      Assessment: false,
      Scheduling: false,
    },
  };

  handleOpen = (tab) => {
    const open = Object.assign({}, this.state.open);
    open[tab] = !open[tab];
    this.setState({ open });
  };

  onNextClick = () => {
    const { onNextClick, history } = this.props;
    onNextClick(history, `/${Routes.onboarding}/${Routes.studentUpload}`);
  };

  incrementStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  decrementStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  renderFirstStep = (classes) => (
    <div
      style={{
        flexGrow: 1,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle1" className={classNames(classes.italic, classes.bold, classes.largeFont)}>
        Welcome to Connect. This is your captain speaking. Our crew is delighted to have you on board! We’ll be taking
        off as soon as possible.
      </Typography>
      <Typography variant="body1" className={classes.largeFont}>
        First, we just need to make sure we have everything we need:
      </Typography>
      <List dense>
        <ListItem className={classes.mediumFont}>1. Student Roster</ListItem>
        <ListItem className={classes.mediumFont}>2. Assessment Import</ListItem>
        <ListItem className={classes.mediumFont}>3. Account Validation</ListItem>
      </List>
      <Typography className={classes.largeFont}>
        The next few screens will set up your account and make sure all your reports are as accurate as possible.
      </Typography>
      <div
        style={{
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          display: 'flex',
        }}
      >
        <BackSkipNext handleNext={this.incrementStep} />
      </div>
    </div>
  );

  renderSecondStep = (classes, info) => (
    <Grid container justify="space-between" direction="column">
      <Typography gutterBottom className={classNames(classes.italic, classes.bold, classes.largeFont)}>
        Prior to boarding, all passengers must verify some basic information. Please have your documents available
        before approaching the gate.
      </Typography>
      <Typography className={classes.largeFont}>You’ll need access to the following student information:</Typography>
      <List>
        {Object.keys(info).map((key, idx) => (
          <React.Fragment key={`info-${idx}`}>
            <ListItem style={{ height: '40px' }}>
              <Checkbox color="primary" tabIndex={-1} disableRipple />
              <div onClick={() => this.handleOpen(key)} className={classes.cursor}>
                <ListItemText disableTypography primary={<div className={classes.mediumFont}>{key}</div>} />
                <ListItemSecondaryAction>
                  {this.state.open[key] ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
              </div>
            </ListItem>
            <Collapse in={this.state.open[key]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.collapse}>
                {info[key].map((details, idx) => (
                  <ListItem key={idx} style={{ width: '40%' }}>
                    <ListItemIcon>
                      <Lens fontSize="small" className={classes.smallFont} />
                    </ListItemIcon>
                    <ListItemText
                      inset
                      disableTypography
                      primary={<div className={classes.smallFont}>{details}</div>}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
      <Typography className={classes.largeFont}>
        Take a moment to export this info from your Student Information System.
      </Typography>
      <Typography className={classes.largeFont}>
        Remember to roster all of your students, not just those taking STAAR.
      </Typography>
      <BackSkipNext handleBack={this.decrementStep} handleNext={this.onNextClick} />
    </Grid>
  );

  render() {
    const { classes } = this.props;
    const information = {
      'Student Identification': [
        'Texas Unique ID (SSN)',
        'Local ID',
        '10-digit TSDS ID',
        'Student Name',
        'Grade Level',
        'Graduation',
        'Campus Number',
        'Enrollment',
        'Mobility',
      ],
      Demographics: ['Ethnicity', 'EcoDis', 'SpEd', 'LEP/EL'],
      'TELPAS Info': ['Years in US Schools', 'Asylee/Refugee/SIFE'],
      Assessment: ['Test Assigned'],
      Scheduling: ['Teachers for Each Assigned Test'],
    };
    if (this.state.step === 0) {
      return this.renderFirstStep(classes);
    }
    return this.renderSecondStep(classes, information);
  }
}

export default withStyles(styles)(WelcomePage);
