import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { getValue } from '../utils';
import classNames from 'classnames';

const styles = (theme) => ({
  clickableCell: {
    background: 'rgba(0, 0, 0, 0.08)',
    padding: 0,
    '&:hover,&:focus': {
      background: 'rgba(0, 0, 0, 0.2)',
    },
  },
});

const indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  ecoDis: 'Economically Disadvantaged',
  lep: 'EL (Current & Monitored)',
  specialEdCurrent: 'Special Ed (Current)',
  specialEdFormer: 'Special Ed (Former)',
  continuouslyEnrolled: 'Continuously Enrolled',
  // nonContinuouslyEnrolled: 'Non-Continuously Enrolled',
  highlyMobile: 'Highly Mobile',
  highFocus: 'High Focus',
  acceleratedTesterMath: 'Accelerated Tester Math',
  acceleratedTesterReading: 'Accelerated Tester Reading',
  acceleratedTesterScience: 'Accelerated Tester Science',
};

const CustomTableCell = withStyles((theme) => ({
  head: {
    padding: 8,
  },
  body: {
    padding: '4px 8px',
    textAlign: 'center',
    width: '6.6667%',
  },
}))(TableCell);

const RowLabelCell = withStyles((theme) => ({
  head: {
    padding: 8,
    textAlign: 'center',
  },
  body: {
    padding: '4px 8px',
    textAlign: 'left',
    width: '10%',
  },
}))(TableCell);

const RowHeaderCell = withStyles((theme) => ({
  head: {
    padding: 8,
    textAlign: 'center',
  },
  body: {
    padding: '4px 8px',
    textAlign: 'left',
    width: '10%',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    height: 'unset',
  },
}))(TableRow);

const RowComponent = ({
  classes,
  label,
  path,
  subtable,
  handleGetStudentsList,
  studentsListPath,
  profile,
  isHeader,
}) => (
  <CustomTableRow>
    {!isHeader && <RowLabelCell>{label}</RowLabelCell>}
    {isHeader && <RowHeaderCell>{label}</RowHeaderCell>}
    {Object.entries(indicators).map(([key1], index) => {
      const value = getValue(subtable, path, key1);
      return (
        <CustomTableCell key={key1} style={index % 2 ? { background: 'rgba(255,255,255,0.4)' } : {}}>
          {studentsListPath && value ? (
            <Button
              className={classes.clickableCell}
              color="inherit"
              onClick={() => handleGetStudentsList(profile.organizationId, `${studentsListPath}/${key1}`)}
              disabled={!value}
            >
              {getValue(subtable, path, key1)}
            </Button>
          ) : (
            getValue(subtable, path, key1)
          )}
        </CustomTableCell>
      );
    })}
  </CustomTableRow>
);
RowComponent.propTypes = {
  label: PropTypes.string.isRequired,
  subtable: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

const SubjectSubTable = ({
  classes,
  rowComponents,
  subtableLabel,
  subtable,
  studentsListPath,
  handleGetStudentsList,
  profile,
}) => {
  if (!subtable) return null;
  return (
    <React.Fragment>
      <CustomTableRow>
        <RowLabelCell>{subtableLabel}</RowLabelCell>
        {Object.entries(indicators).map(([key1], index) => (
          <CustomTableCell key={key1} style={index % 2 ? { background: 'rgba(255,255,255,0.4)' } : {}} />
        ))}
      </CustomTableRow>
      {rowComponents.map((row, index) => (
        <RowComponent
          classes={classes}
          key={index}
          label={row.label}
          path={row.path}
          subtable={subtable}
          studentsListPath={row.studentsPath ? `${profile.uid}/${studentsListPath}/${row.studentsPath}` : null}
          handleGetStudentsList={handleGetStudentsList}
          profile={profile}
          isHeader={row.isHeader}
        />
      ))}
    </React.Fragment>
  );
};
SubjectSubTable.propTypes = {
  subtable: PropTypes.object,
  classes: PropTypes.any,
  subtableLabel: PropTypes.string.isRequired,
  studentsListPath: PropTypes.string,
  handleGetStudentsList: PropTypes.func,
  rowComponents: PropTypes.array,
  profile: PropTypes.object,
};

export default withStyles(styles)(SubjectSubTable);
