import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withStyles, Button, Icon, StepButton, Step, Stepper } from '@material-ui/core';

import { Routes } from '../../App/App.routes';
import WelcomePage from '../WelcomePage';
import StudentsPage from '../StudentsPage';
import AssessmentsPage from '../AssessmentsPage';
// import TempValidationPage from '../TempValidationPage';
import { LoadingCircle } from '../../common';
import TakeOffPage from '../TakeOffPage';

function getSteps() {
  return [
    {
      icon: 'home',
      label: 'Welcome',
      route: Routes.welcome,
    },
    {
      icon: 'group_add',
      label: 'Students',
      route: Routes.studentUpload,
    },
    {
      icon: 'file_copy',
      label: 'Assessments',
      route: Routes.assessments,
    },
    // {
    //   icon: 'check',
    //   label: 'Validation',
    //   route: Routes.validation,
    // },
    {
      icon: 'flight_takeoff',
      label: 'Take off',
      route: Routes.takeOff,
    },
  ];
}

const styles = (theme) => ({
  content: {
    margin: 'auto',
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  container: {
    width: '800px',
    alignSelf: 'center',
    margin: '70px',
    padding: '30px',
    backgroundColor: 'white',
    borderRadius: '15px',
    minHeight: '550px',
    display: 'flex',
    flexFlow: 'column',
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  },
  icon: {
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '18px',
    color: 'white',
  },
  signOut: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing.unit,
  },
});

class OnBoardingBase extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    completed: PropTypes.object.isRequired,
    user: PropTypes.object,
    profile: PropTypes.object,
    needsInit: PropTypes.bool.isRequired,
    init: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    classes: PropTypes.any.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  currentStep = () => {
    const { match } = this.props;
    const page = match.params.page;
    const pages = {
      [Routes.welcome]: 0,
      [Routes.studentUpload]: 1,
      [Routes.assessments]: 2,
      // [Routes.validation]: 3,
      [Routes.takeOff]: 3,
    };
    return pages[page];
  };

  handleStepClick = (route) => {
    const { history, completed } = this.props;
    if (
      (route !== Routes.takeOff && route !== Routes.validation) ||
      (route === Routes.takeOff && completed[Routes.studentUpload] && completed[Routes.assessments])
    ) {
      history.push(`/${Routes.onboarding}/${route}`);
    }
  };

  redirectToWelcome = () => <Redirect to={`/${Routes.onboarding}/${Routes.welcome}`} />;

  handleStep = (step) => {
    console.log(step);
  };

  onSignOutClick = () => {
    const { signOut, user } = this.props;
    signOut(user);
  };

  shouldInit() {
    const { needsInit, init, history, loading, profile } = this.props;
    if (profile && needsInit && !loading) {
      init(profile, history);
    }
  }

  render() {
    const { loading, classes, needsInit, completed, user } = this.props;
    const steps = getSteps();
    const activeStep = this.currentStep();
    const colors = { active: '#3EA1F8', inactive: '#D5D5D5', completed: '#7FD453' };

    return (
      <React.Fragment>
        <div className={classes.content} style={{ display: needsInit || loading ? 'none' : '' }}>
          {!!user && (
            <Button className={classes.signOut} onClick={this.onSignOutClick}>
              Sign Out
            </Button>
          )}
          <div className={classes.container}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => {
                let color;
                if (activeStep === index) {
                  color = colors.active;
                } else if (completed[step.route]) {
                  color = colors.completed;
                } else {
                  color = colors.inactive;
                }
                return (
                  <Step key={step.label}>
                    <StepButton
                      disableTouchRipple
                      onClick={() => this.handleStepClick(step.route)}
                      completed={completed[step.route]}
                      icon={
                        <Icon style={{ backgroundColor: color }} className={classes.icon}>
                          {step.icon}
                        </Icon>
                      }
                    >
                      {step.label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            {loading && <LoadingCircle classes={classes} />}
            {!needsInit && !loading && (
              <Switch>
                <Route exact path={`/${Routes.onboarding}`} render={this.redirectToWelcome} />
                <Route exact path={`/${Routes.onboarding}/${Routes.welcome}`} component={WelcomePage} />
                <Route exact path={`/${Routes.onboarding}/${Routes.studentUpload}`} component={StudentsPage} />
                <Route exact path={`/${Routes.onboarding}/${Routes.assessments}`} component={AssessmentsPage} />
                {/* <Route exact path={`/${Routes.onboarding}/${Routes.validation}`} component={TempValidationPage} /> */}
                <Route exact path={`/${Routes.onboarding}/${Routes.takeOff}`} component={TakeOffPage} />
                <Redirect to={`/${Routes.onboarding}`} />
              </Switch>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OnBoardingBase);
