import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Switch, Grid } from '@material-ui/core';
import { Button, ExportCSVButton, LoadingCircle, makeCsv, TitleBackButton } from '../common';
import ProgressGoalsList from './ProgressGoalsList';
import styles from './ProgressGoals.styles';
import PgFilters from './PgFilters';
import { saveAs } from 'file-saver';

class ProgressGoals extends Component {
  static propTypes = {
    students: PropTypes.array,
    classes: PropTypes.object,
    loading: PropTypes.string.isRequired,
    loadingMore: PropTypes.string.isRequired,
    profile: PropTypes.object,
    avatars: PropTypes.object,
    errorMsg: PropTypes.any,
    init: PropTypes.func,
    exportProgressGoals: PropTypes.func,
    getProgressGoals: PropTypes.func,
    filters: PropTypes.any,
    nextToken: PropTypes.any,
    progressGoalsCSV: PropTypes.object,
    loadingCSV: PropTypes.bool,
    recalculateProgressGoals: PropTypes.func,
  };

  state = {
    isPercent: true,
    prevSelectedFilters: '',
  };

  inited = false;

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate = async (prevProps) => {
    const { progressGoalsCSV, loadingCSV } = this.props;
    this.shouldInit();
    if (progressGoalsCSV && prevProps.loadingCSV && prevProps.loadingCSV !== loadingCSV) {
      const { data, columns } = progressGoalsCSV;
      const output = await makeCsv(data, columns);
      const blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
      if (blob) {
        saveAs(blob, 'Progress-Goals.csv');
      }
    }
  };

  togglePercentSwitch = () => {
    const { isPercent } = this.state;
    this.setState({ isPercent: !isPercent });
  };

  shouldInit = () => {
    const { profile, init, getProgressGoals, filters } = this.props;
    const { prevSelectedFilters } = this.state;
    const jsonSelectedFilters = JSON.stringify(filters);
    if (profile && !this.inited) {
      this.inited = true;
      getProgressGoals(filters);
      init(profile);
      this.setState({
        prevSelectedFilters: jsonSelectedFilters,
      });
    } else if (this.inited && jsonSelectedFilters !== prevSelectedFilters) {
      this.setState({
        prevSelectedFilters: jsonSelectedFilters,
      });
      getProgressGoals(filters);
    }
  };

  onLoadMore = () => {
    const { getProgressGoals, filters, nextToken } = this.props;
    if (nextToken) {
      getProgressGoals(filters, nextToken);
    }
  };

  exportCsv = () => {
    const { exportProgressGoals, filters } = this.props;
    exportProgressGoals(filters);
  };

  onRecalculatePG = () => {
    const { recalculateProgressGoals, filters } = this.props;
    recalculateProgressGoals(filters);
  };

  render() {
    const { classes, loading, profile, students, avatars, loadingMore, errorMsg, loadingCSV } = this.props;
    const { isPercent } = this.state;

    const staarView = isPercent ? 'percentScore' : 'scaleScore';
    const showLoading = loading || !profile || !avatars;
    return (
      <div className={classes.root}>
        <TitleBackButton title="Real STAAR Progress Goals">
          {!loading && errorMsg ? (
            <Typography className={classes.errorMsg} color="error">
              {errorMsg}
            </Typography>
          ) : (
            <Grid>
              {profile && profile.role !== 'campusAdmin' && (
                <Button color="outlined" large onClick={this.onRecalculatePG}>
                  Re-calculate Progress Goals
                </Button>
              )}
              <ExportCSVButton title="Export CSV" onClick={this.exportCsv} loading={loadingCSV} />
            </Grid>
          )}
        </TitleBackButton>
        {showLoading && (
          <div className={classes.loadingWrapper}>
            <div>
              <LoadingCircle classes={classes} />
              <div style={{ height: 16 }} />
              <Typography>Loading progress goals ... this may take a second.</Typography>
            </div>
          </div>
        )}
        <div className={classes.content} style={{ display: showLoading ? 'none' : '' }}>
          <div className={classes.left}>
            <PgFilters />
            <Typography color="primary" className={classes.viewScore}>
              View Score
            </Typography>
            <div className={classes.viewRow}>
              <Typography color="primary" align="center">
                Scale Score
              </Typography>
              <Switch
                checked={isPercent}
                onChange={this.togglePercentSwitch}
                value="isPercent"
                classes={{
                  switchBase: classes.colorSwitchBase,
                  checked: classes.colorChecked,
                  bar: classes.colorBar,
                }}
              />
              <Typography color="primary" align="center">
                Percent
              </Typography>
            </div>
            <p className={classes.disclaimer}>
              Disclaimer: These reports and all data within are for illustrative purposes only and are subject to
              change. Official ratings and designations come only from the Texas Education Agency.
            </p>
          </div>
          <ProgressGoalsList
            students={students}
            view={staarView}
            avatars={avatars}
            onLoadMore={this.onLoadMore}
            loading={loading || loadingMore}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProgressGoals);
