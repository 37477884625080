import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Routes } from '../../App';
import { BackSkipNext } from '../common';
import HelpTable from './components/HelpTable';
import { LoadingCircle, Dropzone } from '../../common';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    minWidth: 700,
  },
  button: {
    margin: theme.spacing.unit,
  },
  tableWrapper: {
    marginBottom: theme.spacing.unit * 4,
  },
  helpTableDesc: {
    marginBottom: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  uploadTitle: {
    marginTop: theme.spacing.unit * 2,
  },
  question: {
    marginBottom: theme.spacing.unit * 2,
  },
  questionWrapper: {
    textAlign: 'center',
  },
  questionButtons: {
    marginBottom: theme.spacing.unit * 12,
  },
  backButton: {
    marginBottom: theme.spacing.unit,
  },
  smallFont: {
    fontSize: '0.75rem',
  },
  mediumFont: {
    fontSize: '0.875rem',
  },
  largeFont: {
    fontSize: '1rem',
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
});

class AssessmentsPage extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onNextClick: PropTypes.func.isRequired,
    classes: PropTypes.any.isRequired,
    status: PropTypes.shape({
      CAF2018: PropTypes.bool.isRequired,
      FYLI2017: PropTypes.bool.isRequired,
      FYLI2016: PropTypes.bool.isRequired,
      SYLI2015: PropTypes.bool.isRequired,
      CCMR2018: PropTypes.bool.isRequired,
    }),
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    init: PropTypes.func.isRequired,
    profile: PropTypes.object,
    onDrop: PropTypes.func.isRequired,
    dropError: PropTypes.string.isRequired,
    uploadProgressMsg: PropTypes.string.isRequired,
  };

  state = {
    schoolSelection: '',
    nextError: '',
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  onNextClick = () => {
    const { onNextClick, history, status } = this.props;
    // const { schoolSelection } = this.state;
    let finished = true;
    // if (!schoolSelection) {
    //   finished = false;
    // } else if (schoolSelection === 'primary') {
    //   if (!status.CAF2018.uploaded) finished = false;
    // } else {
    //   Object.values(status).forEach((obj) => {
    //     if (!obj.uploaded) finished = false;
    //   });
    // }
    if (!status.CAF2018.uploaded) finished = false;
    if (finished) {
      onNextClick(history, `/${Routes.onboarding}/${Routes.takeOff}`);
    } else {
      this.setState({
        nextError: "Wouldn't you like to upload your assessments before moving to the next step?",
      });
    }
  };

  onBackClick = () => {
    const { history } = this.props;
    history.push(`/${Routes.onboarding}/${Routes.studentUpload}`);
  };

  onSkipClick = () => {
    const { history } = this.props;
    history.push(`/${Routes.onboarding}/${Routes.validation}`);
  };

  onPrimaryClick = () => {
    this.setState({
      schoolSelection: 'primary',
    });
  };

  onSecondaryClick = () => {
    this.setState({
      schoolSelection: 'secondary',
    });
  };

  onUploadClick = () => {
    this.dropZoneRef.open();
    this.setState({
      nextError: '',
    });
  };

  onChangeChoiceClick = () => {
    this.setState({
      schoolSelection: '',
    });
  };

  onDrop = (accepted, rejected) => {
    const { onDrop, profile } = this.props;
    onDrop(accepted, rejected, profile);
  };

  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  initialized = false;

  shouldInit() {
    const { profile, init, loading } = this.props;
    if (profile && !this.initialized && !loading) {
      this.initialized = true;
      init(profile);
    }
  }

  render() {
    const { classes, loading, loadingMsg, status, dropError, uploadProgressMsg } = this.props;
    const { schoolSelection, nextError } = this.state;
    return (
      <div className={classes.root}>
        {(loading || uploadProgressMsg) && <LoadingCircle classes={classes} msg={loadingMsg || uploadProgressMsg} />}
        {!(loading || uploadProgressMsg) && (
          <React.Fragment>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classNames(classes.italic, classes.bold, classes.largeFont)}
            >
              Before taking off, we need to securely stow all your accountability baggage in the correct compartment.
            </Typography>
            <Typography variant="body1" className={classNames(classes.helpTableDesc, classes.largeFont)}>
              CoPilot requires the following state assessment and accountability data:
            </Typography>
            <Dropzone
              disableClick
              accept={['text/plain', 'application/vnd.ms-excel', 'application/pdf']}
              ref={this.saveDropZoneRef}
              onDrop={this.onDrop}
              multiple={false}
            >
              <HelpTable
                uploaded={status}
                includeSecondary={schoolSelection === 'secondary'}
                onUploadClick={this.onUploadClick}
              />
            </Dropzone>
            {dropError && (
              <Typography align="center" variant="subtitle1" color="error" className={classes.dropError}>
                {dropError}
              </Typography>
            )}
            {nextError && (
              <Typography
                variant="subtitle1"
                align="center"
                color="primary"
                style={{
                  marginBottom: '16px',
                }}
              >
                {nextError}
              </Typography>
            )}
            <BackSkipNext handleBack={this.onBackClick} handleNext={this.onNextClick} />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AssessmentsPage);
