const styles = (theme) => ({
  yesLabel: {
    height: '35px',
    background: theme.palette.success.main,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#fff',
    justifyContent: 'center',
    '&:hover,&:focus': {
      background: theme.palette.success.main,
    },
  },
  noLabel: {
    height: '35px',
    background: '#DE8C8C',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#fff',
    justifyContent: 'center',
    '&:hover,&:focus': {
      background: '#DE8C8C',
    },
  },
  dashLabel: {
    height: '35px',
    background: '#ECECEC',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
});

export default styles;
