import { combineReducers } from 'redux';
import { MARK_COMPLETED, INIT_ONBOARDING } from './onBoarding.actions';
import { Routes } from '../../../App/App.routes';
import { ASYNC_FINISH, ASYNC_ERROR, ASYNC_START } from '../../../common';

const initialStatus = {
  [Routes.welcome]: false,
  [Routes.studentUpload]: false,
  [Routes.assessments]: false,
  [Routes.validation]: false,
  [Routes.takeOff]: false,
  done: false,
};
const status = (state = initialStatus, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === MARK_COMPLETED) {
        return Object.assign({}, state, {
          [action.data.step]: true,
        });
      }
      if (action.model === INIT_ONBOARDING) {
        if (action.data.status) {
          return action.data.status;
        }
        return initialStatus;
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === INIT_ONBOARDING) {
        return 'Initializing ...';
      }
      if (action.model === MARK_COMPLETED) {
        return 'Saving ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === INIT_ONBOARDING || action.model === MARK_COMPLETED) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === INIT_ONBOARDING) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
  loadingMsg,
  needsInit,
});

export default 'onBoarding.reducers.js';
