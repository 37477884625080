import { editableHeaderColumns, mathColumns, readingColumns } from '../+store';

export const getCCMRColWidth = (key, isEditing) => {
  if (isEditing) return 120;
  return 60;
};

export const getRowWidth = (isEditing, isReadingExpanded, isMathExpanded) => {
  const readingMult = isReadingExpanded ? 1 : 0;
  const mathMult = isMathExpanded ? 1 : 0;
  const computeRowLength = (colWidth) =>
    510 +
    60 +
    (Object.keys(editableHeaderColumns).length +
      readingMult * Object.keys(readingColumns).length +
      mathMult * Object.keys(mathColumns).length) *
      colWidth;
  if (isEditing) return computeRowLength(120);
  return computeRowLength(60);
};

export default '';
