import parse from 'csv-parse';
const EOC2021CustomCols = [
  'Adm_Date',
  'GradeLvl_Tested',
  'ESCReg_Num',
  'CDCno',
  'District',
  'Campus',
  'L_Name',
  'F_Name',
  'MI',
  'Student_ID',
  'Sex',
  'DOB',
  'Blank_1',
  'Hispanic_Latino',
  'AM_Indian_Alaskan',
  'Asian',
  'Black_African_American_Code',
  'Native_HI_Pacific_Isl',
  'White',
  'Ethnic_Race_Rep_Cat',
  'ED',
  'Title_1_Part_A',
  'Migrant',
  'Blank_2',
  'LEP',
  'Bilingual',
  'ESL',
  'Blank_3',
  'Special_Ed',
  'Blank_4',
  'GT',
  'At_Risk',
  'Blank_5',
  'Local_ID',
  'Doc_no',
  'Blank_6',
  'RUI',
  'TMIP',
  'HSEP',
  'Group_Name',
  'Blank_7',
  'New_to_TX',
  'TX_UNIQUE_STUDENT_ID',
  'EOC_Code',
  'LCL_Use',
  'Blank_8',
  'ACY_A',
  'ACY_B',
  'ACY_C',
  'ACY_D',
  'ACY_E',
  'Blank_9',
  'FOR_TAKS_TAAS_TEAMS_TESTERS_ONLY',
  'Blank_10',
  'DS',
  'Braille',
  'LP',
  'Oral_Accommodation',
  'XD',
  'Blank_11',
  'TTS',
  'STT',
  'Blank_12',
  'Content_and_Lang',
  'Spell_Assist',
  'Amer_Signed_Video',
  'Blank_13',
  'SC',
  'Blank_14',
  'SC_Default',
  'Blank_15',
  'Test_Mode',
  'Blank_16',
  'Test_Version',
  'FTT_Retest',
  'Blank_17',
  'Rep_Cat',
  'Test_Form_Vrsn',
  'Raw',
  'SS',
  'Blank_19',
  'Comp_Scr',
  'Alt_TAAS_TEAMS_E2_R_Raw',
  'Alt_TAAS_TEAMS_E2_R_Perf',
  'Alt_TAAS_TEAMS_E2_W_Raw',
  'Alt_TAAS_TEAMS_E2_W_Perf',
  'Alt_TAAS_TEAMS_A1_Perf',
  'Alt_TAAS_TEAMS_Bio_Perf',
  'Alt_TAAS_TEAMS_E2_Perf',
  'Alt_TAAS_TEAMS_USH_Perf',
  'TAKS_TAAS_TEAMS',
  'Blank_20',
  'Passing_STD_Approaches',
  'Blank_21',
  'Meets_Grade',
  'Approaches_Grade',
  'Masters_Grade',
  'Approaches_Grade_2012_2015',
  'Approaches_Current',
  'Blank_22',
];

const isEOC2021Custom = (file) =>
  new Promise((resolve) => {
    if (file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
      //text/csv'
      resolve(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      parse(text, {}, (err, output) => {
        if (err) {
          resolve(false);
          return;
        }
        if (JSON.stringify(output[0].filter((x) => x)) === JSON.stringify(EOC2021CustomCols)) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    };
    reader.readAsText(file);
  });

export default isEOC2021Custom;
