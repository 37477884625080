import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Logo } from '../common/Logo';
import { Routes } from '../App';
import AssessmentChecklist from '../Assessments/AssessmentChecklist';
import UserInfoBanner from '../common/UserInfoBanner';

const mountainsSvg = require('../../assets/img/mountains2.svg');

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  body: {
    position: 'absolute',
    zIndex: 5,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  background1: {
    backgroundImage: `url('${mountainsSvg}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    top: 120,
    left: 0,
  },
  background2: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(134, 240, 255, 0.65)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    top: 120,
    left: 0,
  },
  topBar: {
    background: 'none',
    width: '100%',
    padding: '0 3.125rem',
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,
    height: 120,
  },
  content: {
    height: '100%',
    paddingTop: 120,
  },
  notSure: {
    height: '100%',
    padding: 0,
  },
  icon: {
    fontSize: '1.525rem',
  },
  logout: {
    color: theme.palette.primary.main,
    fontSize: '1.225rem',
    marginLeft: 10,
  },
  logoutContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoIcon: {
    width: 125,
    height: 45,
  },
  logoProfileInfoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '70%',
  },
  logoutButton: {
    background: 'none',
    border: 0,
    cursor: 'pointer',
    '&:focus': {
      outline: 0,
    },
  },
});

class AppHeaderFooter extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    signOut: PropTypes.func.isRequired,
    location: PropTypes.object,
    user: PropTypes.object,
    profile: PropTypes.object,
    notSure: PropTypes.any,
  };

  onSignOutClick = () => {
    const { signOut, user } = this.props;
    signOut(user);
  };

  renderHeader = () => {
    const { classes, location, profile } = this.props;
    return (
      <Grid
        container
        alignItems="center"
        justify={location.pathname !== '/' ? 'space-between' : 'flex-end'}
        className={classes.topBar}
      >
        {location.pathname !== '/' && (
          <div className={classes.logoProfileInfoWrapper}>
            <Logo logoClassName={classes.logoIcon} />
            {profile && <UserInfoBanner />}
          </div>
        )}
        {/*{profile && (location.pathname === `/${Routes.aToF}` || location.pathname === `/${Routes.flightPlan}`) && (*/}
        {/*|| location.pathname === `/${Routes.ccmrTracking}`*/}
        {/*<AssessmentChecklist*/}
        {/*route={location.pathname === `/${Routes.flightPlan}` || location.pathname === `/${Routes.aToF}`}*/}
        {/*/>*/}
        {/*)}*/}
        <div>
          <button type="button" onClick={this.onSignOutClick} className={classes.logoutButton}>
            <Grid item className={classes.logoutContainer}>
              <ExitToAppIcon className={classes.icon} color="primary" />
              <Typography align="center" className={classes.logout}>
                Logout
              </Typography>
            </Grid>
          </button>
        </div>
      </Grid>
    );
  };

  render() {
    const {
      classes,
      children,
      notSure,
      // location,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.background1} />
        <div className={classes.background2} />
        <div className={classes.body}>
          {!notSure && this.renderHeader()}
          <div className={notSure ? classes.notSure : classes.content}>{children}</div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AppHeaderFooter);
