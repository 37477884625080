import * as React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Print from '@material-ui/icons/Print';
import { Typography, Button } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    marginBottom: '1.5rem',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginLeft: '0.6875rem',
    marginRight: '0.6875rem',
    fontSize: '1rem',
  },
  print: {
    fontSize: '1.25rem',
  },
});

class PrintButton extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  render() {
    const { classes, title, onClick } = this.props;
    return (
      <Button onClick={onClick} style={{ textDecoration: 'none' }}>
        <div className={classes.container}>
          <Print color="primary" className={classes.print} />
          <Typography align="center" variant="h3" className={classes.title}>
            {title}
          </Typography>
        </div>
      </Button>
    );
  }
}

export default withStyles(styles)(PrintButton);
