import { asyncFinish, asyncStart, asyncError } from '../../../common';
import { Routes } from '../../../App';
import { auth } from '../../../App/+store/firebase/firebase.actions';

let unregisterAuthObserver;

export const AUTH_FORGET_PASSWORD_UNSET = 'auth.forgetPassword.unset';
export const unsetForgetPassword = () => (dispatch) => {
  dispatch({ type: AUTH_FORGET_PASSWORD_UNSET });
};

export const AUTH_FORGET_PASSWORD = 'auth.forgetPassword';
export const forgetPassword = (data) => (dispatch) => {
  dispatch(asyncStart(AUTH_FORGET_PASSWORD));
  dispatch({ type: AUTH_FORGET_PASSWORD, data });
};

export const AUTH_SIGN_UP = 'auth.signUp';
export const signUp = (data) => (dispatch) => {
  dispatch(asyncStart(AUTH_SIGN_UP));
  dispatch({ type: AUTH_SIGN_UP, data });
};

export const GET_CAMPUS_DISTRICT_LIST = 'auth.getCampusDistrictList';
export const initCampusDistrictList = () => (dispatch) => {
  dispatch(asyncStart(GET_CAMPUS_DISTRICT_LIST));
  dispatch({ type: GET_CAMPUS_DISTRICT_LIST });
};

export const AUTH_INIT = 'auth.init';
export const AUTH_GET_PROFILE = 'auth.getProfile';
export const getProfile = (user) => ({
  type: AUTH_GET_PROFILE,
  user,
});

export const authInit = () => (dispatch) => {
  dispatch(asyncStart(AUTH_INIT));

  unregisterAuthObserver = auth.onAuthStateChanged((user) => {
    dispatch(asyncFinish(AUTH_INIT, { user }));
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        const maxAuthPeriodMS = 8 * 60 * 60 * 1000; // 8 hours
        const authTimeMS = idTokenResult.claims.auth_time * 1000;
        const currentAuthPeriodMS = Date.now() - authTimeMS;
        // Auto signout after maxAuthPeriodMS if user refresh the page or not
        if (currentAuthPeriodMS > maxAuthPeriodMS) {
          //console.log('maxAuthPeriodMS:' + maxAuthPeriodMS);
          dispatch(signOut(user));
        } else {
          setTimeout(() => {
            //console.log('setTimeout maxAuthPeriodMS:' + maxAuthPeriodMS);
            dispatch(signOut(user));
          }, maxAuthPeriodMS - currentAuthPeriodMS);
        }
        dispatch(asyncStart(AUTH_GET_PROFILE));
        dispatch(getProfile(user));
      });
    }
  });
};

export const AUTH_TEARDOWN = 'auth.teardown';
export const authTeardown = () => (dispatch) => {
  unregisterAuthObserver();
  dispatch({ type: AUTH_TEARDOWN });
};

export const AUTH_SIGN_OUT = 'auth.signOut';
export const signOut = (user) => (dispatch) => {
  dispatch(asyncStart(AUTH_SIGN_OUT));
  // Eventually remove this: Sign out of old app
  auth.signOut().then(
    () => {
      dispatch(asyncFinish(AUTH_SIGN_OUT, { user }));
    },
    (error) => {
      dispatch(asyncError(AUTH_INIT, 'Signing Out', error, { user }));
    }
  );
};

export const AUTH_SIGN_IN = 'auth.signIn';
export const signIn = (userData, history) => (dispatch) => {
  dispatch(asyncStart(AUTH_SIGN_IN));
  auth
    .signInWithEmailAndPassword(userData.email, userData.password)
    .then((payload) => {
      dispatch(asyncFinish(AUTH_SIGN_IN, { user: payload.user }));
      return payload.user;
    })
    .then((user) => {
      dispatch(asyncStart(AUTH_GET_PROFILE));
      dispatch({ type: AUTH_GET_PROFILE, user });
    })
    .then(() => {
      history.push('');
    })
    .catch((error) => {
      dispatch(asyncError(AUTH_SIGN_IN, 'Signing In Failed', error.message));
    });
};

export const SIGN_IN = 'auth.signIn';
export const signInAction = (values) => (dispatch) => {
  dispatch(asyncStart(SIGN_IN));
  dispatch({
    type: SIGN_IN,
    values,
  });
};

export const GET_SIGN_UP_CODE_INFO = 'auth.getSignUpCode';
export const getSignUpCodeInfo = (signUpCode) => (dispatch) => {
  dispatch(asyncStart(GET_SIGN_UP_CODE_INFO));
  dispatch({
    type: GET_SIGN_UP_CODE_INFO,
    signUpCode,
  });
};
