import firebase from 'firebase';
import { asyncStart, asyncFinish, asyncError, asyncProgress } from '../../../common';
import { Validators } from '../../../StudentRoster';
import { addRoster } from '../../../common/+store/database/database.api';

export const INIT_ROSTER = 'onboarding.studentsPage.init';
export const initStudentsPage = (user) => (dispatch) => {
  dispatch(asyncStart(INIT_ROSTER));
  const orgId = user.organizationId;
  const owner = user.role === 'campusAdmin' ? user.campusNumber : 'district';
  firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/files/rosters/${owner}`)
    .once('value')
    .then((snapshot) => {
      const rosters = snapshot.val();
      if (rosters === null) {
        dispatch(
          asyncFinish(INIT_ROSTER, {
            uploaded: null,
          })
        );
      } else {
        dispatch(
          asyncFinish(INIT_ROSTER, {
            uploaded: !!rosters,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(asyncError(INIT_ROSTER, 'Get rosters status from firebase db', err));
      // Todo: error messages
      throw err;
    });
};

const createFileUpdateObject = (user, rosters, file) => {
  const currentVersion = rosters.versions;
  const newFileKey = firebase
    .database()
    .ref()
    .child(`users/${user.uid}/onboarding/rosters`)
    .push().key;
  const storageKey = `users/${user.uid}/rosters/${newFileKey}`;
  return {
    updates: {
      [`users/${user.uid}/onboarding/rosters/versions`]: currentVersion + 1,
      [`users/${user.uid}/onboarding/rosters/files/v${currentVersion}`]: {
        storageKey,
        origName: file.name,
      },
    },
    storageKey,
  };
};

const createFirstFileObject = (user, file) => {
  const newFileKey = firebase
    .database()
    .ref()
    .child(`users/${user.uid}/onboarding/rosters`)
    .push().key;
  const storageKey = `users/${user.uid}/rosters/${newFileKey}`;
  return {
    updates: {
      [`users/${user.uid}/onboarding/rosters`]: {
        versions: 1,
        files: {
          v0: {
            storageKey,
            origName: file.name,
          },
        },
      },
    },
    storageKey,
  };
};

export const UPLOAD_ROSTER = 'onboarding.studentsPage.upload';
const saveFile = (user, file, dispatch) => {
  // firebase.database().ref(`users/${user.uid}/onboarding/rosters`).once('value').then((snapshot) => {
  //   const rosters = snapshot.val();
  //   console.log(file);
  //   let updates;
  //   let storageKey;
  //   if (rosters && rosters.versions) {
  //     ({ updates, storageKey } = createFileUpdateObject(user, rosters, file));
  //   } else {
  //     ({ updates, storageKey } = createFirstFileObject(user, file));
  //   }
  //   console.log('updates', updates);
  // Save to storage
  // Points to the root reference

  const orgId = user.organizationId;
  const owner = user.role === 'campusAdmin' ? user.campusNumber : 'district';
  const storageKey = `organizations/${orgId}/roster/${owner}`;
  const roster = {
    origName: file.name,
    storageKey,
    ownerId: user.uid,
  };

  const storageRef = firebase.storage().ref();
  const rostersRef = storageRef.child(storageKey);

  const metadata = {
    customMetadata: {
      type: 'roster',
      organizationId: orgId,
      owner,
      ownerId: user.uid,
    },
  };
  const uploadTask = rostersRef.put(file, metadata);
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          dispatch(
            asyncProgress(UPLOAD_ROSTER, {
              progress,
              message: `${progress}% Paused`,
            })
          );
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          dispatch(
            asyncProgress(UPLOAD_ROSTER, {
              progress,
              message: `${progress}%`,
            })
          );
          break;
        default:
          break;
      }
    },
    (error) => {
      dispatch(asyncError(UPLOAD_ROSTER, 'Save to storage', error));
    },
    async () => {
      try {
        await addRoster(roster, user);
        dispatch(asyncFinish(UPLOAD_ROSTER, { uploaded: true }));
        dispatch(initStudentsPage(user));
      } catch (err) {
        dispatch(asyncError(UPLOAD_ROSTER, 'Update firebase db', err));
      }
    }
  );
};

export const studentsPageUpload = (user, file) => (dispatch) => {
  dispatch(asyncStart(UPLOAD_ROSTER));
  const validation = Validators.validateStudentRoster(file);
  if (validation) {
    validation
      .then(() => {
        saveFile(user, file, dispatch);
      })
      .catch((err) => {
        console.log('validation', err);
        dispatch(asyncError(UPLOAD_ROSTER, 'Validation error', err.message, { uploaded: false }));
      });
  }
};

export const onRosterDrop = (accepted, rejected, user) => (dispatch) => {
  if (accepted.length) {
    const file = accepted[0];
    dispatch(studentsPageUpload(user, file));
  } else {
    dispatch(asyncError(UPLOAD_ROSTER, 'Dropzone rejected file', null, { rejected }));
  }
};
