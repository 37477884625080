import { combineReducers } from 'redux';
import { Assessments, UPLOAD_ASSESSMENT, GET_ASSESSMENT_LIST } from '../../../Assessments';
import { INIT_ASSESSMENTS } from './assessmentsPage.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START, ASYNC_PROGRESS } from '../../../common';

const initialAssessmentObj = {
  uploaded: false,
  error: '',
};
const initialAssessments = {
  [Assessments.CAF2018.shortName]: false,
  [Assessments.FYLI2017.shortName]: false,
  [Assessments.FYLI2016.shortName]: false,
  [Assessments.SYLI2015.shortName]: false,
  [Assessments.CCMR2018.shortName]: false,
  [Assessments.CCMR2019.shortName]: false,
};
const status = (state = initialAssessments, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ASSESSMENT) {
        return Object.assign({}, state, {
          [action.data.type]: true,
        });
      }
      if (action.model === GET_ASSESSMENT_LIST) {
        return {
          [Assessments.CAF2018.shortName]: !!action.data.assessments.find(
            (a) => a.type === Assessments.CAF2018.shortName
          ),
          [Assessments.SYLI2015.shortName]: !!action.data.assessments.find(
            (a) => a.type === Assessments.SYLI2015.shortName
          ),
          [Assessments.FYLI2016.shortName]: !!action.data.assessments.find(
            (a) => a.type === Assessments.FYLI2016.shortName
          ),
          [Assessments.FYLI2017.shortName]: !!action.data.assessments.find(
            (a) => a.type === Assessments.FYLI2017.shortName
          ),
          [Assessments.CCMR2018.shortName]: !!action.data.assessments.find(
            (a) => a.type === Assessments.CCMR2018.shortName
          ),
        };
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === UPLOAD_ASSESSMENT) {
        return Object.assign({}, state, {
          [action.data.assessmentType]: false,
        });
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === INIT_ASSESSMENTS) {
        return 'Initializing ...';
      }
      if (action.model === UPLOAD_ASSESSMENT) {
        return 'Saving ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === INIT_ASSESSMENTS || action.model === UPLOAD_ASSESSMENT) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const uploadProgressMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_PROGRESS:
      if (action.model === UPLOAD_ASSESSMENT) {
        return action.data.message;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ASSESSMENT) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === INIT_ASSESSMENTS) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const dropError = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === UPLOAD_ASSESSMENT) {
        return '';
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === UPLOAD_ASSESSMENT) {
        return action.error.message;
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
  loadingMsg,
  needsInit,
  dropError,
  uploadProgressMsg,
});

export default 'assessmentsPage.reducers.js';
