import firebase from 'firebase';
export const getAToFAndFlightPlan = firebase
  .functions()
  .httpsCallable('aToFAndFlightPlan', { timeout: 10 * 60 * 1000 });

export const getStudentsListApi = async (orgId, path) =>
  firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/aToFStudents/${path}`)
    .once('value')
    .then((snapshot) => snapshot.val());

export default 'aToF.api.js';

//httpsCallableFromURL( getFunctions(),process.env.REACT_APP_API_URL_ATOF, { timeout: 10 * 60 * 1000 });

/*
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
export const getAuthToken = () => from(firebase.auth().currentUser.getIdToken());
export const getAToFAndFlightPlan = (data, token) =>
  ajax
    .post(`${process.env.REACT_APP_API_URL}/aToFAndFlightPlan`, JSON.stringify(data), {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    })
    .pipe(map((response) => response.response));
*/
