import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableCell, TableRow, TableBody } from '@material-ui/core';
import SubjectSubTable from '../Common/SubjectSubTable';
import D2AAnnualGrowthTable from '../D2AAnnualGrowthTable';
import D2AAcceleratedLearningTable from '../D2AAcceleratedLearningTable';
import SectionMsg from '../Common/SectionMsg';

const styles = (theme) => ({
  root: {},
  all: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #E1E1E1;',
  },
  reading: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(248, 220, 83, 0.7);',
  },
  math: {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(92, 186, 243, 0.7);',
  },
  menu: {},
  textField: {},
  formControl: {
    display: 'block',
  },
  finalTable: {
    width: 300,
  },
});

const indicators = {
  all: 'All Students',
  black: 'African American',
  hispanic: 'Hispanic',
  white: 'White',
  nativeAmerican: 'American Indian',
  asian: 'Asian',
  pacificIslander: 'Pacific Islander',
  twoOrMoreRaces: 'Two or More Races',
  ecoDis: 'Economically Disadvantaged',
  lep: 'EL (Current & Monitored)',
  specialEdCurrent: 'Special Ed (Current)',
  specialEdFormer: 'Special Ed (Former)',
  continuouslyEnrolled: 'Continuously Enrolled',
  // nonContinuouslyEnrolled: 'Non-Continuously Enrolled',
  highlyMobile: 'Highly Mobile',
  highFocus: 'High Focus',
  acceleratedTesterMath: 'Accelerated Tester Math',
  acceleratedTesterReading: 'Accelerated Tester Reading',
  acceleratedTesterScience: 'Accelerated Tester Science',
};

const types = {
  all: 'All Subjects',
  reading: 'Reading',
  math: 'Math',
};

const CustomTableCell = withStyles((theme) => ({
  head: {
    padding: 8,
    textAlign: 'center',
  },
  body: {
    padding: '8px 4px',
  },
}))(TableCell);

class D2aTable extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    report: PropTypes.object.isRequired,
  };

  render() {
    const { report, classes } = this.props;
    const { tables, msg } = report.d2a;
    const rowComponents = [
      // { label: '# Limited Progress', path: 'count.limitedProgress', studentsPath: 'limitedProgress' },
      { label: 'Annual Growth', path: '', studentsPath: '', isHeader: true },
      { label: '# Zero Point', path: 'count.zeroPoint', studentsPath: 'zeroPoint' },
      { label: '# Half Point', path: 'count.halfPoint', studentsPath: 'halfPoint' },
      { label: '# One Point', path: 'count.onePoint', studentsPath: 'onePoint' },

      { label: 'Accelerated Learning', path: '', studentsPath: '', isHeader: true },
      {
        label: '# Unsuccessfully Accelerated Tests',
        path: 'count.unsuccessfullyAcceleratedLearningTests',
        studentsPath: 'unsuccessfullyAcceleratedLearningTests',
      },
      {
        label: '# Successfully Accelerated Tests (1/4 point)',
        path: 'count.successfullyAcceleratedLearningTests',
        studentsPath: 'successfullyAcceleratedLearningTests',
      },
      { label: 'Totals', path: '', studentsPath: '', isHeader: true },
      { label: '# Total Points', path: 'count.total' }, // Numerator
      { label: '# Total Tests', path: 'totalTests' }, // Denominator
      { label: 'Total %', path: 'percent.total' }, // Quotient
    ];
    return (
      <div id="reportD2a" key="reportD2a">
        {!!msg && <SectionMsg msg={report.d2a.msg} />}
        {Object.entries(types).map(([name, label]) => (
          <div key={name + 'div'} id={name + 'div'}>
            <Table key={name + 'table'} className={classes[name]}>
              <TableHead>
                <TableRow>
                  <CustomTableCell />
                  {Object.entries(indicators).map(([key1, label1], index) => (
                    <CustomTableCell key={key1} style={index % 2 ? { background: 'rgba(255,255,255,0.4)' } : {}}>
                      {label1}
                    </CustomTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <SubjectSubTable
                  studentsListPath={`d2a/${name}`}
                  subtableLabel={label}
                  subtable={tables[name]}
                  rowComponents={rowComponents}
                />
              </TableBody>
            </Table>
            {name != 'all' && (
              <D2AAnnualGrowthTable
                subjectName={name}
                classes={classes}
                studentsListPath={`d2aAnnualGrowth/${name}`}
                subtableLabel={label}
                subtable={tables[name]}
                rowComponents={rowComponents}
              />
            )}

            {name != 'all' && (
              <D2AAcceleratedLearningTable
                subjectName={name}
                classes={classes}
                studentsListPath={`d2aAcceleratedLearning/${name}`}
                subtableLabel={label}
                subtable={tables[name]}
                rowComponents={rowComponents}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(D2aTable);
