export const ASYNC_START = 'async.start';
export const asyncStart = (model, msg, data) => ({
  model,
  msg,
  data,
  type: ASYNC_START,
});
export const ASYNC_PROGRESS = 'async.progress';
export const asyncProgress = (model, data) => ({
  model,
  data,
  type: ASYNC_PROGRESS,
});
export const ASYNC_FINISH = 'async.finish';
export const asyncFinish = (model, data) => ({
  model,
  data,
  type: ASYNC_FINISH,
});
export const ASYNC_ERROR = 'async.error';
export const asyncError = (model, desc, error, data = {}) => ({
  model,
  desc,
  error,
  data,
  type: ASYNC_ERROR,
});
