import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { asyncFinish } from '../../../common/+store/async';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import { GET_ORG_TAGS, SAVE_ORG_TAG, DELETE_ORG_TAG } from './ManageOrgTags.actions';
import { getOrgTags, saveOrgTag, deleteOrgTag } from './ManageOrgTags.api';

const getOrgTagsEpic = (action$) =>
  action$.pipe(
    ofType(GET_ORG_TAGS),
    switchMap((action) => from(getOrgTags()).pipe(commonApiCallFragment(action$, action, 'Getting Org Tags'))),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(GET_ORG_TAGS, results.response.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const saveOrgTagEpic = (action$) =>
  action$.pipe(
    ofType(SAVE_ORG_TAG),
    switchMap((action) => from(saveOrgTag(action.data)).pipe(commonApiCallFragment(action$, action, 'Save Org Tag'))),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(SAVE_ORG_TAG, results.response.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const deleteOrgTagEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_ORG_TAG),
    switchMap((action) =>
      from(deleteOrgTag(action.data)).pipe(commonApiCallFragment(action$, action, 'Delete Org Tag'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(DELETE_ORG_TAG, results.response.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const epics = combineEpics(getOrgTagsEpic, saveOrgTagEpic, deleteOrgTagEpic);

export default epics;
