import commoneStyles from '../CCMRCommon/CCMRCommon.styles';

const styles = (theme) => ({
  ...commoneStyles(theme),
  scoreBoardRoot: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 16,
  },
  rawScoreWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  editOnOffText: {
    whiteSpace: 'nowrap',
    paddingRight: '2px',
    color: '#656565',
    fontFamily: 'Questrial',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    textAlign: 'center',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scoreWrapper: {
    margin: '0 20px',
    borderRight: `1px solid ${theme.palette.primary.main}`,
    paddingRight: 20,
  },
  iconsContainer: {
    width: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    height: 30,
    width: 30,
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    cursor: 'pointer',
  },
  editingIconContainer: {
    border: `1px solid ${theme.palette.success.main}`,
  },
  editingIconColor: {
    color: theme.palette.success.main,
  },
  yesNoInfoDiv: {
    fontFamily: 'Questrial',
    fontSize: '14px',
    lineHeight: '14px',
    textAlign: 'center',
    color: '#6C6C6C',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: -5,
  },
  yesNoInfoLabel: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    margin: '5px',
  },
  filterContainer: {
    width: 250,
    background: 'white',
    padding: theme.spacing.unit * 2,
    position: 'absolute',
    top: 150,
    zIndex: 20,
    right: 65,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
});

export default styles;
