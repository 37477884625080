import { connect } from 'react-redux';

import Component from './StudentForm.component';
import {
  parseStudentDbToForm,
  parseStudentUiToDb,
  getCurrentRosterYear,
} from '../+store/studentRoster/studentRoster.utils';
import {
  editStudentRequestCancel,
  editStudentConfirm,
  addStudentRequestCancel,
  addStudentConfirm,
  changeProfilePicture,
  profilePictureEditClose,
  uploadProfilePicture,
} from '../+store/studentRoster/studentRoster.actions';

const mapStateToProps = (state) => ({
  profile: state.modules.Auth.auth.profile,
  roster: state.modules.StudentRoster.studentRoster.roster,
  tags: state.modules.Settings.manageOrgTags.tags,
  snapshotYear: state.modules.StudentRoster.studentRoster.selectedSnapshotYear,
  sid: state.modules.StudentRoster.studentRoster.selectedStudentToEdit,
  newStudent: state.modules.StudentRoster.studentRoster.isAddingNewStudent,
  cPP: state.modules.StudentRoster.studentRoster.isProfilePictureModalOpen,
  closePP: state.modules.StudentRoster.studentRoster.closePP,
  user: state.modules.Auth.auth.profile,
  uploadPPMsg: state.modules.StudentRoster.studentRoster.uploadProfilePictureMessage,
  uploadPP: !!state.modules.StudentRoster.studentRoster.uploadProfilePictureMessage,
  avatars: state.modules.StudentRoster.studentRoster.avatars,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { ...newStateProps } = stateProps;
  const orgId = newStateProps.user.organizationId;
  const studentId = newStateProps.sid;
  const isNewStudent = newStateProps.newStudent;
  const isProfilePictureModalOpen = newStateProps.cPP;
  const { closePP, profile, uploadPP, uploadPPMsg, snapshotYear } = newStateProps;
  const year = snapshotYear || getCurrentRosterYear();
  let student = null;
  if (!isNewStudent) {
    student = parseStudentDbToForm(newStateProps.roster[year][newStateProps.sid]);
  } else {
    student = parseStudentDbToForm();
  }
  return {
    ...ownProps,
    profile,
    student,
    uploadPP,
    uploadPPMsg,
    isProfilePictureModalOpen,
    closePP,
    handleCancel: () => {
      if (isNewStudent) {
        dispatch(addStudentRequestCancel());
      } else {
        dispatch(editStudentRequestCancel());
      }
    },
    handleSubmit: (values) => {
      const studentObj = parseStudentUiToDb(values);
      if (isNewStudent) {
        dispatch(addStudentConfirm(orgId, studentObj, year));
      } else {
        dispatch(editStudentConfirm(studentId, orgId, studentObj, year));
      }
    },
    handleChangePP: () => {
      dispatch(changeProfilePicture(isProfilePictureModalOpen));
    },
    handlePPEditClose: () => {
      dispatch(profilePictureEditClose(isProfilePictureModalOpen));
    },
    onDrop: (accepted) => {
      dispatch(uploadProfilePicture(accepted[0], profile, studentId, year));
    },
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);

export default Container;
