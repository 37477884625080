import { connect } from 'react-redux';
import { initStudentsRoster, getAvatars } from '../StudentRoster/+store/studentRoster';
import { getAccountabilityWeights } from './+store/accountabilityWeight/accountabilityWeight.actions';
import {
  sortObjByProperty,
  filterObjByProperty,
  getTeachers,
  filterBySubject,
  filterByTeacher,
  ethnicity,
  indicators,
  testSubjects,
  gradeLevels,
  resetFilters,
  filterByTag,
} from '../common/+store/FilterBox';
import Component from './AccountabilityWeight.component';
import { selectWeightedStudents } from './+store/accountabilityWeight/accountabilityWeight.selectors';
import { isMultipleCampusAdmin } from '../common/utils';

const filterStudents = (students, selectedFilterItems, model, sortByKeys, sortReverse) => {
  let filteredStudents = students;
  const customFunctions = {
    teacher: filterByTeacher,
    subject: filterBySubject,
    tags: filterByTag,
  };
  filteredStudents = filterObjByProperty(filteredStudents, selectedFilterItems.filterOptions, customFunctions);
  filteredStudents = sortObjByProperty(filteredStudents, sortByKeys, sortReverse);
  return Object.entries(filteredStudents);
};

const mapStateToProps = (state) => {
  const students = selectWeightedStudents(state);
  const { tags } = state.modules.Settings.manageOrgTags;
  const { selectedFilterItems, model, sortByKeys, sortReverse } = state.modules.Common.filterBox;
  const { profile } = state.modules.Auth.auth;
  const { schoolList } = state.modules.Settings.schoolSettings;
  const sortOptions = {
    accWeight: { label: 'Accountability Weights', reverse: true, keys: ['accWeight'] },
    firstName: { label: 'First Name', reverse: false, keys: ['firstName'] },
    lastName: { label: 'Last Name', reverse: false, keys: ['lastName'] },
    studentId: { label: 'Student ID', reverse: false, keys: ['studentId'] },
  };
  const teachersOptions = getTeachers(students);
  const filterBoxParams = {
    model: 'accountabilityWeight',
    sortSection: { label: 'SORT BY', options: sortOptions },
    filterSections: [
      {
        id: 'gradeLevel',
        filterKey: 'gradeLevel',
        label: 'Grade Level',
        values: gradeLevels,
        type: 'list',
      },
      {
        id: 'ethnicity',
        filterKey: 'indicators',
        label: 'Ethnicity',
        values: ethnicity,
        type: 'boolean',
      },
      {
        id: 'otherIndicators',
        filterKey: 'indicators',
        label: 'Student Indicators',
        values: indicators,
        type: 'boolean',
      },
      {
        id: 'subjects',
        filterKey: 'subject',
        label: 'Test Subjects',
        values: testSubjects,
        type: 'custom',
      },
    ],
  };
  if (teachersOptions && teachersOptions.length) {
    filterBoxParams.filterSections.push({
      id: 'teachers',
      filterKey: 'teacher',
      label: 'Test Teachers',
      values: teachersOptions,
      type: 'custom',
    });
  }
  if (tags) {
    const tagsOptions = Object.entries(tags).map(([firebaseId, tag]) => ({ value: firebaseId, label: tag.name }));
    if (tagsOptions && tagsOptions.length) {
      filterBoxParams.filterSections.push({
        id: 'tags',
        filterKey: 'tags',
        label: 'Tags',
        values: tagsOptions,
        type: 'custom',
      });
    }
  }
  if (schoolList && profile && (profile.role === 'districtAdmin' || isMultipleCampusAdmin(profile))) {
    const schoolListOptions = Object.entries(schoolList)
      .filter(([campusNumber, school]) => campusNumber.length !== 6)
      .map(([campusNumber, school]) => ({ value: campusNumber, label: school.name }));
    filterBoxParams.filterSections.push({
      id: 'campusNumber',
      filterKey: 'campusNumber',
      label: 'Campuses',
      values: schoolListOptions,
      type: 'list',
    });
  }
  return {
    profile,
    filterBoxParams,
    isEmpty: Object.keys(students).length === 0,
    students: filterStudents(students, selectedFilterItems, model, sortByKeys, sortReverse),
    username: state.modules.Auth.auth.user.displayName,
    user: state.modules.Auth.auth.user,
    needsInit: state.modules.AccountabilityWeight.accountabilityWeight.needsInit,
    loadingMsg: state.modules.AccountabilityWeight.accountabilityWeight.loadingMsg,
    loading: !!state.modules.AccountabilityWeight.accountabilityWeight.loadingMsg,
    avatars: state.modules.StudentRoster.studentRoster.avatars,
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: (user) => {
    dispatch(initStudentsRoster(user));
    dispatch(getAvatars());
    dispatch(resetFilters());
  },
  getWeights: (campusDistrictNumber) => {
    dispatch(getAccountabilityWeights(campusDistrictNumber));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
