export default () => ({
  base: {
    // borderRight: '1px solid #656565',
    marginRight: 10,
    // paddingRight: 40,
    marginLeft: 'auto',
    height: 38,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  menuItem: {
    padding: 0,
    height: 'unset',
    '&:hover,&:focus': {
      backgroundColor: 'white',
    },
  },
  sublist: {
    flexDirection: 'column',
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paper: {
      margin: 0,
    },
  },
  panel: {
    boxShadow: 'none',
    width: '100%',
    '&:before': {
      backgroundColor: 'white',
    },
    margin: 0,
  },
  header: {
    paddingLeft: 16,
    fontSize: 18,
    paddingTop: 8,
  },
  yesIcon: {
    color: '#79f5ab',
    marginRight: 8,
    fontSize: '15px',
  },
  noIcon: {
    color: '#FF5656',
    marginRight: 8,
    fontSize: '15px',
  },
  row: {
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 20,
    alignItems: 'center',
    minHeight: 36,
  },
  noPadding: {
    padding: 0,
  },
});
