const isCCMR = (file) =>
  new Promise((resolve) => {
    var assessmentYear = 0;
    if (
      file.type != 'application/vnd.ms-excel' &&
      file.type != 'text/csv' &&
      file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      resolve(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      const lines = text.split(/\r\n?|\n/);
      const lookingFor = {
        confidential: false,
        tea: false,
        desc: false,
      };
      lines.forEach((line) => {
        if (line.includes('C O N F I D E N T I A L')) {
          lookingFor.confidential = true;
        }
        if (line.includes('TEXAS EDUCATION AGENCY')) {
          lookingFor.tea = true;
        }
        var yearLine = 'College, Career, and Military Readiness Student Listing';
        if (line.includes(yearLine)) {
          assessmentYear = line.substr(line.indexOf(yearLine) - 5, 4);
        }
        if (line.includes('District and Campus Student Listing')) {
          lookingFor.desc = true;
        }
      });
      if (Object.values(lookingFor).every((x) => x)) {
        resolve({ isValid: true, assessmentYear: assessmentYear });
      } else {
        resolve({ isValid: false });
      }
    };
    reader.readAsText(file);
  });

export default isCCMR;
