import { createSelector } from 'reselect';
import { getCurrentRosterYear } from '../../../StudentRoster/+store/studentRoster';

const getStudents = (state) => {
  const students = state.modules.StudentRoster.studentRoster.roster;
  const currentSnapshotYear = getCurrentRosterYear();
  return students && students[currentSnapshotYear];
};

const getWeights = (state) => state.modules.AccountabilityWeight.accountabilityWeight.weights;

export const selectWeightedStudents = createSelector(getStudents, getWeights, (students, weights) => {
  if (!students) return {};
  if (!weights) return {};
  return Object.entries(students).reduce((prev, [studentId, student]) => {
    prev[studentId] = Object.assign({}, student, {
      accWeight: weights[studentId] || 0,
    });
    return prev;
  }, {});
});

export default 'accountabilityWeight.selectors.js';
