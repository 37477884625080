import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableCell, TableRow, TableBody, Typography } from '@material-ui/core';
import { ReportSectionKeys } from '../+store/aToF';
import { numberStringOrDash } from '../D3/Common/utils';

const styles = {
  root: {
    marginBottom: '200px',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(134, 240, 255, 0.65)',
  },
  componentRow: {
    height: '24px',
    // background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(61, 232, 255, 0.65)',
  },
  subComponentRow: {
    height: '24px',
    // background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(134, 240, 255, 0.65)',
  },
  clickCell: {
    cursor: 'pointer',
  },
  componentCell: {
    fontWeight: 'bolder',
    letterSpacing: '1px',
    color: '#585858',
  },
  subComponentCell: {
    paddingLeft: '30px',
  },
  centerText: {
    textAlign: 'center',
  },
  whiteBg: {
    backgroundColor: 'rgba(256, 256, 256, 0.5)',
  },
  summaryTableNote: {
    color: 'darkGray',
    fontSize: 'x-small',
  },
};

const SummaryTableCell = withStyles((theme) => ({
  head: {
    padding: 8,
    textAlign: 'center',
  },
  body: {
    padding: '8px 4px',
  },
}))(TableCell);

const getMaxOfThree = (a, b, c) => {
  // Filter out NaN values
  const numbers = [a, b, c].filter((num) => !isNaN(num));

  // If all values are NaN, return NaN
  if (numbers.length === 0) {
    return NaN;
  }

  // Find the maximum value among the non-NaN numbers
  return Math.max(...numbers);
};

const displayAmountWithAsterisk = (report, amount) => {
  if (report) {
    var d1Amount = report.d1 != null && report.d1.overall != null ? report.d1.overall.score : null;
    var d2Amount = report.d2a != null ? report.d2a.scaled : null;
    var d3Amount = report.d2b != null ? report.d2b.scaled : null;
    var bestOfFirstThreeDomains = getMaxOfThree(d1Amount, d2Amount, d3Amount);
    if (amount && amount >= bestOfFirstThreeDomains) {
      return amount + ' * ';
    }
  }
  return amount;
};
class Summary extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    report: PropTypes.object.isRequired,
    onChangeReportSection: PropTypes.func.isRequired,
    isFreeAccount: PropTypes.bool.isRequired,
  };

  render() {
    const { report, classes, isFreeAccount, onChangeReportSection } = this.props;
    const summaryTable = [
      {
        component: true,
        cols: [
          'Domain I: Student Achievement',
          displayAmountWithAsterisk(report, report.d1.overall.score),
          report.d1.overall.letter,
        ],
      },
    ];
    if (report.d1.staar && report.d1.staar.scaled && report.d1.staar.letter) {
      summaryTable.push({
        component: false,
        key: ReportSectionKeys.d1staar,
        cols: ['STAAR Performance', report.d1.staar.scaled, report.d1.staar.letter],
      });
    }

    if (report.d1.ccmr && report.d1.ccmr.raw) {
      summaryTable.push({
        component: false,
        key: ReportSectionKeys.d1ccmr,
        cols: [
          'CCMR',
          !isNaN(report.d1.ccmr.scaled) && parseFloat(report.d1.ccmr.scaled).toFixed(),
          report.d1.ccmr.letter,
        ],
        isNotClickable: report.ccmrSource == 'manual',
      });
    }
    if (report.d1.gradRate && report.d1.gradRate.scaled) {
      summaryTable.push({
        component: false,
        key: ReportSectionKeys.d1gradRate,
        cols: ['Graduation Rate', report.d1.gradRate.scaled, report.d1.gradRate.letter],
      });
    }

    summaryTable.push({
      component: true,
      cols: ['Domain II: School Progress', '', ''],
    });

    summaryTable.push({
      component: false,
      key: ReportSectionKeys.d2a,
      cols: [
        'Part A: Academic Growth',
        displayAmountWithAsterisk(report, numberStringOrDash(report.d2a.scaled)),
        numberStringOrDash(report.d2a.letter),
      ],
    });
    summaryTable.push({
      component: false,
      key: ReportSectionKeys.d2b,
      cols: [
        `Part B: Relative Performance (Eco Dis: ${report.d2b.ecoDis}%)`,
        displayAmountWithAsterisk(report, !isNaN(report.d2b.scaled) && parseFloat(report.d2b.scaled).toFixed(0)),
        report.d2b.letter,
      ],
    });
    summaryTable.push({
      component: true,
      key: ReportSectionKeys.d3,
      cols: ['Domain III: Closing the Gaps', report.d3.scaled, report.d3.letter],
    });

    /*summaryTable.push({
      component: true,
      key: ReportSectionKeys.campusesWeights,
      cols: ['Campuses Weights', '', ''],
    });
    */

    return (
      <div id="reportSummary" className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <SummaryTableCell />
              <SummaryTableCell>Scaled Score</SummaryTableCell>
              <SummaryTableCell>Letter Grade</SummaryTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summaryTable.map((row, index) => (
              <TableRow key={index} className={row.component ? classes.componentRow : classes.subComponentRow}>
                {row.cols.map((cell, index2) => {
                  let isClickable = index2 === 0 && !isFreeAccount && row.key;

                  const handleTitleClicked = () => {
                    if (isClickable && !row.isNotClickable) {
                      onChangeReportSection(row.key);
                    }
                  };
                  return (
                    <SummaryTableCell
                      key={index2}
                      className={classNames({
                        [classes.componentCell]: !!row.component,
                        [classes.subComponentCell]: !row.component && index2 == 0,
                        [classes.centerText]: index2 > 0,
                        [classes.whiteBg]: index2 % 2 !== 0,
                        [classes.clickCell]: isClickable && !row.isNotClickable,
                      })}
                      onClick={handleTitleClicked}
                    >
                      {cell}
                    </SummaryTableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography className={classes.summaryTableNote}>
          * indicates the domain that comprises 70% of the overall score
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Summary);
