import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { Grid, TextField, withStyles, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { Button } from '../../common';
import { testSubjects } from '../+store/studentRoster/studentRoster.utils';

const styles = (theme) => ({
  button: {
    marginLeft: 0,
  },
  subtitle: {
    marginTop: 3 * theme.spacing.unit,
  },
});

class StudentStaarForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    student: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    profile: PropTypes.object,
  };

  componentDidMount = () => {
    this.shouldInit();
  };

  shouldInit() {
    const { init, studentId, profile, loading } = this.props;
    if (profile && !loading) {
      init(studentId);
    }
  }

  getValidationSchema = (tests) => {
    let validationModel = {};
    if (tests != null) {
      tests.forEach((test, index) => {
        //var rawScoreKey = `studentPriorStaarTests.${index}.rawScore`;
        var scaleScoreKey = `studentPriorStaarTests.${index}.scaleScore`;
        //validationModel[rawScoreKey] = Yup.string().required(`Please insert a valid rawScore number record ${index}`);
        //validationModel[scaleScoreKey] =Yup.string().required(`Please insert a valid scaleScore number record ${index}`);
      });
    }
    let validationSchema = Yup.object().shape(validationModel);
    return validationSchema;
  };

  getStudentPriorStaarModel = () => {
    const { studentPriorStaarModel, loadedStudentPriorStaarModel, priorSnapshotYear } = this.props;
    if (loadedStudentPriorStaarModel) {
      if (
        studentPriorStaarModel == null ||
        studentPriorStaarModel.studentPriorStaarTests == null ||
        studentPriorStaarModel.studentPriorStaarTests.length == 0
      ) {
        var studentPriorStaarModelForAdd = {};
        studentPriorStaarModelForAdd.studentPriorStaarTests = [];
        Object.entries(testSubjects)
          .sort()
          .map(([key, value]) => {
            let administrationDate = priorSnapshotYear + 1 + '-05-01';
            let subject = key;
            let assessmentId = 'STAAR' + (priorSnapshotYear + 1);
            let testModel = {
              testId: `${administrationDate}:${subject}:${assessmentId}`,
              subject: subject,
              scaleScore: null,
              //rawScore: null,
              assessmentId: assessmentId,
              administrationDate: administrationDate,
              testedGrade: null,
            };
            studentPriorStaarModelForAdd.studentPriorStaarTests.push(testModel);
          });
        return studentPriorStaarModelForAdd;
      } else {
        return studentPriorStaarModel;
      }
    }
    return null;
  };

  renderTests = (values) => {
    //const grades = ['3', '4', '5', '6', '7', '8'];
    //const elemSubjects = ['reading', 'math', 'writing', 'socialStudies', 'science'];
    //const { classes } = this.props;
    return (
      <FieldArray
        name="tests"
        render={(arrayHelpers) => (
          <Grid container direction="column" justify="center" alignItems="flex-start">
            {values.studentPriorStaarTests.map((test, index) => {
              test.subjectName = testSubjects[test.subject];
              return (
                <Grid container spacing={24} key={index} style={{ textAlign: 'left' }}>
                  <Grid item xs={3} sm={3}>
                    <Field
                      key={`studentPriorStaarTests.${index}.administrationDate`}
                      name={`studentPriorStaarTests.${index}.administrationDate`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id={`studentPriorStaarTests.${index}.administrationDate`}
                          label="Administration Date"
                          margin="normal"
                          disabled={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Field
                      key={`studentPriorStaarTests.${index}.subjectName`}
                      name={`studentPriorStaarTests.${index}.subjectName`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id={`studentPriorStaarTests.${index}.subjectName`}
                          label="Subject"
                          margin="normal"
                          disabled={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <Field
                      key={`studentPriorStaarTests.${index}.testedGrade`}
                      name={`studentPriorStaarTests.${index}.testedGrade`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id={`studentPriorStaarTests.${index}.testedGrade`}
                          label="Tested Grade"
                          margin="normal"
                          type="number"
                          disabled={true}
                        />
                      )}
                    />
                    <Typography
                      variant="caption"
                      gutterBottom
                      color="error"
                      align="left"
                      style={{ paddingRight: '16px' }}
                    >
                      <ErrorMessage
                        key={`err${`studentPriorStaarTests.${index}.testedGrade`}`}
                        name={`studentPriorStaarTests.${index}.testedGrade`}
                      />
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={2} sm={2}>
                  <Field
                    key={`studentPriorStaarTests.${index}.rawScore`}
                    name={`studentPriorStaarTests.${index}.rawScore`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id={`studentPriorStaarTests.${index}.rawScore`}
                        label="Raw Score"
                        margin="normal"
                        type="number"
                      />
                    )}
                  />
                  <Typography
                    variant="caption"
                    gutterBottom
                    color="error"
                    align="left"
                    style={{ paddingRight: '16px' }}
                  >
                    <ErrorMessage
                      key={`err${`studentPriorStaarTests.${index}.rawScore`}`}
                      name={`studentPriorStaarTests.${index}.rawScore`}
                    />
                  </Typography>
                </Grid> */}
                  <Grid item xs={2} sm={2}>
                    <Field
                      key={`studentPriorStaarTests.${index}.scaleScore`}
                      name={`studentPriorStaarTests.${index}.scaleScore`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id={`studentPriorStaarTests.${index}.scaleScore`}
                          label="Scale Score"
                          margin="normal"
                          type="number"
                        />
                      )}
                    />
                    <Typography
                      variant="caption"
                      gutterBottom
                      color="error"
                      align="left"
                      style={{ paddingRight: '16px' }}
                    >
                      <ErrorMessage
                        key={`err${`studentPriorStaarTests.${index}.scaleScore`}`}
                        name={`studentPriorStaarTests.${index}.scaleScore`}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}
      />
    );
  };

  render() {
    const { handleSubmit, handleCancel, classes, loadedStudentPriorStaarModel, priorSnapshotYear } = this.props;
    let testsModel = this.getStudentPriorStaarModel();
    return (
      <div>
        <div>
          <h3>
            Prior Snapshot Year {priorSnapshotYear}- STAAR {priorSnapshotYear + 1}{' '}
          </h3>
          {!loadedStudentPriorStaarModel && <div>Loading Student Prior Staar....</div>}
        </div>
        {testsModel && (
          <Formik
            initialValues={testsModel}
            validationSchema={this.getValidationSchema(testsModel.studentPriorStaarTests)}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {this.renderTests(values)}
                {
                  <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Button submit color="dark" className={classes.button}>
                      Save Student Staar
                    </Button>
                    <Button color="outlined" onClick={handleCancel} className={classes.button}>
                      Cancel
                    </Button>
                  </Grid>
                }
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(StudentStaarForm);
