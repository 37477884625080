import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Table, TableCell, TableRow, TableBody } from '@material-ui/core';

const styles = {
  root: {
    marginBottom: '200px',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(134, 240, 255, 0.65)',
  },
  componentRow: {
    // background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(61, 232, 255, 0.65)',
  },
  subComponentRow: {
    // background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(134, 240, 255, 0.65)',
  },
  clickCell: {
    cursor: 'pointer',
  },
  componentCell: {
    fontWeight: 'bold',
    letterSpacing: '1px',
    color: '#585858',
  },
  subComponentCell: {},
  centerText: {
    textAlign: 'center',
  },
  whiteBg: {
    backgroundColor: 'rgba(256, 256, 256, 0.5)',
  },
};

const SummaryTableCell = withStyles((theme) => ({
  head: {
    padding: 8,
    textAlign: 'center',
  },
  body: {
    padding: '8px 4px',
  },
}))(TableCell);

class CampusesWeights extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    report: PropTypes.object.isRequired,
  };

  render() {
    const { report, classes } = this.props;

    return (
      <div id="reportSummary" className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <SummaryTableCell>School</SummaryTableCell>
              <SummaryTableCell>School Name</SummaryTableCell>
              <SummaryTableCell>Enrollment</SummaryTableCell>
              <SummaryTableCell>Weight</SummaryTableCell>

              <SummaryTableCell>Student Achievement D1</SummaryTableCell>
              <SummaryTableCell>D1 Points</SummaryTableCell>

              <SummaryTableCell>Academic Growth D2A</SummaryTableCell>
              <SummaryTableCell>D2A Points</SummaryTableCell>

              <SummaryTableCell>Relative Performance D2B</SummaryTableCell>
              <SummaryTableCell>D2B Points</SummaryTableCell>

              <SummaryTableCell>Closing the Gaps D3</SummaryTableCell>
              <SummaryTableCell>D3 Points</SummaryTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report &&
              report.campusesWeights &&
              Object.entries(report.campusesWeights).map((row, index) => (
                <TableRow key={index}>
                  <SummaryTableCell>{row[1].campusNumber}</SummaryTableCell>
                  <SummaryTableCell>{row[1].campusName}</SummaryTableCell>
                  <SummaryTableCell>{row[1].grade3To12StudentsCount}</SummaryTableCell>
                  <SummaryTableCell>{(row[1].studentsWeight * 100).toFixed(2)}%</SummaryTableCell>

                  <SummaryTableCell>{row[1].scoreDomain1}</SummaryTableCell>
                  <SummaryTableCell>{row[1].pointsDomain1.toFixed(2)}</SummaryTableCell>

                  <SummaryTableCell>{row[1].scoreDomain2a}</SummaryTableCell>
                  <SummaryTableCell>{row[1].pointsDomain2a.toFixed(2)}</SummaryTableCell>

                  <SummaryTableCell>{row[1].scoreDomain2b}</SummaryTableCell>
                  <SummaryTableCell>{row[1].pointsDomain2b.toFixed(2)}</SummaryTableCell>

                  <SummaryTableCell>{row[1].scoreDomain3}</SummaryTableCell>
                  <SummaryTableCell>{row[1].pointsDomain3.toFixed(2)}</SummaryTableCell>
                </TableRow>
              ))}

            {report && report.districtDomains && (
              <TableRow key={'districtLastRow'}>
                <SummaryTableCell>{report.districtDomains.districtNumber}</SummaryTableCell>
                <SummaryTableCell>{report.districtDomains.districtName}</SummaryTableCell>
                <SummaryTableCell>{report.districtDomains.grade3To12StudentsCount}</SummaryTableCell>
                <SummaryTableCell>{}</SummaryTableCell>

                <SummaryTableCell>{report.districtDomains.totalPointsDomain1.toFixed(2)}</SummaryTableCell>
                <SummaryTableCell>{}</SummaryTableCell>

                <SummaryTableCell>{report.districtDomains.totalPointsDomain2a.toFixed(2)}</SummaryTableCell>
                <SummaryTableCell>{}</SummaryTableCell>

                <SummaryTableCell>{report.districtDomains.totalPointsDomain2b.toFixed(2)}</SummaryTableCell>
                <SummaryTableCell>{}</SummaryTableCell>

                <SummaryTableCell>{report.districtDomains.totalPointsDomain3.toFixed(2)}</SummaryTableCell>
                <SummaryTableCell>{}</SummaryTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(CampusesWeights);
