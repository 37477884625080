import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow, IconButton, Icon } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
//import EditIcon from '@material-ui/icons/Edit';
import EditIcon from '@material-ui/icons/FilterNone';

import { studentInfoLabels as headers, ethnicityOption, sexOptions } from '../+store/studentRoster/studentRoster.utils';

const styles = (theme) => ({
  cell: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    flexShrink: 0,
    flexGrow: 0,
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  clickableCell: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    flexShrink: 0,
    flexGrow: 0,
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    cursor: 'pointer',
  },
  testCell: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    flexShrink: 0,
    flexGrow: 0,
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  profilePicture: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  iconCell: {
    color: theme.palette.primary.main,
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    flexShrink: 0,
    flexGrow: 0,
  },
  studentRow: {
    display: 'flex',
    minHeight: '80px',
    width: '100%',
  },
  noIcon: {
    width: 50,
    color: '#656565',
    fontSize: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
  },
});

class Student extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    student: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    rosterTableHeaders: PropTypes.object,
    studentAvatar: PropTypes.string,
    handleEditProfilePicture: PropTypes.func.isRequired,
  };

  deleteIcon = () => (
    <IconButton style={{ padding: '4px' }} onClick={this.props.handleDelete}>
      <DeleteIcon color="error" />
    </IconButton>
  );

  editIcon = () => (
    <IconButton style={{ padding: '4px' }} onClick={this.props.handleEdit}>
      <EditIcon color="primary" />
    </IconButton>
  );

  render() {
    const { student, classes, rosterTableHeaders, studentAvatar, handleEditProfilePicture } = this.props;
    const name = `${student.firstName} ${student.middleName} ${student.lastName}`;
    const ethnicity = ethnicityOption[student.ethnicity];
    const sexCode = student.sexCode ? sexOptions[student.sexCode] : null;
    const studentHeaders = rosterTableHeaders || headers;
    const dateOfBirth = student.dateOfBirth ? moment(student.dateOfBirth, 'YYYYMMDD').format('MM/DD/YYYY') : null;
    const studentForTable = {
      ...student,
      name,
      ethnicity,
      sexCode,
      dateOfBirth,
    };
    const tableHeaders = Object.keys(studentHeaders);
    return (
      <TableRow className={classes.studentRow} hover>
        <TableCell
          className={classes.iconCell}
          style={{
            width: 100,
          }}
        >
          {this.editIcon()}
          {this.deleteIcon()}
        </TableCell>
        <TableCell
          className={classes.clickableCell}
          style={{
            width: 60,
          }}
          onClick={handleEditProfilePicture}
        >
          {studentForTable.photoUrl || studentAvatar ? (
            <img src={studentForTable.photoUrl || studentAvatar} alt="Logo" className={classes.profilePicture} />
          ) : (
            <Icon className={classes.noIcon}>person</Icon>
          )}
        </TableCell>
        {tableHeaders.map((header) => (
          <TableCell
            key={header}
            align="center"
            className={classes.cell}
            style={{
              width: 1550 / tableHeaders.length,
            }}
          >
            {studentForTable[header] == null ? '-' : studentForTable[header]}
          </TableCell>
        ))}
        <TableCell
          className={classes.cell}
          key="tests"
          align="center"
          style={{
            width: 150,
            display: 'flex',
          }}
        >
          {studentForTable.tests.map((test, index) => (
            <div key={`test${index}`} className={classes.testCell}>
              {test.subject && test.subject.toLowerCase() != 'writing'
                ? _.startCase(`${test.subject} ${test.teacher && `(${test.teacher})`}`)
                : '-'}
            </div>
          ))}
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(Student);
