import { connect } from 'react-redux';

import Component from './FilterItem.component';
import { updateFilterOptions } from '../../+store/FilterBox';

const mapStateToProps = (state, ownProps) => {
  const { selectedFilterItems, model } = state.modules.Common.filterBox;
  const items =
    selectedFilterItems.filterOptions && selectedFilterItems.filterOptions[ownProps.id]
      ? selectedFilterItems.filterOptions[ownProps.id].values
      : [];
  return {
    selected: ownProps.model === model ? items.includes(ownProps.item.value) : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleFilterOptionsChange: (isAdding) => {
    dispatch(
      updateFilterOptions(
        ownProps.id,
        ownProps.type,
        ownProps.filterKey,
        ownProps.item.value,
        ownProps.model,
        isAdding,
        ownProps.byRoster,
        ownProps.byCCMR
      )
    );
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
