import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import routes from './routes';

import { epics as errorEpics } from './errors';

export const reducer = combineReducers({
  routes,
});

export const epics = combineEpics(errorEpics);

export * from './firebase';
