import * as React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import ErrorIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Error';
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Modal,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import styles from './CCMRBulkEntry.styles';
import {
  calcStudentCCMRPoint,
  getUnknownStudentIds,
  mathColumns,
  readingColumns,
  editableHeaderColumns,
} from '../+store';
import { Button } from '../../common/Button';

const YesRadio = withStyles({
  root: {
    color: '#656565',
    '&$checked': {
      color: '#68D190',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const NoRadio = withStyles({
  root: {
    color: '#656565',
    '&$checked': {
      color: '#EE9696',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class CCMRBulkEntry extends React.PureComponent {
  static propTypes = {
    students: PropTypes.object,
    profile: PropTypes.object,
    ccmrData: PropTypes.object,
    bulkEntryKey: PropTypes.string,
    updateBulkCCMRTrackingData: PropTypes.func.isRequired,
    cancelSelectCCMRKey: PropTypes.func.isRequired,
    classes: PropTypes.object,
  };

  state = {
    studentIds: null,
    unknownIds: null,
    updatedStudents: null,
    checkedValue: '',
  };

  setCheckedValue = (event) => {
    this.setState({ checkedValue: event.target.value });
  };

  getKeyName = () => {
    const { bulkEntryKey } = this.props;
    if (mathColumns[bulkEntryKey]) {
      return `Math - ${mathColumns[bulkEntryKey]}`;
    }
    if (readingColumns[bulkEntryKey]) {
      return `Reading - ${readingColumns[bulkEntryKey]}`;
    }
    return editableHeaderColumns[bulkEntryKey] || bulkEntryKey;
  };

  onStudentIdsFormSubmit = (values) => {
    const { ccmrData } = this.props;
    const results = getUnknownStudentIds(values.studentIds, ccmrData);
    const studentIds = results[0];
    const unknownIds = results[1];
    const updatedStudents = {};
    studentIds.forEach(([id, firebaseId]) => {
      updatedStudents[firebaseId] = ccmrData[firebaseId];
    });
    this.setState({ studentIds, unknownIds, updatedStudents });
  };

  saveUpdatedCCMRStudents = () => {
    const { updateBulkCCMRTrackingData, profile } = this.props;
    const { updatedStudents } = this.state;
    this.updateAllCCMR();
    updateBulkCCMRTrackingData(profile, updatedStudents);
  };

  updateAllCCMR = () => {
    const { studentIds, checkedValue } = this.state;
    let value = true;
    if (checkedValue === 'no') {
      value = false;
    } else if (checkedValue === '-') {
      value = null;
    }
    studentIds.forEach(([id, firebaseId], index) => {
      this.updateCCMR(value, firebaseId);
    });
  };

  updateCCMR = (value, firebaseId) => {
    const { updatedStudents } = this.state;
    const { bulkEntryKey } = this.props;
    let updatedStudent = updatedStudents[firebaseId];
    updatedStudent[bulkEntryKey] = value;
    updatedStudent = calcStudentCCMRPoint(bulkEntryKey, updatedStudent);
    updatedStudents[firebaseId] = Object.assign({}, updatedStudents[firebaseId], updatedStudent);
    this.setState({ updatedStudents: Object.assign({}, updatedStudents) });
  };

  renderBulkEntryCCMR = () => {
    const { cancelSelectCCMRKey, classes } = this.props;
    const { checkedValue, studentIds } = this.state;
    const studentNum = studentIds.length;
    const keyName = this.getKeyName();
    return (
      <Grid key="bulkEntry" container direction="column" justify="space-between" alignItems="flex-start">
        <Typography variant="subtitle1" align="left" gutterBottom className={classes.warning}>
          <WarningIcon fontSize="small" className={classes.warningIcon} />
          {`You are about to update ${studentNum} student record${
            studentNum === 1 ? '' : 's'
          }. How would you like to update their ${keyName} field?`}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="value" name="value" value={checkedValue} onChange={this.setCheckedValue}>
            <FormControlLabel value="yes" control={<YesRadio />} label="Check All as Yes" />
            <FormControlLabel value="no" control={<NoRadio />} label="Check All as No" />
            <FormControlLabel value="-" control={<Radio color="primary" />} label="Uncheck All" />
          </RadioGroup>
        </FormControl>
        <div className={classes.buttonContainer}>
          <Button className={classes.button} color="dark" onClick={this.saveUpdatedCCMRStudents}>
            Save
          </Button>
          <Button className={classes.button} color="light" onClick={cancelSelectCCMRKey}>
            Cancel
          </Button>
        </div>
      </Grid>
    );
  };

  renderStudentIdsForm = () => {
    const { classes, cancelSelectCCMRKey } = this.props;
    return (
      <Formik
        initialValues={{
          studentIds: '',
        }}
        validationSchema={Yup.object().shape({
          studentIds: Yup.string().required('This field is required.'),
        })}
        onSubmit={this.onStudentIdsFormSubmit}
      >
        {() => (
          <Form>
            <Grid
              key="studentIds"
              container
              direction="column"
              justify="space-between"
              alignItems="flex-start"
              item
              xs={12}
              sm={4}
            >
              <Field
                key="studentIds"
                name="studentIds"
                render={({ field, form: { errors } }) => (
                  <TextField
                    {...field}
                    className={classes.ids}
                    multiline
                    rows="6"
                    variant="outlined"
                    id="studentIds"
                    label="Student ID(s)"
                    margin="normal"
                    helperText="*Accepted ID(s): Texas Unique ID (SSN), Local ID, 10-digit TSDS ID"
                    error={!!errors.studentIds}
                  />
                )}
              />
              <Typography variant="caption" gutterBottom color="error" align="left" style={{ paddingRight: '16px' }}>
                <ErrorMessage key="error" name="studentIds" />
              </Typography>
            </Grid>
            <Button className={classes.button} submit color="dark">
              Next
            </Button>
            <Button className={classes.button} color="light" onClick={cancelSelectCCMRKey}>
              Cancel
            </Button>
          </Form>
        )}
      </Formik>
    );
  };

  render = () => {
    const { cancelSelectCCMRKey, classes } = this.props;
    const { studentIds, unknownIds } = this.state;
    const keyName = this.getKeyName();
    const studentNums = studentIds ? studentIds.length : 0;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={cancelSelectCCMRKey}
      >
        <div className={classes.modal}>
          <Typography variant="h6" color="primary" align="left" gutterBottom>
            {studentNums
              ? `Add Data for ${keyName}`
              : `Please enter comma-separated and/or white-spaced ids to add data for ${keyName}`}
          </Typography>
          <Divider variant="fullWidth" />
          {unknownIds && unknownIds.length ? (
            <Typography variant="subtitle1" color="error" align="left" gutterBottom className={classes.error}>
              <ErrorIcon color="error" fontSize="small" className={classes.errorIcon} />
              {studentNums
                ? `None of the following ids will be updated as they didn't match any high school student in the roster: ${unknownIds.join(
                    ', '
                  )}.`
                : 'None of the provided ids matched any high school student in the roster. Please try again!'}
            </Typography>
          ) : null}
          {studentNums ? this.renderBulkEntryCCMR() : this.renderStudentIdsForm()}
        </div>
      </Modal>
    );
  };
}

export default withStyles(styles)(CCMRBulkEntry);
