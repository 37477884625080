const styles = (theme) => ({
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 64,
    background: 'linear-gradient(0deg, rgba(175, 228, 236, 0.65) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;',
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 3,
    outline: 'none',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%)',
    textAlign: 'center',
    maxHeight: '80%',
    overflow: 'auto',
  },
  ids: {
    height: 155,
    width: theme.spacing.unit * 58,
    marginBottom: 16,
  },
  error: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
  },
  errorIcon: {
    marginRight: 5,
  },
  warning: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    color: theme.palette.warning.main,
  },
  warningIcon: {
    marginRight: 5,
    color: theme.palette.warning.main,
  },
  button: {
    width: 150,
  },
  buttonContainer: {
    width: '100%',
  },
});

export default styles;
