import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, SvgIcon, Typography } from '@material-ui/core';
import UserInfoBanner from '../UserInfoBanner';

const styles = (theme) => ({
  logoIcon: {
    width: 153,
    height: 62,
  },
  logoTitle: {
    color: theme.palette.primary.main,
    fontSize: '0.925rem',
  },
});

function CopilotIcon(props) {
  return (
    <SvgIcon viewBox="0 0 153 62" {...props}>
      <path
        d="M90.4662 43.9452C87.7214 48.7541 82.57 52.0003 76.6756 52.0003C67.9098 52.0003 60.7789 44.8236 60.7789 36.0015C60.7789 27.1793 67.9098 20.0026 76.6756 20.0026C82.6048 20.0026 87.7846 23.2902 90.5168 28.15H101.303C97.9893 17.6411 88.202 10.0029 76.6756 10.0029C62.4327 10.0029 50.843 21.6671 50.843 36.0015C50.843 50.3358 62.4327 62 76.6756 62C88.1673 62 97.9323 54.4064 101.275 43.9452H90.4662Z"
        fill="#656565"
      />
      <path
        d="M96.9457 17.3579L99.8771 21.3329H142.299C142.299 21.3329 150.707 19.923 153 13.2555H105.863C105.863 13.2555 101.661 13.8952 96.9457 17.3579Z"
        fill="#656565"
      />
      <path
        d="M101.278 23.513C101.278 23.513 103.953 29.5376 103.827 31.4631H124.973C124.973 31.4631 134.4 31.3358 137.84 23.513H101.278Z"
        fill="#656565"
      />
      <path
        d="M104.08 34.2797C104.08 34.2797 104.589 39.5374 103.314 42.0993C103.314 42.0993 118.601 44.0216 122.933 34.2797H104.08Z"
        fill="#656565"
      />
      <path
        d="M102.884 44.3526C102.884 44.3526 100.257 51.1792 99.1593 51.9494C99.1593 51.9494 105.082 51.0106 108.713 44.3526"
        fill="#656565"
      />
      <path
        d="M56.0544 17.6124L53.1229 21.5875H10.7011C10.7011 21.5875 2.29264 20.1776 0 13.5101H47.1368C47.1368 13.5101 51.3394 14.1498 56.0544 17.6124Z"
        fill="#656565"
      />
      <path
        d="M51.7221 23.7644C51.7221 23.7644 49.0468 29.789 49.1733 31.7145H28.0272C28.0272 31.7145 18.6005 31.5872 15.1599 23.7644H51.7221Z"
        fill="#656565"
      />
      <path
        d="M48.9203 34.5343C48.9203 34.5343 48.4112 39.7919 49.6856 42.3539C49.6856 42.3539 34.3992 44.2762 30.0669 34.5343H48.9203Z"
        fill="#656565"
      />
      <path
        d="M50.1125 44.6071C50.1125 44.6071 52.7403 51.4338 53.8376 52.204C53.8376 52.204 47.9147 51.2651 44.2844 44.6071"
        fill="#656565"
      />
      <path
        d="M76.6628 0L77.8898 2.50151L80.6346 2.90252L78.6487 4.85345L79.1167 7.6032L76.6628 6.30471L74.2089 7.6032L74.6769 4.85345L72.691 2.90252L75.4359 2.50151L76.6628 0Z"
        fill="#656565"
      />
      <path
        d="M65.4432 3.99738L66.297 5.73507L68.2038 6.01514L66.8219 7.36774L67.1476 9.28047L65.4432 8.37662L63.7355 9.28047L64.0612 7.36774L62.6825 6.01514L64.5893 5.73507L65.4432 3.99738Z"
        fill="#656565"
      />
      <path
        d="M87.5601 3.99738L88.4139 5.73507L90.3208 6.01514L88.942 7.36774L89.2677 9.28047L87.5601 8.37662L85.8556 9.28047L86.1814 7.36774L84.8026 6.01514L86.7095 5.73507L87.5601 3.99738Z"
        fill="#656565"
      />
    </SvgIcon>
  );
}

class Logo extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    logoClassName: PropTypes.any,
    titleClassName: PropTypes.any,
    wrapperClassName: PropTypes.any,
    isHomePage: PropTypes.bool,
  };

  render() {
    const { classes, logoClassName, titleClassName, wrapperClassName, isHomePage } = this.props;
    if (isHomePage) {
      return (
        <Link to="" style={{ textDecoration: 'none' }}>
          <Grid className={wrapperClassName}>
            <CopilotIcon className={logoClassName} color="primary" />
            <UserInfoBanner isHomePage />
          </Grid>
        </Link>
      );
    }
    return (
      <Link to="" style={{ textDecoration: 'none' }}>
        <Grid className={wrapperClassName}>
          <CopilotIcon className={logoClassName} color="primary" />
          <Typography align="center" gutterBottom className={titleClassName || classes.logoTitle}>
            CoPilot
          </Typography>
        </Grid>
      </Link>
    );
  }
}

export default withStyles(styles)(Logo);
