import { combineReducers } from 'redux';
import { _reducer as onBoarding } from './onBoarding';
import { _reducer as assessmentsPage } from './assessmentsPage';
import { _reducer as studentsPage } from './rosterPage';

export const reducer = combineReducers({
  onBoarding,
  assessmentsPage,
  studentsPage,
});

export * from './onBoarding/onBoarding.actions';
export * from './assessmentsPage/assessmentsPage.actions';
export * from './rosterPage/rosterPage.actions';
