import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { _reducer as flightPlan, epics as flightPlanEpics } from './flightPlan';

export const reducer = combineReducers({
  flightPlan,
});

export const epics = combineEpics(flightPlanEpics);
