import { combineReducers } from 'redux';
import { INIT_ASSESSMENT_CHECKLIST, GET_CCMR_SOURCE } from './assessementChecklist.actions';
import { CcmrSourceType } from './assessmentCheckList.utils';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';

const assessementCheckList = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === INIT_ASSESSMENT_CHECKLIST) {
        return action.data.checkList;
      }
      return state;
    default:
      return state;
  }
};
const ccmrSource = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_CCMR_SOURCE) {
        return action.data.source === CcmrSourceType.TRACKER;
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === INIT_ASSESSMENT_CHECKLIST) {
        return 'Initializing ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === INIT_ASSESSMENT_CHECKLIST) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  assessementCheckList,
  ccmrSource,
  loadingMsg,
});

export default 'studentsPage.reducers.js';
