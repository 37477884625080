import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import { Routes } from '../../App';
import { BackSkipNext } from '../common';
import Download from '../../../assets/img/download.png';
import Edit from '../../../assets/img/edit.png';
import Upload from '../../../assets/img/upload.png';
import { Dropzone } from '../../common/Dropzone';
import { LoadingCircle } from '../../common/LoadingCircle';

const styles = () => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructions: {
    color: '#02A1FE',
    textAlign: 'center',
  },
  imgWrapper: {
    margin: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'none',
    border: 0,
    padding: 0,
    outline: 0,
  },
  img: {
    width: '200px',
    height: '200px',
    border: '15px solid #02A1FE',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  activeImg: {
    border: '15px solid #7FD453',
  },
  smallFont: {
    fontSize: '0.75rem',
  },
  mediumFont: {
    fontSize: '0.875rem',
  },
  largeFont: {
    fontSize: '1rem',
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
});

class StudentsPage extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onNextClick: PropTypes.func.isRequired,
    status: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    needsInit: PropTypes.bool.isRequired,
    init: PropTypes.func.isRequired,
    profile: PropTypes.object,
    user: PropTypes.object,
    onDrop: PropTypes.func.isRequired,
    onDropForDeleteRemaining: PropTypes.func,
    classes: PropTypes.any.isRequired,
    uploadProgressMsg: PropTypes.string.isRequired,
  };

  state = {
    instructionsClicked: false,
    templateClicked: false,
    nextError: '',
  };

  componentDidMount() {
    this.shouldInit();
    this.initState();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  initState = () => {
    this.setState({
      nextError: '',
    });
  };

  onNextClick = () => {
    const { onNextClick, history, status } = this.props;
    if (status.uploaded) {
      onNextClick(history, `/${Routes.onboarding}/${Routes.assessments}`);
    } else {
      this.setState({
        nextError: "Wouldn't you like to upload a student roster before moving to the next step?",
      });
    }
  };

  onBackClick = () => {
    const { history } = this.props;
    history.push(`/${Routes.onboarding}/${Routes.welcome}`);
  };

  onSkipClick = () => {
    const { history } = this.props;
    history.push(`/${Routes.onboarding}/${Routes.assessments}`);
  };

  onUploadClick = () => {
    this.dropZoneRef.open();
    this.setState({
      nextError: '',
    });
  };

  onDrop = (accepted, rejected) => {
    const { onDrop, profile, user } = this.props;
    onDrop(accepted, rejected, user, profile);
  };

  onDropForDeleteRemaining = (accepted, rejected) => {
    const { onDropForDeleteRemaining, profile, user } = this.props;
    onDropForDeleteRemaining(accepted, rejected, user, profile);
  };

  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  onTemplateClick = () => {
    window.open(
      'https://docs.google.com/spreadsheets/d/1x4s7DhtgHFKyM75iC1y5VTgNvbxaAsCRCCAYLirZB9I/export?format=csv'
    );
    this.setState({ templateClicked: true });
  };

  onInstructionsClick = () => {
    window.open('https://docs.google.com/document/d/1jEi4a8ni4kOVJg5zM1q85xNEaorTdxQbyGZOhG3XHIs/export?format=pdf');
    this.setState({ instructionsClicked: true });
  };

  shouldInit() {
    const { profile, needsInit, init, loading } = this.props;
    if (profile && needsInit && !loading) {
      init(profile);
    }
  }

  render() {
    const { status, loading, classes, uploadProgressMsg } = this.props;
    const { nextError } = this.state;

    if (loading || uploadProgressMsg) {
      return <LoadingCircle classes={classes} msg={uploadProgressMsg} />;
    }

    return (
      <div className={classes.root}>
        <Typography gutterBottom className={classNames(classes.italic, classes.bold, classes.largeFont)}>
          There are three steps to the rostering process:
        </Typography>
        <div className={classes.row}>
          <div className={classes.column}>
            <span className={classNames(classes.instructions, classes.mediumFont)}>1. Download the template.</span>
            <button type="button" className={classes.imgWrapper} onClick={this.onTemplateClick}>
              <img
                className={this.state.templateClicked ? classNames(classes.img, classes.activeImg) : classes.img}
                src={Download}
                alt="Download"
              />
            </button>
          </div>
          <div className={classes.column}>
            <span className={classNames(classes.instructions, classes.mediumFont)}>2. Download instructions.</span>
            <button type="button" className={classes.imgWrapper} onClick={this.onInstructionsClick}>
              <img
                className={this.state.instructionsClicked ? classNames(classes.img, classes.activeImg) : classes.img}
                src={Edit}
                alt="download-instructions"
              />
            </button>
          </div>
          <div className={classes.column}>
            <span className={classNames(classes.instructions, classes.mediumFont)}>
              {status.uploaded ? '3. Update your uploaded roster.' : '3. Upload it back.'}
            </span>
            <button type="button" className={classes.imgWrapper} onClick={this.onUploadClick}>
              <Dropzone disableClick accept=".csv" ref={this.saveDropZoneRef} onDrop={this.onDrop} multiple={false}>
                <img
                  className={status.uploaded ? classNames(classes.img, classes.activeImg) : classes.img}
                  src={Upload}
                  alt="Upload"
                />
              </Dropzone>
            </button>
          </div>
        </div>
        {status && status.error && (
          <Typography align="center" color="error">
            {status.error}
          </Typography>
        )}
        {nextError && (
          <Typography variant="subtitle1" align="center" color="primary">
            {nextError}
          </Typography>
        )}
        <BackSkipNext handleBack={this.onBackClick} handleSkip={this.onSkipClick} handleNext={this.onNextClick} />
      </div>
    );
  }
}

export default withStyles(styles)(StudentsPage);
