import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { _reducer as ccmrTracking } from './ccmrTracking.reducers';
import ccmrTrackingEpics from './ccmrTracking.epics';

export const reducer = combineReducers({
  ccmrTracking,
});

export const epics = combineEpics(ccmrTrackingEpics);

export * from './ccmrTracking.actions';
export * from './ccmrTracking.utils';
