import bugsnag from '@bugsnag/js';
import { combineEpics, ofType } from 'redux-observable';
import { switchMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { ASYNC_ERROR, ASYNC_FINISH } from '../../../common/+store/index';
import { GET_REPORT } from '../../../AtoF/+store/aToF';
import { GET_FLIGHT_PLAN } from '../../../FlightPlan/+store/flightPlan';

export const bugsnagger = {};

if (process.env.NODE_ENV === 'production') {
  bugsnagger.bugsnagClient = bugsnag('7a72fb52464310b110fa71e641da4084');
}

const catchErrorEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => {
      if (action.type === ASYNC_ERROR) {
        return true;
      }
      if (action.type === ASYNC_FINISH && action.model === GET_REPORT && action.data.reportError) {
        return true;
      }
      if (action.type === ASYNC_FINISH && action.model === GET_FLIGHT_PLAN && action.data.reportError) {
        return true;
      }
    }),
    switchMap((action) => {
      if (process.env.NODE_ENV === 'production') {
        /*bugsnagger.bugsnagClient.notify(new Error(`${action.type}-${action.model}`), {
          metaData: {
            action,
            profile: state$.value.modules.Auth.auth.profile,
          },
        });*/
        console.log('ASYNC_ERROR', `${action.type}-${action.model}`);
      } else {
        console.log('ASYNC_ERROR', action);
      }
      return of({ type: 'noop' });
    })
  );

const epics = combineEpics(catchErrorEpic);
export default epics;
