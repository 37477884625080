const isEOC = (file, alt = false) =>
  new Promise((resolve) => {
    var assessmentYear = 0;
    if (file.type != 'text/plain') {
      resolve({ isValid: false });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      if (text.length < 2000) {
        resolve({ isValid: false });
        return;
      }

      const lines = text.split(/\r\n?|\n/);

      if (lines && lines.length > 0) {
        if (!/^-?\d+$/.test(lines[0].slice(2, 4))) {
          resolve({ isValid: false });
          return;
        } else {
          assessmentYear = parseInt(lines[0].slice(2, 4));
          if (lines[0][1] === '3') assessmentYear += 1;
        }
      }

      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        if (line) {
          if (
            line.length !== 2000 ||
            line[line.length - 1] !== '.' || //2022 // Blank space for eoc 2021(1623, 1999))
            (assessmentYear < 24 && !/^\s+$/.test(line.slice(1622, 1631))) ||
            (assessmentYear >= 24 && !/^\s+$/.test(line.slice(1626, 1631) || !/^\s+$/.test(line.slice(1669, 1999))))
          ) {
            resolve({ isValid: false });
            return;
          }
          // to differentiate from this year's EOC 2021 from EOC ALT 2021
          // EOC2021 ALT has BLANK between 132-140 while EOC2021 don't
          if (alt && file.name.toUpperCase().indexOf('ALT') == -1) {
            //!/^\s+$/.test(line.slice(132, 141))
            resolve({ isValid: false });
            return;
          }
          if (!alt && file.name.toUpperCase().indexOf('ALT') != -1) {
            ///^\s+$/.test(line.slice(132, 141))
            resolve({ isValid: false });
            return;
          }
        }
      }
      resolve({
        isValid: true,
        assessmentYear: assessmentYear.toString(),
      });
    };
    reader.readAsText(file);
  });

export default isEOC;
