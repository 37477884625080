import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SortSection } from './SortSection';
import { FilterSection } from './FilterSection';

const styles = () => ({
  div: {
    width: '200px',
  },
});

class FilterBox extends React.PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    sortSection: PropTypes.object,
    filterSections: PropTypes.array,
    classes: PropTypes.any,
    children: PropTypes.any,
    style: PropTypes.object,
    onChange: PropTypes.func,
  };

  render() {
    const { model, sortSection, filterSections, classes, children, style, onChange } = this.props;
    return (
      <Grid
        container
        justify="flex-start"
        alignItems="flex-start"
        direction="column"
        className={classes.div}
        style={style || {}}
      >
        <SortSection model={model} label={sortSection.label} options={sortSection.options} onChange={onChange} />
        <Typography color="primary" style={{ marginTop: '8px' }}>
          FILTER
        </Typography>
        {filterSections.map((filterSection, index) => (
          <FilterSection
            model={model}
            key={index}
            filterKey={filterSection.filterKey}
            id={filterSection.id}
            label={filterSection.label}
            values={filterSection.values}
            type={filterSection.type}
            byRoster={filterSection.byRoster}
            byCCMR={filterSection.byCCMR}
            onChange={onChange}
          />
        ))}
        {children}
      </Grid>
    );
  }
}

export default withStyles(styles)(FilterBox);
