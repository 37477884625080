import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Modal, Typography } from '@material-ui/core';
import { Button } from '../../../common';
import { isMultipleCampusAdmin } from '../../../common/utils';
import styles from '../ManageUsers.styles';

class DeleteModal extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    classes: PropTypes.any.isRequired,
    user: PropTypes.object.isRequired,
    isInvitedUser: PropTypes.bool,
    onActivateAccount: PropTypes.func.isRequired,
    onDeactivateAccount: PropTypes.func.isRequired,
    onCancelInvite: PropTypes.func.isRequired,
    firebaseId: PropTypes.string.isRequired,
    handleDeleteClose: PropTypes.func.isRequired,
  };

  handleConfirmDelete = () => {
    const {
      isInvitedUser,
      user,
      firebaseId,
      onActivateAccount,
      onDeactivateAccount,
      onCancelInvite,
      handleDeleteClose,
    } = this.props;
    if (isInvitedUser) {
      onCancelInvite(firebaseId);
    } else if (user.deactivated) {
      onActivateAccount(firebaseId);
    } else {
      onDeactivateAccount(firebaseId);
    }
    handleDeleteClose();
  };

  render() {
    const { handleDeleteClose, classes, user, isInvitedUser, profile } = this.props;
    const isAuthMultiCampusAdmin = isMultipleCampusAdmin(profile);
    const isUpdatedMultiCampusAdmin = isMultipleCampusAdmin(user);
    let msg;
    if (isInvitedUser) {
      msg = `Are you sure you want to cancel the invitation of ${user.emailAddress}${
        isAuthMultiCampusAdmin ? ' to your campuses' : ''
      }?`;
    } else if (user.deactivated) {
      msg = `Are you sure you want to activate ${user.emailAddress}?`;
    } else if (isUpdatedMultiCampusAdmin && profile.role === 'campusAdmin') {
      msg = `Are you sure you want to revoke ${user.emailAddress} access to your campus${
        isAuthMultiCampusAdmin ? 'es' : ''
      }?`;
    } else {
      msg = `Are you sure you want to deactivate ${user.emailAddress}?`;
    }
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open
        onClose={handleDeleteClose}
      >
        <div className={classNames(classes.modal, classes.smallModal)}>
          <Typography variant="h6" id="modal-title" gutterBottom>
            {msg}
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center">
            <Button color="white" onClick={handleDeleteClose}>
              Cancel
            </Button>
            <Button color="dark" onClick={this.handleConfirmDelete}>
              Yes, I am sure.
            </Button>
          </Grid>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(DeleteModal);
