const isTELPAS = (file, alt = false) =>
  new Promise((resolve) => {
    var assessmentYear = 0;
    console.log(typeof file.type);
    if (file.type != 'text/plain' && !(file.name.endsWith('.dat') || file.name.endsWith('.txt'))) {
      resolve({ isValid: false });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      if (text.length < 1200) {
        resolve({ isValid: false });
        return;
      }
      const lines = text.split(/\r\n?|\n/);
      if (lines && lines.length > 0) {
        if (!/^-?\d+$/.test(lines[0].slice(2, 4))) {
          resolve({ isValid: false });
          return;
        } else {
          assessmentYear = lines[0].slice(2, 4);
        }
      }
      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        if (line) {
          if (
            line.length !== 1200 ||
            line[line.length - 1] !== '.' ||
            line.slice(901, 903).trim() // 2021 Removed TELPAS COMPREHENSION SCORE
          ) {
            resolve({ isValid: false });
            return;
          }
          // to differentiate from this year's TELPAS from TELPAS ALT
          // TELPAS ALT has BLANK between 201-260 whereas TELPAS has BLANK between 244-260 only.
          if ((alt && !/^\s+$/.test(line.slice(200, 243))) || (!alt && /^\s+$/.test(line.slice(200, 243)))) {
            resolve({ isValid: false });
            return;
          }
        }
      }
      resolve({
        isValid: true,
        assessmentYear: assessmentYear,
      });
    };
    reader.readAsText(file);
  });

export default isTELPAS;
