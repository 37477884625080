import { createSelector } from 'reselect';
import moment from 'moment';
import { Subjects, Grades } from './flightPlan.constants';
import { getCurrentRosterYear } from '../../../StudentRoster/+store/studentRoster';

const getProfile = (state) => state.modules.Auth.auth.profile;
const getSchoolList = (state) => state.modules.Settings.schoolSettings.schoolList;

export const schoolListPlusDistrict = createSelector(getProfile, getSchoolList, (profile, schoolList) => {
  let schools = Object.entries(schoolList).reduce((prev, [campusNumber, campus]) => {
    prev[campusNumber] = campus.name;
    return prev;
  }, {});
  delete schools[profile.districtNumber];
  schools = Object.entries(schools).sort((a, b) => a[1].localeCompare(b[1]));
  if (profile && profile.role === 'districtAdmin') {
    if (schools.length > 1) {
      schools.unshift([profile.districtNumber, 'District']);
    }
  }
  return schools;
});

const getFilterFields = (state) => state.modules.FlightPlan.flightPlan.filterFields;

export const selectFilterFields = createSelector(getFilterFields, (filterFields) => {
  if (filterFields) {
    const { subjects, grades, teachers } = filterFields;
    const formattedSubjects = [];
    (subjects || []).forEach((subject) => {
      formattedSubjects.push({
        value: subject,
        label: Subjects[subject],
      });
    });
    const formattedGrades = [];
    (grades || []).forEach((grade) => {
      formattedGrades.push({
        value: grade,
        label: Grades[grade],
      });
    });
    const formattedTeachers = [];
    (teachers || []).forEach((teacher) => {
      formattedTeachers.push({
        value: teacher,
        label: teacher,
      });
    });
    return {
      subjects: formattedSubjects,
      grades: formattedGrades,
      teachers: formattedTeachers,
    };
  }
  return {
    subjects: [],
    grades: [],
    teachers: [],
  };
});

const getStudents = (state) => {
  const students = state.modules.StudentRoster.studentRoster.roster;
  const currentSnapshotYear = getCurrentRosterYear();
  return students && students[currentSnapshotYear];
};

const getFlightPlan = (state) => state.modules.FlightPlan.flightPlan.flightPlan;
const getDesiredScore = (state) => state.modules.FlightPlan.flightPlan.desiredAFScore;
const getGroupByValue = (state) => state.modules.FlightPlan.flightPlan.groupByValue;

export const selectFlightPlan = createSelector(
  getStudents,
  getFlightPlan,
  getDesiredScore,
  getGroupByValue,
  (students, flightPlan, desiredScore, groupByValue) => {
    const steps = [];
    if (students && flightPlan) {
      flightPlan.forEach((fps, index) => {
        const student = students[fps.studentId] || null;
        let teacher = '';
        if (student) {
          if (student.currentYearTests && student.currentYearTests[0]) {
            const test = student.currentYearTests[0].tests.find((t) => t.subject === fps.subject);
            teacher = test ? test.teacher : '';
          }
          steps.push({
            impact: fps.impact,
            attainability: fps.attainability,
            flightPlanScore: index,
            roundedROOS: fps.roundedROOS,
            testSubject: fps.testSubject,
            subject: fps.subject,
            steps: { ...fps.steps },
            studentId: fps.studentId,
            student,
            teacher,
          });
        }
      });
      let maxIndex = -1;
      steps.forEach((step, index) => {
        if (maxIndex < 0 && step.roundedROOS >= desiredScore) {
          maxIndex = index;
        }
      });
      const otherField = 'Other';
      let group = steps.slice(0, maxIndex + 1);
      const groupedBy = group.reduce((testGroup, stepOject) => {
        if (groupByValue === 'subject' || groupByValue === 'teacher') {
          if (stepOject[groupByValue]) {
            if (testGroup[stepOject[groupByValue]]) {
              testGroup[stepOject[groupByValue]].push(stepOject);
            } else {
              testGroup[stepOject[groupByValue]] = [stepOject];
            }
          } else if (testGroup[otherField]) {
            testGroup[otherField].push(stepOject);
          } else {
            testGroup[otherField] = [stepOject];
          }
        }
        if (groupByValue === 'grade') {
          if (stepOject.student) {
            if (testGroup[stepOject.student.gradeLevel]) {
              testGroup[stepOject.student.gradeLevel].push(stepOject);
            } else {
              testGroup[stepOject.student.gradeLevel] = [stepOject];
            }
          } else if (testGroup[otherField]) {
            testGroup[otherField].push(stepOject);
          } else {
            testGroup[otherField] = [stepOject];
          }
        }
        if (groupByValue === 'studentGroup') {
          if (stepOject.student) {
            Object.entries(stepOject.student.indicators).filter(([key, val]) => {
              if (val) {
                if (testGroup[key]) {
                  testGroup[key].push(stepOject);
                } else {
                  testGroup[key] = [stepOject];
                }
              }
              return testGroup;
            });
          } else if (testGroup[otherField]) {
            testGroup[otherField].push(stepOject);
          } else {
            testGroup[otherField] = [stepOject];
          }
        }
        return testGroup;
      }, {});
      const result = groupByValue && groupByValue !== 'all' ? groupedBy : group;
      return result;
    }
    return [];
  }
);

export default 'flightPlan.selectors.js';
