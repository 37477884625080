import parse from 'csv-parse';

const CCMRColumns = [
  'District Name',
  'Campus Name',
  'Campus #',
  'Student Name',
  'Student ID',
  'SSN',
  'TSDS',
  'Local ID',
  'DOB',
  'Ethnicity',
  'Eco Dis',
  'EL Status',
  'Special Ed',
  'Cont Enrolled',
  'Grad Status',
  'CCMR',
  'TSI ELA/Reading & Math',
  'TSI ELA/Reading',
  'TSIA Reading High Score',
  'TSIA Reading',
  'ACT English High Score',
  'ACT ELA',
  'SAT ELA High Score',
  'SAT ELA',
  'College Prep ELA',
  'ACT Super Composite Score',
  'TSI Math',
  'TSIA Math High Score',
  'TSIA Math',
  'ACT Math High Score',
  'ACT Math',
  'SAT Math High Score',
  'SAT Math',
  'College Prep Math',
  'AP/IB',
  'Dual Credit',
  'Industry Cert',
  'Level 1 or 2 Cert',
  'Assoc Degree,',
  'OnRamps',
  'SPED: IEP/Workforce',
  'SPED: Adv Grad Plan',
  'Excluded',
];
const isCCMRCSV = (file) =>
  new Promise((resolve) => {
    if (file.type != 'application/vnd.ms-excel' && file.type != 'text/csv') {
      resolve({ isValid: false });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      parse(text, {}, (err, output) => {
        if (err) {
          resolve({ isValid: false });
          return;
        }
        if (JSON.stringify(output[0]) === JSON.stringify(CCMRColumns)) {
          resolve({ isValid: true });
        } else {
          resolve({ isValid: false });
        }
      });
    };
    reader.readAsText(file);
  });

export default isCCMRCSV;
