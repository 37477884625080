import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { numberStringOrDash } from '../D3/Common/utils';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: 90,
    paddingLeft: 8,
  },
  cell: {
    fontWeight: 'bold',
    marginRight: 8,
  },
};

class SectionSummary extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    raw: PropTypes.number,
    scaled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    letter: PropTypes.string,
  };

  render() {
    const { raw, scaled, letter, classes } = this.props;
    return (
      <div id="reportSectionSummary" className={classes.root}>
        {raw !== undefined && (
          <Typography className={classes.cell}>{`Raw Score: ${numberStringOrDash(raw)}`}</Typography>
        )}
        <Typography className={classes.cell}>{`Scaled Score: ${numberStringOrDash(scaled)}`}</Typography>
        <Typography className={classes.cell}>{`Letter Grade: ${numberStringOrDash(letter)}`}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(SectionSummary);
