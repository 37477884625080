const styles = (theme) => ({
  headerRow: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: 12,
  },
  headerCell: {
    padding: 8,
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#656565',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    height: 60,
    flexShrink: 0,
    width: 60,
    zIndex: 12,
  },
  headerSticky: {
    position: 'sticky',
    background: '#f3feff',
  },
  headerStickySafari: {
    position: '-webkit-sticky',
  },
});

export default styles;
