import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { asyncError, asyncFinish, asyncProgress } from '../../../common/+store/index';
import {
  UPLOAD_ROSTER,
  DELETE_STUDENT_CONFIRM,
  EDIT_STUDENT_CONFIRM,
  ADD_STUDENT_CONFIRM,
  UPLOAD_PROFILE_PICTURE,
  GET_AVATARS,
  EXPORT_ROSTER,
  SET_STUDENT_PRIOR_STAAR_DATA,
  GET_STUDENT_PRIOR_STAAR_DATA,
} from './studentRoster.actions';
import {
  deleteStudent,
  updateStudent,
  addStudent,
  onFileUpload,
  onFileUploadForDeleteRemaining,
  onChangeProfilePicture,
  getAvatarsUrl,
  queryRosterApi,
  getStudentStaarTests,
  setStudentStaarTests,
} from './studentRoster.api';
import { saveFile, saveProfilePicture } from './studentRoster.storage';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';

const uploadStudentRosterEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_ROSTER),
    switchMap((action) =>
      from(
        saveFile(action.file, action.user, action.profile, action.year, (percent, message) => {
          const { dispatch } = action;
          dispatch(
            asyncProgress(UPLOAD_ROSTER, {
              progress: percent,
              message,
            })
          );
        })
      ).pipe(commonApiCallFragment(action$, action, 'Upload Student Roster'))
    ),
    switchMap((result) => {
      if (result.success) {
        const { dispatch } = result.action;
        dispatch(
          asyncProgress(UPLOAD_ROSTER, {
            progress: 100,
            message: 'Processing ...',
          })
        );
        return from(
          onFileUpload({
            name: result.response,
            type: result && result.action && result.action.isForDeleteRemaining ? 'rosterForDeleteRemaining' : 'roster',
            origName: result.action.file.name,
            year: result.action.year,
          })
        ).pipe(commonApiCallFragment(action$, result.action, 'Upload Student Roster'));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        if (result.response.data.studentsUpdated) {
          return of(
            asyncFinish(result.action.type, {
              uploaded: true,
              message: 'Roster uploaded successfully',
              data: result.data,
            })
          );
        }
        return of(
          asyncError(result.action.type, 'saveError', new Error('Roster uploaded, but zero students were created'), {
            data: result.data,
            uploaded: false,
          })
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const deleteStudentEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_STUDENT_CONFIRM),
    switchMap((action) =>
      from(deleteStudent(action.orgId, action.studentId, action.year)).pipe(
        map((data) => ({ action, success: true, data })),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'Could not delete student', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            studentId: results.action.studentId,
            year: results.action.year,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const editStudentEpic = (action$) =>
  action$.pipe(
    ofType(EDIT_STUDENT_CONFIRM),
    switchMap((action) =>
      from(updateStudent(action.orgId, action.studentId, action.student, action.year)).pipe(
        map((data) => ({ action, success: true, data })),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'Could not update student', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            studentId: results.action.studentId,
            student: results.action.student,
            year: results.action.year,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const addStudentEpic = (action$) =>
  action$.pipe(
    ofType(ADD_STUDENT_CONFIRM),
    switchMap((action) =>
      from(addStudent(action.orgId, action.student, action.year)).pipe(
        map((data) => ({ action, success: true, data })),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'Could not add student', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            studentId: results.data.key,
            student: results.action.student,
            year: results.action.year,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const uploadProfilePictureEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_PROFILE_PICTURE),
    switchMap((action) =>
      from(
        saveProfilePicture(action.file, action.profile, action.studentId, action.year, (percent, message) => {
          const { dispatch } = action;
          dispatch(
            asyncProgress(UPLOAD_PROFILE_PICTURE, {
              progress: percent,
              message,
            })
          );
        })
      ).pipe(commonApiCallFragment(action$, action, 'Upload Profile Picture'))
    ),
    switchMap((result) => {
      if (result.success) {
        const { dispatch } = result.action;
        dispatch(
          asyncProgress(UPLOAD_PROFILE_PICTURE, {
            progress: 100,
            message: 'Processing ...',
          })
        );
        return from(onChangeProfilePicture(result.response)).pipe(
          commonApiCallFragment(action$, result, 'Upload various types of profile picture')
        );
      }
      return of(result);
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(UPLOAD_PROFILE_PICTURE, {
            photoUrl: results.response.data,
            success: true,
            studentId: results.action.action.studentId,
            year: results.action.action.year,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getAvatarsEpic = (action$) =>
  action$.pipe(
    ofType(GET_AVATARS),
    switchMap((action) => from(getAvatarsUrl()).pipe(commonApiCallFragment(action$, action, 'Get Avatars'))),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinish(result.action.type, {
            avatarsUrl: result.response,
          })
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const exportRosterEpic = (action$) =>
  action$.pipe(
    ofType(EXPORT_ROSTER),
    switchMap((action) => {
      return from(queryRosterApi({ model: 'rosterExportCsv', snapshotYear: action.snapshotYear })).pipe(
        commonApiCallFragment(action$, action, 'Export Progress Goals')
      );
    }),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinish(result.action.type, { data: result.response.data }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const setStudentPriorStaarDataEpic = (action$) =>
  action$.pipe(
    ofType(SET_STUDENT_PRIOR_STAAR_DATA),
    switchMap((action) =>
      from(setStudentStaarTests(action.data)).pipe(
        map((data) => ({ action, success: true, data })),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'Could update student assessment', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            studentId: results.data.key,
            student: results.action.student,
            year: results.action.year,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getStudentPriorStaarDataEpic = (action$) =>
  action$.pipe(
    ofType(GET_STUDENT_PRIOR_STAAR_DATA),
    switchMap((action) =>
      from(getStudentStaarTests(action.data)).pipe(commonApiCallFragment(action$, action, 'Getting Student Assessment'))
    ),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinish(result.action.type, {
            studentPriorStaarTests: result.response.data,
          })
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  );

const epics = combineEpics(
  uploadStudentRosterEpic,
  addStudentEpic,
  editStudentEpic,
  deleteStudentEpic,
  uploadProfilePictureEpic,
  getAvatarsEpic,
  exportRosterEpic,
  getStudentPriorStaarDataEpic,
  setStudentPriorStaarDataEpic
);
export default epics;
