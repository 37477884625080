import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AuthLayout from '../AuthLayout';
import TextInputField from '../TextInputField';
import { Button } from '../../common/Button';
import { Routes } from '../../App';
import { LoadingCircle } from '../../common';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '190px',
    zIndex: 10,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '470px',
    alignSelf: 'flex-start',
  },
  button: {
    marginRight: 0,
    textTransform: 'capitalize',
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    marginBottom: theme.spacing.unit,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  success: {
    color: theme.palette.success.main,
    display: 'flex',
    alignItems: 'center',
  },
  successIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing.unit,
  },
  backButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing.unit * 2,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    borderRadius: theme.spacing.unit,
    textDecoration: 'none',
    cursor: 'pointer',
    width: '23rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link2: {
    color: theme.palette.white,
    fontSize: '0.875rem',
  },
});

class ForgotPassword extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    handleSubmit: PropTypes.func.isRequired,
    resetEmailSent: PropTypes.bool.isRequired,
  };

  renderEmailConfirmed = () => {
    const { classes } = this.props;
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Typography variant="h5" gutterBottom className={classes.success}>
          <Icon className={classes.successIcon}>check_circle</Icon>
          Success!
        </Typography>
        <Typography variant="body1" gutterBottom color="primary">
          {' '}
          We have sent you an email to reset your password!{' '}
        </Typography>
        <Typography gutterBottom> If the email does not arrive soon, check your spam folder. </Typography>
        <Link key="sign-in" to={`/${Routes.signIn}`} className={classes.backButton}>
          <Typography className={classes.link2}>Back to Sign In</Typography>
        </Link>
      </Grid>
    );
  };

  renderForgetPasswordForm = () => {
    const { classes, loading, handleSubmit } = this.props;
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className={classes.form} style={{ display: loading ? 'none' : 'block' }}>
            <TextInputField id="email" label="Email" type="email" touched={touched.email} error={errors.email} />
            <div className={classes.buttonRow}>
              <Link key="sign-in" to={`/${Routes.signIn}`} style={{ textDecoration: 'none' }}>
                <Typography className={classes.link}>Back to Sign In</Typography>
              </Link>
              <Button submit large color="white" className={classes.button}>
                {' '}
                Reset my password{' '}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    const { resetEmailSent } = this.props;
    return (
      <AuthLayout title="Forgot Your Password?">
        {resetEmailSent ? this.renderEmailConfirmed() : this.renderForgetPasswordForm()}
      </AuthLayout>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
