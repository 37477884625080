import { connect } from 'react-redux';
import Component from './HomePage.component';
import { campusDistrictInfo } from '../Settings/+store/SchoolSettings/SchoolSettings.selectors';

const mapStateToProps = (state) => ({
  campusDistrictInfo: campusDistrictInfo(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
