import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { ExportCSVButton, LoadingCircle, TitleBackButton, makeCsv } from '../common';
import WeightsRow from './WeightsRow';
import { FilterBox } from '../common/FilterBox';
import styles from './AccountabilityWeight.styles';
import { saveAs } from 'file-saver';

class AccountabilityWeight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: 50,
      loadingState: false,
    };
    this.showMore = this.showMore.bind(this);
  }

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  showMore = (event) => {
    const node = event.currentTarget;
    if (node) {
      node.addEventListener(
        'scroll',
        () => {
          if (node.scrollTop + node.clientHeight >= node.scrollHeight - 48) {
            this.loadMoreItems();
          }
        },
        true
      );
    }
  };

  loadMoreItems() {
    const { students } = this.props;
    const { loadingState, items } = this.state;
    if (loadingState || (students && items >= students.length)) {
      return;
    }
    this.setState({ loadingState: true });
    setTimeout(() => {
      this.setState({ items: items + 10, loadingState: false });
    }, 500);
  }

  shouldInit() {
    const { profile, needsInit, init, loading, getWeights } = this.props;
    if (profile && needsInit && !loading) {
      init(profile);
      if (profile.role === 'campusAdmin') {
        getWeights(profile.campusNumber);
      } else {
        getWeights(profile.districtNumber);
      }
    }
  }

  renderStudentsTable = () => {
    const { students, classes, avatars } = this.props;
    const { loadingState, items } = this.state;

    const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;
    const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
    return (
      <div onScroll={(event) => this.showMore(event)} className={classes.studentTable}>
        <Table className={classes.table}>
          <TableHead
            className={classNames('', { [classes.tableHeadFirefox]: isFirefox, [classes.tableHeadSafari]: isSafari })}
          >
            <TableRow>
              <TableCell align="left" className={classNames(classes.headCell, { [classes.headCellChrome]: isChrome })}>
                Student
              </TableCell>
              <TableCell align="left" className={classNames(classes.headCell, { [classes.headCellChrome]: isChrome })}>
                <Grid container alignItems="center">
                  <Typography className={classes.largeText} color="primary">
                    Mathematics
                  </Typography>
                  <Typography className={classes.smallText} color="primary">
                    Teacher
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell align="left" className={classNames(classes.headCell, { [classes.headCellChrome]: isChrome })}>
                <Grid container alignItems="center">
                  <Typography className={classes.largeText} color="primary">
                    Reading
                  </Typography>
                  <Typography className={classes.smallText} color="primary">
                    Teacher
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell
                align="center"
                className={classNames(classes.headCell, { [classes.headCellChrome]: isChrome })}
              >
                Accountability Weight
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.slice(0, items).map(([studentId, student], index) => (
              <WeightsRow
                key={index}
                student={student}
                weight={student.accWeight ? student.accWeight : 0}
                avatars={avatars}
              />
            ))}
          </TableBody>
        </Table>
        {loadingState ? <p className="loading">Loading more students...</p> : null}
      </div>
    );
  };

  exportCsv = async () => {
    const { students } = this.props;
    const csvColumns = [
      'Student ID',
      'TSDS ID',
      'Local ID',
      'Last Name',
      'First Name',
      'Grade',
      'Campus Number',
      'Accountability Weight',
    ];
    const data = students.map(([studentId, student]) => ({
      'Student ID': student.studentId || '-',
      'TSDS ID': student.tsdsId || '-',
      'Local ID': student.localId || '-',
      'Last Name': student.firstName || '-',
      'First Name': student.lastName || '-',
      Grade: student.gradeLevel || '-',
      'Campus Number': student.campusNumber || '-',
      'Accountability Weight': !isNaN(student.accWeight) ? student.accWeight.toFixed(1) : 0,
    }));
    const output = await makeCsv(data, csvColumns);
    const blob = new Blob([output], { type: 'text/csv;charset=utf-8;' });
    if (blob) {
      saveAs(blob, 'Accountability-Weights.csv');
    }
  };

  render() {
    const { students, classes, loading, profile, isEmpty, filterBoxParams, avatars } = this.props;
    const { model, sortSection, filterSections } = filterBoxParams;
    const showLoading = loading || !profile || !avatars;
    return (
      <div className={classes.background}>
        <div className={classes.root}>
          <TitleBackButton title="Accountability Weights">
            {!isEmpty && students.length && <ExportCSVButton title="Export CSV" onClick={this.exportCsv} />}
          </TitleBackButton>
          {showLoading && (
            <div className={classes.loadingWrapper}>
              <div>
                <LoadingCircle classes={classes} />
                <div style={{ height: 16 }} />
                <Typography>Loading accountability weights ...</Typography>
              </div>
            </div>
          )}
          <div className={classes.mainContainer} style={{ display: showLoading ? 'none' : '' }}>
            {
              <div className={classes.left}>
                {!isEmpty && (
                  <FilterBox
                    model={model}
                    filterSections={filterSections}
                    sortSection={sortSection}
                    style={{ marginRight: 60 }}
                  />
                )}
              </div>
            }
            <div className={classes.tableContainer}>
              {isEmpty && (
                <Typography color="primary" align="center">
                  Please upload a roster to view Accountability Weights.
                </Typography>
              )}
              {!isEmpty && students.length === 0 ? (
                <Typography color="primary" align="center">
                  No student matches the selected filters.
                </Typography>
              ) : (
                this.renderStudentsTable()
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AccountabilityWeight);
