import { connect } from 'react-redux';

import Component from './OnBoardingBase.component';
import { initOnBoarding } from '../+store';
import { signOut } from '../../Auth';

const mapStateToProps = (state, ownProps) => ({
  needsInit: state.modules.OnBoarding.onBoarding.needsInit,
  loadingMsg: state.modules.OnBoarding.onBoarding.loadingMsg,
  loading: !!state.modules.OnBoarding.onBoarding.loadingMsg,
  user: state.modules.Auth.auth.user,
  profile: state.modules.Auth.auth.profile,
  completed: state.modules.OnBoarding.onBoarding.status,
});

const mapDispatchToProps = (dispatch) => ({
  init: (user, history) => {
    dispatch(initOnBoarding(user, history));
  },
  signOut: (user) => {
    dispatch(signOut(user));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
