import { connect } from 'react-redux';

import Component from './SortSection.component';
import { sortBy } from '../../+store/FilterBox';

const mapStateToProps = (state, ownProps) => {
  const { sortByField, model } = state.modules.Common.filterBox;
  return {
    sortByField: ownProps.model === model ? sortByField : '',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSortByChange: (e) => {
    const value = e.target.value;
    const { reverse, keys } = ownProps.options[value];
    dispatch(sortBy(value, ownProps.model, keys, reverse));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
