import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SchoolBuilding from '@material-ui/icons/LocationCity';
import UserManage from '@material-ui/icons/SupervisedUserCircle';
import TagManage from '@material-ui/icons/TextRotateUp';
import classNames from 'classnames';
import { LoadingCircle, TitleBackButton } from '../common';
import styles from './Settings.styles';
import { Routes } from '../App';
import SchoolSettings from './SchoolSettings';
import AccountSettings from './AccountSettings';
import ManageUsers from './ManageUsers';
import ManageOrgTags from './ManageOrgTags';

class Settings extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    match: PropTypes.object.isRequired,
    needsInit: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    needsUpdate: PropTypes.bool.isRequired,
    init: PropTypes.func.isRequired,
    loadingMsg: PropTypes.string,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit() {
    const { profile, needsInit, init, loading } = this.props;
    if (profile && needsInit && !loading) {
      init(profile);
    }
  }

  renderNavItem = (route, label) => {
    const { classes, match } = this.props;
    let Icon;
    if (route === Routes.accountSettings) {
      Icon = AccountCircle;
    } else if (route === Routes.campusSettings) {
      Icon = SchoolBuilding;
    } else if (route === Routes.orgTagsSettings) {
      Icon = TagManage;
    } else {
      Icon = UserManage;
    }
    return (
      <Link to={`/${Routes.settings}/${route}`} className={classes.link}>
        <div className={classes.settingsNavItemWrapper}>
          <Icon color="primary" className={classes.settingsNavIcon} />
          <div
            className={classNames(classes.settingsNavItem, {
              [classes.settingsNavItemActive]: match.params.type === route,
            })}
          >
            {label}
          </div>
        </div>
      </Link>
    );
  };

  render() {
    const { classes, profile, loading, needsUpdate, match, loadingMsg } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.headerDiv}>
          <TitleBackButton title="Settings" />
        </div>
        <div className={classes.settingsWrapper}>
          <div className={classes.settingsNavBar}>
            {this.renderNavItem(Routes.accountSettings, 'Account Settings')}
            {this.renderNavItem(Routes.campusSettings, 'District/Campus Settings')}
            {this.renderNavItem(Routes.userSettings, 'Manage Users')}
            {this.renderNavItem(Routes.orgTagsSettings, 'Manage Tags')}
            <div className={classes.version}>{`Version ${process.env.REACT_APP_VERSION}`}</div>
          </div>
          {(!profile || loading || needsUpdate) && <LoadingCircle classes={classes} msg={loadingMsg} />}
          <div
            className={classNames({
              [classes.body]: match.params.type !== Routes.userSettings,
              [classes.manageUsersBody]: match.params.type === Routes.userSettings,
            })}
            style={{ display: !profile || loading || needsUpdate ? 'none' : 'block' }}
          >
            {match.params.type === Routes.campusSettings && <SchoolSettings />}
            {match.params.type === Routes.accountSettings && <AccountSettings />}
            {match.params.type === Routes.userSettings && <ManageUsers profile={profile} />}
            {match.params.type === Routes.orgTagsSettings && <ManageOrgTags />}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Settings);
