import { connect } from 'react-redux';

import Component from './AssessmentGroupManager.component';
import {
  getAssessmentGroups,
  addAssessmentGroup,
  deleteAssessmentGroup,
  updateAssessmentGroup,
  changeAssessmentGroupVisibility,
} from '../+store/assessments/assessments.actions';
import { groupNeedsToBeFetched } from '../+store/assessments/assessments.selectors';
import { openCreateGroupDialog, closeCreateGroupDialog } from '../+store/assessmentManager';

const mapStateToProps = (state) => ({
  profile: state.modules.Auth.auth.profile,
  needsInit: groupNeedsToBeFetched(state),
  loadingMsg: state.modules.Assessments.groupAssessments.loadingMsg,
  loading: !!state.modules.Assessments.groupAssessments.loadingMsg,
  assessmentGroups: state.modules.Assessments.groupAssessments.assessmentGroups,
  assessmentGroupCreateOpen: state.modules.Assessments.assessmentManager.assessmentGroupCreateOpen,
});

const mapDispatchToProps = (dispatch) => ({
  getAssessmentGroups: (profile) => {
    dispatch(getAssessmentGroups(profile));
  },
  onCreateAssessmentGroupClick: () => {
    dispatch(openCreateGroupDialog());
  },
  onCloseAssessmentGroupClick: () => {
    dispatch(closeCreateGroupDialog());
  },
  handleAddAssessmentGroup: (profile, assessmentGroup) => {
    dispatch(addAssessmentGroup(profile, assessmentGroup));
  },
  handleEditAssessmentGroup: (profile, assessmentGroupId, assessmentGroup) => {
    dispatch(updateAssessmentGroup(profile, assessmentGroupId, assessmentGroup));
  },
  handleGroupDelete: (profile, assessmentGroupId) => {
    dispatch(deleteAssessmentGroup(profile, assessmentGroupId));
  },
  onAssessmentGroupVisibilityBySuperAdminOnlyChange: (profile, assessmentGroup) => {
    dispatch(changeAssessmentGroupVisibility(profile, assessmentGroup));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
