import { asyncStart } from '../../../common';

export const GET_ORG_TAGS = 'ManageOrgTags.getOrgTags';
export const getOrgTags = () => (dispatch) => {
  dispatch(asyncStart(GET_ORG_TAGS));
  dispatch({ type: GET_ORG_TAGS });
};

export const SAVE_ORG_TAG = 'ManageOrgTags.saveOrgTag';
export const saveOrgTag = (data) => (dispatch) => {
  dispatch(asyncStart(SAVE_ORG_TAG));
  dispatch({
    type: SAVE_ORG_TAG,
    data,
  });
};

export const DELETE_ORG_TAG = 'ManageOrgTags.deleteOrgTag';
export const deleteOrgTag = (data) => (dispatch) => {
  dispatch(asyncStart(DELETE_ORG_TAG));
  dispatch({
    type: DELETE_ORG_TAG,
    data,
  });
};
