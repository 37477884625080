import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = () => ({
  info: {
    textAlign: 'center',
    fontSize: 16,
    color: '#282828',
  },
});

class SectionMsg extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    msg: PropTypes.object,
  };

  componentDidMount() {}

  render() {
    const { classes, msg } = this.props;
    const ps = msg.msg.split('\n');
    return (
      <div>
        {ps.map((p, i) => (
          <Typography key={i} className={classes[msg.level]}>
            {p}
          </Typography>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(SectionMsg);
