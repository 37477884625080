import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    borderRadius: '10px',
  },
  noMargin: {
    margin: 0,
  },
  label: {
    textAlign: 'center',
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },
  transparent: {
    border: 0,
    backgroundColor: 'transparent',
  },
  transparentLabel: {
    color: theme.palette.primary.main,
  },
  outlined: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
  },
  outlinedLabel: {
    color: theme.palette.primary.main,
  },
  dark: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    '&:hover,&:focus': {
      background: theme.palette.primary.main,
    },
  },
  darkLabel: {
    color: theme.palette.white,
  },
  light: {
    border: `1px solid ${theme.palette.primary.light}`,
    backgroundColor: theme.palette.primary.light,
    '&:hover,&:focus': {
      background: theme.palette.primary.light,
    },
  },
  lightLabel: {
    color: theme.palette.white,
  },
  white: {
    border: `1px solid ${theme.palette.white}`,
    backgroundColor: theme.palette.white,
    '&:hover,&:focus': {
      background: theme.palette.white,
    },
  },
  whiteLabel: {
    color: theme.palette.primary.main,
  },
});

class CustomButton extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    className: PropTypes.any,
    color: PropTypes.oneOf(['dark', 'light', 'white', 'outlined', 'transparent']),
    onClick: PropTypes.func,
    large: PropTypes.bool,
    submit: PropTypes.bool,
    noMargin: PropTypes.bool,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    color: 'dark',
    large: false,
    submit: false,
  };

  render() {
    const { classes, className, children, onClick, color, large, submit, isDisabled, noMargin } = this.props;
    return (
      <Button
        classes={{
          root: classNames(classes.button, classes[color], { [classes.noMargin]: noMargin }),
          label: classNames(classes.labels, classes[`${color}Label`]),
        }}
        className={className}
        style={{ minWidth: large ? '11rem' : 'auto' }}
        onClick={onClick}
        type={submit ? 'submit' : 'button'}
        disabled={isDisabled}
      >
        {children}
      </Button>
    );
  }
}

export default withStyles(styles)(CustomButton);
