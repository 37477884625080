import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { _reducer as aToF, epics as aToFEpics } from './aToF';

export const reducer = combineReducers({
  aToF,
});

export const epics = combineEpics(aToFEpics);
