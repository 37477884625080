import { createSelector } from 'reselect';
import { Subjects, Grades } from './aToF.constants';

const getProfile = (state) => state.modules.Auth.auth.profile;
const getSchoolList = (state) => state.modules.Settings.schoolSettings.schoolList;

export const schoolListPlusDistrict = createSelector(getProfile, getSchoolList, (profile, schoolList) => {
  let schools = Object.entries(schoolList).reduce((prev, [campusNumber, campus]) => {
    prev[campusNumber] = campus.name;
    return prev;
  }, {});
  delete schools[profile.districtNumber];
  schools = Object.entries(schools).sort((a, b) => a[1].localeCompare(b[1]));
  if (profile && profile.role === 'districtAdmin') {
    if (schools.length > 1) {
      schools.unshift([profile.districtNumber, 'District']);
    }
  }
  return schools;
});

const getFilterFields = (state) => state.modules.AtoF.aToF.filterFields;

export const selectFilterFields = createSelector(getFilterFields, (filterFields) => {
  if (filterFields) {
    let { subjects, grades, teachers } = filterFields;
    if (subjects && subjects.length) {
      subjects = subjects.sort((sub1, sub2) => sub1.localeCompare(sub2));
    }
    const formattedSubjects = [];
    (subjects || []).forEach((subject) => {
      formattedSubjects.push({
        value: subject,
        label: Subjects[subject],
      });
    });
    const formattedGrades = [];
    if (grades && grades.length) {
      grades = grades.sort((grade1, grade2) => grade1 - grade2);
    }
    (grades || []).forEach((grade) => {
      formattedGrades.push({
        value: grade,
        label: Grades[grade],
      });
    });
    const formattedTeachers = [];
    if (teachers && teachers.length) {
      teachers = teachers.sort((t1, t2) => t1.localeCompare(t2));
    }
    (teachers || []).forEach((teacher) => {
      formattedTeachers.push({
        value: teacher,
        label: teacher,
      });
    });
    return {
      subjects: formattedSubjects,
      grades: formattedGrades,
      teachers: formattedTeachers,
    };
  }
  return {
    subjects: [],
    grades: [],
    teachers: [],
  };
});

export default 'assessment.selectors.js';
