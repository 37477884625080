import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { Logo } from '../../common/Logo';
import { Routes } from '../../App';
import { LoadingCircle } from '../../common/LoadingCircle';

const mountainsSvg = require('../../../assets/img/mountains.svg');

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    background: 'linear-gradient(180deg, #FFFFFF -0.97%, rgba(255, 255, 255, 0) 102.06%), rgba(134, 240, 255, 0.65)',
    transform: 'rotate(0.04deg)',
    height: '100%',
  },
  logoIcon: {
    width: 231,
    height: 93,
  },
  logoTitle: {
    color: theme.palette.primary.main,
    fontSize: '2.25rem',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '2.5rem',
    textTransform: 'capitalize',
    width: '50%',
  },
  errorMsg: {
    color: theme.palette.error.dark,
    fontSize: '0.875rem',
    marginLeft: theme.spacing.unit,
  },
  mountains: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
  },
  backdrop: {
    height: '100%',
    overflow: 'scroll',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '100px',
    paddingBottom: '100px',
  },
});

class AuthLayout extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    classes: PropTypes.any,
    error: PropTypes.string,
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
  };

  render() {
    const { title, children, classes, error, profile, loading, loadingMsg } = this.props;

    if (profile) {
      return <Redirect to="" />;
    }

    return (
      <div className={classes.root}>
        <div className={classes.backdrop}>
          <Logo
            wrapperClassName={classes.logoWrapper}
            logoClassName={classes.logoIcon}
            titleClassName={classes.logoTitle}
          />
          {title && (
            <Typography gutterBottom variant="h1" color="error" align="center" className={classes.title}>
              {title}
            </Typography>
          )}
          {error && (
            <Typography className={classes.errorMsg} gutterBottom>
              {error}
            </Typography>
          )}
          {loading ? <LoadingCircle msg={loadingMsg} classes={classes} /> : children}
          <img className={classes.mountains} src={mountainsSvg} alt="Copilot Mountains Background" />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AuthLayout);
