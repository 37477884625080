import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import { asyncStart, asyncFinish } from '../../../common';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
};
const app = firebase.initializeApp(config);
if (process.env.NODE_ENV === 'development') {
  app.functions().useFunctionsEmulator('http://localhost:5000');
}
const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

export { app, db, auth, storage, firebase };

export const FIREBASE_INIT = 'firebase.init';
export const firebaseInit = () => (dispatch) => {
  dispatch(asyncStart(FIREBASE_INIT));
  dispatch(asyncFinish(FIREBASE_INIT));
};
