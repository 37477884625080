import { asyncStart } from '../../../common';

export const EXPORT_PROGRESS_GOALS = 'progressGoals.export';
export const exportProgressGoals = (sortBy, filters) => (dispatch) => {
  dispatch(asyncStart(EXPORT_PROGRESS_GOALS));
  dispatch({ type: EXPORT_PROGRESS_GOALS, sortBy, filters });
};

export const GET_PROGRESS_GOALS = 'progressGoals.get';
export const getProgressGoals = ({ sortBy, limit, nextToken, ignoreCache, filters, stagedFilters }) => (dispatch) => {
  dispatch(asyncStart(GET_PROGRESS_GOALS, '', { nextToken }));
  dispatch({
    type: GET_PROGRESS_GOALS,
    ignoreCache,
    sortBy,
    limit,
    nextToken,
    filters,
    stagedFilters,
  });
};

export const CALCULATE_PROGRESS_GOALS = 'progressGoals.calculate';
export const calculateProgressGoals = (ignoreCache) => (dispatch) => {
  dispatch(asyncStart(CALCULATE_PROGRESS_GOALS));
  dispatch({
    type: CALCULATE_PROGRESS_GOALS,
    ignoreCache,
  });
};

export const UPDATE_FILTERS = 'progressGoals.updateFilters';
export const updateFilters = (filter) => ({
  type: UPDATE_FILTERS,
  filter,
});

export const APPLY_FILTERS = 'progressGoals.applyFilters';
export const applyFilters = (filters) => ({
  type: APPLY_FILTERS,
  filters,
});

export const RESET_FILTERS = 'progressGoals.resetFilters';
export const resetFilters = () => ({
  type: RESET_FILTERS,
});
