import owasp from 'owasp-password-strength-test';
import ErrorIcon from '@material-ui/icons/Cancel';
import * as React from 'react';
import firebase from 'firebase';
import stringify from 'csv-stringify';

export const isRealNumber = (num) => num != null && !Number.isNaN(Number(num));

export const passwordStrengthGrade = (password) => {
  const strength = owasp.test(password || '');
  const width = (5 - strength.errors.length) / 5;
  const colors = '#68D190';

  const style = {
    root: {
      backgroundColor: 'transparent',
      width: '370px',
      marginBottom: 10,
      minWidth: '370px',
    },
    progress: {
      width: width * 75 > 0 ? `${width * 100}%` : '0%',
      height: 8,
      backgroundColor: password.length > 0 ? colors : null,
    },
    errorMsgZone: {
      color: '#EE9696',
      fontSize: '0.875rem',
      marginBottom: '8px',
      paddingLeft: 8,
      alignItems: 'center',
      display: 'flex',
    },
    errorIcon: {
      fontSize: 20,
      marginRight: 5,
    },
  };
  return (
    <div style={style.root}>
      <div style={style.progress} />
      {strength.errors.map((error, index) => (
        <div style={style.errorMsgZone} key={index}>
          <ErrorIcon style={style.errorIcon} />
          {error}
        </div>
      ))}
    </div>
  );
};

export const isMultipleCampusAdmin = (user) =>
  user.role === 'campusAdmin' && user.campusNumber && typeof user.campusNumber !== 'string';

export const isCampusAdminForThisCampus = (user, campusNumber) => {
  if (!user.campusNumber) {
    return false;
  }
  if (typeof user.campusNumber === 'string') {
    return user.campusNumber === campusNumber;
  }
  return user.campusNumber.some((campus) => campus === campusNumber);
};

export const getMultipleCampusData = async (user, databaseRefPath, orderByChild = true) => {
  let multipleCampusData = {};
  await Promise.all(
    user.campusNumber.map(async (campus) => {
      let campusData = null;
      if (orderByChild) {
        campusData = await firebase
          .database()
          .ref(databaseRefPath)
          .orderByChild('campusNumber')
          .equalTo(campus)
          .once('value')
          .then((snapshot) => snapshot.val());
      } else {
        campusData = await firebase
          .database()
          .ref(`${databaseRefPath}/${campus}`)
          .once('value')
          .then((snapshot) => snapshot.val());
      }
      multipleCampusData[campus] = campusData;
    })
  );

  return multipleCampusData;
};

export const makeCsv = (shapedData, columns) =>
  new Promise((resolve, reject) => {
    stringify(
      shapedData,
      {
        columns,
        record_delimiter: 'windows',
        header: true,
        delimiter: ',',
      },
      (err, output) => {
        if (err) {
          console.log(err);
          reject(err);
        }
        resolve(output);
      }
    );
  });

export default '';
