import { AssessmentTypes, getDynamicAssessmentModel } from '../assessments/assessments.types';
import { updateAssessmentChecklist, getAssessmentChecklist } from './assessementChecklist.db';

const getTypeFromAssessmentType = (deletedType) => {
  let year = '2019';
  let type;
  switch (deletedType) {
    case AssessmentTypes.FYLI2017:
    case AssessmentTypes.FYLI2016:
    case AssessmentTypes.SYLI2015:
      year = '2018';
      type = 'gradData';
      break;
    case AssessmentTypes.FYLI2018:
    case AssessmentTypes.F5YLI2017:
    case AssessmentTypes.SYLI2016:
      type = 'gradData';
      break;
    case AssessmentTypes.CCMR2018:
    case AssessmentTypes.CCMR2019:
      type = 'ccmr';
      year = deletedType.substring(4);
      break;
    case AssessmentTypes.SAT2019:
      type = 'sat';
      year = deletedType.substring(3);
      break;
    case AssessmentTypes.STAAR2019:
      type = 'staar';
      break;
    case AssessmentTypes.STAARALT2019:
      type = 'staarAlt';
      break;
    case AssessmentTypes.EOC2019:
      type = 'eoc';
      break;
    case AssessmentTypes.EOCALT2019:
      type = 'eocAlt';
      break;
    case AssessmentTypes.TELPAS2019:
      type = 'telpas';
      break;
    case AssessmentTypes.STAAR2021:
      type = 'staar';
      break;
    case AssessmentTypes.STAARALT2021:
      type = 'staarAlt';
      break;
    case AssessmentTypes.EOC2021:
      type = 'eoc';
      break;
    case AssessmentTypes.EOCALT2021:
      type = 'eocAlt';
      break;
    case AssessmentTypes.TELPAS2021:
      type = 'telpas';
      break;
    default:
      break;
  }

  var dynamicAssessment = getDynamicAssessmentModel(deletedType);
  switch (dynamicAssessment.shortName) {
    case AssessmentTypes.STAAR:
      type = 'staar';
      break;
    case AssessmentTypes.STAARALT:
      type = 'staarAlt';
      break;
    case AssessmentTypes.EOC:
      type = 'eoc';
      break;
    case AssessmentTypes.EOCALT:
      type = 'eocAlt';
      break;
    case AssessmentTypes.TELPAS:
      type = 'telpas';
      break;
    case AssessmentTypes.TELPASALT:
      type = 'telpasAlt';
      break;
    case AssessmentTypes.CCMR:
      type = 'ccmr';
      break;
    case AssessmentTypes.STAARCustom:
      type = 'staarCustom';
      break;
  }
  return {
    type,
    year,
  };
};
export const deleteFromAssessmentChecklist = async (deletedType, user) => {
  const checkList = await getAssessmentChecklist(user);
  const info = getTypeFromAssessmentType(deletedType);
  const assessmentObject = checkList[info.year][info.type];
  Object.keys(assessmentObject).forEach((key) => {
    assessmentObject[key] = false;
  });
  await updateAssessmentChecklist(checkList, user);
};

export default 'assessementChecklist.delete.js';
