const isCCMR2018 = (file) =>
  new Promise((resolve) => {
    if (file.type !== 'application/vnd.ms-excel') {
      resolve(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      const lines = text.split(/\r\n?|\n/);
      const lookingFor = {
        confidential: false,
        tea: false,
        ccmr2018: false,
        desc: false,
      };
      lines.forEach((line) => {
        if (line.includes('C O N F I D E N T I A L')) {
          lookingFor.confidential = true;
        }
        if (line.includes('TEXAS EDUCATION AGENCY')) {
          lookingFor.tea = true;
        }
        if (line.includes('2018 College, Career, and Military Readiness Student Listing')) {
          lookingFor.ccmr2018 = true;
        }
        if (line.includes('District and Campus Student Listing')) {
          lookingFor.desc = true;
        }
      });
      if (Object.values(lookingFor).every((x) => x)) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    reader.readAsText(file);
  });

export default isCCMR2018;
