import { connect } from 'react-redux';
import queryString from 'query-string';
import Component from './CCMRTracking.component';
import { getAvatars, getCurrentRosterYear, initStudentsRoster } from '../StudentRoster/+store/studentRoster';
import { getSchools } from '../Settings/+store/SchoolSettings';
import {
  getCCMRTrackingData,
  updateCCMRTrackingData,
  filterCCMRByRosterProperty,
  sortCCMRByRosterProperty,
  ccmrFilterBoxParams,
  didGraduate,
  selectCCMRKey,
  updateIsMonitorFilter,
} from './+store';
import { resetFilters, getTeachers, getMentors } from '../common/+store/FilterBox';
import { isMultipleCampusAdmin } from '../common';

const getIgnoreCache = () => {
  const parsedQuery = queryString.parse(window.location.search);
  return parsedQuery.cache === 'false' || false;
};

const filterByGradStatus = (student, values) => {
  if (values.length === 2) return true;
  return values[0] === didGraduate(student.gradYear, student.graduated);
};

const filterStudents = (ccmrtData, students, selectedFilterItems, sortByField, sortReverse, isMonitorFilter) => {
  let filteredStudents = ccmrtData;
  const customFunctions = {
    gradStatus: filterByGradStatus,
  };
  if (!students) return {};
  filteredStudents = filterCCMRByRosterProperty(
    filteredStudents,
    students,
    selectedFilterItems.filterOptions,
    customFunctions,
    isMonitorFilter
  );
  filteredStudents = sortCCMRByRosterProperty(filteredStudents, students, sortByField, sortReverse);
  return filteredStudents;
};

const mapStateToProps = (state) => {
  const { selectedFilterItems, sortByField, sortReverse } = state.modules.Common.filterBox;
  const ccmrData = state.modules.CCMRTracking.ccmrTracking.ccmrTrackingData || {};
  let students = state.modules.StudentRoster.studentRoster.roster;
  const currentSnapshotYear = getCurrentRosterYear();
  students = students && students[currentSnapshotYear];
  const { profile } = state.modules.Auth.auth;
  const { schoolList } = state.modules.Settings.schoolSettings;
  const teacherList = students != null ? getTeachers(students) : {};
  const mentorsList = students != null ? getMentors(students) : {};
  if (
    schoolList &&
    Object.keys(schoolList).length &&
    profile &&
    (profile.role === 'districtAdmin' || isMultipleCampusAdmin(profile))
  ) {
    const schoolListOptions = Object.entries(schoolList)
      .filter(([campusNumber, school]) => campusNumber.length !== 6)
      .map(([campusNumber, school]) => ({ value: campusNumber, label: school.name }));
    let filterSection = ccmrFilterBoxParams.filterSections.find((f) => f.id === 'campusNumber');

    if (filterSection) {
      filterSection.values = schoolListOptions;
    } else {
      filterSection = {
        id: 'campusNumber',
        filterKey: 'campusNumber',
        label: 'Campuses',
        values: schoolListOptions,
        type: 'list',
        byRoster: true,
      };
      ccmrFilterBoxParams.filterSections.push(filterSection);
    }
  }

  if (teacherList) {
    let filterSectionTeacher = ccmrFilterBoxParams.filterSections.find((f) => f.id === 'teacher');
    if (filterSectionTeacher) {
      filterSectionTeacher.values = teacherList;
    } else {
      filterSectionTeacher = {
        id: 'teacher',
        filterKey: 'teachers',
        label: 'Teachers',
        values: teacherList,
        type: 'list',
        byRoster: true,
      };
      ccmrFilterBoxParams.filterSections.push(filterSectionTeacher);
    }
  }

  if (mentorsList) {
    let filterSectionMentor = ccmrFilterBoxParams.filterSections.find((f) => f.id === 'mentor');
    if (filterSectionMentor) {
      filterSectionMentor.values = mentorsList;
    } else {
      filterSectionMentor = {
        id: 'mentor',
        filterKey: 'mentor',
        label: 'Mentors',
        values: mentorsList,
        type: 'list',
        byRoster: true,
      };
      ccmrFilterBoxParams.filterSections.push(filterSectionMentor);
    }
  }

  return {
    students,
    bulkEntryKey: state.modules.CCMRTracking.ccmrTracking.bulkEntryKey,
    filterBoxParams: ccmrFilterBoxParams,
    loading: !!state.modules.CCMRTracking.ccmrTracking.loadingMsg,
    loadingMsg: state.modules.CCMRTracking.ccmrTracking.loadingMsg,
    loadingRoster: !!state.modules.StudentRoster.studentRoster.loadingMsg,
    schoolList: state.modules.Settings.schoolSettings.schoolList,
    profile: state.modules.Auth.auth.profile,
    username: state.modules.Auth.auth.user.displayName,
    user: state.modules.Auth.auth.user,
    ccmrTrackingData: filterStudents(
      ccmrData,
      students,
      selectedFilterItems,
      sortByField,
      sortReverse,
      state.modules.CCMRTracking.ccmrTracking.isMonitorFilter
    ),
    // ccmrTrackingData: ccmrData,
    avatars: state.modules.StudentRoster.studentRoster.avatars,
    ignoreCache: getIgnoreCache(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: (user) => {
    dispatch(initStudentsRoster(user));
    dispatch(getAvatars());
    dispatch(resetFilters());
  },
  getSchools: (profile) => {
    dispatch(getSchools(profile));
  },
  getCCMRTrackingData: (profile, ignoreCache) => {
    dispatch(getCCMRTrackingData(profile, ignoreCache));
  },
  updateCCMRTrackingData: (profile, firebaseId, studentData) => {
    dispatch(updateCCMRTrackingData(profile.organizationId, firebaseId, studentData));
  },
  selectCCMRKey: (key) => {
    dispatch(selectCCMRKey(key));
  },
  updateIsMonitorFilter: (val) => {
    dispatch(updateIsMonitorFilter(val));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
