import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, List, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FilterItem from './FilterItem.container';

const styles = (theme) => ({
  div: {
    width: '200px',
    marginRight: '60px',
  },
  filterTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  filterBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
});

class FilterSection extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    filterKey: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    model: PropTypes.string.isRequired,
    type: PropTypes.string,
    byRoster: PropTypes.bool,
    byCCMR: PropTypes.string,
    onChange: PropTypes.func,
  };

  state = {
    open: false,
  };

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { filterKey, id, label, values, classes, model, type, byRoster, byCCMR, onChange } = this.props;
    var sortedValues = [];
    if (values) {
      sortedValues = values.sort(function(a, b) {
        if (!isNaN(a.label)) {
          if (Number(a.label) < Number(b.label)) {
            return -1;
          }
          if (Number(a.label) > Number(b.label)) {
            return 1;
          }
          return 0;
        } else {
          return a.label.localeCompare(b.label);
        }
      });
    }

    const { open } = this.state;
    return (
      <Grid item className={classes.div}>
        <div onClick={this.toggleMenu} className={classes.filterTitle}>
          <Icon className={classes.icon} color="primary">
            {open ? 'remove' : 'add'}
          </Icon>
          <Typography color="primary">{label}</Typography>
        </div>
        {open && (
          <List dense className={classes.filterBox}>
            {sortedValues.map((value, index) => (
              <FilterItem
                id={id}
                key={index}
                filterKey={filterKey}
                item={value}
                model={model}
                type={type}
                byRoster={byRoster}
                byCCMR={byCCMR}
                onChange={onChange}
              />
            ))}
          </List>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(FilterSection);
