import { connect } from 'react-redux';
import {
  activateAccount,
  deactivateAccount,
  resetUserPassword,
  cancelInvite,
  resendInvite,
} from '../../+store/ManageUsers';
import Component from './User.component';

const mapStateToProps = (state) => ({
  schoolList: state.modules.Settings.schoolSettings.schoolList,
  profile: state.modules.Auth.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  onActivateAccount: (userUid) => {
    dispatch(activateAccount(userUid));
  },
  onDeactivateAccount: (userUid) => {
    dispatch(deactivateAccount(userUid));
  },
  onResetPassword: (userUid, password) => {
    dispatch(resetUserPassword(userUid, password));
  },
  onCancelInvite: (signUpCode) => {
    dispatch(cancelInvite(signUpCode));
  },
  onResendInvite: (signUpCode) => {
    dispatch(resendInvite(signUpCode));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
