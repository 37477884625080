import { connect } from 'react-redux';

import Component from './UserInfoBanner.component';
import { getSchools } from '../../Settings/+store/SchoolSettings';
import { getOrgTags } from '../../Settings/+store/ManageOrgTags';
const mapStateToProps = (state) => ({
  profile: state.modules.Auth.auth.profile,
  schoolList: state.modules.Settings.schoolSettings.schoolList,
  tags: state.modules.Settings.manageOrgTags.tags,
});

const mapDispatchToProps = (dispatch) => ({
  initSchools: (profile) => {
    dispatch(getSchools(profile));
  },
  initTags: (profile) => {
    dispatch(getOrgTags());
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
