import { combineReducers } from 'redux';
import {
  GET_ASSESSMENT_LIST,
  VALIDATE_ASSESSMENT_FILE,
  UPLOAD_ASSESSMENT,
  ADD_ASSESSMENT_GROUP,
  UPDATE_ASSESSMENT_GROUP,
  CHANGE_ASSESSMENT_GROUP_VISIBILITY,
} from '../assessments/assessments.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_PROGRESS, ASYNC_START } from '../../../common';
import {
  CANCEL_UPLOAD,
  OPEN_UPLOAD_DIALOG,
  CLOSE_UPLOAD_DIALOG,
  CLOSE_CREATE_GROUP_DIALOG,
  OPEN_CREATE_GROUP_DIALOG,
} from './assessmentManager.actions';

const initialStatus = {
  message: '',
  error: '',
};
const status = (state = initialStatus, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ASSESSMENT_LIST) {
        return Object.assign({}, state, {
          message: action.data.message,
          error: '',
        });
      }
      if (action.model === UPLOAD_ASSESSMENT) {
        return Object.assign({}, state, {
          message: 'Your assessment was successfully uploaded.',
          error: '',
        });
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === GET_ASSESSMENT_LIST) {
        return Object.assign({}, state, {
          message: '',
          error: action.error.message,
        });
      }
      if (action.model === VALIDATE_ASSESSMENT_FILE) {
        return Object.assign({}, state, {
          message: '',
          error: action.error.message,
        });
      }
      if (action.model === UPLOAD_ASSESSMENT) {
        return Object.assign({}, state, {
          message: '',
          error: action.error.message,
        });
      }
      return state;
    default:
      return state;
  }
};

const uploadStaging = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === VALIDATE_ASSESSMENT_FILE) {
        return action.data;
      }
      if (action.model === UPLOAD_ASSESSMENT) {
        return null;
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === UPLOAD_ASSESSMENT) {
        return null;
      }
      return state;
    case CANCEL_UPLOAD:
      return null;
    default:
      return state;
  }
};

const uploadProgressMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === UPLOAD_ASSESSMENT) {
        return 'Uploading ...';
      }
      return state;
    case ASYNC_PROGRESS:
      if (action.model === UPLOAD_ASSESSMENT) {
        return action.data.message;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ASSESSMENT) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const uploadOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_UPLOAD_DIALOG:
      return true;
    case CLOSE_UPLOAD_DIALOG:
      return false;
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ASSESSMENT) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const assessmentGroupCreateOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_CREATE_GROUP_DIALOG:
      return true;
    case CLOSE_CREATE_GROUP_DIALOG:
      return false;
    case ASYNC_FINISH:
      if (
        action.model === ADD_ASSESSMENT_GROUP ||
        action.model === UPDATE_ASSESSMENT_GROUP ||
        action.model === CHANGE_ASSESSMENT_GROUP_VISIBILITY
      ) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
  uploadStaging,
  uploadProgressMsg,
  uploadOpen,
  assessmentGroupCreateOpen,
});

export default 'studentsPage.reducers.js';
