import { connect } from 'react-redux';

import Component from './SignUp.component';
import { initCampusDistrictList, signUp, getSignUpCodeInfo } from '../+store/auth/auth.actions';
import { doRedirect } from '../../App/+store/routes';

const mapStateToProps = (state) => ({
  profile: state.modules.Auth.auth.profile,
  districtList: state.modules.Auth.auth.uniqueDistricts,
  campusList: state.modules.Auth.auth.uniqueCampuses,
  authenticated: state.modules.Auth.auth.authenticated,
  msg: state.modules.Auth.auth.signUpMsg,
  loading: !!state.modules.Auth.auth.signUpMsg,
  signUpCodeInfo: state.modules.Auth.auth.signUpCodeInfo,
  redirectToSignUp: state.modules.Auth.auth.redirectToSignUp,
});

const getCreateNewOrg = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const createNewOrg = queryParams.get('createNewOrg');
  return createNewOrg === 'true' || false;
};

const mapDispatchToProps = (dispatch) => ({
  init: (signUpCode = null) => {
    dispatch(initCampusDistrictList());
    if (signUpCode) {
      dispatch(getSignUpCodeInfo(signUpCode));
    }
  },
  onSubmit: (data) => {
    var createNewOrg = getCreateNewOrg();
    if (createNewOrg === true) {
      data.createNewOrg = createNewOrg;
    }
    dispatch(signUp(data));
  },
  doRedirect: (history, to) => {
    dispatch(doRedirect(history, to));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
