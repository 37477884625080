import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Select, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';

const styles = (theme) => ({
  select: {
    width: '200px',
  },
  root: {
    marginBottom: theme.spacing.unit,
  },
});

class SortSection extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    sortByField: PropTypes.string,
    label: PropTypes.string.isRequired,
    // should be in shape {id: {keys: [value], reverse: false, label: xxx}, id: {keys: [value], reverse: true, label: yyy}, ...}
    options: PropTypes.object.isRequired,
    handleSortByChange: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  handleChange = (e) => {
    const { handleSortByChange, onChange } = this.props;
    handleSortByChange(e);
    if (onChange) onChange(e);
  };

  render() {
    const { sortByField, options, classes, label } = this.props;

    return (
      <Grid item className={classes.root}>
        <Typography color="primary">{label}</Typography>
        <Select className={classes.select} onChange={this.handleChange} value={sortByField}>
          {Object.entries(options).map(([value, option], index) => (
            <MenuItem key={index} value={value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    );
  }
}

export default withStyles(styles)(SortSection);
