// { firebaseStorageDownloadTokens: '6ca062a6-cc9e-4741-9f52-58a88ed90513',
//   organizationId: '-LbXhJVIng9uvAICiDvv',
//   owner: 'district',
//   ownerId: 'lVfohbODIqQ9odyT1u5x35D7UOW2',
//   type: 'roster' }

import { storage, firebase } from '../../../App/+store/firebase';
import { getCurrentRosterYear } from './studentRoster.utils';

export const saveFile = async (file, user, profile, year, onProgress) => {
  const orgId = profile.organizationId;
  const owner = profile.role === 'campusAdmin' ? profile.campusNumber : 'district';
  const fileKey = await firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
    .push().key;
  const storageKey = `organizations/${orgId}/roster/${owner}/${fileKey}`;

  const storageRef = storage.ref();
  const rostersRef = storageRef.child(storageKey);

  const metadata = {
    customMetadata: {
      type: 'roster',
      organizationId: orgId,
      owner,
      ownerId: profile.uid,
      year: year || getCurrentRosterYear(),
    },
  };
  return new Promise((resolve, reject) => {
    const uploadTask = rostersRef.put(file, metadata);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            onProgress(progress, `${progress}% Paused`);
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            onProgress(progress, `${progress}%`);
            break;
          default:
            break;
        }
      },
      (error) => {
        reject(error);
      },
      async () => {
        resolve(storageKey);
      }
    );
  });
};

export const saveProfilePicture = async (file, profile, studentId, onProgress, year) =>
  new Promise((resolve, reject) => {
    const { organizationId } = profile;
    const storageKey = `organizations/${organizationId}/photos/${year}/${studentId}`;
    const storageRef = storage.ref();
    const ppRef = storageRef.child(`${storageKey}/original`);

    const metadata = {
      customMetadata: {
        type: 'originalPP',
        organizationId,
        ownerId: profile.uid,
      },
    };
    const uploadTask = ppRef.put(file, metadata);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            onProgress(progress, `${progress}% Paused`);
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            onProgress(progress, `${progress}%`);
            break;
          default:
            break;
        }
      },
      (error) => {
        reject(error);
      },
      async () => {
        resolve({
          storageKey,
          studentId,
          organizationId,
        });
      }
    );
  });

export default 'studentRoster.storage.js';
