import { combineReducers } from 'redux';
import { GET_REPORT, UPDATE_FILTERS, GET_FILTER_FIELDS, GET_STUDENTS_LIST, CLOSE_STUDENTS_LIST } from './aToF.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../common';
import { getCurrentYear } from '../../../common/+store/utils';
import { GET_ASSESSMENT_LIST } from '../../../Assessments';
import { GET_FLIGHT_PLAN } from '../../../FlightPlan/+store/flightPlan';

const initialStatus = {
  message: '',
  error: '',
};
const status = (state = initialStatus, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          message: action.data.message,
          error: '',
        });
      }
      if (action.model === GET_FILTER_FIELDS) {
        return Object.assign({}, state, {
          message: action.data.message,
          error: '',
        });
      }
      if (action.model === GET_ASSESSMENT_LIST) {
        return Object.assign({}, state, {
          message: action.data.message,
          error: '',
        });
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          message: '',
          error: action.error.message,
        });
      }
      if (action.model === GET_FILTER_FIELDS) {
        return Object.assign({}, state, {
          message: '',
          error: action.error.message,
        });
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          report: 'Loading A-F Report ...',
        });
      }
      if (action.model === GET_FILTER_FIELDS) {
        return Object.assign({}, state, {
          filters: 'Loading Filter Fields ...',
        });
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          report: null,
        });
      }
      if (action.model === GET_FILTER_FIELDS) {
        return Object.assign({}, state, {
          filters: null,
        });
      }
      return state;
    default:
      return state;
  }
};

const reportData = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return action.data || null;
      }
      return state;
    case ASYNC_START:
      if (action.model === GET_REPORT) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const reportError = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return action.data.reportError || null;
      }
      return state;
    default:
      return state;
  }
};
const waitMessage = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return action.data.reportError == null ? '' : 'There is an error';
      }
      return state;
    case ASYNC_START:
      if (action.model === GET_REPORT) {
        if (action && action.data && action.data.campusDistrictNumber && action.data.campusDistrictNumber.length == 6) {
          return 'I’m sorry to announce that your flight has been delayed. Please try running the district report again in a few minutes. If the problem persists, please contact support.';
        }
      }

      return state;

    default:
      return state;
  }
};
const filterFields = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_FILTER_FIELDS || action.model === GET_REPORT || action.model === GET_FLIGHT_PLAN) {
        return action.data.filterFields || state;
      }
      return state;
    default:
      return state;
  }
};

const showStudentsList = (state = null, action) => {
  if (action.type === ASYNC_FINISH && action.model === GET_STUDENTS_LIST) {
    var studentsByCount = {};
    if (action.data.students) {
      studentsByCount = action.data.students.reduce((a, c) => ((a[c] = (a[c] || 0) + 1), a), Object.create(null));
    }
    return studentsByCount; //[...new Set(action.data.students)];
  }
  if (action.type === CLOSE_STUDENTS_LIST) {
    return null;
  }
  return state;
};

const filters = (
  state = {
    assessmentSource: [],
    school: null,
    subject: ['all'],
    grade: ['all'],
    teacher: ['all'],
    studentGroup: ['all'],
    schoolYear: getCurrentYear(),
  },
  action
) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

export const _reducer = combineReducers({
  status,
  loadingMsg,
  reportData,
  filters,
  filterFields,
  reportError,
  waitMessage,
  showStudentsList,
});

export default 'studentsPage.reducers.js';
