import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { reducer as App, epics as appEpics } from './App';
import { reducer as Auth, epics as AuthEpics, AUTH_SIGN_OUT } from './Auth';
import { reducer as OnBoarding } from './OnBoarding';
import { reducer as StudentRoster, epics as StudentRosterEpics } from './StudentRoster';
import { reducer as AtoF, epics as AtoFEpics } from './AtoF';
import { reducer as Settings, epics as SettingsEpic } from './Settings/+store';
import { reducer as Assessments, epics as AssessmentEpics } from './Assessments';
import { reducer as FlightPlan, epics as FlightPlanEpics } from './FlightPlan';
import { reducer as ProgressGoals, epics as ProgressGoalsEpics } from './ProgressGoals';
import { reducer as AccountabilityWeight, epics as AccountabilityWeightEpics } from './AccountabilityWeight/+store';
import { reducer as CCMRTracking, epics as CCMRTrackingEpics } from './CCMRTracking/+store';
import { reducer as Common } from './common/+store';
import { ASYNC_FINISH } from './common/+store/async';

const appReducer = combineReducers({
  App,
  Auth,
  OnBoarding,
  StudentRoster,
  AtoF,
  Settings,
  Assessments,
  AccountabilityWeight,
  Common,
  FlightPlan,
  CCMRTracking,
  ProgressGoals,
});

// reset the store when logout
const moduleReducer = (state, action) => {
  if (action.type === ASYNC_FINISH && action.model === AUTH_SIGN_OUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const epics = combineEpics(
  StudentRosterEpics,
  AuthEpics,
  AtoFEpics,
  SettingsEpic,
  AssessmentEpics,
  AccountabilityWeightEpics,
  FlightPlanEpics,
  CCMRTrackingEpics,
  appEpics,
  ProgressGoalsEpics
);

export default moduleReducer;
