import { connect } from 'react-redux';
import Component from './StudentsList.component';
import { closeStudentsList } from '../../+store/aToF';

const mapStateToProps = (state) => {
  const year = state.modules.AtoF.aToF.filters.schoolYear;
  const roster = state.modules.StudentRoster.studentRoster.roster;
  return {
    students: year && roster ? roster[year - 1] : null,
    studentsList: state.modules.AtoF.aToF.showStudentsList,
    avatars: state.modules.StudentRoster.studentRoster.avatars,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleClose: () => {
    dispatch(closeStudentsList());
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
