import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Typography, withStyles } from '@material-ui/core';
import { getStudentAvatarUrl } from '../../../StudentRoster/+store/studentRoster';

const styles = (theme) => ({
  modal: {
    position: 'absolute',
    background: 'linear-gradient(0deg, rgba(175, 228, 236, 0.65) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;',
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    maxHeight: 600,
    overflowY: 'scroll',
  },
  icon: {
    width: 50,
    height: 50,
  },
  student: {
    minWidth: '20%',
  },
});

const getStudentProfilePicture = (student, avatars, iconClass) => {
  const avatarUrl = getStudentAvatarUrl(student, avatars);
  const studentPhotoUrl = student && student.photoUrl && student.photoUrl.small ? student.photoUrl.small : avatarUrl;
  if (studentPhotoUrl) {
    return <img src={studentPhotoUrl} alt="Logo" className={iconClass} />;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

const StudentsListComponent = ({ classes, handleClose, studentsList, students, avatars }) => {
  if (!studentsList || !Object.entries(studentsList).length || students == null) return null;
  const getStudentLocalId = (id) => {
    if (students[id] && students[id].localId) {
      return <div>{students[id].localId}</div>;
    } else {
      return <div>{id}</div>;
    }
  };
  const getStudentName = (id) =>
    `${students[id] && students[id].firstName ? students[id].firstName : 'N/A'} ${
      students[id] && students[id].lastName && students[id].lastName.length > 0
        ? students[id].lastName.substring(0, 1)
        : ''
    }`;
  const getAvatar = (student, iconClass) => getStudentProfilePicture(student, avatars, iconClass);
  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open onClose={handleClose}>
      <div className={classes.modal}>
        <Typography variant="h6" id="modal-title" gutterBottom>
          Students
        </Typography>
        <Grid container justify="center" spacing={16}>
          {Object.entries(studentsList).map((item, idx) => (
            <Grid key={idx} item className={classes.student}>
              <Grid container direction="column" justify="center" alignItems="center">
                {getAvatar(students[item[0]], classes.icon)}
                {getStudentName(item[0])} ({item[1]}){getStudentLocalId(item[0])}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </Modal>
  );
};

StudentsListComponent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  students: PropTypes.object,
  studentsList: PropTypes.object,
  classes: PropTypes.any,
};

export default withStyles(styles)(StudentsListComponent);
