import firebase from 'firebase';
import _ from 'lodash';
import { isMultipleCampusAdmin } from '../../../common';
import { deleteFromAssessmentChecklist } from '../assessementChecklist/assessmentCheckList.delete';
import { getDynamicAssessmentModel } from './assessments.types';
const canAccess = (owner, profile) => {
  // Anyone can view district-uploaded files and District Admins can view all uploaded files.
  if (owner === 'district' || (profile && profile.role === 'districtAdmin')) {
    return true;
  }
  if (owner == null) {
    console.log('Assessment owner is null');
    return false;
  } else {
    // Multiple Campus Admin can view files that belong to any of their campuses.
    if (isMultipleCampusAdmin(profile)) {
      const campuses = profile.campusNumber;
      return typeof owner === 'string' ? campuses.includes(owner) : _.intersection(owner, campuses).length;
    }
    // Single Campus Admins can only view files that belong to their campus or district
    return typeof owner === 'string' ? profile.campusNumber === owner : owner.includes(profile.campusNumber);
  }
};
const isNotVisibleBySuperAdminOnly = (profile, assessmentSetOrGroup) => {
  return (
    (profile && (profile.type == 'superAdmin' || profile.isSuperAdmin == true)) ||
    assessmentSetOrGroup.isVisibleBySuperAdminOnly != true
  );
};

export const getAssessmentList = async (profile) => {
  const orgId = profile.organizationId;
  // TODO: make this an api call for the sake of role-based security [ADD JIRA CARD]
  // Get assessmentSets
  const assessmentSets = await firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
    .once('value')
    .then((snapshot) => snapshot.val());
  const assessmentList = [];
  if (assessmentSets) {
    Object.entries(assessmentSets).forEach(([aId, aSet]) => {
      if (aSet.parsed && canAccess(aSet.owner, profile)) {
        if (isNotVisibleBySuperAdminOnly(profile, aSet)) {
          assessmentList.push({
            id: aId,
            ...aSet,
          });
        }
      }
    });
  }
  return assessmentList;
};

export const addAssessmentListListener = async (profile, dispatchCallback) => {
  const orgId = profile.organizationId;
  const ref = await firebase.database().ref(`newDatabase/organizations/${orgId}/files/assessmentSets`);
  const dbCallback = (snapshot) => {
    const assessmentSets = snapshot.val();
    const assessmentList = [];
    if (assessmentSets) {
      Object.entries(assessmentSets).forEach(([aId, aSet]) => {
        if (canAccess(aSet.owner, profile)) {
          assessmentList.push({
            id: aId,
            ...aSet,
          });
        }
      });
    }
    dispatchCallback(assessmentList);
  };
  await ref.on('value', dbCallback);
  return dbCallback;
};

export const removeAssessmentListListener = async (profile, dbCallback) => {
  const orgId = profile.organizationId;
  const ref = await firebase.database().ref(`newDatabase/organizations/${orgId}/files/assessmentSets`);
  if (dbCallback) {
    await ref.off(dbCallback);
  } else {
    await ref.off();
  }
};

export const saveAssessmentSet = async (assessmentSetObj, user) => {
  const orgId = user.organizationId;
  const [[assessmentSetKey, assessmentSet]] = Object.entries(assessmentSetObj);
  return firebase
    .database()
    .ref(`newDatabase/organizations/${orgId}/files/assessmentSets/${assessmentSetKey}`)
    .update(assessmentSet);
};

export const createAssessmentSet = async (profile, file, assessmentType, fields, customType = '') => {
  const orgId = profile.organizationId;
  let assessmentSet = null;
  let assessmentSetKey = '';
  let storageKey = '';
  const now = new Date();
  const nowString = now.toISOString().replace('.', 'd');
  // - The goal here is for the user to never overwrite files
  // If the assessment type is not unique we create a brand
  // new assessment set for every upload (interims)
  // - If the assessment type is unique then we change the file pointer
  // to reference the newest uploaded file, but keep a reference to the older
  // file(s) in `otherFiles`
  if (getDynamicAssessmentModel(assessmentType).unique) {
    const assessmentSetObj = await firebase
      .database()
      .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
      .orderByChild('type')
      .equalTo(assessmentType)
      .once('value')
      .then((snapshot) => snapshot.val());
    if (assessmentSetObj) {
      [[assessmentSetKey, assessmentSet]] = Object.entries(assessmentSetObj);
      if (!assessmentSet.otherFiles) assessmentSet.otherFiles = {};
      const dbKey = assessmentSet.file.uploadedAt || 'previous';
      assessmentSet.otherFiles[dbKey] = assessmentSet.file;
      const fileKey = await firebase
        .database()
        .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
        .push().key;
      storageKey = `organizations/${orgId}/assessmentSets/${assessmentSetKey}/${fileKey}`;
      assessmentSet.file = {
        origName: file.name,
        storageKey,
        uploadedAt: nowString,
      };
    }
  }
  if (!assessmentSet) {
    assessmentSetKey = firebase
      .database()
      .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
      .push().key;
    const fileKey = await firebase
      .database()
      .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
      .push().key;
    storageKey = `organizations/${orgId}/assessmentSets/${assessmentSetKey}/${fileKey}`;
    assessmentSet = {
      type: assessmentType,
      ...fields,
      owner: profile.role === 'districtAdmin' ? 'district' : profile.campusNumber,
      createdBy: profile.uid,
      parsed: false,
      file: {
        origName: file.name,
        storageKey,
        uploadedAt: nowString,
      },
      otherFiles: {},
    };
    if (customType) {
      assessmentSet.customType = customType;
    }
  }
  return { [assessmentSetKey]: assessmentSet };
};

export const deleteAssessmentSet = async (profile, assessment) => {
  const orgId = profile.organizationId;
  const assessmentId = assessment.id;
  const { shortName, unique } = getDynamicAssessmentModel(assessment.type);
  try {
    await firebase
      .database()
      .ref(`newDatabase/organizations/${orgId}/files/assessmentSets`)
      .update({
        [assessmentId]: null,
      });
  } catch {}
  if (unique && shortName && shortName.length > 0) {
    await firebase
      .database()
      .ref(`newDatabase/organizations/${orgId}/assessmentData`)
      .update({
        [shortName]: null,
      });
    await deleteFromAssessmentChecklist(shortName, profile);
  } else {
    await firebase
      .database()
      .ref(`newDatabase/organizations/${orgId}/assessmentData`)
      .update({
        [assessmentId]: null,
      });
  }
};

export const getAssessmentGroupList = async (profile) => {
  const organizationId = profile.organizationId;
  const assessmentGroupList = await firebase
    .database()
    .ref(`newDatabase/organizations/${organizationId}/assessmentGroups`)
    .once('value')
    .then((snapshot) => snapshot.val());
  return Object.entries(assessmentGroupList)
    .filter(([groupId, group]) => canAccess(group.owner, profile) && isNotVisibleBySuperAdminOnly(profile, group))
    .reduce((obj, [groupId, group]) => {
      obj[groupId] = group;
      return obj;
    }, {});
};

export const deleteAssessmentGroup = async (organizationId, assessmentGroupId) =>
  firebase
    .database()
    .ref(`newDatabase/organizations/${organizationId}/assessmentGroups`)
    .update({
      [assessmentGroupId]: null,
    });

export const addAssessmentGroup = async (profile, assessmentGroup) => {
  assessmentGroup.owner = profile.role === 'districtAdmin' ? 'district' : profile.campusNumber;
  assessmentGroup.createdBy = profile.uid;
  assessmentGroup.updatedBy = profile.uid;
  assessmentGroup.name = assessmentGroup.name.trim();
  return firebase
    .database()
    .ref(`newDatabase/organizations/${profile.organizationId}/assessmentGroups`)
    .push(assessmentGroup);
};

export const updateAssessmentGroup = async (profile, assessmentGroupId, assessmentGroup) => {
  assessmentGroup.updatedBy = profile.uid;
  assessmentGroup.name = assessmentGroup.name.trim();
  return firebase
    .database()
    .ref(`newDatabase/organizations/${profile.organizationId}/assessmentGroups`)
    .update({ [assessmentGroupId]: assessmentGroup });
};

export const deleteCascadeAssessmentFromGroups = async (profile, assessmentGroups) =>
  firebase
    .database()
    .ref(`newDatabase/organizations/${profile.organizationId}/assessmentGroups`)
    .update(assessmentGroups);

export const updateLastInterimTimestamp = async (profile, timestamp) =>
  firebase
    .database()
    .ref(`newDatabase/organizations/${profile.organizationId}/metadata`)
    .update({ timestamp });

export const changeAssessmentSetVisibility = async (profile, assessment) => {
  if (profile && (profile.type == 'superAdmin' || profile.isSuperAdmin == true)) {
    const orgId = profile.organizationId;
    const assessmentId = assessment.id;
    if (assessmentId && assessmentId.length > 0) {
      await firebase
        .database()
        .ref(`newDatabase/organizations/${orgId}/files/assessmentSets/${assessmentId}`)
        .update({
          isVisibleBySuperAdminOnly: assessment.isVisibleBySuperAdminOnly,
        });
    }
  }
};

export const changeAssessmentGroupVisibility = async (profile, assessmentGroup) => {
  if (profile && (profile.type == 'superAdmin' || profile.isSuperAdmin == true)) {
    const orgId = profile.organizationId;
    const assessmentGroupId = assessmentGroup.id;
    if (assessmentGroupId && assessmentGroupId.length > 0) {
      await firebase
        .database()
        .ref(`newDatabase/organizations/${orgId}/assessmentGroups/${assessmentGroupId}`)
        .update({
          isVisibleBySuperAdminOnly: assessmentGroup.isVisibleBySuperAdminOnly,
        });
    }
  }
};

export default 'aToF.db.js';
