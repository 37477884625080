import { combineReducers } from 'redux';
import {
  INIT_ROSTER,
  DELETE_STUDENT_REQ,
  DELETE_STUDENT_REQ_CANCEL,
  DELETE_STUDENT_CONFIRM,
  EDIT_STUDENT_REQ,
  EDIT_STUDENT_REQ_CANCEL,
  EDIT_STUDENT_CONFIRM,
  ADD_STUDENT_REQ,
  ADD_STUDENT_REQ_CANCEL,
  ADD_STUDENT_CONFIRM,
  CLOSE_SNACKBAR,
  UPLOAD_ROSTER,
  CHANGE_PROFILE_PICTURE,
  CLOSE_PROFILE_PICTURE,
  UPLOAD_PROFILE_PICTURE,
  GET_AVATARS,
  SELECT_SNAPSHOT_YEAR,
  EXPORT_ROSTER,
  SET_STUDENT_PRIOR_STAAR_DATA,
  GET_STUDENT_PRIOR_STAAR_DATA,
} from './studentRoster.actions';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_PROGRESS, ASYNC_START } from '../../../common';

const initialStatus = {
  uploaded: false,
  message: '',
  error: '',
};

const avatars = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_AVATARS) {
        return action.data.avatarsUrl;
      }
      return state;
    default:
      return state;
  }
};

const roster = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === INIT_ROSTER) {
        return Object.assign({}, state, {
          [action.data.year]: action.data.roster,
        });
      }
      if (action.model === DELETE_STUDENT_CONFIRM) {
        delete state[action.data.year][action.data.studentId];
        return Object.assign({}, state, {
          [action.data.year]: Object.assign({}, state[action.data.year]),
        });
      }
      if (
        action.model === EDIT_STUDENT_CONFIRM ||
        action.model === ADD_STUDENT_CONFIRM ||
        action.model === SET_STUDENT_PRIOR_STAAR_DATA
      ) {
        return Object.assign({}, state, {
          [action.data.year]: Object.assign({}, state[action.data.year], {
            [action.data.studentId]: Object.assign({}, state[action.data.studentId], action.data.student),
          }),
        });
      }
      if (action.model === UPLOAD_PROFILE_PICTURE) {
        const updatedStudent = Object.assign({}, state[action.data.studentId]);
        updatedStudent.photoUrl = Object.assign({}, action.data.photoUrl);
        state[action.data.studentId] = updatedStudent;
        return Object.assign({}, state, {
          [action.data.year]: Object.assign({}, state[action.data.year]),
        });
      }
      return state;
    default:
      return state;
  }
};

const status = (state = initialStatus, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ROSTER) {
        return Object.assign({}, state, {
          uploaded: action.data.uploaded,
          message: action.data.message,
          error: '',
        });
      }
      if (action.model === INIT_ROSTER) {
        return Object.assign({}, state, {
          uploaded: !!action.data.uploaded,
          message: '',
          error: '',
        });
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === UPLOAD_ROSTER) {
        return Object.assign({}, state, {
          uploaded: !!action.data.uploaded,
          message: '',
          error: action.error.message,
        });
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === INIT_ROSTER) {
        return 'Initializing ...';
      }
      if (action.model === UPLOAD_ROSTER) {
        return 'Saving ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === INIT_ROSTER || action.model === UPLOAD_ROSTER) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const uploadProfilePictureMessage = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === UPLOAD_PROFILE_PICTURE) {
        return 'Profile Picture Uploading ...';
      }
      return state;
    case ASYNC_PROGRESS:
      if (action.model === UPLOAD_PROFILE_PICTURE) {
        return action.data.message;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === UPLOAD_PROFILE_PICTURE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const uploadedRoster = (state = false, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ROSTER) {
        return true;
      }
      return state;
    default:
      return state;
  }
};

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === INIT_ROSTER || action.model === UPLOAD_ROSTER) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const uploadProgressMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_PROGRESS:
      if (action.model === UPLOAD_ROSTER) {
        return action.data.message;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === UPLOAD_ROSTER) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const selectedStudentToDelete = (state = null, action) => {
  if (action.type === DELETE_STUDENT_REQ) {
    return action.studentId;
  }
  if (action.type === DELETE_STUDENT_REQ_CANCEL) {
    return null;
  }
  if (action.type === ASYNC_FINISH) {
    if (action.model === DELETE_STUDENT_CONFIRM) {
      return null;
    }
  }
  if (action.type === ASYNC_ERROR) {
    if (action.model === DELETE_STUDENT_CONFIRM) {
      return null;
    }
  }
  return state;
};

const selectedStudentToEdit = (state = null, action) => {
  if (action.type === EDIT_STUDENT_REQ) {
    return action.studentId;
  }
  if (action.type === EDIT_STUDENT_REQ_CANCEL) {
    return null;
  }
  if (action.type === ASYNC_FINISH) {
    if (action.model === EDIT_STUDENT_CONFIRM || action.model === SET_STUDENT_PRIOR_STAAR_DATA) {
      return null;
    }
  }
  if (action.type === ASYNC_ERROR) {
    if (action.model === EDIT_STUDENT_CONFIRM || action.model === SET_STUDENT_PRIOR_STAAR_DATA) {
      return null;
    }
  }
  return state;
};

const isProfilePictureModalOpen = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === CHANGE_PROFILE_PICTURE) {
        return true;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === UPLOAD_PROFILE_PICTURE) {
        return false;
      }
      return state;
    case CLOSE_PROFILE_PICTURE:
    case EDIT_STUDENT_REQ:
      return false;
    default:
      return state;
  }
};

const isAddingNewStudent = (state = false, action) => {
  if (action.type === ADD_STUDENT_REQ) {
    return true;
  }
  if (action.type === ADD_STUDENT_REQ_CANCEL) {
    return false;
  }
  if (action.type === ASYNC_FINISH) {
    if (action.model === ADD_STUDENT_CONFIRM) {
      return false;
    }
  }
  if (action.type === ASYNC_ERROR) {
    if (action.model === ADD_STUDENT_CONFIRM) {
      return false;
    }
  }
  return state;
};

const selectedSnapshotYear = (state = null, action) => {
  if (action.type === SELECT_SNAPSHOT_YEAR) {
    return action.snapshotYear;
  }
  return state;
};

const msg = (state = null, action) => {
  if (action.type === ASYNC_FINISH) {
    if (action.model === DELETE_STUDENT_CONFIRM) {
      return {
        text: 'Student was successfully deleted!',
        isError: false,
      };
    }
    if (action.model === ADD_STUDENT_CONFIRM) {
      return {
        text: 'Student was successfully added!',
        isError: false,
      };
    }
    if (action.model === EDIT_STUDENT_CONFIRM) {
      return {
        text: 'Student was successfully edited!',
        isError: false,
      };
    }

    if (action.model === SET_STUDENT_PRIOR_STAAR_DATA) {
      return {
        text: 'Student prior staar was successfully saved!',
        isError: false,
      };
    }
  }
  if (action.type === ASYNC_ERROR) {
    if (action.model === DELETE_STUDENT_CONFIRM) {
      return {
        text: `Failed to delete student. ${action.error}`,
        isError: true,
      };
    }
    if (action.model === ADD_STUDENT_CONFIRM) {
      return {
        text: `Failed to add student. ${action.error}`,
        isError: true,
      };
    }
    if (action.model === EDIT_STUDENT_CONFIRM) {
      return {
        text: `Failed to edit student. ${action.error}`,
        isError: true,
      };
    }
    if (action.model === SET_STUDENT_PRIOR_STAAR_DATA) {
      return {
        text: `Failed to save prior staar. ${action.error}`,
        isError: true,
      };
    }
  }
  if (action.type === CLOSE_SNACKBAR) {
    return null;
  }
  return state;
};

const rosterCSV = (state = null, action) => {
  if (action.type === ASYNC_FINISH && action.model === EXPORT_ROSTER) {
    return action.data.data;
  }
  if (action.type === ASYNC_START) {
    return null;
  }
  return state;
};

const loadingCSV = (state = false, action) => {
  if (action.model === EXPORT_ROSTER) {
    return action.type === ASYNC_START;
  }
  return state;
};

const studentPriorStaarModel = (state = null, action) => {
  if (action.type === EDIT_STUDENT_REQ_CANCEL) {
    return null;
  }
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_STUDENT_PRIOR_STAAR_DATA) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const loadedStudentPriorStaarModel = (state = false, action) => {
  if (action.model === GET_STUDENT_PRIOR_STAAR_DATA) {
    if (action.type === ASYNC_FINISH) {
      return true;
    } else if (action.type === ASYNC_START) {
      return false;
    }
  }
  return state;
};

export const _reducer = combineReducers({
  status,
  loadingMsg,
  needsInit,
  uploadProgressMsg,
  roster,
  selectedStudentToEdit,
  selectedStudentToDelete,
  isAddingNewStudent,
  msg,
  uploadedRoster,
  isProfilePictureModalOpen,
  uploadProfilePictureMessage,
  avatars,
  selectedSnapshotYear,
  rosterCSV,
  loadingCSV,
  studentPriorStaarModel,
  loadedStudentPriorStaarModel,
});

export default 'studentsPage.reducers.js';
