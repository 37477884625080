import { connect } from 'react-redux';

import Component from './SubjectSubTable.component';
import { getStudentsList } from '../../+store/aToF';

const mapStateToProps = (state) => ({
  profile: state.modules.Auth.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  handleGetStudentsList: (orgId, path) => {
    dispatch(getStudentsList(orgId, path));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
