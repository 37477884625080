import { asyncStart } from '../../../common';

export const GET_ACCOUNTABILITY_WEIGHTS = 'accountabilityWeight.getAccountabilityWeights';
export const getAccountabilityWeights = (campusDistrictNumber) => (dispatch) => {
  dispatch(asyncStart(GET_ACCOUNTABILITY_WEIGHTS));
  dispatch({
    type: GET_ACCOUNTABILITY_WEIGHTS,
    campusDistrictNumber,
  });
};
