import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { asyncError, asyncFinish, createUser } from '../../../common/+store/index';
import {
  firebaseDbOnce,
  signInWithCustomToken,
  resetPasswordWithEmail,
} from '../../../App/+store/firebase/firebase.api';
import {
  AUTH_GET_PROFILE,
  SIGN_IN,
  AUTH_SIGN_UP,
  GET_CAMPUS_DISTRICT_LIST,
  AUTH_FORGET_PASSWORD,
  getProfile,
  authInit,
  GET_SIGN_UP_CODE_INFO,
} from './auth.actions';
import { commonApiCallFragment } from '../../../common/+store/utils/utils.api';
import { signUp, getSignUpCodeInfo } from './auth.api';
import { bugsnagger } from '../../../App/+store/errors/errors.epics';

const getSignUpCodeInfoEpic = (action$) =>
  action$.pipe(
    ofType(GET_SIGN_UP_CODE_INFO),
    switchMap((action) =>
      from(getSignUpCodeInfo(action.signUpCode)).pipe(commonApiCallFragment(action$, action, 'Getting Sign Up Info'))
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(GET_SIGN_UP_CODE_INFO, results.response.data.signUpInfo));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const forgetPasswordEpic = (action$) =>
  action$.pipe(
    ofType(AUTH_FORGET_PASSWORD),
    switchMap((action) =>
      from(resetPasswordWithEmail(action.data.email)).pipe(
        commonApiCallFragment(action$, action, 'Auth Forget Password')
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(results.action.type));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const signUpEpic = (action$) =>
  action$.pipe(
    ofType(AUTH_SIGN_UP),
    switchMap((action) =>
      from(signUp(action.data)).pipe(
        commonApiCallFragment(action$, action, 'Auth Sign Up', {
          action,
        }),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'signupDB', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return from(signInWithCustomToken(results.response.data, results)).pipe(
          commonApiCallFragment(action$, results.action, 'Auth Sign Up', {
            profile: results.response.data.profile,
            action: results.action,
          })
        );
      }
      return of({
        action: results.action,
        success: false,
      });
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            uid: results.profile.uid,
            profile: results.profile,
          }),
          getProfile(results.response.user)
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const getProfileEpic = (action$) =>
  action$.pipe(
    ofType(AUTH_GET_PROFILE),
    switchMap((action) =>
      from(firebaseDbOnce(`newDatabase/users/${action.user.uid}`)).pipe(
        map((data) => ({ action, success: true, data })),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'Read profile from db', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        if (!results.data) return of({ success: false, action: { type: 'noop', results } });
        return from(firebaseDbOnce(`newDatabase/organizations/${results.data.organizationId}/metadata`)).pipe(
          map((data) => ({
            action: results.action,
            success: true,
            profile: results.data,
            data,
          })),
          catchError((error) =>
            of({
              success: false,
              action: asyncError(results.action.type, 'Read profile from db', error),
            })
          )
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        if (!results.data) {
          /*if (process.env.NODE_ENV === 'production') {
            bugsnagger.bugsnagClient.notify(new Error('Get Profile Epic Edge Case'), {
              metaData: {
                results: results,
                action: results.action,
                profile: results.profile,
              },
            });
          }*/
          return of({ success: false, action: { type: 'noop', results } });
        }
        const profile = {
          ...results.profile,
          userType: results.data.userType,
          lastInterimTimestamp: results.data.lastInterimTimestamp,
        };
        return of(
          asyncFinish(results.action.type, {
            profile,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const signInEpic = (action$) =>
  action$.pipe(
    ofType(SIGN_IN),
    switchMap((action) => of('test').pipe(commonApiCallFragment(action$, action, 'Get Assessment List'))),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinish(results.action.type, {
            assessments: results.response,
          })
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

const initUniqueCampusAndDistricts = (action$) =>
  action$.pipe(
    ofType(GET_CAMPUS_DISTRICT_LIST),
    switchMap((action) =>
      from(firebaseDbOnce('newDatabase/dataStructure/constants/public')).pipe(
        map((data) => ({ action, success: true, data })),
        catchError((error) =>
          of({
            success: false,
            action: asyncError(action.type, 'Read schools from db', error),
          })
        )
      )
    ),
    switchMap((results) => {
      if (results.success) {
        return of(asyncFinish(results.action.type, results.data));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
  );

export default combineEpics(
  getProfileEpic,
  signInEpic,
  initUniqueCampusAndDistricts,
  signUpEpic,
  forgetPasswordEpic,
  getSignUpCodeInfoEpic
);
