const moment = require('moment');

const validateFile = (text, type) =>
  new Promise((resolve) => {
    const lines = text.split(/\r\n?|\n/);
    if (type === 'application/vnd.ms-excel' || type === 'text/csv') {
      const lookingFor = /,LATEST_SAT_TOTAL,/;
      lines.forEach((line) => {
        if (lookingFor.exec(line)) {
          resolve(true);
        }
      });
      resolve(false);
      return;
    }
    if (type === 'text/plain') {
      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        if (line) {
          if (line.length !== 2661) {
            resolve(false);
            return;
          }
          const birthDate = line.slice(202, 212);
          // const readingScore = Number(line.slice(676, 678));
          // const mathScore = Number(line.slice(679, 682));
          if (!(birthDate && moment(birthDate, 'YYYY-MM-DD', true).isValid())) {
            resolve(false);
            return;
          }
        }
      }
      resolve(true);
    }
  });

const isSAT2019 = (file) =>
  new Promise((resolve) => {
    if (file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv' && file.type != 'text/plain') {
      resolve(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      const isValidated = await validateFile(reader.result, file.type);
      resolve(isValidated);
    };
    reader.readAsText(file);
  });

export default isSAT2019;
