import firebase from 'firebase';

export const firebaseDbOnce = (dbPath) =>
  new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(dbPath)
      .once('value')
      .then((snapshot) => {
        resolve(snapshot.val());
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createUserWithEmailAndPassword = async (email, password) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);
export const signInWithCustomToken = async (data) => firebase.auth().signInWithCustomToken(data.token);

export const resetPasswordWithEmail = async (email) => firebase.auth().sendPasswordResetEmail(email);

export default 'firebase.api.js';
