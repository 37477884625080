import { isRealNumber } from '../../utils';

const ElemSubjects = {
  reading: 'reading',
  math: 'math',
  writing: 'writing',
  socialStudies: 'socialStudies',
  science: 'science',
};

const HighSchoolSubjects = {
  algebraOne: 'algebraOne',
  biology: 'biology',
  englishOne: 'englishOne',
  englishTwo: 'englishTwo',
  usHistory: 'usHistory',
  englishOneReading: 'englishOneReading', // Only has general vars
  englishOneWriting: 'englishOneWriting', // Only has general vars
};

export const ethnicity = [
  { value: 'asian', label: 'Asian' },
  { value: 'black', label: 'African American' },
  { value: 'hispanic', label: 'Hispanic' },
  { value: 'nativeAmerican', label: 'Native American' },
  { value: 'pacificIslander', label: 'Pacific Islander' },
  { value: 'twoOrMoreRaces', label: 'Two or More Races' },
  { value: 'white', label: 'White' },
];

export const gradeLevels = [
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
];

export const testSubjects = [
  { value: 'reading', label: 'Reading' },
  { value: 'math', label: 'Mathematics' },
  { value: 'science', label: 'Science' },
  { value: 'writing', label: 'Writing' },
  { value: 'socialStudies', label: 'Social Studies' },
  { value: 'englishOne', label: 'English I' },
  { value: 'englishTwo', label: 'English II' },
  { value: 'algebraOne', label: 'Algebra' },
  { value: 'biology', label: 'Biology' },
  { value: 'usHistory', label: 'US History' },
];

export const indicators = [
  { value: 'ecoDis', label: 'Economically Disadvantage' },
  { value: 'specialEdCurrent', label: 'Special Ed (Current)' },
  { value: 'specialEdFormer', label: 'Special Ed (Former)' },
  { value: 'lepCurrent', label: 'EL (Current)' },
  { value: 'lepMonitored', label: 'EL (Monitored)' },
  { value: 'continuouslyEnrolled', label: 'Continuously Enrolled' },
  // { value: 'nonContinuouslyEnrolled', label: 'Non-continuously Enrolled' },
  { value: 'highlyMobile', label: 'Highly Mobile' },
  { value: 'acceleratedTesterMath', label: 'Accelerated Tester Math' },
  { value: 'acceleratedTesterReading', label: 'Accelerated Tester Reading' },
  { value: 'acceleratedTesterScience', label: 'Accelerated Tester Science' },
];

const getSubject = (test) => {
  if (test === HighSchoolSubjects.biology) {
    return ElemSubjects.science;
  }
  if (test === HighSchoolSubjects.algebraOne) {
    return ElemSubjects.math;
  }
  if (test === HighSchoolSubjects.usHistory) {
    return ElemSubjects.socialStudies;
  }
  if (test === HighSchoolSubjects.englishOne || test === HighSchoolSubjects.englishTwo) {
    return ElemSubjects.reading;
  }
  return test;
};

export const getTeachersForStudent = (student, allowedSubjects = [], campusNumber = null) => {
  const teachersOptions = [];
  // Object.values(students).forEach((student) => {
  // if (campusNumber && !student.campusNumber.includes(campusNumber)) {
  //  return;
  // }
  if (student.currentYearTests && student.currentYearTests[0] && student.currentYearTests[0].tests) {
    student.currentYearTests[0].tests.forEach((test) => {
      if (test.teacher && test.teacher.length && !teachersOptions.includes(test.teacher)) {
        if (allowedSubjects.length === 0 || allowedSubjects.includes(getSubject(test.subject))) {
          teachersOptions.push(test.teacher);
        }
      }
    });
  }
  // });
  return teachersOptions;
};

export const getTeachers = (students, allowedSubjects = [], campusNumber = null) => {
  const teachersOptions = [];
  Object.values(students).forEach((student) => {
    if (campusNumber && !student.campusNumber.includes(campusNumber)) {
      return;
    }
    if (student.currentYearTests && student.currentYearTests[0] && student.currentYearTests[0].tests) {
      student.currentYearTests[0].tests.forEach((test) => {
        if (test.teacher && test.teacher.length && !teachersOptions.includes(test.teacher)) {
          if (allowedSubjects.length === 0 || allowedSubjects.includes(getSubject(test.subject))) {
            teachersOptions.push(test.teacher);
          }
        }
      });
    }
  });
  return teachersOptions
    .sort((a, b) => {
      const aSplit = a.split(/\s/);
      const aLast = aSplit[aSplit.length - 1];
      const bSplit = b.split(/\s/);
      const bLast = bSplit[bSplit.length - 1];
      const sorted = [aLast, bLast].sort();
      if (aLast === bLast) {
        return 0;
      }
      if (sorted[0] === aLast) {
        return -1;
      }
      return 1;
    })
    .map((teacher) => ({ value: teacher, label: teacher }));
};

export const getMentors = (students, allowedSubjects = [], campusNumber = null) => {
  const mentorsOptions = [];
  Object.values(students).forEach((student) => {
    if (campusNumber && !student.campusNumber.includes(campusNumber)) {
      return;
    }
    if (student.mentor && student.mentor.length > 0 && !mentorsOptions.includes(student.mentor)) {
      mentorsOptions.push(student.mentor);
    }
  });
  return mentorsOptions
    .sort((a, b) => {
      const aSplit = a.split(/\s/);
      const aLast = aSplit[aSplit.length - 1];
      const bSplit = b.split(/\s/);
      const bLast = bSplit[bSplit.length - 1];
      const sorted = [aLast, bLast].sort();
      if (aLast === bLast) {
        return 0;
      }
      if (sorted[0] === aLast) {
        return -1;
      }
      return 1;
    })
    .map((mentor) => ({ value: mentor, label: mentor }));
};

export const filterBySubject = (student, subjects) => {
  if (student.currentYearTests && student.currentYearTests[0] && student.currentYearTests[0].tests) {
    return student.currentYearTests[0].tests
      .map((test) => {
        if (test.subject && test.subject.length) {
          return subjects.includes(test.subject);
        }
        return false;
      })
      .reduce((result, included) => result || included);
  }
  return false;
};

export const filterByTeacher = (student, teachers) => {
  if (student.currentYearTests && student.currentYearTests[0] && student.currentYearTests[0].tests) {
    return student.currentYearTests[0].tests
      .map((test) => {
        if (test.teacher && test.teacher.length) {
          return teachers.includes(test.teacher);
        }
        return false;
      })
      .reduce((result, included) => result || included);
  }
  return false;
};

export const filterByTag = (student, tags) => {
  if (student.tags && tags) {
    return student.tags
      .map((tagId) => {
        return tags.includes(tagId);
      })
      .reduce((result, included) => result || included);
  }
  return false;
};

const objectify = (arr) => Object.assign(...arr.map(([k, v]) => ({ [k]: v })));

// filterBy should look like this:
// filterBy = {id: {key: key, values: [value1, value2, ...], type: 'boolean'},
// id: {key: key, values: [value1, value2, ...], type: 'list'}}
export const filterObjByProperty = (data, filterBy = {}, customFunctions = {}) => {
  const res = Object.entries(data).filter(([id, item]) =>
    Object.values(filterBy)
      .map((filters) => {
        const { values, type, key } = filters;
        if (values.length === 0) return true;
        if (type === 'custom') {
          const filterFunc = customFunctions[key];
          return filterFunc ? filterFunc(item, values) : true;
        }
        if (type === 'boolean') {
          return !!values.filter((value) => key in item && item[key][value]).length;
        }
        return (
          key in item &&
          values.some((value) => {
            if (isRealNumber(value) && isRealNumber(item[key])) {
              return Number(value) === Number(item[key]);
            }
            return value === item[key];
          })
        );
      })
      .reduce((result, included) => result && included, true)
  );
  return res.length ? objectify(res) : {};
};

export const sortObjByProperty = (data, keys = [], reverse = false) => {
  function compare(a, b) {
    const order = reverse ? -1 : 1;
    let ele1 = a[1];
    let ele2 = b[1];

    for (const key of keys) {
      if (typeof ele1 === 'object' && typeof ele2 === 'object' && key in ele1 && key in ele2) {
        ele1 = ele1[key];
        ele2 = ele2[key];
      } else {
        if (typeof ele1 === 'object' && key in ele1) return order;
        if (typeof ele2 === 'object' && key in ele2) return -1 * order;
        return 0;
      }
    }

    if (typeof ele1 === 'string' || typeof ele2 === 'string') {
      return ele1.localeCompare(ele2) * order;
    }
    return (ele1 - ele2) * order;
  }
  const dataArr = Object.entries(data).sort(compare);
  if (dataArr.length) {
    return objectify(dataArr);
  }
  return {};
};
