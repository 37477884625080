import { connect } from 'react-redux';

import Component from './Settings.component';
import { getSchools, getGradeSpanTable } from './+store/SchoolSettings/SchoolSettings.actions';
import { getAssessmentList } from '../Assessments';

const mapStateToProps = (state) => {
  const loading = !!state.modules.Settings.settings.loadingMsg.length;
  return {
    loading,
    profile: state.modules.Auth.auth.profile,
    needsInit: state.modules.Settings.schoolSettings.needsInit,
    needsUpdate: state.modules.Settings.schoolSettings.needsUpdate,
    loadingMsg: loading ? state.modules.Settings.settings.loadingMsg[0] : '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: (id) => {
    dispatch(getSchools(id));
    dispatch(getAssessmentList(id));
    dispatch(getGradeSpanTable());
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
