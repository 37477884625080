import { createSelector } from 'reselect';
import moment from 'moment';

const lastFetched = (state) => state.modules.Assessments.assessments.lastFetched;
const groupLastFetched = (state) => state.modules.Assessments.groupAssessments.lastFetched;

export const needsToBeFetched = createSelector(
  lastFetched,
  (momentlastFetched) => momentlastFetched.unix() === moment(0).unix()
);

export const groupNeedsToBeFetched = createSelector(
  groupLastFetched,
  (momentlastFetched) => momentlastFetched.unix() === moment(0).unix()
);

export default 'assessment.selectors.js';
