import { AssessmentTypes } from './assessments.types';
import isFYLI2017 from './validation/isFYLI2017';
import isFYLI2016 from './validation/isFLYI2016';
import isSYLI2015 from './validation/isSYLI2015';
import isCCMR2018 from './validation/isCCMR2018';
import isCCMR2019 from './validation/isCCMR2019';
import isFYLI2018 from './validation/isFYLI2018';
import isF5YLI2017 from './validation/isF5YLI2017';
import isSYLI2016 from './validation/isSYLI2016';
import isCsvGradData from './validation/isCsvGradData';
import isSTAAR2021Custom from './validation/isSTAAR2021Custom';
import isEOC2021Custom from './validation/isEOC2021Custom';
import isSAT2019 from './validation/isSAT2019';

import isSTAAR from './validation/isSTAAR';
import isTELPAS from './validation/isTELPAS';
import isEOC from './validation/isEOC';
import isCCMR from './validation/isCCMR';
import isCCMRCSV from './validation/isCCMRCSV';
import isFYLI from './validation/isFYLI';
import isF5YLI from './validation/isF5YLI';
import isSYLI from './validation/isSYLI';
import isSTAARCustom from './validation/isSTAARCustom';

import isComboAssessmentFile from './validation/isComboAssessmentFile';
import isInterim from './validation/isInterim';
import isDMAC from './validation/isDMAC';

export const determineFileType = async (file) => {
  // Combo
  if (await isComboAssessmentFile(file)) {
    return AssessmentTypes.ComboAssessment;
  }

  // Interim
  if (await isInterim(file)) {
    return AssessmentTypes.Interim;
  }
  if (await isDMAC(file)) {
    return AssessmentTypes.DMACInterim;
  }

  var currentYear = new Date().getFullYear();
  var firstPartOfYear = currentYear.toString().substring(0, 2);

  //Dynamic SAT (STAAR - STAARALT-EOC-EOCALT-TELPAS-TELPASALT)
  var validationResult = await isSTAAR(file);
  if (validationResult.isValid == true) {
    var assesmentType = AssessmentTypes.STAAR + firstPartOfYear + validationResult.assessmentYear;
    return assesmentType;
  }
  validationResult = await isSTAAR(file, true);
  if (validationResult.isValid == true) {
    return AssessmentTypes.STAARALT + firstPartOfYear + validationResult.assessmentYear;
  }
  validationResult = await isTELPAS(file);
  if (validationResult.isValid == true) {
    return AssessmentTypes.TELPAS + firstPartOfYear + validationResult.assessmentYear;
  }

  validationResult = await isTELPAS(file, true);
  if (validationResult.isValid == true) {
    var assesmentType = AssessmentTypes.TELPASALT + firstPartOfYear + validationResult.assessmentYear;
    return assesmentType;
  }
  validationResult = await isEOC(file);
  if (validationResult.isValid == true) {
    return AssessmentTypes.EOC + firstPartOfYear + validationResult.assessmentYear;
  }
  validationResult = await isEOC(file, true);
  if (validationResult.isValid == true) {
    return AssessmentTypes.EOCALT + firstPartOfYear + validationResult.assessmentYear;
  }

  validationResult = await isSTAARCustom(file);

  if (validationResult.isValid == true) {
    let staarCustomYear = currentYear;
    const yearRegex = /\d{4}/; // Matches a sequence of 4 digits
    const match = file.name.match(yearRegex);
    if (match) {
      staarCustomYear = match[0];
    }
    return AssessmentTypes.STAARCustom + staarCustomYear;
  }

  //Dynamic CCMR
  validationResult = await isCCMR(file);
  if (validationResult.isValid == true) {
    return AssessmentTypes.CCMR + validationResult.assessmentYear;
  }

  validationResult = await isCCMRCSV(file);
  if (validationResult.isValid == true) {
    return AssessmentTypes.CCMRCSV + currentYear;
  }

  //Dynamic Grade Data (FYLI-F5YLI-SYLI)
  validationResult = await isFYLI(file); // Previous Grade Year 4
  if (validationResult.isValid == true) {
    return AssessmentTypes.FYLI + validationResult.assessmentYear;
  }

  validationResult = await isF5YLI(file); // Previous Grade Year 5
  if (validationResult.isValid == true) {
    return AssessmentTypes.F5YLI + validationResult.assessmentYear;
  }

  validationResult = await isSYLI(file); // Previous Grade Year 6
  if (validationResult.isValid == true) {
    return AssessmentTypes.SYLI + validationResult.assessmentYear;
  }
  /////////////////////////////2021 Custom///////////////////////////////////////////////
  if (await isSTAAR2021Custom(file)) {
    return AssessmentTypes.STAAR2021Custom;
  }
  if (await isEOC2021Custom(file)) {
    return AssessmentTypes.EOC2021Custom;
  }
  ////////////////////////////////////////2019//////////////////////////////////

  if (await isFYLI2017(file)) {
    return AssessmentTypes.FYLI2017;
  }
  if (await isFYLI2016(file)) {
    return AssessmentTypes.FYLI2016;
  }
  if (await isSYLI2015(file)) {
    return AssessmentTypes.SYLI2015;
  }
  if (await isFYLI2018(file)) {
    return AssessmentTypes.FYLI2018;
  }
  if (await isF5YLI2017(file)) {
    return AssessmentTypes.F5YLI2017;
  }
  if (await isSYLI2016(file)) {
    return AssessmentTypes.SYLI2016;
  }
  if (await isCCMR2019(file)) {
    return AssessmentTypes.CCMR2019;
  }
  if (await isCCMR2018(file)) {
    return AssessmentTypes.CCMR2018;
  }
  if (await isSAT2019(file)) {
    return AssessmentTypes.SAT2019;
  }

  const results = await isCsvGradData(file);
  if (results) {
    switch (`${results.year}:${results.type}`) {
      case '2016:SIX-YEAR':
        return AssessmentTypes.SYLI2016;
      case '2017:FIVE-YEAR':
        return AssessmentTypes.F5YLI2017;
      case '2018:FOUR-YEAR':
        return AssessmentTypes.FYLI2018;
      case '2015:SIX-YEAR':
        return AssessmentTypes.SYLI2015;
      case '2016:FIVE-YEAR':
        return AssessmentTypes.FYLI2016;
      case '2017:FOUR-YEAR':
        return AssessmentTypes.FYLI2017;
      default:
        return null;
    }
  }
  return null;
};

export default 'assessments.validation2.js';
