import { asyncStart, asyncFinish, asyncError } from '../../../common';
import { getStudents } from '../../../common/+store/database/database.api';
import { getCurrentRosterYear } from './studentRoster.utils';

export const SELECT_SNAPSHOT_YEAR = 'studentRoster.selectSnapshotYear';
export const selectSnapshotYear = (snapshotYear) => (dispatch) => {
  dispatch({ type: SELECT_SNAPSHOT_YEAR, snapshotYear });
};

export const GET_AVATARS = 'studentRoster.getAvatars';
export const getAvatars = () => (dispatch) => {
  dispatch(asyncStart(GET_AVATARS));
  dispatch({ type: GET_AVATARS });
};

export const INIT_ROSTER = 'studentRoster.init';
export const initStudentsRoster = (user, year = null) => (dispatch) => {
  const snapshotYear = year || getCurrentRosterYear();
  dispatch(asyncStart(INIT_ROSTER));
  getStudents(user, snapshotYear)
    .then((students) => {
      if (!students) {
        dispatch(
          asyncFinish(INIT_ROSTER, {
            uploaded: null,
            roster: null,
            year: snapshotYear,
          })
        );
      } else {
        dispatch(
          asyncFinish(INIT_ROSTER, {
            uploaded: !!students,
            roster: students,
            year: snapshotYear,
          })
        );
      }
    })
    .catch((err) => dispatch(asyncError(INIT_ROSTER, 'Get rosters status from firebase db', err)));
};

export const UPLOAD_ROSTER = 'studentRoster.upload';
export const uploadStudentRoster = (accepted, rejected, user, profile, year, isForDeleteRemaining) => (dispatch) => {
  dispatch(asyncStart(UPLOAD_ROSTER));
  dispatch({
    type: UPLOAD_ROSTER,
    file: accepted[0],
    user,
    profile,
    dispatch,
    year,
    isForDeleteRemaining,
  });
  if (rejected.length) {
    dispatch(asyncError(UPLOAD_ROSTER, 'Files rejected', null, { rejected }));
  }
};

export const UPDATE_STUDENT = 'studentRoster.updateStudent';
export const updateStudent = (user, student, year = null) => (dispatch) => {
  const snapshotYear = year || getCurrentRosterYear();
  dispatch(asyncStart(UPDATE_STUDENT));
  dispatch({
    type: UPDATE_STUDENT,
    userId: user.uid,
    orgId: user.orgId,
    student,
    year: snapshotYear,
  });
};

export const DELETE_STUDENT_REQ = 'studentRoster.deleteStudent.request';
export const deleteStudentRequest = (studentId) => (dispatch) => {
  dispatch({
    type: DELETE_STUDENT_REQ,
    studentId,
  });
};

export const DELETE_STUDENT_REQ_CANCEL = 'studentRoster.deleteStudent.requestCancel';
export const deleteStudentRequestCancel = () => (dispatch) => {
  dispatch({
    type: DELETE_STUDENT_REQ_CANCEL,
  });
};

export const DELETE_STUDENT_CONFIRM = 'studentRoster.deleteStudent.confirm';
export const deleteStudentConfirm = (studentId, orgId, year = null) => (dispatch) => {
  const snapshotYear = year || getCurrentRosterYear();
  dispatch({
    type: DELETE_STUDENT_CONFIRM,
    studentId,
    orgId,
    year: snapshotYear,
  });
};

export const EDIT_STUDENT_REQ = 'studentRoster.editStudent.request';
export const editStudentRequest = (studentId) => (dispatch) => {
  dispatch({
    type: EDIT_STUDENT_REQ,
    studentId,
  });
};

export const EDIT_STUDENT_REQ_CANCEL = 'studentRoster.editStudent.requestCancel';
export const editStudentRequestCancel = () => (dispatch) => {
  dispatch({
    type: EDIT_STUDENT_REQ_CANCEL,
  });
};

export const EDIT_STUDENT_CONFIRM = 'studentRoster.editStudent.confirm';
export const editStudentConfirm = (studentId, orgId, student, year = null) => (dispatch) => {
  const snapshotYear = year || getCurrentRosterYear();
  dispatch({
    type: EDIT_STUDENT_CONFIRM,
    studentId,
    orgId,
    student,
    year: snapshotYear,
  });
};

export const CLOSE_PROFILE_PICTURE = 'studentRoster.profilePicture.close';
export const profilePictureEditClose = () => (dispatch) => {
  dispatch({
    type: CLOSE_PROFILE_PICTURE,
  });
};

export const UPLOAD_PROFILE_PICTURE = 'studentRoster.profilePicture.upload';
export const uploadProfilePicture = (file, profile, studentId, year = null) => (dispatch) => {
  const snapshotYear = year || getCurrentRosterYear();
  dispatch(asyncStart(UPLOAD_PROFILE_PICTURE));
  dispatch({
    type: UPLOAD_PROFILE_PICTURE,
    file,
    profile,
    studentId,
    dispatch,
    year: snapshotYear,
  });
};

export const CHANGE_PROFILE_PICTURE = 'studentRoster.profilePicture.change';
export const changeProfilePicture = (onOff) => (dispatch) => {
  dispatch(asyncStart(CHANGE_PROFILE_PICTURE));
  dispatch({
    type: CHANGE_PROFILE_PICTURE,
    onOff,
  });
};

export const CONFIRM_PROFILE_PICTURE = 'studentRoster.profilePicture.confirm';

export const ADD_STUDENT_REQ = 'studentRoster.addStudent.request';
export const addStudentRequest = () => (dispatch) => {
  dispatch({
    type: ADD_STUDENT_REQ,
  });
};

export const ADD_STUDENT_REQ_CANCEL = 'studentRoster.addStudent.requestCancel';
export const addStudentRequestCancel = () => (dispatch) => {
  dispatch({
    type: ADD_STUDENT_REQ_CANCEL,
  });
};

export const ADD_STUDENT_CONFIRM = 'studentRoster.addStudent.confirm';
export const addStudentConfirm = (orgId, student, year = null) => (dispatch) => {
  const snapshotYear = year || getCurrentRosterYear();
  dispatch({
    type: ADD_STUDENT_CONFIRM,
    orgId,
    student,
    year: snapshotYear,
  });
};

export const CLOSE_SNACKBAR = 'studentRoster.closeSnackbar';
export const closeSnackbar = () => (dispatch) => {
  dispatch({
    type: CLOSE_SNACKBAR,
  });
};

export const EXPORT_ROSTER = 'studentRoster.exportRoster';
export const exportRoster = (snapshotYear = null) => (dispatch) => {
  dispatch(asyncStart(EXPORT_ROSTER));
  dispatch({
    type: EXPORT_ROSTER,
    snapshotYear,
  });
};

export const SET_STUDENT_PRIOR_STAAR_DATA = 'studentRoster.setStudentPriorStaarData';
export const setStudentPriorStaarData = (dataModel) => (dispatch) => {
  dispatch({
    type: SET_STUDENT_PRIOR_STAAR_DATA,
    data: dataModel,
  });
};

export const GET_STUDENT_PRIOR_STAAR_DATA = 'studentRoster.getStudentPriorStaarData';
export const getStudentPriorStaarData = (studentId, priorSnapshotYear = null) => (dispatch) => {
  dispatch(asyncStart(GET_STUDENT_PRIOR_STAAR_DATA));
  dispatch({
    type: GET_STUDENT_PRIOR_STAAR_DATA,
    data: {
      studentId: studentId,
      snapshotYear: priorSnapshotYear,
    },
  });
};
