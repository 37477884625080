import moment from 'moment';
import _ from 'lodash';
import { isRealNumber } from '../../../common/utils';

export const getCurrentRosterYear = () => {
  const currentTime = new Date();
  const month = currentTime.getMonth();
  const year = currentTime.getFullYear();
  if (month < 6) {
    return year - 1;
  }
  return year;
};

export const elOptions = {
  C: 'Current English Learner',
  F: 'First Year Monitor',
  S: 'Second Year Monitor',
  3: 'Third Year Monitor',
  4: 'Fourth Year Monitor',
  N: 'Non-LEP',
};

const AgeGroups = ['teacher', 'teen', 'child'];
const Sex = ['female', 'male'];
const Ethnicity = ['black', 'asian', 'hispanic', 'nativeAmerican', 'pacificIslander', 'white'];

export const ethnicityOption = {
  [Ethnicity[0]]: 'African American',
  [Ethnicity[1]]: 'Asian',
  [Ethnicity[2]]: 'Hispanic',
  [Ethnicity[3]]: 'Native American',
  [Ethnicity[4]]: 'Pacific Islander',
  [Ethnicity[5]]: 'White',
  multiEthnicity: 'Two or More Races',
};

export const sexOptions = {
  F: 'Female',
  M: 'Male',
};

export const yesNoOptions = {
  Y: 'Yes',
  N: 'No',
};

export const spEdOptions = {
  C: 'Currently receiving special education services',
  F: 'Formerly received special education services',
  N: ' Never reported as receiving special education',
};

export const enrollmentOptions = {
  CE: 'Continuously Enrolled',
  NC: 'Non-continuously Enrolled ',
};

export const yearsInUsOptions = {
  1: 'Current School Year',
  2: '2 school years ',
  3: '3 school years ',
  4: '4 school years ',
  5: '5 school years ',
  6: '6 school years ',
  0: 'Non-EL/+6 school years ',
};

export const gradeOptions = {
  EE: 'Early Education',
  PK: 'Pre-Kindergarten',
  KG: 'Kindergarten',
  1: '1st Grade',
  2: '2nd Grade',
  3: '3rd Grade',
  4: '4th Grade',
  5: '5th Grade',
  6: '6th Grade',
  7: '7th Grade',
  8: '8th Grade',
  9: '9th Grade',
  10: '10th Grade',
  11: '11th Grade',
  12: '12th Grade',
  13: 'Graduated',
};

export const studentInfoLabels = {
  studentId: 'STUDENT-ID',
  tsdsId: 'TSDS Unique ID',
  localId: 'Local ID',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  sexCode: 'Sex',
  dateOfBirth: 'Date of Birth',
  gradeLevel: 'Grade Level',
  gradYear: 'Graduation Date',
  campusNumber: 'Campus Number',
  ethnicity: 'Ethnicity',
  ecoDis: 'Is Economically Disadvantaged?',
  spEd: 'Receiving Special Education Services?',
  lep: 'English Learner',
  enrollment: 'Enrollment',
  snapshot: 'Enrolled on Snapshot Day?',
  yearsInUS: 'Years in US Schools',
  asylumStatus: 'Is Asylee/Refugee/SIFE?',
  tags: 'Tags',
  highlyMobile: 'Highly Mobile',
  acceleratedTesterMath: 'Accelerated Tester Math',
  acceleratedTesterReading: 'Accelerated Tester Reading',
  acceleratedTesterScience: 'Accelerated Tester Science',
  monitor: 'Monitor',
  mentor: 'Mentor',
};

export const testSubjects = {
  reading: 'Reading',
  math: 'Mathematics',
  science: 'Science',
  // writing: 'Writing',
  socialStudies: 'Social Studies',
  englishOne: 'English I',
  englishTwo: 'English II',
  algebraOne: 'Algebra',
  biology: 'Biology',
  usHistory: 'US History',
};

export function sortBy(array, order, orderBy) {
  const orderConst = order === 'desc' ? 1 : -1;
  const orderByKey = orderBy === 'name' ? 'lastName' : orderBy;
  return array.sort((a, b) => {
    let ele1 = a[1][orderByKey];
    let ele2 = b[1][orderByKey];
    if (!ele1 && !ele2) {
      return 0;
    }
    if (ele1 && !ele2) {
      return orderConst;
    }
    if (ele2 && !ele1) {
      return -orderConst;
    }
    if (orderBy === 'dateOfBirth') {
      if (moment(ele1, 'YYYYMMDD').isValid() && moment(ele2, 'YYYYMMDD').isValid()) {
        return moment(ele1, 'YYYYMMDD').diff(moment(ele2, 'YYYYMMDD')) * orderConst;
      }
    }
    if (orderBy === 'gradYear') {
      if (moment(ele1, 'MMYYYY').isValid() && moment(ele2, 'MMYYYY').isValid()) {
        return moment(ele1, 'MMYYYY').diff(moment(ele2, 'MMYYYY')) * orderConst;
      }
    }
    if (isRealNumber(ele1) && isRealNumber(ele2)) {
      ele1 = Number(ele1);
      ele2 = Number(ele2);
    }
    if (typeof ele1 === 'string' || typeof ele2 === 'string') {
      return ele1.localeCompare(ele2) * orderConst;
    }
    return (ele1 - ele2) * orderConst;
  });
}

const getSex = (student) => {
  if (student && student.sexCode) {
    if (student.sexCode === 'F') {
      return Sex[0];
    }
    return Sex[1];
  }
  return Sex[1];
};

const getAgeGroup = (student, isTeacher = false) => {
  if (isTeacher) {
    return AgeGroups[0];
  }
  if (student) {
    const grade = Number.isNaN(student.gradeLevel) ? 0 : parseInt(student.gradeLevel, 10);
    if (grade >= 9) {
      return AgeGroups[1];
    }
  }
  return AgeGroups[2];
};

export const getEthnicity = (student, isAvatar) => {
  if (student && student.indicators) {
    if (student.indicators.black) {
      return Ethnicity[0];
    }
    if (student.indicators.asian) {
      return Ethnicity[1];
    }
    if (student.indicators.hispanic) {
      return Ethnicity[2];
    }
    if (student.indicators.nativeAmerican) {
      return Ethnicity[3];
    }
    if (student.indicators.pacificIslander) {
      return Ethnicity[4];
    }
    if (student.indicators.white) {
      return Ethnicity[5];
    }
  }
  if (isAvatar) {
    if (student && student.ethnicity && student.ethnicity !== 'multiEthnicity') {
      return _.camelCase(student.ethnicity);
    }
    // return Ethnicity[Math.floor(Math.random() * Ethnicity.length)];
  }
  return 'multiEthnicity';
};

export const getStudentAvatarUrl = (student, avatars, isTeacher = false) => {
  if (!avatars) {
    return null;
  }
  if (student == null) {
    return null;
  }
  const age = getAgeGroup(student, isTeacher);
  const sex = getSex(student);
  const ethnicity = getEthnicity(student, true);
  const isAvatarNumberMissing = !student || student.avatarNumber == null;
  const avatarNumber = isAvatarNumberMissing ? 0 : student.avatarNumber;
  // Todo: below is a hack to make sure things don't break with ethnicity is "twoOrMoreRaces"
  const ethnicAvatar = avatars[ethnicity] || avatars.hispanic;
  return ethnicAvatar[age][sex][avatarNumber];
};

export const getEnrollment = (student) => {
  if (student.indicators.continuouslyEnrolled) {
    return 'CE';
  }
  return 'NC';
};

export const getSpEd = (student) => {
  if (student.indicators.specialEdCurrent) {
    return 'C';
  }
  if (student.indicators.specialEdFormer) {
    return 'F';
  }
  return 'N';
};

export const testVersions = {
  staar: 'STAAR',
  staarAlt2: 'STAAR Alt 2',
};

export const languages = {
  english: 'English',
  spanish: 'Spanish',
};

const computeGrade = (gradeLevel) => {
  if (isRealNumber(gradeLevel)) {
    return Number(gradeLevel);
  }
  return 0;
};

export const getStudentTests = (student) => {
  let tests = [];
  if (student && student.currentYearTests && !!student.currentYearTests.length && student.currentYearTests[0].tests) {
    [{ tests }] = student.currentYearTests;
  }
  return tests;
};

const AdministrationDates = {
  spring2015: '2015-03-01',
  summer2015: '2015-06-01',
  fall2015: '2015-09-01',
  spring2016: '2016-03-01',
  summer2016: '2016-06-01',
  fall2016: '2016-09-01',
  spring2017: '2017-03-01',
  summer2017: '2017-06-01',
  fall2017: '2017-09-01',
  march2017: '2017-03-01',
  april2017: '2017-04-01',
  may2017: '2017-05-01',
  june2017: '2017-06-01',
  spring2018: '2018-03-01',
  summer2018: '2018-06-01',
  fall2018: '2018-09-01',
  march2018: '2018-03-01',
  april2018: '2018-04-01',
  may2018: '2018-05-01',
  june2018: '2018-06-01',
};

const parseTests = (student) => {
  const elemSubjects = ['reading', 'math', 'writing', 'socialStudies', 'science'];
  student.tests.forEach((test) => {
    let { testedGrade } = test;
    test.campusNumber = student.campusNumber;
    test.enrolledGrade = computeGrade(student.gradeLevel);
    if (testedGrade) {
      testedGrade = computeGrade(testedGrade);
    }
    if (!elemSubjects.includes(test.subject)) {
      testedGrade = test.subject;
    }
    if (!testedGrade) {
      testedGrade = computeGrade(student.gradeLevel);
    }
    test.testedGrade = testedGrade;
  });
  return student.tests;
};

export const parseNewStudent = {
  studentId: '',
  dateOfBirth: moment('01012014', 'YYYYMMDD'),
  sexCode: 'M',
  tsdsId: '',
  localId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  gradeLevel: '',
  gradYear: moment(),
  photoUrl: {},
  campusNumber: '',
  yearsInUS: 0,
  snapshot: 'Y',
  asylumStatus: 'N',
  highlyMobile: 'N',
  ecoDis: 'N',
  ethnicity: 'W',
  enrollment: 'CE',
  spEd: 'N',
  currentYearTests: [],
  tests: [],
  lep: 'N',
  avatarNumber: Math.round(Math.random()),
  monitor: 'N',
  mentor: '',
  acceleratedTester: 'N',
};

export const parseStudentTestsToDb = (student) => {
  let currentYearTests = [];
  const tests = parseTests(student);

  if (student && student.currentYearTests && !!student.currentYearTests.length && student.currentYearTests[0].tests) {
    ({ currentYearTests } = student);
    currentYearTests[0].tests = tests;
  } else {
    currentYearTests.push({
      testDate: AdministrationDates.spring2018,
      administrationDate: AdministrationDates.spring2018,
      tests,
    });
  }

  return currentYearTests;
};

export const parseStudentUiToDb = (student) => ({
  studentId: student.studentId != null ? student.studentId : null,
  tsdsId: student.tsdsId != null ? student.tsdsId : null,
  localId: student.localId != null ? student.localId : null,
  firstName: student.firstName,
  middleName: student.middleName,
  lastName: student.lastName,
  gradeLevel: student.gradeLevel,
  gradYear: student.gradYear ? moment(student.gradYear).format('MMYYYY') : '',
  campusNumber: student.campusNumber,
  yearsInUS: student.yearsInUS || '',
  lep: student.lep,
  sexCode: student.sexCode,
  dateOfBirth: student.dateOfBirth ? moment(student.dateOfBirth).format('YYYYMMDD') : '',
  snapshot: student.snapshot === 'Y',
  asylumStatus: student.asylumStatus === 'Y',
  indicators: {
    continuouslyEnrolled: student.enrollment === 'CE',
    nonContinuouslyEnrolled: student.enrollment === 'NC',
    hispanic: student.ethnicity === Ethnicity[2],
    nativeAmerican: student.ethnicity === Ethnicity[3],
    asian: student.ethnicity === Ethnicity[1],
    black: student.ethnicity === Ethnicity[0],
    pacificIslander: student.ethnicity === Ethnicity[4],
    white: student.ethnicity === Ethnicity[5],
    twoOrMoreRaces: student.ethnicity === 'multiEthnicity',
    ecoDis: student.ecoDis === 'Y',
    specialEdCurrent: student.spEd === 'C',
    specialEdFormer: student.spEd === 'F',
    lepCurrent: student.lep === 'C',
    lepMonitored: ['F', 'S', '3', '4', '1', '2'].includes(student.lep),
    highlyMobile: student.highlyMobile === 'Y',
    acceleratedTester: student.acceleratedTester === 'Y',
  },
  currentYearTests: parseStudentTestsToDb(student),
  avatarNumber: student.avatarNumber,
  tags: student.tags ? student.tags : [],
  monitor: student.monitor === 'Y',
  mentor: student.mentor != null ? student.mentor : null,
});

export const parseStudentDbToForm = (student = null) => {
  if (!student) {
    return parseNewStudent;
  }
  let lep;
  if (!student.lep) {
    lep = 'N';
  } else if (student.lep === 'Y') {
    lep = 'C';
  } else {
    ({ lep } = student);
  }
  const isAvatarNumberMissing = student.avatarNumber == null;
  const avatarNumber = isAvatarNumberMissing ? 0 : student.avatarNumber;
  const dateOfBirth = student.dateOfBirth ? moment(student.dateOfBirth, 'YYYYMMDD').toDate() : null;
  let gradYear = student.gradYear || null;
  if (student.gradYear) {
    if (student.gradYear.length === 4) {
      gradYear = `05${student.gradYear}`;
    } else if (student.gradYear.length === 5) {
      gradYear = `0${student.gradYear}`;
    }
  }
  gradYear = gradYear ? moment(gradYear, 'MMYYYY').toDate() : null;
  return {
    dateOfBirth,
    gradYear,
    gradeLevel: student.gradeLevel || '',
    sexCode: student.sexCode || '',
    studentId: student.studentId,
    tsdsId: student.tsdsId,
    localId: student.localId,
    firstName: student.firstName,
    middleName: student.middleName,
    lastName: student.lastName,
    photoUrl: student.photoUrl,
    campusNumber: student.campusNumber,
    yearsInUS: student.yearsInUS,
    snapshot: student.snapshot ? 'Y' : 'N',
    asylumStatus: student.asylumStatus ? 'Y' : 'N',
    ecoDis: student.indicators.ecoDis ? 'Y' : 'N',
    highlyMobile: student.indicators.highlyMobile ? 'Y' : 'N',
    acceleratedTester: student.indicators.acceleratedTester ? 'Y' : 'N',
    ethnicity: getEthnicity(student),
    enrollment: getEnrollment(student),
    spEd: getSpEd(student),
    currentYearTests: student.currentYearTests,
    tests: getStudentTests(student),
    avatarNumber,
    lep,
    tags: student.tags ? student.tags : [],
    monitor: student.monitor ? 'Y' : 'N',
    mentor: student.mentor,
  };
};

export const parseStudentDbToUi = (student) => {
  let lep;
  if (!student.lep) {
    lep = 'N';
  } else if (student.lep === 'Y') {
    lep = 'C';
  } else {
    ({ lep } = student);
  }
  const isAvatarNumberMissing = student.avatarNumber == null;
  const avatarNumber = isAvatarNumberMissing ? 0 : student.avatarNumber;

  let gradYear = student.gradYear || null;
  if (gradYear) {
    if (gradYear.length === 4) {
      gradYear = `05${gradYear}`;
    } else if (gradYear.length === 5) {
      gradYear = `0${gradYear}`;
    }
    gradYear = moment(gradYear, 'MMYYYY').format('MM/YYYY');
  }

  return {
    studentId: student.studentId,
    dateOfBirth: student.dateOfBirth || '',
    sexCode: student.sexCode || '',
    tsdsId: student.tsdsId,
    localId: student.localId,
    firstName: student.firstName,
    middleName: student.middleName,
    lastName: student.lastName,
    gradeLevel: student.gradeLevel,
    photoUrl: student.photoUrl && student.photoUrl.small ? student.photoUrl.small : '',
    gradYear,
    campusNumber: student.campusNumber,
    yearsInUS: yearsInUsOptions[student.yearsInUS || 0],
    lep: elOptions[lep],
    snapshot: student.snapshot ? 'Yes' : 'No',
    asylumStatus: student.asylumStatus ? 'Yes' : 'No',
    ecoDis: student.indicators.ecoDis ? 'Yes' : 'No',
    highlyMobile: student.indicators.highlyMobile ? 'Yes' : 'No',
    acceleratedTesterMath: student.indicators.acceleratedTesterMath ? 'Yes' : 'No',
    acceleratedTesterReading: student.indicators.acceleratedTesterReading ? 'Yes' : 'No',
    acceleratedTesterScience: student.indicators.acceleratedTesterScience ? 'Yes' : 'No',
    ethnicity: getEthnicity(student),
    enrollment: enrollmentOptions[getEnrollment(student)],
    spEd: spEdOptions[getSpEd(student)],
    currentYearTests: student.currentYearTests,
    tests: getStudentTests(student),
    avatarNumber,
    monitor: student.monitor ? 'Yes' : 'No',
    mentor: student.mentor,
  };
};
