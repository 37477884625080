import firebase from 'firebase';
import { asyncStart, asyncFinish, asyncError } from '../../../common';
import { Routes } from '../../../App';

export const INIT_ONBOARDING = 'onboarding.init';
export const initOnBoarding = (user, history) => async (dispatch) => {
  dispatch(asyncStart(INIT_ONBOARDING));
  try {
    const status = await firebase
      .database()
      .ref(`newDatabase/organizations/${user.organizationId}/onboardingStatus`)
      .once('value')
      .then((snapshot) => snapshot.val());
    // calculate current step
    let currentStep = `/${Routes.onboarding}/${Routes.welcome}`;
    if (status && status[Routes.welcome]) {
      currentStep = `/${Routes.onboarding}/${Routes.studentUpload}`;
      if (status[Routes.studentUpload]) {
        currentStep = `/${Routes.onboarding}/${Routes.assessments}`;
        if (status[Routes.assessments]) {
          currentStep = `/${Routes.onboarding}/${Routes.takeOff}`;
          if (status[Routes.takeOff]) {
            currentStep = `/${Routes.roster}`;
          }
        }
      }
    }
    // Todo: Re-enable this after onboarding development is complete
    // if (!status || !status[Routes.takeOff]) {
    //   history.push(currentStep);
    // }
    dispatch(asyncFinish(INIT_ONBOARDING, { status }));
  } catch (err) {
    dispatch(asyncError(INIT_ONBOARDING, 'Get onboarding status from firebase db', err));
    // Todo: error messages
    throw err;
  }
};

export const MARK_COMPLETED = 'onboarding.markCompleted';
export const markCompleted = (step, next, history) => (dispatch, getState) => {
  dispatch(asyncStart(MARK_COMPLETED));
  const { profile } = getState().modules.Auth.auth;
  const currentState = getState().modules.OnBoarding.onBoarding.status;
  currentState[step] = true;
  const updates = {
    [`newDatabase/organizations/${profile.organizationId}/onboardingStatus`]: currentState,
  };
  firebase
    .database()
    .ref()
    .update(updates, (error) => {
      if (error) {
        dispatch(asyncError(MARK_COMPLETED, 'Update firebase db', error));
      } else {
        history.push(next);
        dispatch(asyncFinish(MARK_COMPLETED, { step }));
      }
    });
};
