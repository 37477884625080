import moment from 'moment';
import { checkIfSuccess } from '../../AssessmentChecklist';

const getRoster = (roster, year) => ({
  status: roster.status,
  file: {
    name: year,
    status: roster.status,
  },
  latestUpdate: {
    name: 'Latest Update',
    status: moment(roster.latestUpdate)
      .utc()
      .format('YYYY-MM-DD, HH:mm'),
  },
  studentsUpdated: {
    name: 'Students Updated',
    status: roster.studentsUpdated,
  },
});

const getSAT = (sat, year) => ({
  status: sat && sat.file,
  name: 'CCMR Tracking',
  file: {
    name: year,
    status: sat ? sat.file : false,
  },
  count: {
    name: 'Student Found',
    status: sat.count || 0,
  },
});

const getSTAAR = (assessments, generalStatus) => ({
  staar: {
    name: 'STAAR Grades',
    status: generalStatus,
    assessments: {
      primary38: {
        name: '3-8',
        status: assessments.primary38,
      },
      primary58: {
        name: '5-8',
        status: assessments.primary58,
      },
      retest58: {
        name: '5-8 Retest',
        status: assessments.retest58,
      },
    },
  },
});
const getSTAARAlt = (assessments, generalStatus) => ({
  staarAlt: {
    name: 'STAAR Alternate Grades',
    status: generalStatus,
    assessments: {
      april38: {
        name: 'April 3-8',
        status: assessments.april38,
      },
    },
  },
});
const getEOC = (assessments, generalStatus) => ({
  eoc: {
    name: 'STAAR End of Course',
    status: generalStatus,
    assessments: {
      summer: {
        name: 'Summer',
        status: assessments.summer,
      },
      winter: {
        name: 'Winter',
        status: assessments.winter,
      },
      spring: {
        name: 'Spring',
        status: assessments.spring,
      },
    },
  },
});
const getEOCAlt = (assessments, generalStatus) => ({
  eocAlt: {
    name: 'STAAR End of Course Alternate',
    status: generalStatus,
    assessments: {
      spring: {
        name: 'Spring',
        status: assessments.spring,
      },
    },
  },
});
const getTelpas = (assessments, generalStatus) => ({
  telpas: {
    name: 'TELPAS',
    status: generalStatus,
    assessments: {
      spring: {
        name: 'Spring',
        status: assessments.spring,
      },
    },
  },
});
const getCCMR = (ccmr) => ({
  ccmr: {
    name: 'CCMR',
    // status: (ccmr && sat) && ccmr.file && sat.file,
    status: ccmr && ccmr.file,
    assessments: {
      file: {
        name: 'Student Listing',
        status: ccmr ? ccmr.file : false,
      },
      // sat: {
      //   name: 'SAT',
      //   status: sat ? sat.file : false,
      // },
    },
  },
});

const getGradData = (assessments, generalStatus) => ({
  gradData: {
    name: 'Grad Data Information',
    status: generalStatus,
    assessments: {
      fourYear: {
        name: '4 Year Longdutional',
        status: assessments.fourYear,
      },
      fiveYear: {
        name: '5 Year Longdutional',
        status: assessments.fiveYear,
      },
      sixYear: {
        name: '6 Year Longdutional',
        status: assessments.sixYear,
      },
    },
  },
});

export const checkListMapper = (checkList, isLastGradeAvailable) => {
  const result = {};
  if (!checkList) {
    return {
      success: false,
      message: 'Upload Assessmen!!!',
    };
  }
  result.roster = getRoster(checkList.roster, '2019');
  Object.entries(checkList).forEach(([year, cList]) => {
    if (year !== 'roster') {
      const eocCheck = checkIfSuccess(cList.eoc);
      const eocAltCheck = checkIfSuccess(cList.eocAlt);
      const staarCheck = checkIfSuccess(cList.staar);
      const staarAltCheck = checkIfSuccess(cList.staarAlt);
      const telpasCheck = checkIfSuccess(cList.telpas);
      result[year] = {
        ...getEOC(cList.eoc, eocCheck),
        ...getEOCAlt(cList.eocAlt, eocAltCheck),
        ...getSTAAR(cList.staar, staarCheck),
        ...getSTAARAlt(cList.staarAlt, staarAltCheck),
        ...getTelpas(cList.telpas, telpasCheck),
      };
      if (isLastGradeAvailable) {
        result[year] = {
          ...result[year],
          ...getCCMR(cList.ccmr),
          ...getGradData(cList.gradData, checkIfSuccess(cList.gradData)),
        };
        result.ccmrTracking = getSAT(cList.sat, year);
      }
    }
  });
  return {
    success: true,
    checkList: result,
  };
};

export const isCCMRandGradDataAvailable = (schoolList) => {
  const list = Object.values(schoolList).filter((sc) => sc.gradeEnd === '12');
  return schoolList && !!list.length;
};

export default 'assessementChecklist.mapper.js';
