import parse from 'csv-parse';
const STAAR2021CustomCols = [
  'Adm_Date',
  'GradeLvl_Tested',
  'ESCReg_Num',
  'CDCno',
  'District',
  'Campus',
  'L_Name',
  'F_Name',
  'MI',
  'Student_ID',
  'Sex',
  'DOB',
  'Blank_1',
  'Hispanic_Latino',
  'AM_Indian_Alaskan',
  'Asian',
  'Black_African_American_Code',
  'Native_HI_Pacific_Isl',
  'White',
  'Ethnic_Race_Rep_Cat',
  'ED',
  'Title_1_Part_A',
  'Migrant',
  'Blank_2',
  'LEP',
  'Bilingual',
  'ESL',
  'Blank_3',
  'Special_Ed',
  'Blank_4',
  'GT',
  'At_Risk',
  'Blank_5',
  'Local_ID',
  'Doc_no',
  'Enrolled_Grade',
  'RUI',
  'TMIP',
  'Blank_6',
  'New_to_TX',
  'TX_UNIQUE_STUDENT_ID',
  'LCL_Reading',
  'LCL_Math',
  'LCL_Writing',
  'LCL_SS',
  'LCL_Science',
  'ACY_Reading',
  'ACY_Math',
  'ACY_Writing',
  'ACY_SS',
  'ACY_Science',
  'EOC_Above_Reading',
  'EOC_Above_Math',
  'EOC_Above_SS',
  'EOC_Above_Science',
  'Blank_7',
  'R_DS',
  'R_Braille',
  'R_LP',
  'R_Oral_Acc',
  'R_XD',
  'Blank_8',
  'R_TTS',
  'Blank_9',
  'R_Content_and_Lang',
  'R_ASL_Signed_Video',
  'Blank_10',
  'M_DS',
  'M_Braille',
  'M_LP',
  'M_Oral_Acc',
  'M_XD',
  'M_Basic_Cal',
  'Blank_11',
  'M_TTS',
  'Blank_12',
  'M_Content_and_Lang',
  'M_ASL_Signed_Video',
  'Blank_13',
  'Wri_DS',
  'Wri_Braille',
  'Wri_LP',
  'Wri_XD',
  'Wri_Oral_Acc',
  'Wri_TTS',
  'Wri_ASL_Signed_Video',
  'Wri_STT',
  'Wri_Content_and_Lang',
  'Wri_Spelling',
  'Blank_15',
  'SSDS',
  'SSBraille',
  'SSLP',
  'SS_Oral_Acc',
  'SSXD',
  'Blank_16',
  'SSTTS',
  'Blank_17',
  'SSContent_and_Lang',
  'SS_ASL_Signed_Video',
  'Blank_18',
  'Sci_DS',
  'Sci_Braille',
  'Sci_LP',
  'Sci_Oral_Acc',
  'Sci_XD',
  'Sci_Basic_Cal',
  'Blank_19',
  'Sci_TTS',
  'Blank_20',
  'Sci_Content_and_Lang',
  'Sci_ASL_Signed_Video',
  'Blank_21',
  'SCR',
  'SCM',
  'SCWri',
  'SCSS',
  'SCSci',
  'SCDefault_R',
  'SCDefault_M',
  'SCDefault_Wri',
  'SCDefault_SS',
  'SCDefault_Sci',
  'Blank_22',
  'R_Cat_1',
  'R_Raw',
  'R_Scale',
  'R_Lang_Ver',
  'R_Test_Ver',
  'R_Test_VerMode',
  'Blank_23',
  'R_Meets',
  'R_Approaches',
  'R_Masters',
  'M_Cat_1',
  'Blank_32',
  'M_Raw',
  'M_Scale',
  'M_Lang_Ver',
  'M_Test_Ver',
  'M_Test_VerMode',
  'Blank_33',
  'M_Meets',
  'M_Approaches',
  'M_Masters',
  'Wri_Cat_1',
  'Wri_Raw',
  'Wri_Scale',
  'Wri_Lang_Ver',
  'Wri_Test_Ver',
  'Wri_Mode',
  'Blank_42',
  'Wri_Test_Form_Vrsn',
  'Wri_Meets',
  'Wri_Approaches',
  'Wri_Masters',
  'Wri_Comp_Score',
  'Blank_43',
  'SS_Cat_1',
  'SS_Raw',
  'SS_Scale',
  'SS_Lang_Ver',
  'SS_Test_Ver',
  'SS_Mode',
  'Blank_48',
  'SSMeets',
  'SSApproaches',
  'SSMasters',
  'Sci_Cat_1',
  'Sci_Raw',
  'Sci_Scale',
  'Sci_Lang_Ver',
  'Sci_Test_Ver',
  'Sci_Mode',
  'Blank_54',
  'Sci_Meets',
  'Sci_Approaches',
  'Sci_Masters',
];

const isSTAAR2021Custom = (file) =>
  new Promise((resolve) => {
    if (file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
      //text/csv'
      resolve(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      parse(text, {}, (err, output) => {
        if (err) {
          resolve(false);
          return;
        }
        if (JSON.stringify(output[0].filter((x) => x)) === JSON.stringify(STAAR2021CustomCols)) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    };
    reader.readAsText(file);
  });

export default isSTAAR2021Custom;
